import { PROFILE_ACTION_TYPE } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case PROFILE_ACTION_TYPE.EDIT_PROFILE_START:
      return {
        loading: true,
      };
    case PROFILE_ACTION_TYPE.EDIT_PROFILE_SUCCESS:
      return {
        loading: false,
        showAlert: true,
        success: true,
        ...payload,
      };
    case PROFILE_ACTION_TYPE.EDIT_PROFILE_ERROR:
      return {
        loading: false,
        showAlert: true,
        error: "Ha ocurrido un error",
        ...payload,
      };
    case PROFILE_ACTION_TYPE.EDIT_PROFILE_END:
      return {
        loading: false,
        showAlert: false,
      };

    case PROFILE_ACTION_TYPE.PROFILE_PICTURE_START:
      return {
        loading: true,
      };
    case PROFILE_ACTION_TYPE.PROFILE_PICTURE_SUCCESS:
      return {
        loading: false,
        showAlert: true,
        success: true,
        ...payload,
      };
    case PROFILE_ACTION_TYPE.PROFILE_PICTURE_ERROR:
      return {
        loading: false,
        showAlert: true,
        error: "Ha ocurrido un error",
        ...payload,
      };
    case PROFILE_ACTION_TYPE.PROFILE_PICTURE_END:
      return {
        loading: false,
        showAlert: false,
      };

    case PROFILE_ACTION_TYPE.CLEAR_ERROR:
      return {
        showAlert: false,
        error: false,
        status: null,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
