import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PreviewFlashcardsList from "../../components/PreviewFlashcardsList";
import AuthenticateLayout from "../../layouts/AuthenticateLayout";
import { cmsAPI } from "../../utils/http-client";
import { useEditViewTopic } from "../editflashcards/selectors";
import { useSubject } from "../subjectProfile/selectors";

const FlashcardsPreviewView = ({ ...props }) => {
  let topicId;
  const route = useRouteMatch();
  const history = useHistory();

  const activeTopic = useEditViewTopic();
  const activeSubject = useSubject();
  const [loading, setLoading] = useState(false);
  const [flashcards, setFlashcards] = useState([]);
  const [topic, setTopic] = useState({});
  const [error, setError] = useState(false);
  const [delteSuccess, setDelteSuccess] = useState(false);
  const [delteError, setDelteError] = useState(false);
  const [deletingFlashcard, setDeletingFlashcard] = useState(false);
  const getFlashcards = (id) => {
    cmsAPI
      .get(`flashcards?topic=${id}`)
      .then(({ data }) => {
        console.log("Flashcards Preview:", data);
        if (data != null && data.length) {
          if (data[0]?.topic) {
            setTopic(data[0].topic);
          }
        }
        setFlashcards(data);
      })
      .catch((err) => {
        console.log("Error flashcard preview:", err);
        setFlashcards([]);
      });
  };
  const deleteFlashcardByID = (flashcardId) => {
    setDeletingFlashcard(true);
    cmsAPI
      .delete(`flashcards/${flashcardId}`)
      .then(({ data }) => {
        let indexFC = flashcards.findIndex((f) => f.id == data.id);
        // console.log("INDEXFC",indexFC);
        flashcards.splice(indexFC, 1);
        setFlashcards(flashcards);
        setDeletingFlashcard(false);
        setDelteSuccess(true);
      })
      .catch((err) => {
        setDeletingFlashcard(false);
        setDelteError(true);
      });
  };
  useEffect(() => {
    // console.log("Route", route);
    if (!route?.params?.topicId) return history.push("/dashboard");
    topicId = route.params.topicId;
    getFlashcards(route.params.topicId);
    setTimeout(() => {
      setDeletingFlashcard(false);
      setDelteSuccess(false);
      setDelteError(false);
    }, 2000);
  }, [route, delteSuccess]);

  return (
    <AuthenticateLayout>
      <div>
        <Breadcrumb>
          <BreadcrumbItem
            onClick={() => {
              history.push(`/dashboard`);
            }}
          >
            <span style={{ cursor: "pointer" }} className="text-primary">
              Inicio
            </span>
          </BreadcrumbItem>
          {activeSubject?.id ? (
            <BreadcrumbItem
              onClick={() => {
                history.push(`/subjects/${activeSubject?.id}`);
              }}
            >
              <span style={{ cursor: "pointer" }} className="text-primary">
                {activeSubject?.name || "..."}
              </span>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          {activeTopic?.id ? (
            <BreadcrumbItem
              onClick={() => {
                history.push(
                  `/subjects/${activeSubject?.id}/edit/${activeTopic?.id}`
                );
              }}
            >
              <span style={{ cursor: "pointer" }} className="text-primary">
                {activeTopic?.name || "..."}
              </span>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          <BreadcrumbItem active>Flashcards</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="text-center">
          Lista de flashcards {topic != {} && topic?.name && `: ${topic.name}`}
        </h1>
        <div>
          <PreviewFlashcardsList
            deleting={deletingFlashcard}
            deletingSuccess={delteSuccess}
            deletingError={delteError}
            deleteAction={(flashcardId) => {
              deleteFlashcardByID(flashcardId);
            }}
            review={true}
            flashcards={flashcards}
          ></PreviewFlashcardsList>
        </div>
      </div>
    </AuthenticateLayout>
  );
};

export default FlashcardsPreviewView;
