import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import {
  Button,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import DropDownCustom from "../../../components/DropDownCustom";
import ModalView from "../../../components/ModalView";
import { ThemeColors } from "../../../lib/Colors";
import { useFlashcardIndexActive } from "../selectors";
import DeleteFlashcardModal from "./ConfirmDeleteFlashcard";
import SaveFlashcard from "./SaveFlashcard";
const HeaderActions = ({
  duplicateAction = () => {},
  nextAction,
  reviewAction,
  cloneAction,
  nameValue,
  subtitleValue = "",
  saveAction,
  usavedChangesFlag,
  onChangeName,
  onChangeSubtitle,
  autosave,
  ...props
}) => {
  const dispatch = useDispatch();
  const indexActive = useFlashcardIndexActive();
  const [localName, setLocalName] = useState(nameValue || "");
  const [localSubtitle, setLocalSubtitle] = useState(subtitleValue || "");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpenSubtitle, setTooltipOpenSubtitle] = useState(false);
  const toggleSubtitleTooltip = () => {
    setTooltipOpenSubtitle(!tooltipOpenSubtitle);
  };
  useEffect(() => {
    if (subtitleValue) setShowSubtitle(true);
    else setShowSubtitle(false);
    return () => {};
  }, [indexActive, subtitleValue]);
  useEffect(() => {
    //console.log("Name", nameValue);
    setLocalName(nameValue);
    setLocalSubtitle(subtitleValue);
    if (subtitleValue) setShowSubtitle(true);
    else setShowSubtitle(false);
  }, [nameValue, subtitleValue]);
  return (
    <div className="d-flex mb-2">
      <div className="mr-auto row align-items-center">
        <h3 className="col">Flashcard:#{parseInt(indexActive) + 1 || "..."}</h3>
        <Input
          onChange={(e) => {
            autosave()
            let value = e.target.value;
            onChangeName(value);
            setLocalName(value);
          }}
          value={localName}
          className="col"
          type="text"
          name="flashcardName"
          id="flashcardName"
          placeholder="nombre de carta (opcional)"
        />
        <i
          id="TooltipCardName"
          style={{ color: ThemeColors.primaryBg }}
          className="far fa-question-circle ml-2"
        >
          <Tooltip
            placement="right"
            isOpen={tooltipOpen}
            target="TooltipCardName"
            toggle={toggle}
          >
            Nombre de la carta (opcional)
          </Tooltip>
        </i>
        <div className="d-flex align-align-items-center ml-4">
          {showSubtitle && (
            <Input
              onChange={(e) => {
                autosave()
                let value = e.target.value;
                onChangeSubtitle(value);
                setLocalSubtitle(value);
              }}
              value={localSubtitle}
              className="col ml-2"
              type="text"
              name="flashcardSubtitle"
              id="flashcardSubtitle"
              placeholder="subtitulo"
            />
          )}
          <i
            onClick={() => {
              if (showSubtitle == true && subtitleValue) {
                onChangeSubtitle("");
                setLocalSubtitle("");
              }
              setShowSubtitle((prevState) => !prevState);
            }}
            id="TooltipCardSubtitle"
            style={{ color: ThemeColors.primaryBg }}
            className={showSubtitle ? "fas fa-minus ml-2" : "fas fa-plus ml-2"}
          >
            <Tooltip
              placement="right"
              isOpen={tooltipOpenSubtitle}
              target="TooltipCardSubtitle"
              toggle={toggleSubtitleTooltip}
            >
              {showSubtitle
                ? "Quitar subtitulo"
                : "Agregar subtitulo de la flashcard"}
            </Tooltip>
          </i>
        </div>
      </div>
      <div className="ml-auto">
        <div className="d-flex justify-content-end">
          <SaveFlashcard
            saveAction={saveAction}
            usavedChangesFlag={usavedChangesFlag}
          ></SaveFlashcard>
          <ReactTooltip />
          <Button data-tip="Clonar esta flashcard" size="sm" onClick={duplicateAction} color="primary ml-2">
            <i className="fas fa-clone"></i>
          </Button>
          <Button size="sm" onClick={reviewAction} color="primary ml-2">
            <i className="fas fa-eye"></i>
          </Button>
          <DeleteFlashcardModal
            hideToggleComponent
            toggleClassName={"ml-2"}
            toggleSize={"sm"}
          ></DeleteFlashcardModal>
          <Button
            size="sm"
            className="ml-3"
            onClick={nextAction}
            color="success"
          >
            <i className="fas fa-forward"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default HeaderActions;
