import React, { Component, useEffect, useState, createRef } from "react";
import TopicCard from "../../../components/TopicCard";
import { getUser } from "../../../lib/localstorage";
import { cmsAPI } from "../../../utils/http-client";
import { Link } from "react-router-dom";
import { SubjectListSkeleton } from "../../../components/SubjectListSkeleton";
import { SubjectSkeleton } from "../../../components/SubjectSkeleton";
import { notify } from "../../../components/AlertNotify";
import NotificationAlert from "react-notification-alert";

export const MyFlashCards = (props) => {
  const notificationAlert = createRef();
  const [UserTopics, setUserTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);

  const [AlertModalOpen, setAlertModalOpen] = useState(false);

  useEffect(() => {
    //console.log("Fetching MyFlashcards");
    const user = getUser();
    //?user_topic=${user.id}
    cmsAPI
      .get(`/usertopics/mine`)
      .then((res) => {
        //console.log("TopicsUSER>", res);
        setUserTopics(res.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log("ErrorUT", err);
        setRequestError(true);
        setLoading(false);
      });
  }, []);
  //console.log("TopicsList", UserTopics);
  if (UserTopics < [0] && loading) {
    return <SubjectSkeleton />;
  }
  return (
    <div>
      <NotificationAlert ref={notificationAlert} />
      <div className="shadow mb-3 p-3 bg-white rounded">
        <div className="text-center mb-3">
          <div>
            <h1 className="text-center m-4"> Mis Flashcards </h1>
          </div>
          <div className="m-4">
            <Link to={"/myflashcards/create"} className="btn btn-primary">
              Crear flashcards
            </Link>
          </div>
          <div>
            <hr className="mt-2" style={{ width: "70%" }} />
            <hr className="mb-5" style={{ width: "30%" }} />
          </div>
        </div>
        {UserTopics >= [0] ? (
          <div>
            {UserTopics.map((usertopic, index) => (
              <TopicCard
                inMyFlashcards
                onClickDelete={() => {
                  //console.log("Delete<>",usertopic);
                  cmsAPI
                    .delete(`/usertopics/deleteMyTopic/${usertopic.id}`)
                    .then((res) => {
                      //console.log("Deleted Success");
                      notify(
                        "success",
                        "Se ha borrado exitosamente",
                        notificationAlert
                      );
                      setTimeout(() => window.location.reload(false), 1500);
                    })
                    .catch((err) => {
                      notify(
                        "danger",
                        "Ocurrió un error al intentar borrarlo",
                        notificationAlert
                      );
                      //console.log("Deleted Error");
                    });
                }}
                onClickEdit={() => {
                  props.history.push(`/myflashcards/edit/${usertopic.id}`);
                }}
                buttonOnClick={() => {
                  props.history.push(`/myflashcards/match/${usertopic.id}`);
                }}
                btnTextMyFlashcard={true}
                key={index}
                {...usertopic.topic}
              />
            ))}
          </div>
        ) : (
          <div className="text-center">
            <h3>No tienes flashcards</h3>
            <Link to="/subjects" className="btn btn-outline-primary">
              Explorar materias
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
