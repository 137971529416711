import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import DropDownDots from "../../../components/DropDownDots";
import ImagePicker from "../../../components/ImagePicker";
import ModalView from "../../../components/ModalView";
import { ProgressPie } from "../../../components/ProgressPie";
import { ShareSubjectButton } from "../../../components/DropDownDots/ShareButtons";
import CreateSubjectModal from "../../../components/CreateSubjectModal/";

import { ThemeColors } from "../../../lib/Colors";
import { completeName } from "../../../lib/SolveUserName";
import {
  useSuperbmindUser,
  useUserJWT,
  useUserRole,
  useUserRoleType,
  useUserIsAdmin,
  useUser,
} from "../../../redux/selectors";
import { cmsAPI } from "../../../utils/http-client";
import { editSubjectName, editSubjectClear, removeSubject } from "../actions";
import {
  useRemoveSubjectError,
  useRemoveSubjectLoading,
  useRemoveSubjectSuccess,
  useSubject,
  useSubjectEditLoading,
  useSubjectEditSuccess,
} from "../selectors";
import { toggleModal } from "../../../components/CreateSubjectModal/actions";
import { isAdmin } from "../../../utils/isAdmin";

const SubjectProfileHeader = ({
  selectedTopics,
  selectedFlashcardsCount,
  ...props
}) => {
  const subject = useSubject();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const user = useUserJWT();
  const userData = useUser();
  const removeSubjectLoading = useRemoveSubjectLoading();
  const removeSubjectSuccess = useRemoveSubjectSuccess();
  const removeSubjectError = useRemoveSubjectError();
  const successEditing = useSubjectEditSuccess();
  const loadingEditing = useSubjectEditLoading();
  const userRole = useUserRoleType();
  const superbmindUser = useSuperbmindUser();
  const [activeModal, setActiveModal] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [subjectImage, setSubjectImage] = useState([]);
  const [isAdminFlag, setIsAdmin] = useState(false);
  const userAdminFlag = useUserIsAdmin();
  const imAdmin = useUserIsAdmin();
  const [subjectForm, setSubjectForm] = useState({
    name: "",
    description: "",
  });
  const toggleEditModal = () => {
    setActiveModal(!activeModal);
  };
  const saveSubject = () => {
    let subjectToEdit = {};
    if (user)
      if (subjectName === subject.name && !subjectImage) {
        toggleEditModal();
      }
    if (subjectName != subject.name && subjectName) {
      subjectToEdit.name = subjectName;
    }
    if (subjectImage != subject.image && subjectImage) {
      subjectToEdit.image = subjectImage;
    }
    dispatch(editSubjectName(subject.id, subjectToEdit));
  };
  useEffect(() => {
    if (!successEditing) return;
    setActiveModal(false);
    dispatch(editSubjectClear());
  }, [successEditing]);
  useEffect(() => {
    setIsAdmin(userAdminFlag);
  }, [userAdminFlag]);
  useEffect(() => {
    if (!subject) return;
    setSubjectForm(subject);
    setSubjectName(subject.name);
  }, [subject]);
  const toggleDelete = () => {
    setOpenDelete(!openDelete);
  };
  const deleteAction = () => {
    if (!subject) return;
    dispatch(removeSubject(subject.id));
  };
  useEffect(() => {
    if (removeSubjectSuccess == true) {
      history.push("/dashboard");
    }
    return () => {};
  }, [removeSubjectSuccess]);
  const { name, description, image } = subject;
  return (
    <div className="mb-2">
      <div className="row">
        <div className="col-4 col-lg-2 ">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <img
                src={
                  image
                    ? cmsAPI.resolveAssetAPI(image.url)
                    : "/images/image420.png"
                }
                className="rounded mx-auto d-block float-right"
                alt="subject logo"
                style={{
                  height: "10vw",
                  width: "10vw",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-5 col-lg-7 d-flex flex-column">
          <h4
            data-toggle="tooltip"
            data-placement="top"
            title={name}
            style={styles.titleStyle}
          >
            {name}
          </h4>
          <p className="d-flex">
            Creador:
            <Link to="#">
              {completeName(subject.owner)}
              {subject?.owner?.verified && (
                <img
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Verificado"
                  className="ml-2"
                  src="/assets/img/verified.png"
                  style={{ width: "15px" }}
                />
              )}
            </Link>
            <span className="ml-3">{`${0} de ${
              selectedFlashcardsCount || 0
            } flashcards estudiadas`}</span>
          </p>
          <div className="d-flex flex-row">
            {subject.imOwner ||
              imAdmin ||
              (subject.imOwner && (
                <div>
                  <Button
                    color={
                      selectedTopics != null && selectedTopics.length > 0
                        ? "primary"
                        : "link"
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {selectedTopics != null && selectedTopics.length > 0
                      ? "Estudiar " + selectedTopics.length + " Pilas "
                      : ""}{" "}
                    <i
                      style={{
                        fontSize: "1.5em",
                        marginLeft:
                          selectedTopics != null && selectedTopics.length > 0
                            ? 5
                            : 0,
                        color:
                          selectedTopics != null && selectedTopics.length > 0
                            ? "white"
                            : ThemeColors.primary,
                      }}
                      className="fas fa-play"
                    />
                  </Button>
                </div>
              ))}
            <div>
              <ShareSubjectButton />
            </div>
            <div>
              <CreateSubjectModal
                toggle={() => setActiveModal(!activeModal)}
                subjectToEdit={subject}
                editMode
                isOpen={activeModal}
              />
              <ModalView
                hideComponent
                confirmComponent={
                  <Button onClick={deleteAction} color="danger">
                    Eliminar
                  </Button>
                }
                toggleAction={toggleDelete}
                toggleFlag={openDelete}
                centered
                title="Eliminar materia"
              >
                <h2>¿estás seguro que quieres eliminar esta materia?</h2>
                {removeSubjectLoading && (
                  <div className="text-center">
                    <Loading type="spin" color={ThemeColors.primary}></Loading>
                  </div>
                )}
                {removeSubjectSuccess && <p class="text-success">Eliminado</p>}
                {removeSubjectError && (
                  <p class="text-danger">Error al eliminar</p>
                )}
              </ModalView>
              {(isAdminFlag || subject.imOwner) && (
                <DropDownDots
                  dropdownOptions={[
                    {
                      show: true,
                      label: "Editar",
                      action: () => {
                        setActiveModal(!activeModal);
                      },
                    },
                    {
                      show: true,
                      label: "Eliminar",
                      action: () => {
                        toggleDelete();
                      },
                    },
                    {
                      show: false,
                      divider: true,
                    },
                    {
                      show: false,
                      className: "text-danger",
                      label: "Eliminar",
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
        {(subject.imOwner || imAdmin) && (
          <div className="col-3 col-lg-3">
            <div className="container h-100">
              <ProgressPie selectedTopics={selectedTopics} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
const styles = {
  titleStyle: {
    fontSize: "3.2vw",
    width: "100%",
  },
};
export default SubjectProfileHeader;
