import React, { useEffect, useState } from "react";
import { useUser, useUserJWT } from "../../../redux/selectors";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Row,
  Alert,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { UpdateMe } from "../actions";
import {
  useProfileUpdatedUser,
  useProfileUpdateError,
  useProfileUpdateLoading,
  useProfileUpdateSuccess,
} from "../selectors";
import { setUserData } from "../../login/actions";
import { getUser, setUser } from "../../../lib/localstorage";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../../lib/Colors";

const ProfileForm = (props) => {
  const dispatch = useDispatch();
  const userState = useUser();
  const [userForm, setUserForm] = useState({});
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const editLoading = useProfileUpdateLoading();
  const editSuccess = useProfileUpdateSuccess();
  const editError = useProfileUpdateError();
  const editedUser = useProfileUpdatedUser();
  const localStorageUser = getUser();
  const jwt = useUserJWT();
  const assignNewLocalUser = () => {
    if (editedUser && editSuccess) {
      editedUser.jwt = jwt;
      console.log("editedUser", editedUser);
      setUser(editedUser);
      dispatch(setUserData(editedUser));
    }
  };
  useEffect(() => {
    assignNewLocalUser();
  }, [editedUser]);
  useEffect(() => {
    if (userState && !name && !name) {
      setEmail(userState.email);
      setName(userState.name);
    }
  }, [userState]);
  const handleInput = () => {
    if (!email) return setInvalidEmail(true);
    if (!name) return setInvalidName(true);
    dispatch(UpdateMe({ name, email }));
  };
  const showAlert = () => {
    if (editSuccess)
      return (
        <Alert color={"success"} className="m-3">
          Editado con exito
        </Alert>
      );
    if (editError)
      return (
        <Alert color={"danger"} className="m-3">
          Ha ocurrido un error, intenta más tarde
        </Alert>
      );
    else return;
  };
  return (
    <Form>
      {showAlert()}
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          disabled={editLoading}
          invalid={invalidEmail}
          value={email || ""}
          onChange={(e) => {
            let value = e.target.value;
            setEmail(value);
          }}
          type="email"
          name="email"
          id="email"
          placeholder="myname@mail.com"
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Nombre(s)</Label>
        <Input
          disabled={editLoading}
          invalid={invalidName}
          value={name || ""}
          onChange={(e) => {
            let value = e.target.value;
            setName(value);
          }}
          type="text"
          name="name"
          id="name"
          placeholder="Pablo Zárate"
        />
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <Button disabled={editLoading} onClick={handleInput}>
          {"Guardar"}
        </Button>
        {editLoading && (
          <ReactLoading
            width={"3vw"}
            color={ThemeColors.primary}
            type={"spin"}
          />
        )}
      </FormGroup>
    </Form>
  );
};
export default ProfileForm;
