import React, { Component } from 'react';
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import PickTopicDD from './components/PickTopicDD';

class CreateTopicView extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <AuthenticateLayout>
                <PickTopicDD {...this.props}/>
            </AuthenticateLayout>
         );
    }
}
 
export default CreateTopicView;