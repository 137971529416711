import { USER_ACTION_TYPE } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case USER_ACTION_TYPE.USER_LOGIN_START:
      return {
        loading: true,
      };
    case USER_ACTION_TYPE.USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true,
        jwt: payload.jwt || state.jwt || null,
        ...payload,
      };
    case USER_ACTION_TYPE.USER_LOGIN_ERROR:
      return {
        loading: false,
        error: "Usuario o contraseña incorrectos",
      };
    case USER_ACTION_TYPE.USER_LOGIN_END:
      return {
        loading: false,
      };
    case USER_ACTION_TYPE.USER_LOGOUT:
      return {
        user: {},
        logged: false,
      };
    case USER_ACTION_TYPE.CLEAR_ERROR:
      return {
        error: false,
        status: null,
        loading: false,
      };
    case USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_START:
      return {
        ...state,
        superbmind: {
          loading: true,
          error: false,
        },
      };
    case USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_SUCCESS:
      return {
        ...state,
        superbmind: {
          loading: false,
          error: false,
          success: true,
          ...payload,
        },
      };
    case USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_ERROR:
      return {
        ...state,
        superbmind: {
          loading: false,
          error: true,
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
