import React, { Component } from "react";
import cx from "classnames";
import * as ResetPasswordActions from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AlertCard from "../../../components/AlertCard";
import { withCookies } from "react-cookie";
import { resetPassword } from "../actions";
import { Link, withRouter } from "react-router-dom";
import qs from "qs";

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidPassword: "",
      passwordConfirmation: "",
      password: "",
      message: "",
      alertOpen: true,
      alertType: "danger",
    };
  }
  componentDidUpdate = (prevProps) => {
    if (!prevProps.resetPasswordError && this.props.resetPasswordError) {
      this.setState({ alertOpen: true, alertType: "success" });
    }
    if (
      !prevProps.resetPasswordError &&
      this.props.resetPasswordError == true
    ) {
      this.setState({ alertOpen: true, alertType: "danger" });
    }
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let obj = qs.parse(this.props.location.search.replace("?", ""));
   console.log("OBJ", obj.code);
    const { password, passwordConfirmation } = this.state;
    if (!password || !passwordConfirmation) {
      this.setState({
        invalidPassword: true,
      });
      return;
    }
    if (passwordConfirmation != password) {
      this.setState({
        invalidPassword: true,
        message: "Las contraseñas no coinciden",
      });
      return;
    }
    this.setState({
      invalidPassword: false,
      message: null,
    });

    let form = { code: obj.code, password, passwordConfirmation };
   //console.log("Form", form);
    //Send Email to reset password
    this.props.sendResetEmail(form);
  };
  toggleAlert = () => {
    this.setState({ alertOpen: !this.state.alertOpen });
    this.props.clear();
  };
  comparePasswords = () => {
    this.setState({
      invalidPassword: true,
      message: "Las contraseñas no coinciden",
    });
    return "Las contraseñas no coinciden";
  };
  render() {
    let passwordStyle = cx({
      "form-control": true,
      "is-invalid": this.state.invalidPassword,
    });
    let { changePasswordSuccess, resetPasswordError, loading } = this.props;
    let { message, password, passwordConfirmation } = this.state;
    return (
      <div className="row">
        <div className="col-md-9 col-lg-8 mx-auto">
          {resetPasswordError || changePasswordSuccess ? (
            <AlertCard
              isOpen={this.state.alertOpen}
              toggle={() => this.toggleAlert}
              color={changePasswordSuccess ? "success" : "danger"}
              message={
                resetPasswordError
                  ? resetPasswordError
                  : "Contraseña Restablecida"
              }
            />
          ) : (
            ""
          )}
          {password != passwordConfirmation &&
          password.length > 0 &&
          passwordConfirmation.length > 0 ? (
            <AlertCard message="Las contraseñas no coinciden" color="danger" />
          ) : (
            ""
          )}
          {changePasswordSuccess ? (
            <div className="text-center mb-4">
              <h3 className="login-heading">Cambiar Contraseña</h3>
              <p>Se ha cambiado tu contraseña, ahora <Link to="/login">Inicia sesion</Link></p>
            </div>
          ) : (
            <>
              <div className="text-center mb-4">
                <h3 className="login-heading">Cambiar Contraseña</h3>
                <p>Ingresa la nueva contraseña para tu cuenta</p>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-label-group">
                  <input
                    value={this.state.password}
                    name="password"
                    type="password"
                    onChange={this.handleChange}
                    id="password"
                    className={passwordStyle}
                    placeholder="Contraseña"
                    required
                  />
                  <label htmlFor="inputPassword">Contraseña</label>
                </div>
                <div className="form-label-group">
                  <input
                    value={this.state.passwordConfirmation}
                    name="passwordConfirmation"
                    type="password"
                    onChange={this.handleChange}
                    id="passwordConfirmation"
                    className={passwordStyle}
                    placeholder="Repetir Contraseña"
                    required
                  />
                  <label htmlFor="inputPassword">Repetir Contraseña</label>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    disabled={loading ? true : false}
                    className="btn btn-primary"
                    type="submit"
                  >
                    Cambiar contraseña
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    changePasswordSuccess: state.resetPassword.success,
    resetPasswordErrorID: state.resetPassword.errorID,
    resetPasswordError: state.resetPassword.error,
    clearError: state.resetPassword.clearError,
    loading: state.resetPassword.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendResetEmail: bindActionCreators(
      ResetPasswordActions.resetPassword,
      dispatch
    ),
  };
};
export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm))
);
