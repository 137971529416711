import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FLASHCARD_ACTIONTYPE, setActiveFlashcard } from "../actions";
import { useGettingActiveFlashcard } from "../selectors";
const FlashcardsList = (props) => {
  const activeFlashcard = useGettingActiveFlashcard();
  const dispatch = useDispatch();
  const { flashcards } = props;
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Flashcards</h4>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-bordered">
          {flashcards
            ? flashcards.map((flashcard, index) => {
                return (
                  <FlashcardListItem
                    active={
                      activeFlashcard && flashcard.id === activeFlashcard.id
                    }
                    key={index}
                    idNumber={index + 1}
                    onClick={() => {
                     //console.log("Click! ID:", flashcard.id);
                     //console.log("Active:", activeFlashcard);
                      dispatch({
                        type: FLASHCARD_ACTIONTYPE.SET_ACTIVE_FLASHCARD,
                        activeFlashcard: flashcard,
                      });
                    }}
                  />
                );
              })
            : "Ha ocurrido un error"}
        </ul>
      </div>
    </div>
  );
};
export default FlashcardsList;

const FlashcardListItem = (props) => {
  return (
    <li
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
      className={"list-group-item " + (props.active && "active")}
    >
      Flashcard #{props.idNumber}
    </li>
  );
};
