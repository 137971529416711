import { EDITFLASHCARDS_VIEW_ACTIONS } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    //FETCH - START
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_START:
      return {
        ...state,
        loadingSubject: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_SUCCESS:
      return {
        ...state,
        loadingSubject: false,
        successSubject: true,
        subject: payload.subject,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_ERROR:
      return {
        ...state,
        loadingSubject: false,
        errorSubject: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_START:
      return {
        ...state,
        loadingSubject: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_SUCCESS:
      return {
        ...state,
        loadingTopic: false,
        successTopic: true,
        topic: payload.topic,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_ERROR:
      return {
        ...state,
        loadingTopic: false,
        errorTopic: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_START:
      return {
        ...state,
        loadingFlashcards: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.REORDER_FLASHCARDS:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_SUCCESS:
      return {
        ...state,
        flashcardFormActive: payload.flashcards[payload.indexActive],
        flashcardActive: payload.flashcards[payload.indexActive],
        loadingFlashcards: false,
        successFlashcards: true,
        flashcards: payload.flashcards,
        flashcardsFrom: payload.flashcards,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_ERROR:
      return {
        ...state,
        loadingFlashcards: false,
        errorFlashcards: true,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.SET_FLASHCARD_ACTIVE:
      let indexState = state.indexActive;
      let indexA = payload.indexActive;
      if (state.flashcards) {
        if (indexA < 0 || indexA == null) indexA = 0;
      }
      if (indexA) {
        indexState = indexA;
      }
      if (payload.nextIndex == true) {
        indexState += 1;
      }
      if (payload.prevIndex) {
        indexState -= 1;
      }
      return {
        ...state,
        indexActive: indexState,
        ...payload,
      };
    //FETCH - START
    //EDIT TOPIC
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_START:
      return {
        ...state,
        updateTopic: {
          loading: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_SUCCESS:
      return {
        ...state,
        updateTopic: {
          loading: false,
          success: true,
        },
        topic: payload.topic,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_ERROR:
      return {
        ...state,
        updateTopic: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_START:
      return {
        ...state,
        saveSingleFlashcard: {
          loading: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_SUCCESS:
      let stateFlashcards = state.flashcards;
      let savedFlashcard = payload.savedFlashcard;
      let index = stateFlashcards.findIndex((f) => f.id === savedFlashcard.id);
      stateFlashcards[index] = savedFlashcard;
      //console.log("StateF", stateFlashcards);
      //console.log(stateFlashcards);
      return {
        ...state,
        flashcards: stateFlashcards,
        saveSingleFlashcard: {
          loading: false,
          success: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_ERROR:
      return {
        ...state,
        saveSingleFlashcard: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_CLEAR:
      return {
        ...state,
        saveSingleFlashcard: {},
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_START:
      return {
        ...state,
        createFlashcard: {
          loading: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_SUCCESS:
      return {
        ...state,
        createFlashcard: {
          loading: false,
          success: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_CLEAR:
      return {
        ...state,
        createFlashcard: {
          loading: false,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_START:
      return {
        ...state,
        delteSingleFlashcard: {
          loading: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_SUCCESS:
      let flashcardsArr = state.flashcards;
      let flashcardsLength = state.flashcards?.length;
      let deletedId = payload.flashcardDeletedId;
      let newIndexActive = state.indexActive;
      let indexToRemove = flashcardsArr.findIndex((f) => f.id === deletedId);
      flashcardsArr.splice(indexToRemove, 1);
      console.log(flashcardsArr);
      if (flashcardsLength - 1 == indexToRemove) {
        newIndexActive = state.indexActive - 1;
      }
      return {
        ...state,
        indexActive: newIndexActive,
        flashcards: flashcardsArr,
        delteSingleFlashcard: {
          loading: false,
          success: true,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_ERROR:
      return {
        ...state,
        delteSingleFlashcard: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_CLEAR:
      return {
        ...state,
        delteSingleFlashcard: {},
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_START:
      return {
        ...state,
        deleteMultiple: {
          loading: true,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_SUCCESS:
      let payloadFlashcardLength = payload.flashcards?.length;
      let currentIndexActiveInState = state.indexActive;
      let newIndexActive2 = currentIndexActiveInState || 0;
      if (currentIndexActiveInState > payloadFlashcardLength - 1) {
        newIndexActive2 = payloadFlashcardLength - 1;
      }
      return {
        ...state,
        deleteMultiple: {
          loading: false,
          success: true,
          error: false,
        },

        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_ERROR:
      return {
        ...state,
        deleteMultiple: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_CLEAR:
      return {
        ...state,
        deleteMultiple: {
          loading: false,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_START:
      return {
        ...state,
        updateMultiSubtitle: {
          loading: true,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_SUCCESS:
      return {
        ...state,
        updateMultiSubtitle: {
          loading: false,
          success: true,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_ERROR:
      return {
        ...state,
        updateMultiSubtitle: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_CLEAR:
      return {
        ...state,
        updateMultiSubtitle: {
          loading: false,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_START:
      return {
        ...state,
        cloneMultiple: {
          loading: true,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_SUCCESS:
      let clonedFlashcards = payload.clonedFlashcards;
      return {
        ...state,
        cloneMultiple: {
          loading: false,
          success: true,
          error: false,
        },
        flashcards: [...state.flashcards, ...clonedFlashcards],
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_ERROR:
      return {
        ...state,
        cloneMultiple: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_CLEAR:
      return {
        ...state,
        cloneMultiple: {
          loading: false,
          success: false,
          error: false,
        },
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.COPY_MULTIPLE_FLASHCARDS_START:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.COPY_MULTIPLE_FLASHCARDS_CLEAR:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CUT_MULTIPLE_FLASHCARDS_START:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CUT_MULTIPLE_FLASHCARDS_CLEAR:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_START:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_ERROR:
      return {
        ...state,
        ...payload,
      };
    case EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_CLEAR:
      return {
        ...state,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
