import React, { Component } from "react";
import FlashCard from "../../../components/FlashCard";
import cx from "classnames";
import PropTypes from "prop-types";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { cmsAPI } from "../../../utils/http-client";
import { LoadingFlashcard } from "../LoadingFlashcard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { setUser, setFlashcardMatchPending } from "../../../lib/localstorage";
class MatchFlashCardsList extends Component {
  static propTypes = {
    onChange: PropTypes.func,
  };
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      requestError: false,
      flashcards: [],
      flashcardIndexActive: 0,
      displayButtons: false,
      matchEnd: false,
      flashcardsConfident: [],

      savingMatch: false,
      savingError: false,
      savedSuccess: false,
    };
  }
  getFlashcards = (userID, userTopicID) => {
    cmsAPI
      .get(`/flashcards?topic=${userTopicID}`)
      .then((res) => {
       //console.log("UserFLASHCARDS:", res);
        this.setState({
          loading: false,
          flashcards: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          flashcards: [],
          requestError: true,
        });
      });
  };
  handleConfident = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      flashcardConfident: {
        flashcardId: "1",
        confident: "100",
      },
    });
  };
  paginationActive = (indexActive) => {
    const paginationFlashcards = cx({
      "page-item": true,
      active: this.state.flashcardIndexActive == indexActive,
    });
    return paginationFlashcards;
  };
  setFlashcardConfident = (rate) => {
    let userFlashcard = this.state.flashcards[this.state.flashcardIndexActive];
    let { flashcardsConfident } = this.state;
    //console.log("ConfidentRate", rate, "userFlashcard", userFlashcard);
    flashcardsConfident.push({
      userflashcard: userFlashcard.id,
      knowledge: rate,
    });
    this.setState({
      flashcardsConfident,
    });
    //console.log("Flashcards confident", flashcardsConfident);
    let { flashcardIndexActive, flashcards } = this.state;
    //console.log("NEXT!");
    if (flashcardIndexActive + 1 < flashcards.length) {
      this.setState({
        showAnswer: this.state.showAnswer
          ? !this.state.showAnswer
          : this.state.showAnswer,
        flashcardIndexActive: this.state.flashcardIndexActive + 1,
      });
      return;
    }
    //console.log("Match ENDED", flashcardsConfident);
    this.saveFlashcardMatch(flashcardsConfident);
    this.setState({ matchEnd: true });
    return;
  };
  saveFlashcardMatch = (data) => {
    this.setState({
      savingMatch: true,
      savingError: false,
    });
    cmsAPI
      .post("/userflashcardconfidents", { flashcardsConfident: data })
      .then((res) => {
        //console.log("Confident Saved", res);
        this.setState({
          savingMatch: false,
          savingError: false,
        });
      })
      .catch((err) => {
        //console.log("Error saving confident", err);
        this.setState({
          savingMatch: false,
          savingError: true,
        });
      });
  };
  componentDidMount() {
    //console.log("UserFlashcards", this.state.flashcards);
    //console.log("Usertopicid<>>>", this.props.userTopicId);
    this.getFlashcards(this.props.userId, this.props.userTopicId);
  }
  render() {
    //console.log("UserFlashcards", this.state.flashcards);
    if (this.state.matchEnd) {
      return (
        <div className="vertical-center">
          <div className="container">
            <div className="text-center">
              <h2>
                Haz terminado de repasar tus flashcards{" "}
                <span className="badge badge-pill badge-success">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </h2>
              <Link to="/myflashcards" className="btn btn-primary">
                Regresa a Mis Flashcards
              </Link>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.loading && !this.state.error) return <LoadingFlashcard />;
    if (!this.state.loading && this.state.requestError) {
      return (
        <div className="vertical-center">
          <div className="container">
            <div className="text-center">
              <h2>Ocurrió un error</h2>
              <Link to="/myflashcards" className="btn btn-primary">
                Regresa a Mis Flashcards
              </Link>
            </div>
          </div>
        </div>
      );
    }
    if (this.state.flashcards < [0]) {
      return (
        <div>
          <h1>Ha ocurrido un error, vuelve atrás</h1>
        </div>
      );
    } else
      return (
        <div className="">
          <div className="container">
            {/* START CARD FLASHCARD */}
            <div className="card mt-5">
              {/* PAGINATION BAR */}
              <div className="text-center card-header">
                <div>
                  <h4>
                    {this.state.flashcardIndexActive +
                      1 +
                      " / " +
                      this.state.flashcards.length}
                  </h4>
                  <p className="mb-3">
                    Flashcard #{this.state.flashcardIndexActive + 1}
                  </p>
                </div>
              </div>
              <FlashCard
                question={
                  this.state.flashcards[this.state.flashcardIndexActive]
                    .question
                }
                answer={
                  this.state.flashcards[this.state.flashcardIndexActive].answer
                }
                showAnswer={this.state.showAnswer}
                flashcardIndexActive={this.state.flashcardIndexActive + 1}
                toggleAnswer={() => {
                  this.setState({ showAnswer: !this.state.showAnswer });
                }}
              />
              <div className="card-footer text-center text-muted">
                <h4>Confianza de flashcard</h4>
                <span>¿Cuanta confianza tienes en esta flashcard?</span>
                <div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      onClick={() => this.setFlashcardConfident(1)}
                      type="button"
                      className="btn btn-primary"
                    >
                      1
                    </button>
                    <button
                      onClick={() => this.setFlashcardConfident(2)}
                      type="button"
                      className="btn btn-primary"
                    >
                      2
                    </button>
                    <button
                      onClick={() => this.setFlashcardConfident(3)}
                      type="button"
                      className="btn btn-primary"
                    >
                      3
                    </button>
                    <button
                      onClick={() => this.setFlashcardConfident(4)}
                      type="button"
                      className="btn btn-primary"
                    >
                      4
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* END CARD FLASHCARD */}
          </div>
        </div>
      );
  }
}

export default MatchFlashCardsList;
