import React, { Component, useState, useEffect } from "react";
import propTypes from "prop-types";
const FlashCard = ({ ...props }) => {
  const { showAnswer, toggleAnswer } = props;
  const [answer, setAnswer] = useState();
  const [question, setQuestion] = useState();
  useEffect(() => {
    let tempAnswer = props.answer;
    let tempQuestion = props.question;
    changeToIframe(tempAnswer, "answer");
    changeToIframe(tempQuestion, "question");
  }, [props]);

  const changeToIframe = (str, type) => {
    if (!str) return;
    let video_id = str.split("?v=")[1];
    if (video_id) {
      let ampersandPosition = video_id.indexOf("&");
      if (ampersandPosition != -1) {
        video_id = video_id.substring(0, ampersandPosition);
      }
    }
    str = str.replace(
      /<oembed url=/g,
      '<div class="embed-responsive embed-responsive-16by9"><iframe allowfullscreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" class="embed-responsive-item" src='
    );
    str = str.replace(/<\/oembed>/g, "</iframe></div>");
    str = str.replace(
      /https:\/\/www\.youtube\.com\/watch\?v=/g,
      "https://www.youtube.com/embed/"
    );
    //console.log(str);
    if (type === "question") setQuestion(str);
    if (type === "answer") setAnswer(str);
    if (!type) return; //console.log("No seleccionaste un tipo de dato");
    return;
  };
  return (
    <div className="card-body">
      <div className="text-center">
        <div className="mb-3">
          {/* RICH TEXT */}
          <div
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          ></div>
          {/* RICH TEXT */}
          <div className="invalid-feedback">
            Esta es la pregunta de tu flashcard.
          </div>
        </div>
      </div>
      <div className="">
        <div className="mb-3" style={{ minHeight: 200 }}>
          {/* RICH TEXT */}
          {showAnswer && (
            <div
              dangerouslySetInnerHTML={{
                __html: answer,
              }}
            ></div>
          )}
          {/* RICH TEXT */}
          <div className="invalid-feedback">
            Esta es la respuesta de tu flashcard.
          </div>
        </div>
      </div>
      <div className="text-center">
        <button onClick={toggleAnswer} className="btn btn-primary">
          {showAnswer ? " Ocultar respuesta" : "Ver respuesta"}
        </button>
      </div>
    </div>
  );
};
FlashCard.propTypes = {
  showAnswer: propTypes.bool,
  toggleAnswer: propTypes.func,
  answer: propTypes.string,
  question: propTypes.string,
  flashcardIndexActive: propTypes.string,
};
export default FlashCard;
