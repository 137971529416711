import { RESET_PASSWORD_ACTION_TYPE } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_START:
      return {
        loading: true,
      };
    case RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_SUCCESS:
      return {
        success:true,
        loading: false,
        ...payload,
      };
    case RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_ERROR:
      return {
        loading: false,
        error: "Ha ocurrido un error, por favor, vuelve a intentar más tarde",
      };
    case RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_END:
      return {
        loading: false,
      };
    case RESET_PASSWORD_ACTION_TYPE.CLEAR_ERROR:
      return {
        error: false,
        status: null,
        loading: false,
      };
    default:
      return {...state};
  }
};

export default reducer;
