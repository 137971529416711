// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { ThemeColors } from "../../lib/Colors";
import {
  useSPTopics,
  useSPTopicsCount,
} from "../../views/subjectProfile/selectors";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
export const ProgressPie = ({ selectedTopics, data, ...props }) => {
  let randomValue = Math.floor(Math.random() * 100 + 1);
  const profileTopics = useSPTopics();
  const topicsCount = useSPTopicsCount();
  return (
    <div className="row h-100 justify-content-center align-items-center">
      <div style={{ width: "11vw", height: "11vw" }}>
        <ResponsivePie
          data={[
            {
              id: "complete",
              value: 0,
              color: "#e4e4e4",
            },
            {
              id: "rest",
              value: 100,
              color: ThemeColors.primary,
            },
          ]}
          sortByValue={true}
          innerRadius={0.9}
          padAngle={0}
          cornerRadius={0}
          colors={[ThemeColors.primary, "#e4e4e4"]}
          borderWidth={0}
          borderColor={{ from: "color", modifiers: [["darker", ".5"]] }}
          enableRadialLabels={false}
          radialLabelsSkipAngle={10}
          radialLabelsTextColor="#333333"
          radialLabelsLinkColor={{ from: "color" }}
          enableSliceLabels={false}
          sliceLabelsRadiusOffset={0.55}
          sliceLabelsTextColor="#333333"
          isInteractive={false}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[]}
          legends={[]}
        />
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {0}%
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          bottom: 20,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {selectedTopics != null && topicsCount ? (
          <p
            style={{
              fontWeight: "lighter",
              fontSize: "10px",
              color: ThemeColors.searchBg,
            }}
          >
            {`${selectedTopics.length || 0}/${topicsCount}`} Pilas
          </p>
        ) : (
          ""
        )}
      </div>
      <div
        style={{
          position: "absolute",
          marginLeft: "auto",
          marginRight: "auto",
          left: 0,
          right: 0,
          top: 40,
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            fontSize: "10px",
            color: ThemeColors.primary,
          }}
        >
          Dominio{" "}
          <i
            data-toggle="tooltip"
            title="Este es el porcentaje de dominio de la materia basado en las flashcards estudiadas"
            style={{
              cursor: "help",
            }}
            className="fas fa-question-circle"
          ></i>
        </p>
      </div>
    </div>
  );
};
