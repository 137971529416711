import React, { useEffect, useState } from "react";
import { getUser, setUser } from "../../lib/localstorage";
import { cmsAPI } from "../../utils/http-client";
import { withCookies } from "react-cookie";
import NavBar from "../../components/NavBar";
import { Redirect } from "react-router-dom";
import AppFooter from "../../components/Footer";
import NavBarV2 from "../../components/NavBarV2";
import SideBar from "../../components/SideBar";
import QuickSideBar from "../../components/QuickSideBar";
import { useUserJWT } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { logout } from "../../views/login/actions";
import { useHistory } from "react-router-dom";
import * as userActions from "../../views/login/actions";
export const AuthenticateLayout = ({
  hideSidebar = false,
  hideNavBar = false,
  hideFooter,
  ...props
}) => {
  const userJWT = useUserJWT();
  const history = useHistory();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(true);
  const { children } = props;
  const getMe = () => {
    //console.log("In Auth layout", userJWT);
    cmsAPI
      .get("/users/me")
      .then(({ data }) => {
        //console.log("res", res);\
        data.jwt = userJWT;
        setUser(data);
        dispatch(userActions.setUserData(data));
        setValid(true);
        return;
      })
      .catch((err) => {
        if (err.response?.data?.statusCode == 401) {
          setValid(false);
          dispatch(userActions.logout());
          history.push("/login");
          return;
        }
      });
  };
  useEffect(() => {
    async function checkJWT() {
      await getMe();
    }
    checkJWT();
  }, []);
  return (
    <div className="wrapper">
      {!hideNavBar && <NavBarV2 />}
      {!hideSidebar && <SideBar />}
      {props.customLayout ? (
        <>{children}</>
      ) : (
        <div className="main-panel">
          <div className="container">
            <div className="page-inner">{children}</div>
          </div>
          {!hideFooter && <AppFooter />}
        </div>
      )}
      <QuickSideBar />
    </div>
  );
};
