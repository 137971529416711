import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../styles.css";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import HeaderActions from "./HeaderActions";
import Skeleton from "react-loading-skeleton";
import {
  useCreateFlashcardLoading,
  useCreateFlashcardSuccess,
  useDelteSingleFCSuccess,
  useEditViewFlashcards,
  useEditViewTopic,
  useFetchingFlashcardsLoading,
  useFlashcardIndexActive,
  useUpdateMultiSubtitleLoading,
  useUpdateMultiSubtitleSuccess,
} from "../selectors";
import {
  cloneSingleFlashcard,
  createBrandNewFlashcard,
  EDITFLASHCARDS_VIEW_ACTIONS,
  nextIndex,
  setFlashcardIndexActive,
  updateActiveFlashcard,
} from "../actions";
import { useDispatch } from "react-redux";
import FlashcardFormSkeleton from "./FlashcardFormSkeleton";
import { ThemeColors } from "../../../lib/Colors";
import ImagePicker from "../../../components/ImagePicker";
import FroalaEditorComponent from "react-froala-wysiwyg";
import { froalaConfig } from "../../../utils/configs";
import { Button } from "reactstrap";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
let onTimeOutToSave = null;
const FlashcardForm = ({ loadingFlag, ...props }) => {
  //VARIABLES - START
  let editorConfig = {
    removePlugins: ["Title"],
    toolbar: [[]],
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "subscript",
        "superscript",
        "|",
        "alignment",
        "|",
        "link",
        "specialCharacters",
        "|",
        "fontSize",
        "fontColor",
        "fontBackgroundColor",
        "highlight",
        "|",
        "bulletedList",
        "numberedList",
        "outdent",
        "indent",
        "|",
        "undo",
        "redo",
      ],
    },
  };
  // let onTimeOutToSave = null;
  const loadingNewFC = useCreateFlashcardLoading();
  const subtitleLoading = useUpdateMultiSubtitleLoading();
  const dispatch = useDispatch();
  const history = useHistory();
  const route = useRouteMatch();
  let query = useQuery();
  const indexActive = useFlashcardIndexActive();
  const originalFlashcards = useEditViewFlashcards();
  const fetchingFlashcards = useFetchingFlashcardsLoading();
  const [localFlashcards, setLocalFlashcards] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [questionData, setQuestionData] = useState("");
  const [answerData, setAnswerData] = useState("");
  const [questionImage, setQuestionImage] = useState([]);
  const [answerImage, setAnswerImage] = useState([]);
  const [flascardName, setFlashcardName] = useState("");
  const [flascardSubtitle, setFlashcardSubtitle] = useState("");
  const [loading, setLoading] = useState(false);
  const successSubtitles = useUpdateMultiSubtitleSuccess();
  const successCreated = useCreateFlashcardSuccess();
  const successDeleted = useDelteSingleFCSuccess();
  // const [focusIndexParam, setFocusIndexParam] = useState(true);
  let focusIndexParam = true;
  const topicActive = useEditViewTopic();
  //VARIABLES - END
  //UseEffects - START
  useEffect(() => {
    // setLoading(true);
    //console.log("SET", originalFlashcards);
    if (originalFlashcards) {
      setLocalFlashcards(originalFlashcards);
      let flashcardIndex = query.get("flashcardIndex");
      //console.log("MatchParams::", flashcardIndex);
      if (focusIndexParam == true && flashcardIndex != null) {
        dispatch(setFlashcardIndexActive(flashcardIndex));
        focusIndexParam = false;
        // query.delete("flashcardIndex");
        // history.replace({
        //   search: query.toString(),
        // });
      }
    }
    // setLoading(false);
  }, [originalFlashcards, successSubtitles]);
  useEffect(() => {
    if (successCreated && localFlashcards) {
      setTimeout(() => {
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_CLEAR,
        });
      }, 500);
      dispatch(setFlashcardIndexActive(localFlashcards.length));
    }
  }, [successCreated]);
  useEffect(() => {
    if (localFlashcards.length) {
      console.log("setDataFC", indexActive);
      setQuestionData(localFlashcards[indexActive].question || "");
      setAnswerData(localFlashcards[indexActive].answer || "");
      setFlashcardName(localFlashcards[indexActive].name || "");
      setFlashcardSubtitle(localFlashcards[indexActive].subtitle || "");
      setAnswerImage(localFlashcards[indexActive].answermedia[0] || []);
      setQuestionImage(localFlashcards[indexActive].questionmedia[0] || []);
    } else {
      setQuestionData("");
      setAnswerData("");
      setFlashcardName("");
      setFlashcardSubtitle("");
      setAnswerImage([]);
      setQuestionImage([]);
    }
  }, [localFlashcards, indexActive, successSubtitles, successCreated]);
  useEffect(() => {
    //console.log("Params", route);
    let flashcardIndex = query.get("flashcardIndex");
    //console.log("localFlashcards", localFlashcards);
    if (localFlashcards) {
      ////console.log("setDataFC", localFlashcards[indexActive]);
      setQuestionData(localFlashcards[indexActive]?.question || "");
      setAnswerData(localFlashcards[indexActive]?.answer || "");
      setFlashcardName(localFlashcards[indexActive]?.name || "");
      setFlashcardSubtitle(localFlashcards[indexActive]?.subtitle || "");
      setAnswerImage(localFlashcards[indexActive]?.answermedia[0] || []);
      setQuestionImage(localFlashcards[indexActive]?.questionmedia[0] || []);
    }
  }, [indexActive, localFlashcards, successSubtitles, successCreated]);
  useEffect(() => {
    if (localFlashcards?.length) checkUnsavedChanges();
  }, [localFlashcards]);
  useEffect(() => {
    //console.log("Question", questionImage);
    //console.log("Answer", answerImage);
  }, [questionImage, answerImage]);
  // UseEffects - END
  const handleEditorData = (data, field) => {
    autoSave();
    if (field == "answer") {
      setAnswerData(data)
      localFlashcards[indexActive].answer = data;
    }
    if (field == "question") {
      setQuestionData(data)
      localFlashcards[indexActive].question = data;
    }
    if (field == "questionmedia") {
      setQuestionImage(data)
      localFlashcards[indexActive].questionmedia = data;
    }
    if (field == "answermedia") {
      setAnswerImage(data)
      localFlashcards[indexActive].answermedia = data;
    }
  };
  function autoSave() {
    if (onTimeOutToSave != null) {
      clearTimeout(onTimeOutToSave);
    }
    onTimeOutToSave = setTimeout(() => {
      saveActiveFlashcard();
    }, 3000);
  }
  const checkUnsavedChanges = () => {
    if (localFlashcards[indexActive] != originalFlashcards[indexActive])
      setUnsavedChanges(true);
  };
  const saveActiveFlashcard = () => {
    dispatch(updateActiveFlashcard(localFlashcards[indexActive]));
  };
  if (
    !localFlashcards ||
    loadingNewFC ||
    subtitleLoading ||
    fetchingFlashcards ||
    loading ||
    indexActive < 0 ||
    loadingFlag
  ) {
    return <FlashcardFormSkeleton />;
  }
  return (
    <div>
      <HeaderActions
        autosave={autoSave}
        cloneAction={() => {
          //console.log("Clone This");
        }}
        saveAction={saveActiveFlashcard}
        usavedChangesFlag={unsavedChanges}
        nameValue={flascardName}
        subtitleValue={flascardSubtitle}
        duplicateAction={() => {
          clearTimeout(onTimeOutToSave);
          dispatch(cloneSingleFlashcard(localFlashcards[indexActive]));
        }}
        reviewAction={() => {
          ////console.log("Go to review");
          history.push(
            topicActive?.id ? `/flashcards-preview/${topicActive.id}` : "#"
          );
        }}
        nextAction={() => {
          dispatch(
            nextIndex({
              maxLength: localFlashcards?.length,
              currentIndex: indexActive,
              topicId: topicActive.id,
            })
          );
        }}
        onChangeName={(name) => {
          autoSave();
          setFlashcardName(name);
          localFlashcards[indexActive].name = name;
        }}
        onChangeSubtitle={(subtitle) => {
          autoSave();
          setFlashcardSubtitle(subtitle);
          if (localFlashcards[indexActive])
            localFlashcards[indexActive].subtitle = subtitle;
        }}
      />
      <div className="row">
        <div className="col-md-12 col-lg-6">
          {/* QUESTION */}
          <h3 className="">Pregunta</h3>
          <CKEditor
            config={{ ...editorConfig, tabIndex: 1 }}
            editor={Editor}
            data={questionData}
            onChange={(event, editor) => {
              const data = editor.getData();
              //console.log("getDATALL:::", data);
              handleEditorData(data, "question");
            }}
          />
          <ImagePicker
            inputProps={{ tabIndex: 3 }}
            id={"questionImagePicker"}
            name="questionImage"
            data={questionImage}
            setData={(data) => {
              //console.log("SetDataQuestion", data);
              handleEditorData(data, "questionmedia");
            }}
          />
        </div>
        <div className="col-md-12 col-lg-6">
          <h3 className="">Respuesta</h3>
          <CKEditor
            config={{ ...editorConfig, tabIndex: 2 }}
            editor={Editor}
            data={answerData}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleEditorData(data, "answer");
            }}
          />
          <ImagePicker
            inputProps={{ tabIndex: 4 }}
            id={"answerImagePicker"}
            name="answerImage"
            data={answerImage}
            setData={(data) => {
              //console.log("SetDataAnswer", data);
              handleEditorData(data, "answermedia");
            }}
          />
          <p
            style={{ position: "absolute", right: 20, fontSize: 7 }}
            className="text-muted"
          >
            {indexActive}-ID:{localFlashcards[indexActive]?.id || "id"}
          </p>
        </div>
      </div>
    </div>
  );
};
export default FlashcardForm;
