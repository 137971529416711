import React, { Component } from "react";
import { getUser } from "../lib/localstorage";
class SuscriptionSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
    };
  }
  componentDidMount() {
    const user = getUser();
    this.setState(
      {
        user,
      },

      () => {
       //console.log("User state", this.state.user);
      }
    );
   //console.log("Props SuscriptionSelect", this.props);
  }
  render() {
    return (
      <div>
        <div className="container">
          <div className="m-4 text-center">
            <h3 className="my-0 font-weight-normal">Suscripción</h3>
          </div>
          <div className="card-deck mb-3 text-center">
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Basico</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$0</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Solo podrás acceder a flashcards gratiutos</li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-outline-primary"
                >
                  Cambiar a Basico
                </button>
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Mensual</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $200<small className="text-muted">/mes</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>
                    +100 Flashcards.
                  </li>
                  <li>
                    Crear Flashcards personalizadas.
                  </li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Obtener Premium
                </button>
              </div>
            </div>
            <div className="card mb-4 box-shadow">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Anual</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">
                  $100<small className="text-muted">/mes</small>
                </h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>Contenido premium.</li>
                  <li>Crear flashcards publicas.</li>
                  <li><small className="text-muted">Un solo pago de $1,200/año</small></li>
                </ul>
                <button
                  type="button"
                  className="btn btn-lg btn-block btn-primary"
                >
                  Obtener Premium
                </button>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default SuscriptionSelect;
