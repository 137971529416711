import React, { useState, useEffect } from "react";
import { logout } from "../../views/login/actions";
import { Routes } from "../../Routes";
import NavLinks from "./NavLinks";
import { getUser } from "../../lib/localstorage";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { withCookies } from "react-cookie";
import {
  Link,
  withRouter,
  Router,
  useLocation,
  useHistory,
} from "react-router-dom";
import { cmsAPI } from "../../utils/http-client";
import defaultImage from "../../assets/img/avatar-default.png";
import { useUserJWT } from "../../redux/selectors";
const NavBarV2 = (props) => {
  const userJWT = useUserJWT();
  const dipsatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  useEffect(() => {
    const userItem = getUser();
    if (userItem) setUser(userItem);
    return () => {};
  }, []);
  const logoutAction = () => {
    //console.log("LOGOUT ACTION");
    dipsatch(logout());
    history.push("/login");
  };
  let location = useLocation();

  return (
    <div className="main-header">
      <div className="logo-header" data-background-color="dark2">
        <a href="/dashboard" className="logo">
          <img
            src="/assets/img/horizontal-logo.png"
            alt="navbar brand"
            style={{width: 150, alignSelf:'center'}}
            className="img-fluid navbar-brand"
          />
        </a>
        <button
          className="navbar-toggler sidenav-toggler ml-auto"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="icon-menu" />
          </span>
        </button>
        <button className="topbar-toggler more">
          <i className="icon-options-vertical" />
        </button>
        <div className="nav-toggle">
          <button className="btn btn-toggle toggle-sidebar">
            <i className="icon-menu" />
          </button>
        </div>
      </div>
      {/* End Logo Header */}
      {/* Navbar Header */}
      <nav
        className="navbar navbar-header navbar-expand-lg"
        data-background-color="dark"
      >
        <div className="container-fluid">
          <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
            <li className="nav-item dropdown hidden-caret">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="messageDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-envelope" />
              </a>
              <ul
                className="dropdown-menu messages-notif-box animated fadeIn"
                aria-labelledby="messageDropdown"
              >
                <li>
                  <div className="dropdown-title d-flex justify-content-between align-items-center">
                    Messages
                    {/* <a href="#" className="small">
                      Mark all as read
                    </a> */}
                  </div>
                </li>
                <li>
                  <div className="dropdown-title">No tienes mensajes</div>
                </li>

                <li>
                  {/* <a className="see-all" href="javascript:void(0);">
                    See all messages
                    <i className="fa fa-angle-right" />{" "}
                  </a> */}
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown hidden-caret">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="notifDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fa fa-bell" />
                {/* <span className="notification">4</span> */}
              </a>
              <ul
                className="dropdown-menu notif-box animated fadeIn"
                aria-labelledby="notifDropdown"
              >
                <li>
                  <div className="dropdown-title d-flex justify-content-between align-items-center">
                    Notificaciones
                    {/* <a href="#" className="small">
                      Mark all as read
                    </a> */}
                  </div>
                </li>
                <li>
                  <div className="dropdown-title">No tienes notificaciones</div>
                </li>
                <li>
                  <div className="notif-scroll scrollbar-outer">
                    <div className="notif-center"></div>
                  </div>
                </li>
                <li>
                  {/* <a className="see-all" href="javascript:void(0);">
                    See all notifications
                    <i className="fa fa-angle-right" />{" "}
                  </a> */}
                </li>
              </ul>
            </li>
            {user && (
              <>
                {user.role?.type === "occ" ||
                  (user.role?.type === "admin" && (
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="/admin/dashboard"
                        role="button"
                      >
                        Administrar
                      </a>
                    </li>
                  ))}
                <li className="nav-item dropdown hidden-caret">
                  <a
                    className="dropdown-toggle profile-pic"
                    data-toggle="dropdown"
                    href="#"
                    aria-expanded="false"
                  >
                    <div className="avatar-sm">
                      <img
                        src={
                          user && user.profile_picture
                            ? cmsAPI.resolveAssetAPI(user.profile_picture.url)
                            : "/assets/img/avatar-default.png"
                        }
                        alt="..."
                        className="avatar-img rounded-circle"
                      />
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-user animated fadeIn">
                    <div className="dropdown-user-scroll scrollbar-outer">
                      <li>
                        <div className="user-box">
                          <div className="avatar-lg">
                            <img
                              src={
                                user && user.profile_picture
                                  ? cmsAPI.resolveAssetAPI(
                                      user.profile_picture.url
                                    )
                                  : "/assets/img/avatar-default.png"
                              }
                              alt="image profile"
                              className="avatar-img rounded"
                            />
                          </div>
                          <div className="u-text">
                            <h4>{user.firstName + " " + user.lastName}</h4>
                            <p className="text-muted">{user.username}</p>
                            <a
                              href="/profile"
                              className="btn btn-xs btn-secondary btn-sm"
                            >
                              Ver Perfil
                            </a>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="/profile">
                          Mi Perfil
                        </a>
                        <a className="dropdown-item" href="#">
                          Mi Suscripcion
                        </a>
                        <div className="dropdown-divider" />
                        <a className="dropdown-item" href="#">
                          Facturas
                        </a>
                        <div className="dropdown-divider" />
                        <button
                          onClick={() => {
                            logoutAction();
                          }}
                          style={{ cursor: "pointer" }}
                          className="dropdown-item"
                          href="#"
                        >
                          Logout
                        </button>
                      </li>
                    </div>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBarV2;
