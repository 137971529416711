import React, { Component } from 'react';
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import CheckoutForm from './containers/CheckoutForm';

const CheckoutView = (props) => {

    return(
        <AuthenticateLayout>
            <CheckoutForm/>
        </AuthenticateLayout>
    )
}

export default CheckoutView;
