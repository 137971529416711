import React, { Component } from "react";

import cx from "classnames";
import * as UserActions from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AlertCard from "../../../components/AlertCard";
import {
  setRememberLogin,
  getRememberLogin,
  clearRememberLogin,
} from "../../../lib/localstorage";
import { setUser } from "../../../lib/localstorage";
import { withCookies } from "react-cookie";
import { Link, withRouter } from "react-router-dom";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacy: false,
      invalidEmail: false,
      invalidPassword: false,
      email: "",
      password: "",
      remember: false,
      alertOpen: true,
      alertType: "danger",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.loginSuccess && this.props.loginSuccess) {
      this.setState({ alertOpen: true, alertType: "success" });
      // setUser(this.props.user,)
      setUser(
        { ...this.props.user, jwt: this.props.jwt, logged: true },
        this.props.cookies
      );
      setTimeout(() => this.props.history.push("/subjects"), 1000);
    }
    if (!prevProps.loginError && this.props.loginError == true) {
      this.setState({ alertOpen: true, alertType: "danger" });
    }
  };
  componentDidMount() {
    const form = getRememberLogin();
    if (form) {
      this.setState({
        email: form.email,
        password: form.password,
        remember: form.remember,
      });
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.name === "remember" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, remember } = this.state;
    if (email == "") {
      this.setState({
        invalidEmail: true,
      });
      return;
    }
    if (password == "") {
      this.setState({
        invalidPassword: true,
      });
      return;
    }
    if (remember) {
      setRememberLogin({ email, password, remember });
    } else if (!remember) {
      clearRememberLogin();
    }
    let form = { identifier: email, password };
    //console.log("Form", form);
    this.props.login(form);
  };
  toggleAlert = () => {
    this.setState({ alertOpen: !this.state.alertOpen });
  };
  render() {
    let emailStyle = cx({
      "form-control": true,
      "is-invalid": this.state.invalidEmail,
    });
    let passwordStyle = cx({
      "form-control": true,
      "is-invalid": this.state.invalidPassword,
    });
    let { loginSuccess, loginError, loading } = this.props;
    if (this.state.legacy)
      return (
        <div className="row">
          <div className="col-md-9 col-lg-8 mx-auto">
            {(loginError || loginSuccess) && (
              <AlertCard
                isOpen={this.state.alertOpen}
                toggle={() => this.toggleAlert}
                color={loginSuccess ? "success" : "danger"}
                message={loginError ? loginError : "Success"}
              />
            )}
            <div className="text-center">
              <h3 className="login-heading mb-4">Accede a Superbmind</h3>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-label-group">
                <input
                  value={this.state.email}
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  id="inputEmail"
                  className={emailStyle}
                  placeholder="Email"
                  required
                  autoFocus
                />
                <label htmlFor="inputEmail">Email</label>
              </div>
              <div className="form-label-group">
                <input
                  value={this.state.password}
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  id="inputPassword"
                  className={passwordStyle}
                  placeholder="Contraseña"
                  required
                />
                <label htmlFor="inputPassword">Contraseña</label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  disabled={loading ? true : false}
                  className="btn btn-primary"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div className="text-center">
                <Link className="small" to="/forgot-password">
                  ¿Olvidaste la contraseña?
                </Link>
              </div>
            </form>
          </div>
        </div>
      );
    return (
      <>
        <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-primary-gradient">
          <h1 className="title fw-bold text-white mb-3">Superbmind</h1>
          <p className="subtitle text-white op-7">
            Bienvenido a Superbmind, aprende de los temas de tu interes con el
            mejor contenido
          </p>
        </div>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div className="container container-login container-transparent animated fadeIn">
            <h3 className="text-center">Inicia sesión</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="login-form">
                {(loginError || loginSuccess) && (
                  <AlertCard
                    isOpen={this.state.alertOpen}
                    toggle={() => this.toggleAlert}
                    color={loginSuccess ? "success" : "danger"}
                    message={loginError ? loginError : "Success"}
                  />
                )}
                <div className="form-group">
                  <label htmlFor="username" className="placeholder">
                    <b>Email</b>
                  </label>
                  <input
                    value={this.state.email}
                    onChange={this.handleChange}
                    id="email"
                    name="email"
                    className={""}
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password" className="placeholder">
                    <b>Contraseña</b>
                  </label>
                  <Link to="/forgot-password" className="link float-right">
                    Olvide la contraseña
                  </Link>
                  <div className="position-relative">
                    <input
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                      name="password"
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control"
                      required
                    />
                    <div
                      onClick={() => {
                        this.setState({
                          showPassword: !this.state.showPassword,
                        });
                      }}
                      className="show-password"
                    >
                      <i className="icon-eye" />
                    </div>
                  </div>
                </div>
                <div className="form-group form-action-d-flex mb-3">
                  <button
                    disabled={loading ? true : false}
                    type={"submit"}
                    className="btn btn-secondary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
                  >
                    Entrar
                  </button>
                </div>
                <div className="login-account">
                  <span className="msg">No tienes cuenta? </span>
                  <a href="/signup" id="show-signup" className="link">
                    Crea una cuenta
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    loginError: state.user.error,
    user: state.user,
    jwt: state.user.jwt,
    loginSuccess: state.user.logged,
    loading: state.user.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(UserActions.login, dispatch),
  };
};
export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
);
