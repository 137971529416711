import { useSelector } from "react-redux";
export const useUserJWT = () => {
  return useSelector(({ user }) => user && user.jwt);
};
export const useUser = () => {
  return useSelector(({ user }) => user);
};
export const useUserIsAdmin = () => {
  return useSelector(({ user }) => user.isAdmin);
};
export const useSuperbmindUser = () => {
  return useSelector(({ user }) => user && user.superbmind);
};
export const useUserID = () => {
  return useSelector(({ user }) => user && user.id);
};
export const useUserRole = () => {
  return useSelector(({ user }) => user && user.role);
};
export const useUserRoleType = () => {
  return useSelector(({ user }) => user && user.role?.type);
};
export const useUserRoleID = () => {
  return useSelector(({ user }) => user && user.role && user.role.id);
};
export const useSubjects = () => {
  return useSelector(({ subject }) => subject && subject.subjects);
};
export const useMySubjects = () => {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.subjects);
};
