import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getSubcategories } from "../../admin-categories/actions";
import {
  ADMIN_SUBCATEGORY_ACTION_TYPE,
  createScaleAction,
  editScaleAction,
} from "../actions";

const CreateSubcategoryForm = ({ editMode, editData }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [order, setOrder] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useSelector(
    ({ adminCategories }) => adminCategories.categories
  );
  const successCreatingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.createSubcategory.success
  );
  const errorCreatingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.createSubcategory.error
  );
  const loadingCreatingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.createSubcategory.loading
  );
  const successEditingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.editSubcategory.success
  );
  const errorEditingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.editSubcategory.error
  );
  const loadingEditingScale = useSelector(
    ({ adminSubcategories }) => adminSubcategories.editSubcategory.loading
  );
  useEffect(() => {
    console.log("editMode", editMode);
    if (editMode) {
      if (editData != null) {
        setName(editData.name);
        setSlug(editData.slug);
        setOrder(editData.order);
        if (editData.category != null) {
          let category_toedit = {
            ...editData.category,
            value: editData.category.id,
            label: editData.category.name,
          };
          setSelectedCategory(category_toedit);
        }
      }
    }
  }, [editMode]);
  useEffect(() => {
    dispatch(getSubcategories());
  }, []);
  const submitForm = (e) => {
    e.preventDefault();
    const form = { name, slug, category: selectedCategory?.id, order };
    if (!name || !slug) {
      alert("Llena los campos requeridos");
      return;
    }
    if (editMode) {
      dispatch(editScaleAction({ userscaleId: editData.id, form }));
      return;
    } else {
      dispatch(createScaleAction({ form }));
    }
  };
  const renderAlert = () => {
    let alertClassName = "alert alert-secondary alert-dismissible fade show";
    let alertMessage = "";
    if (successCreatingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha creado correctamente";
    }
    if (successEditingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha editado correctamente";
    }
    if (errorCreatingScale || errorEditingScale) {
      alertClassName = "alert alert-danger alert-dismissible fade show";
      alertMessage = "Ha ocurrido un error";
    }
    if (
      !successCreatingScale &&
      !errorCreatingScale &&
      !successEditingScale &&
      !errorEditingScale
    )
      return;
    else
      return (
        <div className={alertClassName} role="alert">
          {alertMessage}
          <button
            onClick={() => {
              dispatch({
                type: ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_USERSCALES_CLEAR,
              });
            }}
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
  };
  return (
    <div>
      {renderAlert()}
      <form onSubmit={submitForm}>
        <div className="form-group">
          <div className="row">
            <div className="col-12">
              <label htmlFor="scalename">Nombre de la subcategoria</label>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                required
                type="name"
                className="form-control"
                id="scalename"
                placeholder="e.g Subteniente (promoción especial), Sargento Primero, Cap. 1/o"
              />
            </div>
            <div className="col-4">
              <label htmlFor="scaleOrder">Orden de la subcategoria</label>
              <input
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
                value={order}
                required
                type="number"
                className="form-control"
                id="scaleOrder"
                placeholder="1,2..10,11"
              />
            </div>
            <div className="col-4">
              <label htmlFor="scalename">Nombre de la subcategoria</label>
              <Select
                hideSelectedOptions
                onChange={(data) => {
                  setSelectedCategory(data);
                }}
                value={selectedCategory}
                closeMenuOnSelect={false}
                defaultValue={selectedCategory}
                options={categories.map((type) => {
                  type.value = type.id;
                  type.label = type.name;
                  return type;
                })}
              />
            </div>
            <div className="col-4">
              <label htmlFor="scaleslug">Slug</label>
              <input
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                value={slug}
                required
                type="name"
                className="form-control"
                id="scaleslug"
                placeholder="e.g subteniente_special_promo, sargento_primero, cap_1ro"
              />
              <small id="emailHelp2" className="form-text text-muted">
                Este es un nombre para usarlo a como referencia a nivel codigo,
                por favor, evite usar caracteres especiales y espacios
              </small>
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <button
              disabled={loadingCreatingScale || loadingEditingScale}
              type="submit"
              className="btn btn-primary"
            >
              {editMode ? "Editar" : "Crear"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateSubcategoryForm;
