import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { cmsAPI } from "../utils/http-client";
import { useUserJWT } from "../redux/selectors";
const PrivateRoute = ({ component: Component, ...rest }) => {
  const jwt = useUserJWT();
  return (
    <Route
      {...rest}
      render={(props) =>
        jwt ? (
          <>
            {cmsAPI.addJwt(jwt)}
            <Component {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export default PrivateRoute;
