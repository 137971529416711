import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { fetchCategoriesDD } from "./actions";
import CategoryLink from "./containers/DropdownItem";
import {
  useCategoriesDD,
  useErrorCategories,
  useFetchingCategories,
  useSuccessCategories,
} from "./selectors";
const CategoriesDD = (props) => {
  const dispatch = useDispatch();
  const categoriesArr = useCategoriesDD();
  const fetching = useFetchingCategories();
  const fetchingError = useErrorCategories();
  const fetchingSuccess = useSuccessCategories();
  useEffect(() => {
    dispatch(fetchCategoriesDD());
  }, []);
  const renderCategories = () => {
    if (fetching) return "cargando...";
    if (fetchingError) return "ha ocurrido un error";
    if (!categoriesArr) return "...";
    return categoriesArr.map((category) => (
      <CategoryLink name={category.name} link={`/c/${category.slug}`} />
    ));
  };
  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret>
        Militares
      </DropdownToggle>
      <DropdownMenu right>{renderCategories()}</DropdownMenu>
    </UncontrolledDropdown>
  );
};
export default CategoriesDD;
