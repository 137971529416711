import React, { Component, createRef } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import ProfileData from "../myprofile/components/ProfileData";
import NotificationAlert from "react-notification-alert";
import AlertNotify, { notify } from "../../components/AlertNotify";
import LandingPage from "./containers/LandingPage";

class IndexView extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    //notify("primary", "Testing notifications alerts", this.notificationAlert);
  }
  render() {
    return (
      <PublicLayout navPosition={"fixed-top"}>
        <NotificationAlert ref={this.notificationAlert} />
        <LandingPage />
      </PublicLayout>
    );
  }
}

export default IndexView;
