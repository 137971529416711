import React, { Component, useState, useEffect } from "react";
import SubjectProfile from "./containers/SubjectProfile";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import { cmsAPI } from "../../utils/http-client";
import reducer from "./reducer";
import { SubjectListSkeleton } from "../../components/SubjectListSkeleton";
import { useDispatch } from "react-redux";
import { getSubjectProfileDATA } from "./actions";
import {
  useSubjectTopics,
  useSubject,
  useFetchingProfile,
  useFetchingSubjectError,
  useFetchingPTopicsSuccess,
} from "./selectors";
import { useHistory } from "react-router-dom";

const SubjectProfileView = (props) => {
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props;
  const subjectTopics = useSubjectTopics();
  const subject = useSubject();
  const fetchingSubject = useFetchingProfile();
  const fetchingSubjectSuccess = useFetchingPTopicsSuccess();
  const fetchingSubjectError = useFetchingSubjectError();

  useEffect(() => {
    if (params.subjectId)
      dispatch(getSubjectProfileDATA(params.subjectId));
  }, [dispatch, params.subjectId]);
  useEffect(() => {
    //console.log("Subject fetched", subject);
  }, [subject]);
  return (
    <AuthenticateLayout>
      {fetchingSubject || !subject ? (
        <SubjectListSkeleton />
      ) : (
        <SubjectProfile
          fetchingSubject={fetchingSubject}
          subjectError={fetchingSubjectError}
          subject={subject}
        />
      )}
    </AuthenticateLayout>
  );
};
export { reducer };
export default SubjectProfileView;
