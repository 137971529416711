import React from "react";
import { Button } from "reactstrap";
import {
  useEditViewFlashcards,
  useUpdateFlashcardError,
  useUpdateFlashcardSuccess,
  useUpdateTopicLoading,
} from "../selectors";
const SaveFlashcard = ({
  saveAction = () => {
    console.log("Atach an action");
  },
  usavedChangesFlag,
  ...props
}) => {
  const buttonSize = "sm"
  const originalFlashcards = useEditViewFlashcards();
  const savingF = useUpdateTopicLoading();
  const saved = useUpdateFlashcardSuccess();
  const savedError = useUpdateFlashcardError();
  const renderStatus = () => {
    if (usavedChangesFlag)
      return <i className="text-primary fas fa-circle"></i>;
    if (savingF) return <i className="far fa-clock"></i>;
    if (saved)
      return <i className="text-success far fa-check-circle"></i>;
    if (savedError)
      return <i className="text-danger fas fa-exclamation-circle"></i>;
  };
  return (
    <div className="d-flex align-items-center">
      {renderStatus()}
      <Button
      size={buttonSize}
      onClick={saveAction} color="success ml-2">
        <i className="fas fa-save"></i>
      </Button>
    </div>
  );
};
export default SaveFlashcard;
