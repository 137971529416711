import React, { Component } from "react";
import TopicsList from "../containers/TopicList";
import FlashcardsList from "../containers/FlashcardsList";
import { cmsAPI } from "../../../utils/http-client";
import QACards from "../containers/QACards";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as FlashcardActions from "../actions";
class TopicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectId: "",
      topicId: "",

      subject: {},
      loadingSubject: true,

      topic: {},
      loadingTopic: true,

      loadingFlashcards: true,
      flashcards: [],

      topics: [],
      loadingTopics: true,
    };
  }
  componentDidMount() {
   //console.log("Props", this.props);
    if (this.props.match.params && this.props.match.params.subjectId) {
      this.getSubject(this.props.match.params.subjectId);
    }
    if (this.props.match.params && this.props.match.params.topicId) {
      this.getTopic(this.props.match.params.topicId);
    }
  }
  getTopic = (topicId) => {
    cmsAPI
      .get(`/topics/${topicId}`)
      .then((res) => {
       //console.log("Topic:", res);
        this.setState(
          {
            topic: res.data,
          },
          () => {}
        );
        cmsAPI
          .get(`/flashcards?topic=${res.data.id}`)
          .then((res) => {
           //console.log("Flashcards:", res);
            this.setState(
              {
                loadingFlashcards: false,
                flashcards: res.data,
              },
              () => {
               //console.log("Flashcards State", this.state.flashcards);
              }
            );
          })
          .catch((err) => {
            this.setState({
              loadingFlashcards: false,
            });
          });
      })
      .catch((err) => {
       //console.log("Topic Err:", err);
        return;
      });
  };
  getSubject = (subjectId) => {
    cmsAPI
      .get(`/subjects/${subjectId}`)
      .then((res) => {
       //console.log("Subject:", res);
        this.setState(
          {
            subject: res.data,
          },
          () => {
            this.getTopics(this.state.subject.id);
          }
        );
      })
      .catch((err) => {
       //console.log("Subject Err:", err);
        return;
      });
  };
  getTopics = (subjectId) => {
   //console.log("");
    this.setState({
      loadingTopics: true,
    });
    cmsAPI
      .get(`/topics?in_subjects=${subjectId}`)
      .then((res) => {
       //console.log("Response Topics:", res);
        this.setState({
          topics: res.data,
          loadingTopics: false,
        });
      })
      .catch((err) => {
       //console.log("Error Topic", err);
        this.setState({
          loadingTopics: false,
        });
      });
  };
  render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <TopicsList
            subjectId={this.state.subject.id}
            topics={this.state.topics}
            topicIdActive={this.state.topicId}
          />
        </div>
        <div className="col-md-3">
          <FlashcardsList flashcards={this.state.flashcards} />
        </div>
        <QACards question={this.props.activeFlashcard ? this.props.activeFlashcard.question : ""} answer={this.props.activeFlashcard ? this.props.activeFlashcard.answer : ""} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeFlashcard: state.topicDetails.activeFlashcard,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setActiveFlashcard: bindActionCreators(
      FlashcardActions.setActiveFlashcard,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicDetails);
