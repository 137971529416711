import React, { Component, useState, useEffect } from "react";
import { cmsAPI } from "../utils/http-client";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ModalAlert from "./ModalAlert";

const TopicCard = (props) => {
  //console.log("Topic", props);
  const [ModalAlertOpen, setModalAlertOpen] = useState(false);
  const history = useHistory();
  const [pathname, setPathname] = useState("");
  useEffect(() => {
   //console.log("History:", history);
    if (history.location && history.location) {
      let value = history.location.pathname;
      setPathname(value);
    }
  }, [history]);
  const {
    id,
    name,
    description,
    image,
    createdDate,
    btnTextMyFlashcard,
    buttonOnClick,
    btnText,
    saved,

    inMyFlashcards,
    onClickEdit,
    onClickDelete,
    onClickDetails,
    hideActionButton,
  } = props;
  if (!props.legacy) {
    return (
      <>
        <div className="row">
          <div className="col-md-1 text-center">
            <div className="avatar avatar-lg">
              <img
                src={
                  image
                    ? cmsAPI.resolveAssetAPI(image.url)
                    : "/images/image420.png"
                }
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="flex-1 ml-3 pt-1">
              <h6 className="text-uppercase fw-bold mb-1">
                {props.name}
                {/* <span className="text-warning pl-3">pending</span> */}
              </h6>
              <span className="text-muted">{props.description}</span>
            </div>
          </div>
          <div className="col-md-3 float-right text-center">
            <div className="pt-1">
              <div className="">
                {!props.hideActionButton && (
                  <div className="">
                    <Link
                      to={`/myflashcards/match/${id}`}
                      className="btn btn-success"
                    >
                      Practicar
                    </Link>
                    <Link
                      to={pathname + `/t/${id}`}
                      className="btn btn-primary"
                    >
                      Detalles
                    </Link>
                    <button className="btn btn-secondary">
                      <i className="fa fa-edit" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="card mb-3">
      <div className="row no-gutters">
        <div className="col-md-4 align-self-center text-center">
          <div className="avatar avatar-xxl">
            <img
              src={
                image ? cmsAPI.resolveAssetAPI(image.url) : "/images/image420.png"
              }
              alt="..."
              className="avatar-img"
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="card-body">
            <h3 className="card-title">{name ? name.toUpperCase() : ""}</h3>
            <p className="card-text">{description}</p>
            <p className="card-text">
              <small className="text-muted">{createdDate}</small>
            </p>
            {hideActionButton && saved ? (
              <Link to="/myflashcards">En Mis Flashcards</Link>
            ) : (
              <div
                className="row text-center"
                role="group"
                aria-label="Basic example"
              >
                {inMyFlashcards && (
                  <>
                    <button
                      onClick={() => {
                        history.push("/myflashcards/match/" + id);
                      }}
                      type="button"
                      className="btn btn-success"
                    >
                      Practicar
                    </button>
                    <button
                      type="button"
                      onClick={onClickEdit}
                      className="btn btn-primary"
                    >
                      Editar
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        history.push(pathname + "/t/" + id);
                      }}
                      className="btn btn-secondary"
                    >
                      Ver detalles
                    </button>
                    <ModalAlert
                      isOpen={ModalAlertOpen}
                      toggle={() => setModalAlertOpen(!ModalAlertOpen)}
                      buttonLabel="Eliminar"
                      modalTitle="Eliminar de mis flashcards"
                      bodyText="Estás apunto de borrar tu progreso en este tema"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          onClickDelete();
                          setModalAlertOpen(!ModalAlertOpen);
                        }}
                        className="btn btn-danger"
                      >
                        Eliminar
                      </button>
                    </ModalAlert>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
TopicCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  createdDate: PropTypes.string,
  btnTextMyFlashcard: PropTypes.bool,
  btnText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  inMyFlashcards: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  hideActionButton: PropTypes.bool,
};
export default TopicCard;
