import React, { Component } from "react";
import PropTypes from "prop-types";
import { cmsAPI } from "../../../utils/http-client";
import Skeleton from "react-loading-skeleton";
import { getSubjectsAction } from "../actions";
import { useDispatch } from "react-redux";
import CategorieCard from "../containers/CategorieCard";
import { CategorieSideItem } from "../containers/CategorieSideItem";

class CategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reqError: false,
      categories: [],
    };
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories = () => {
    this.setState({
      loading: true,
    });
    cmsAPI
      .get("categories?_limit=9999")
      .then((res) => {
        this.setState({
          loading: false,
          categories: res.data,
        });
      })
      .catch((err) => {
       //console.log("error getting categories");
        this.setState({
          loading: false,
          reqError: true,
        });
      });
  };
  render() {
    if (!this.state.loading && this.state.reqError) {
      return (
        <div className="text-center">
          <h1>Ha ocurrido un error al consultar las categorias</h1>
        </div>
      );
    }
    return (
      <>
        {this.state.categories && (
          <>
            {this.state.categories.map((categorie, index) => (
              <CategorieSideItem key={index} {...categorie} />
            ))}
          </>
        )}
      </>
    );
  }
}

CategoriesList.propTypes = {};

export default CategoriesList;
