import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  BrowserRouter as Router,
  useRouteMatch,
  useHistory,
} from "react-router-dom";

const OptionDropdown = ({ color, style, className, options=[{onClick:()=>{},label:"Action"}], ...props }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = (e) => {
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };
  const path = useRouteMatch();
  const history = useHistory();
  useEffect(() => {}, []);
  return (
    <Dropdown
      style={style}
      className={className}
      isOpen={dropdownOpen}
      toggle={(e) => toggle(e)}
    >
      <DropdownToggle color={color || "link"}>
        {<i style={{ fontSize: "1.5em" }} className="fas fa-ellipsis-v" />}
      </DropdownToggle>
      <DropdownMenu>
        {options != null &&
          options.length > 0 &&
          options.map((option) => (
            <DropdownItem onClick={option.onClick}>
              {option?.label || "..."}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default OptionDropdown;
