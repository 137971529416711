import React from "react";
import Skeleton from "react-loading-skeleton";
export const SubjectListSkeleton = (props) => {
  return (
    <div className="">
      <div>
        <div
          className="mx-auto text-center"
          style={{ width: "90%", marginTop: 50 }}
        >
          <div className="row">
            <div className="col-4 d-flex align-items-center justify-content-center">
              <Skeleton width={230} height={230} />
            </div>
            <div className="col-8">
              <Skeleton height={50} style={{margin:20}} count={3} />
            </div>
          </div>
          <div>
            <Skeleton style={{ marginTop: 50 }} count={3} height={120} />
          </div>
        </div>
      </div>
    </div>
  );
};
