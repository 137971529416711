import { useSelector } from "react-redux";
export const useCreating = () => {
  return useSelector(
    ({ createSubject }) => createSubject && createSubject.loading
  );
};
export const useCreatingSuccess = () => {
  return useSelector(
    ({ createSubject }) => createSubject && createSubject.success
  );
};
export const useCreatingError = () => {
  return useSelector(
    ({ createSubject }) => createSubject && createSubject.error
  );
};
export const useCreatedSubject = () => {
  return useSelector(
    ({ createSubject }) => createSubject && createSubject.subject
  );
};

export const useCreatedSubjectModal = () => {
  return useSelector(
    ({ createSubject }) => createSubject && createSubject.modalActive
  );
};
