import { ACTIONS_CATEGORIES_DD } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_START:
      return {
        loading: true,
        categories: state.categories,
        ...payload,
      };
    case ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_SUCCESS:
      return {
        loading: false,
        success: true,
        ...payload,
      };
    case ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_ERROR:
      return {
        loading: false,
        success: false,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
