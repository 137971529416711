import { FLASHCARD_ACTIONTYPE } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case FLASHCARD_ACTIONTYPE.SET_ACTIVE_FLASHCARD:
      return {
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
