import React, { Component } from "react";
import ProfileData from "./components/ProfileData";
import reducer from "./reducer";
import { cmsAPI } from "../../utils/http-client";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

class UserProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingUserError: true,
      loading: true,
      user: {},
    };
  }
  getUserMe = () => {
    cmsAPI
      .get("users/me")
      .then((res) => {
       //console.log("UserProfileView <Response>:", res);
        this.setState({
          fetchingUserError: false,
          loading: false,
          user: res.data,
        });
      })
      .catch((err) => {
       //console.log("UserProfileView <Req-ERROR>:", err);
        this.setState({
          fetchingUserError: true,
          loading: false,
        });
      });
  };
  componentDidMount() {
    this.getUserMe();
  }
  render() {
   //console.log("ThisProps", this.props);
    const { user, loading, fetchingUserError } = this.state;
    return (
      <>
        {loading ? (
          <div className="">
            <div style={{ marginTop: 20 }}>
              <div className="row">
                <div className="col-4">
                  <Skeleton height={200} />
                  <Skeleton style={{ marginTop: 50 }} count={5} height={25} />
                </div>
                <div className="col-8">
                  <Skeleton height={100} />
                  <Skeleton style={{ marginTop: 50 }} count={8} height={25} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ProfileData user={user} />
        )}
      </>
    );
  }
}

export default UserProfileView;

export { reducer };
