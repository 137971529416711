import React, { Component, createRef } from "react";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import { cmsAPI } from "../../../utils/http-client";
import NotificationAlert from "react-notification-alert";
import { notify } from "../../../components/AlertNotify";
import SubjectCard from "../../../components/SubjectCard";
import { Link } from "react-router-dom";
import { CustomInput, Label, FormGroup, Spinner } from "reactstrap";

class PickTopicDD extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {
      privacyRadio: "private",
      //fetching Subject Picked Previously
      subjectLoading: false,
      subjectError: false,
      subjectSuccess: false,
      subject: {},
      //fetchingtopics
      topicsLoading: true,
      topicsError: false,
      topicsSuccess: false,
      topics: [],

      //Request Create subject
      loading: false,
      requestError: false,
      requestSuccess: false,
      createdNew: false,

      userTopicId: "",
      topicId: "",
      selectedOption: null,
      showTopicForm: false,
      options: [{ value: "createTopic", label: "Crear nueva tema" }],

      topicForm: {
        image: [],
        name: "",
        description: "",
        public: false,
      },
    };
  }
  componentDidMount() {
    //console.log("Props create topic", this.props);
    let {
      match: { params },
    } = this.props;
    //console.log("Params ID", params);
    if (!params.subjectId) {
      this.props.history.push({ path: "/myflashcards/create" });
      return;
    }
    this.getSubjectPicked(params.subjectId);
    this.getTopics();
  }
  getSubjectPicked = (id) => {
    this.setState({
      subjectLoading: true,
    });
    //console.log("ID", id);
    cmsAPI
      .get(`/subjects/${id}`)
      .then((res) => {
        //console.log("Response", res);
        this.setState({
          subjectLoading: false,
          subject: res.data,
        });
      })
      .catch((err) => {
        //console.log("Error", err);
        this.setState({
          subjectLoading: true,
          subjectError: true,
        });
      });
  };
  getTopics = () => {
    cmsAPI
      .get(`/topics&_limit=9999`)
      .then((res) => {
        let { options } = this.state;
        res.data.map((subject) => {
          options.push({ value: subject.id, label: subject.name });
        });
        //console.log("topics", res.data, "ºº Options", options);
        this.setState({
          options,
          topics: res.data,
          topicsLoading: false,
          topicsSuccess: true,
        });
      })
      .catch((err) => {
        //console.log("Error al crear topics");
        this.setState({
          topicsError: true,
          topicsLoading: false,
        });
      });
  };
  handlePrivacyChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let { topicForm } = this.state;
    //console.log("Privacy", name, "Value:", value);
    if (value === "public") topicForm.public = true;
    if (value === "private") topicForm.public = false;
    //console.log("Form", topicForm);
    this.setState({
      topicForm,
      privacyRadio: value,
    });
  };
  handleChangeOption = (selectedOption) => {
    if (selectedOption.value === "createTopic") {
      this.setState(
        { selectedOption, showTopicForm: true, topicId: "" },
        () => {
          //  console.log(
          //   `Option selected:`,
          //   this.state.selectedOption,
          //   this.state.showTopicForm
          // )
        }
      );
      return;
    }
    this.setState(
      {
        selectedOption,
        showTopicForm: false,
        topicId: selectedOption.value,
      },
      () => {}
    );
  };
  handleTopicChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let { topicForm } = this.state;
    //console.log("Changinging", name, "Value:", value);
    if (name === "name") {
      topicForm.name = value;
      this.setState({
        topicForm,
      });
      return;
    }
    if (name === "description") {
      topicForm.description = value;
      this.setState({
        topicForm,
      });
      return;
    }
  };
  onDropTopicImage = (picture) => {
    let { topicForm } = this.state;
    topicForm.image = picture;
    //console.log("PictureSubject", picture, "STATE:", topicForm);
    this.setState({
      topicForm,
    });
  };
  handleInput = (e) => {
    e.preventDefault();
    let { topicForm } = this.state;
    if (!topicForm.name)
      return notify(
        "warning",
        "Proporciona un nombre al tema",
        this.notificationAlert
      );
    if (!topicForm.description)
      return notify(
        "warning",
        "Proporciona una descripcion al tema",
        this.notificationAlert
      );
    this.setState({ loading: true });
    let form = new FormData();
    form.append("files.image", topicForm.image[0]);
    form.append(
      "data",
      JSON.stringify({
        subject: this.state.subject.id,
        name: topicForm.name,
        description: topicForm.description,
        public: topicForm.public,
      })
    );
    //console.log("From will send", form);
    cmsAPI
      .post("/topics", form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log("Sent", res);
        cmsAPI
          .post("/flashcards", { topic: res.data.id, answer: "", question: "" })
          .then((response) => {
            //console.log("UserTopic Created", response);
            this.setState({
              newFlashcard: response.data,
            });
          })
          .catch((error) => {
            //console.log("UserTopic Error", error);
          });
        // cmsAPI
        //   .post("/userflashcards/createMultiple", { topicId: res.data.id })
        //   .then((response) => {
        //    //console.log("UserTopic Created", response);
        //     this.setState({
        //       userTopicId: response.data.id,
        //     });
        //   })
        //   .catch((error) => {
        //    //console.log("UserTopic Error", error);
        //   });
        this.setState({
          loading: false,
          createdNew: true,
          topicId: res.data.id,
        });
        notify("success", "Creado con exito", this.notificationAlert);
      })
      .catch((err) => {
        //console.log("Error send", err);
        this.setState({
          loading: false,
        });
        notify("danger", "Error al crear topic", this.notificationAlert);
      });
  };
  render() {
    return (
      <div className="">
        <NotificationAlert ref={this.notificationAlert} />
        {/* New container */}
        <>
          {/* New Form START*/}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Crear Temas</div>
                </div>
                <div className="card-body">
                  <div className="col-md mb-3">
                    {/* TITLE */}
                    <div className="py-5 text-center">
                      <h2>Crear mis flashcards - Paso 2 / 3</h2>
                      <p className="lead">
                        Crea temas para la materia seleccionada
                      </p>
                    </div>
                    {/* TITLE */}
                    <div className="mt-4">
                      <div className="card">
                        <div className="card-header text-center">
                          <h4>Materia</h4>
                        </div>
                        <div className="card-body">
                          <SubjectCard
                            {...this.state.subject}
                            hideActionButton
                            {...this.props}
                          />
                        </div>
                      </div>
                      <>
                        <form onSubmit={this.handleInput}>
                          {/* new form container */}
                          <>
                            <div className="card-body">
                              <h2 className="text-center">Crear Tema</h2>
                              <div className="row">
                                <div className="col-md-4">
                                  <ImageUploader
                                    style={{}}
                                    withPreview
                                    singleImage={true}
                                    withIcon={true}
                                    buttonText="Subir imagen"
                                    onChange={this.onDropTopicImage}
                                    imgExtension={[".jpg", ".jpeg", ".png"]}
                                    maxFileSize={5242880}
                                  />
                                </div>
                                <div className="col-md-8">
                                  <div className="d-flex">
                                    <div className="flex-1 ml-3 pt-1">
                                      <label htmlFor="name">Nombre</label>
                                      <input
                                        onChange={this.handleTopicChange}
                                        value={this.state.topicForm.name}
                                        type="text"
                                        className="form-control"
                                        id="subject"
                                        name="name"
                                        placeholder="Nombre de la materia a la que pertenece tus flashcards"
                                      />
                                      <p className="card-text">
                                        <label htmlFor="subjectDescription">
                                          Descripción
                                        </label>
                                        <input
                                          style={{ height: 200 }}
                                          onChange={this.handleTopicChange}
                                          value={
                                            this.state.topicForm.description
                                          }
                                          type="text"
                                          className="form-control"
                                          id="subjectDescription"
                                          name="description"
                                          placeholder="Descripción de la materia"
                                        />
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <FormGroup>
                                      <Label for="privacyRadios">
                                        Acceso a los usuarios
                                      </Label>
                                      <div>
                                        <CustomInput
                                          checked={
                                            this.state.privacyRadio === "public"
                                          }
                                          type="radio"
                                          id="public"
                                          name="privacy"
                                          label="Publico"
                                          value={"public"}
                                          onChange={this.handlePrivacyChange}
                                        />
                                        <CustomInput
                                          checked={
                                            this.state.privacyRadio ===
                                            "private"
                                          }
                                          type="radio"
                                          id="private"
                                          name="privacy"
                                          label="Privado"
                                          value={"private"}
                                          onChange={this.handlePrivacyChange}
                                        />
                                      </div>
                                    </FormGroup>
                                  </div>
                                </div>
                              </div>
                              <div className="text-center">
                                <button
                                  disabled={
                                    this.state.createdNew || this.state.loading
                                  }
                                  onClick={() => {
                                    //console.log("Clickedd ");
                                  }}
                                  className="btn btn-primary button-small"
                                >
                                  {this.state.loading ? (
                                    <Spinner color={"white"} />
                                  ) : (
                                    "Crear"
                                  )}
                                </button>
                              </div>
                            </div>
                          </>
                          {/* new form container */}
                        </form>
                      </>
                    </div>
                    <div className="text-center">
                      {this.state.topicId && (
                        <button
                          onClick={() => {
                            this.props.history.push(
                              `/myflashcards/create/f/${this.state.topicId}`
                            );
                          }}
                          className="btn btn-primary"
                        >
                          Siguiente paso
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* New Form END */}
        </>
        {/* New container */}
      </div>
    );
  }
}

export default PickTopicDD;
