import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const DropDownCustom = ({toggleText, ...props}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="ml-2 btn-sm"caret>
        {toggleText}
      </DropdownToggle>
      <DropdownMenu>
        {props.children}
      </DropdownMenu>
    </Dropdown>
  );
}

export default DropDownCustom;