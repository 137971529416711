import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import PropTypes from 'prop-types';

const AlertCard = (props) => {
    const {
        toggle,
        isOpen,
        color,
        message
    } = props;
    // const [visible, setVisible] = useState(true);
    // const onDismiss = () => setVisible(false);
  return (
    <Alert color={color} isOpen={isOpen} toggle={toggle}>
        {message}
    </Alert>
  );
}

AlertCard.propTypes = {
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  toggle: PropTypes.func
};
export default AlertCard;