import { cmsAPI } from "../../utils/http-client";
export const SIGNUP_ACTION_TYPE = {
  SIGNUP_START: "SIGNUP_START",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_END: "SIGNUP_END",
  SIGNUP_ERROR: "SIGNUP_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
};
export const signUp = (form) => (dispatch) => {
  dispatch({ type: "SIGNUP_START" });
  cmsAPI
    .post("/auth/local/register", form)
    .then((res) => {
     //console.log("login RESPONSE:", res);
      const { status } = res;
      dispatch({ type: SIGNUP_ACTION_TYPE.SIGNUP_SUCCESS, status: status });
    })
    .catch((err) => {
     console.log("errRes", err.response);
      if (err.response) {
        const { status } = err.response;
        const { id } = err.response.data.message[0].messages[0];
        if (status) {
          return dispatch({
            type: SIGNUP_ACTION_TYPE.SIGNUP_ERROR,
            status: status,
            errorID: id,
          });
        }
      }
      dispatch({ type: SIGNUP_ACTION_TYPE.SIGNUP_END });
    });
};
export const clearError = () => (dispatch) => {
  dispatch({ type: SIGNUP_ACTION_TYPE.CLEAR_ERROR });
};
 