import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { ThemeColors } from "../../../../lib/Colors";
import {
  ADMIN_CATEGORY_ACTION_TYPE,
  createScaleAction,
  editScaleAction,
} from "../actions";

const CreateCategoryForm = ({ editMode, editData }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const successCreatingScale = useSelector(
    ({ adminCategories }) => adminCategories.createCategory.success
  );
  const errorCreatingScale = useSelector(
    ({ adminCategories }) => adminCategories.createCategory.error
  );
  const loadingCreatingScale = useSelector(
    ({ adminCategories }) => adminCategories.createCategory.loading
  );
  const successEditingScale = useSelector(
    ({ adminCategories }) => adminCategories.editCategory.success
  );
  const errorEditingScale = useSelector(
    ({ adminCategories }) => adminCategories.editCategory.error
  );
  const loadingEditingScale = useSelector(
    ({ adminCategories }) => adminCategories.editCategory.loading
  );
  useEffect(() => {
    console.log("editMode", editMode);
    if (editMode) {
      if (editData != null) {
        setName(editData.name);
        setSlug(editData.slug);
        setIsDisabled(editData.disabled);
      }
    }
  }, [editMode]);
  const submitForm = (e) => {
    e.preventDefault();
    const form = { name, slug, disabled: isDisabled };
    if (!name || !slug) {
      alert("Llena los campos requeridos");
      return;
    }
    if (editMode) {
      dispatch(editScaleAction({ userscaleId: editData.id, form }));
      return;
    } else {
      dispatch(createScaleAction({ form }));
    }
  };
  const renderAlert = () => {
    let alertClassName = "alert alert-secondary alert-dismissible fade show";
    let alertMessage = "";
    if (successCreatingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha creado correctamente";
    }
    if (successEditingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha editado correctamente";
    }
    if (errorCreatingScale || errorEditingScale) {
      alertClassName = "alert alert-danger alert-dismissible fade show";
      alertMessage = "Ha ocurrido un error";
    }
    if (
      !successCreatingScale &&
      !errorCreatingScale &&
      !successEditingScale &&
      !errorEditingScale
    )
      return;
    else
      return (
        <div className={alertClassName} role="alert">
          {alertMessage}
          <button
            onClick={() => {
              dispatch({
                type: ADMIN_CATEGORY_ACTION_TYPE.EDIT_USERSCALES_CLEAR,
              });
            }}
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
  };
  return (
    <div>
      {renderAlert()}
      <form onSubmit={submitForm}>
        <div className="form-group">
          <div className="row">
            <div className="col-5">
              <label htmlFor="scalename">Nombre de la categoría</label>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                required
                type="name"
                className="form-control"
                id="scalename"
                placeholder="e.g Subteniente (promoción especial), Sargento Primero, Cap. 1/o"
              />
            </div>
            <div className="col-2">
              <label htmlFor="scaleslug">Activo</label>
              <Switch
                onColor={ThemeColors.primary}
                checkedIcon={false}
                uncheckedIcon={false}
                onChange={() => setIsDisabled((prevState) => !prevState)}
                checked={isDisabled==false}
              />
            </div>
            <div className="col-5">
              <label htmlFor="scaleslug">Slug</label>
              <input
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                value={slug}
                required
                type="name"
                className="form-control"
                id="scaleslug"
                placeholder="e.g subteniente_special_promo, sargento_primero, cap_1ro"
              />
              <small id="emailHelp2" className="form-text text-muted">
                Este es un nombre para usarlo a como referencia a nivel codigo,
                por favor, evite usar caracteres especiales y espacios
              </small>
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <button
              disabled={loadingCreatingScale || loadingEditingScale}
              type="submit"
              className="btn btn-primary"
            >
              {editMode ? "Editar" : "Crear"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateCategoryForm;
