import React, { useEffect, useState } from "react";
import { Children } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteTopic } from "../../../views/subjectProfile/actions";
import ModalView from "../../ModalView";
import { useDeleteSTSuccess } from "../../../views/subjectProfile/selectors";
import FlashcardPreviewModal from "../../FlashcardPreviewModal/FlashcardPreviewModal";

const ActionsDD = ({
  color,
  style,
  className,
  childComponent,
  topic,
  hideDelete,
  showPreviewModal,
  showPreviewOutside,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = (e) => {
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };
  const [deleteModal, setDeleteModal] = useState(false);
  const [preview, setPreviewModal] = useState(false);
  const dispatch = useDispatch();
  const path = useRouteMatch();
  const history = useHistory();
  const deleteSuccess = useDeleteSTSuccess();
  const deleteLoading = useDeleteSTSuccess();
  const deleteError = useDeleteSTSuccess();
  useEffect(() => {
    showPreviewModal(preview);
  }, [preview]);
  useEffect(() => {
    setPreviewModal(showPreviewOutside);
  }, [showPreviewOutside]);
  useEffect(() => {
    if (deleteSuccess) setDeleteModal(false);
  }, [deleteSuccess]);
  return (
    <Dropdown
      style={style}
      className={className}
      isOpen={dropdownOpen}
      toggle={(e) => toggle(e)}
    >
      <ModalView
        hideComponent
        title={`Eliminar Pila`}
        toggleFlag={deleteModal}
        toggleAction={() => setDeleteModal((prevState) => !prevState)}
        centered={true}
        confirmComponent={
          <Button
            onClick={() => {
              dispatch(deleteTopic(topic.id));
            }}
            color="danger"
          >
            Eliminar
          </Button>
        }
      >
        <h3>Estas apunto de eliminar: {topic?.name}</h3>
      </ModalView>
      <ModalView
        hideFooter
        modalBodyStyle={{
          maxHeight: "80vh",
          overflowY: "scroll",
        }}
        modalStyle={{ minWidth: "80vw" }}
        hideComponent
        title={`${topic?.name}`}
        titleStyles={{
          textAlign: "center",
          fontSize: 24,
          fontWeight: "bold",
        }}
        toggleFlag={preview}
        toggleAction={() => setPreviewModal((prevState) => !prevState)}
        centered={true}
      >
        <div>
          <FlashcardPreviewModal
            fetchFlashcards={true}
            topic={topic}
          ></FlashcardPreviewModal>
        </div>
      </ModalView>
      <DropdownToggle color={color}>{childComponent}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() =>
            history.push(topic ? `${path.url}/edit/${topic.id}` : "#")
          }
        >
          Editar
        </DropdownItem>
        <DropdownItem
          onClick={() =>
            setPreviewModal((prevState) => setPreviewModal(!prevState))
          }
        >
          Ver lista de flashcards
        </DropdownItem>
        {/* <DropdownItem>Explorar</DropdownItem> */}
        <DropdownItem divider />
        {!hideDelete && (
          <DropdownItem
            onClick={() => {
              setDeleteModal(true);
            }}
          >
            Eliminar
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ActionsDD;
