import { useSelector } from "react-redux";

export function useMySubjects() {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.subjects);
}
export function useLoadingMySubjects() {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.loading);
}
export function useSuccessMySubjects() {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.success);
}
export function useErrorMySubjects() {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.error);
}
export function useErrorMSGMySubjects() {
  return useSelector(({ mySubjects }) => mySubjects && mySubjects.errorMessage);
}
