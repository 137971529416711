import React, { Component } from "react";
import { useDispatch } from "react-redux";
import { getSubjectsAction } from "../actions";
const CategorieCard = ({ id, name, slug }) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{ cursor: "pointer" }}
      className="col-md-4"
      onClick={() => {
       //console.log("Categorie", id, slug);
        let category = { id, name, slug };
        dispatch(getSubjectsAction(`categories=${id}`, category));
      }}
    >
      <div className="card card-stats card-round">
        <div className="card-body ">
          <div className="row">
            <div className="col-5">
              <div className="icon-big text-center">
                <i className="fa fa-circle" aria-hidden="true" />
              </div>
            </div>
            <div className="col-7 col-stats">
              <div className="numbers">
                <h4
                  className="card-title"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {name}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategorieCard;
