import React, { useState } from "react";
import AuthenticateLayout from "../../layouts/AuthenticateLayout";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import ProfileForm from "./components/ProfileForm";
import ProfilePasswordsForm from "./components/ProfileChangePassword";

const ProfileView = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return (
    <AuthenticateLayout>
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              Informacion basica
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Seguridad
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ProfileForm></ProfileForm>
          </TabPane>
          <TabPane tabId="2">
          <ProfilePasswordsForm></ProfilePasswordsForm>
          </TabPane>
        </TabContent>
      </div>
    </AuthenticateLayout>
  );
};
export default ProfileView;
