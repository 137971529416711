import React, { Component, useEffect, useState } from "react";
import { cmsAPI } from "../../../utils/http-client";
import { getUser } from "../../../lib/localstorage";
const UserSideBar = (props) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    const userItem = getUser();
    if (userItem) setUser(userItem);
    return () => {};
  }, []);
  return (
    <>
      {user && (
        <div className="user">
          <div className="avatar-sm float-left mr-2">
            <img
              src={
                user && user.profile_picture
                  ? cmsAPI.resolveAssetAPI(user.profile_picture.url)
                  : "/assets/img/avatar-default.png"
              }
              alt="..."
              className="avatar-img rounded-circle"
            />
          </div>
          <div className="info">
            <a
              data-toggle="collapse"
              href="#collapseExample"
              aria-expanded="true"
            >
              <span className="">
                <span>{user.username}</span>
                <span className="user-level">
                  Plan: {user.premium ? "Premium" : "Basico"}
                </span>
              </span>
            </a>
            <div className="clearfix" />
            <div className="collapse in" id="collapseExample">
              <ul className="nav">
                <li>
                  <a href="/profile">
                    <span className="link-collapse">Mi Perfil</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserSideBar;
