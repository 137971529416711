import React from "react";
import { useHistory } from "react-router-dom";
const TopicsList = (props) => {
  const { topics, subjectId } = props;
  const history = useHistory();
  const pathname = history.location.pathname;
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title">Temas</h4>
      </div>
      <div className="card-body">
        <ul className="list-group list-group-bordered">
          {topics
            ? topics.map((topic) => {
                return (
                  <TopicListItem
                    active={topic.id === props.topicIdActive}
                    name={topic.name}
                    onClick={() => {
                      //console.log(
                      //   `Click!> /subjects/${subjectId}/t/${topic.id}`
                      // );
                      history.push(`/subjects/${subjectId}/t/${topic.id}`);
                    }}
                  />
                );
              })
            : "Ha ocurrido un error"}
        </ul>
      </div>
    </div>
  );
};
export default TopicsList;

const TopicListItem = ({ active, name, onClick }) => {
  return (
    <li
      data-toggle="tooltip"
      data-placement="right"
      title={name}
      style={{ cursor: "pointer" }}
      onClick={onClick}
      className={"list-group-item " + (active && "active")}
    >
      <p
        style={{
          width: "50%",
          fontSize: 12,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {name}
      </p>
    </li>
  );
};
