import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserScales } from "../../admin-userscales/actions";
import Select from "react-select";
import {
  ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE,
  createScaleAction,
  editSubcategoryScalesAction,
} from "../actions";
import { getSubcategories } from "../../admin-subcategories/actions";

const CreateSubCategoryScaleForm = ({ editMode, editData }) => {
  const dispatch = useDispatch();
  const [selectedScales, setSelectedScales] = useState([]);
  const [subcategoriesToShow, setSubcategoriesToShow] = useState([]);
  const userscales = useSelector(
    ({ adminUserScales }) => adminUserScales.userscales
  );
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [toHideIDs, setToHideIDs] = useState(null);
  const successCreatingScale = useSelector(
    ({ adminSubcategoryScales }) =>
      adminSubcategoryScales.createUserScale.success
  );
  const errorCreatingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.createUserScale.error
  );
  const loadingCreatingScale = useSelector(
    ({ adminSubcategoryScales }) =>
      adminSubcategoryScales.createUserScale.loading
  );
  const successEditingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.editUserScale.success
  );
  const errorEditingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.editUserScale.error
  );
  const loadingEditingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.editUserScale.loading
  );
  const subcategories = useSelector(
    ({ adminSubcategories }) => adminSubcategories.subCategories
  );
  const loadingSubcategories = useSelector(
    ({ adminSubcategories }) => adminSubcategories.loading
  );
  const subcategoryscales = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.userscales
  );
  useEffect(() => {
    dispatch(getUserScales());
    dispatch(getSubcategories());
    if (successCreatingScale) {
      setSelectedSubCategory(null);
      setSelectedScales([]);
    }
  }, [successCreatingScale]);
  useEffect(() => {
    console.log("editMode", editMode);
    if (editMode) {
      if (editData != null) {
        setName(editData?.subCategory?.name);
        if (
          editData != null &&
          editData?.userscales != null &&
          editData?.userscales.length > 0
        ) {
          let uScales = editData.userscales;
          setSelectedScales(
            uScales.map((uScale) => {
              uScale.value = uScale.id;
              uScale.label = uScale.name;
              return uScale;
            })
          );
        }
      }
    }
  }, [editMode]);
  useEffect(() => {
    if (
      !loadingSubcategories &&
      (subcategories != null || subcategories?.length >= 1) &&
      (subcategoryscales != null || subcategoryscales.length >= 1)
    ) {
      let subIdsSelected = subcategoryscales.reduce(
        ({ subCategoryIds, optionsToShow }, subScales) => {
          let found = optionsToShow.findIndex(
            (x) => x?.id == subScales.subCategory?.id
          );
          if (found > -1) {
            optionsToShow.splice(found, 1);
          }
          return {
            subCategoryIds: [...subCategoryIds, subScales.subCategory.id],
            optionsToShow,
          };
        },
        { subCategoryIds: [], optionsToShow: subcategories }
      );
      console.log("subcategories555", subIdsSelected.optionsToShow.length);
      setToHideIDs(subIdsSelected.subCategoryIds);
      setSubcategoriesToShow(subIdsSelected.optionsToShow);
    }
  }, [subcategoryscales, subcategories]);
  const submitForm = (e) => {
    e.preventDefault();
    if (editMode) {
      dispatch(
        editSubcategoryScalesAction({
          subcategoryScalesId: editData.id,
          userScales: selectedScales,
        })
      );
      return;
    } else {
      if (
        selectedSubCategory != null &&
        selectedSubCategory.id != null &&
        selectedScales != null &&
        selectedScales.length > 0
      ) {
        dispatch(
          createScaleAction({
            subCategory: selectedSubCategory.id,
            userscales: selectedScales,
          })
        );
      } else {
        alert("Selecciona las opciones requeridas");
      }
    }
  };
  const renderAlert = () => {
    let alertClassName = "alert alert-secondary alert-dismissible fade show";
    let alertMessage = "";
    if (successCreatingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha creado correctamente";
    }
    if (successEditingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha editado correctamente";
    }
    if (errorCreatingScale || errorEditingScale) {
      alertClassName = "alert alert-danger alert-dismissible fade show";
      alertMessage = "Ha ocurrido un error";
    }
    if (
      !successCreatingScale &&
      !errorCreatingScale &&
      !successEditingScale &&
      !errorEditingScale
    )
      return;
    else
      return (
        <div className={alertClassName} role="alert">
          {alertMessage}
          <button
            onClick={() => {
              dispatch({
                type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.EDIT_SUBCATEGORYSCALES_CLEAR,
              });
            }}
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            style={{
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
  };
  return (
    <div>
      {renderAlert()}
      <form onSubmit={submitForm}>
        <div className="form-group">
          <div className="col">
            <div className="col">
              <label htmlFor="scalename">Nombre de la subcategoria</label>
              {!editMode ? (
                subcategoriesToShow != null &&
                subcategoriesToShow.length > 0 && (
                  <Select
                    onChange={(data) => {
                      setSelectedSubCategory(data);
                    }}
                    value={selectedSubCategory}
                    closeMenuOnSelect={true}
                    options={subcategoriesToShow.map((type) => {
                      type.value = type.id;
                      type.label = type.name;
                      return type;
                    })}
                  />
                )
              ) : (
                <input
                  disabled={editMode}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                  required
                  type="name"
                  className="form-control"
                  id="scalename"
                  placeholder="e.g Subteniente (promoción especial), Sargento Primero, Cap. 1/o"
                />
              )}
            </div>
            <div className="col">
              <label htmlFor="scaleslug">Grados</label>
              <Select
                onChange={(data) => {
                  console.log("Data", data);
                  setSelectedScales(data);
                }}
                closeMenuOnSelect={false}
                isMulti
                value={selectedScales}
                hideSelectedOptions
                options={userscales.map((userScale) => {
                  // if (selectedCategories.includes(userScale) === true) return;
                  userScale.value = userScale.id;
                  userScale.label = userScale.name;
                  return userScale;
                })}
              />
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <button
              disabled={loadingCreatingScale || loadingEditingScale}
              type="submit"
              className="btn btn-primary"
            >
              {editMode ? "Editar" : "Crear"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateSubCategoryScaleForm;
