import React from 'react';

import './privacyPolicy.css';

const PrivacyNotice = () => {
  return (
    <div className="privacy-notice">

      <h1>AVISO DE PRIVACIDAD</h1>

      <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, <strong>PICKETCHY S.A. DE C.V.</strong> pone a su disposición el siguiente aviso de privacidad.</p>

      <p><strong>PICKETCHY S.A. DE C.V.</strong>, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>
      
      <p>Además de lo anterior, informamos a usted que <strong>PICKETCHY S.A. DE C.V.</strong>, tiene su domicilio ubicado en:</p>
      
      <p>Basilo Vadillo 842 Col. La Unión Tecomán Colima cp. 28130</p>

      <p>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para atender los servicios y/o pedidos que solicite:</p>
      <p>Para crear un perfil que el permita al usuario el uso de la plataforma para tener acceso al contenido que seleccione de acuerdo a sus necesidades y la creación a posteriori de contenido propio.</p>
      <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>
      <p>Nombre, Teléfono, Edad, Sexo, Domicilio, especialidad militar, grado y correo electrónico.</p>
      <p>Además de estos datos personales, se hará uso de los siguientes datos que conforme a ley son considerados como datos personales sensibles, por lo cual requieren de su autorización expresa y por escrito para poder hacer uso de ellos. En este sentido, nos

      comprometemos con usted a no realizar ningún tipo de uso o tratamiento de sus datos personales sensibles, en tanto no se recabe la autorización firmada por escrito o a través de un sistema de firma electrónica, en el que usted nos otorgue expresamente el consentimiento para tratar sus siguientes datos personales sensibles:</p>
      <p>Datos bancarios, fotografías, ubicación, acceso a contactos.</p>
      <p>Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.</p>
      <p>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
      <p>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:</p>
      <p>contactopicketchy@superbmind.com.mx</p>
      <p>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:</p>
      <p>Nombre, Domicilio, Teléfono, Sexo, Edad, especialidad militar, grado y Firma o aceptación electrónica</p>
      <p>La respuesta a la solicitud se dará en el siguiente plazo: 5 dias habiles, y se comunicará de la siguiente manera:</p>

      <p>A través de correo electrónico y previa solicitud se le enviar la información del contrato Arco</p>
      <p>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>
      <p>a) Nombre del responsable: Sergio Villalobos Navarrete</p>
      <p>b) Domicilio:</p>
      <p>Basilio Vadillo 842 Col. la Unión Tecomán Colima cp. 28130</p>
      <p>c) Teléfono: 5511228209</p>
      <p>d) Correo electrónico: contactopicketchy@superbmind.com.mx</p>
      <p>e) Otro medio de contacto: contactopicketchy@superbmind.com.mx</p>
      <p>Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal se requiera seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
      <p>Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:</p>
      <p>contactopicketchy@superbmind.com.mx</p>
      <p>Del mismo modo, podrá solicitar la información para conocer el procedimiento y
      requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal, sin embargo, estas solicitudes deberán contener la siguiente información:</p>
      <p>Nombre, teléfono, usuario y correo electronico</p>
      <p>La respuesta a la solicitud de revocación o limitación de divulgación de sus datos se dará a más tardar en el siguiente plazo: 5 días hábiles, y se comunicará de la siguiente forma:</p>
      <p>Mediante correo electrónico se dará respuesta a la solicitud</p>
      <p>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas, por lo cual, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través de la siguiente dirección electrónica:</p>
      <p>https://app.superbmind.com.mx</p>
      <p>Última actualización:</p>
      15/10/2021

    </div>
  );
}

export default PrivacyNotice;
