import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import { useDispatch } from "react-redux";
import CreateTopicModal from "../../../components/CreateTopicModal";
import { toggleTopicModal } from "../../../components/CreateTopicModal/actions";
import TopicListItem from "../../../components/TopicListItem";
import { ThemeColors } from "../../../lib/Colors";
import {
  useSuperbmindUser,
  useUserRoleType,
  useUserIsAdmin,
} from "../../../redux/selectors";
import { useHistory } from "react-router-dom";
import {
  useFetchingPTopics,
  useSPTopics,
  useSubject,
} from "../../subjectProfile/selectors";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const SubjectTopicList = ({
  externalSelected,
  setSelectedFlashcardsCount,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const stateTopics = useSPTopics();
  const loadingTopics = useFetchingPTopics();
  const subject = useSubject();
  const superbmindUser = useSuperbmindUser();
  const userRoleType = useUserRoleType();
  const useIsAdmin = useUserIsAdmin();
  const [isAdminFlag, setIsAdminFlag] = useState(false);
  const isAdminUser = useUserIsAdmin();
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [importingMode, setImportingMode] = useState(false);
  const [reorderMode, setReorderMode] = useState(false);
  const [selectedTopicsTotalFlashcards, setSelectedTopicsTotalFlashcards] =
    useState(0);
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // change background colour if dragging
    border: isDragging ? `2px solid ${ThemeColors.primary}` : "",
    borderRadius: isDragging ? `5px` : "",
    alignItems: isDragging ? `center` : "",
    justifyContent: isDragging ? `center` : "",
    background: "white",
    width: "100%",
    height: "100%",
    color: "black",
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      topics,
      result.source.index,
      result.destination.index
    );

    setTopics(items);
    // dispatch(reoderFlashcards(items));
  }
  const getListStyle = (isDraggingOver) => ({
    background: "white",
    // padding: grid,
    width: "100%",
  });
  useEffect(() => {
    console.log("STATE CHANGE", stateTopics);
    setTopics(stateTopics);
  }, [stateTopics]);
  useEffect(() => {
    setIsAdminFlag(isAdminUser);
  }, [userRoleType, isAdminUser]);
  useEffect(() => {
    externalSelected(selectedTopics);
  }, [selectedTopics]);
  const onCheckTopic = (selected) => {
    let selectedId = selected.id;
    const index = selectedTopics.indexOf(selectedId);
    if (index < 0) {
      let topic = topics.find((top) => top.id == selectedId);
      selectedTopics.push(selectedId);
    } else {
      selectedTopics.splice(index, 1);
    }
    setSelectedTopics([...selectedTopics]);
    let sumatoria = 0;
    for (let i = 0; i < selectedTopics.length; i++) {
      let CurrentSelectedID = selectedTopics[i];
      let topicCount = topics.find(
        (t) => t.id == CurrentSelectedID
      ).flashcardsCount;
      sumatoria += topicCount;
      // console.log("Vuelta", i, ":");
      // console.log("Sumatoria", i, ":", sumatoria);
    }
    setSelectedFlashcardsCount(sumatoria);
  };
  const onCheckAllTopics = (deselect) => {
    let allIds = [];
    if (selectedTopics.length == topics.length || deselect == true) {
      setSelectedTopics([]);
      setSelectedFlashcardsCount(0);
      return;
    }
    allIds = topics.map((tc) => tc.id);
    setSelectedTopics(allIds);
    let sumatoria = 0;
    for (let i = 0; i < allIds.length; i++) {
      let CurrentSelectedID = allIds[i];
      let topicCount = topics.find(
        (t) => t.id == CurrentSelectedID
      ).flashcardsCount;
      sumatoria += topicCount;
      // console.log("Vuelta", i, ":");
      // console.log("Sumatoria", i, ":", sumatoria);
    }
    setSelectedFlashcardsCount(sumatoria);
  };
  const renderItems = () => {
    if ((!topics && loadingTopics) || !topics)
      return (
        <div className="d-flex justify-content-center text-center">
          <Loading color={ThemeColors.primary} type="spin"></Loading>
        </div>
      );
    if (topics <= [] && loadingTopics === false)
      if (isAdminUser || subject.imOwner) {
        return (
          <TopicListItem
          noContentShowActions={true}
          imAdmin={useIsAdmin}
          imOwner={subject?.imOwner}
          subject={subject}
          onCreateTopic={(importing) => {
            dispatch(toggleTopicModal(importing));
          }}
          onClick={() => {
            return;
          }}
          createTopic
        />
        );
      } else
        return (
          <h2 className="text-center text-primary">
            No hay material por mostrar
          </h2>
        );
    return (
      <DragDropContext
        onDragUpdate={(e) => {
          console.log("update", e);
        }}
        onDragEnd={onDragEnd}
      >
        <Droppable className="w-100" droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {topics.map((topic, index) => (
                <>
                  {index == 0 && (subject.imOwner || useIsAdmin) && (
                    <TopicListItem
                      reorderFlag={reorderMode}
                      reorderModeToggle={() => {
                        setReorderMode(!reorderMode);
                      }}
                      selectedTopics={selectedTopics}
                      setSelectedTopics={(selectedTopics) => {
                        setSelectedTopics(selectedTopics);
                      }}
                      onCheckAllTopics={(deselect) =>
                        onCheckAllTopics(deselect)
                      }
                      topics={topics}
                      imAdmin={useIsAdmin}
                      imOwner={subject?.imOwner}
                      subject={subject}
                      onCreateTopic={(importing) => {
                        dispatch(toggleTopicModal(importing));
                      }}
                      onClick={() => {
                        return;
                      }}
                      createTopic
                    />
                  )}
                  <Draggable
                    key={topic.id}
                    draggableId={topic.id.toString()}
                    index={index}
                    isDragDisabled={!reorderMode}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <TopicListItem
                          reorderFlag={reorderMode}
                          selectedTopics={selectedTopics}
                          setSelectedTopics={onCheckTopic}
                          imAdmin={isAdminFlag}
                          imOwner={subject.imOwner}
                          key={index}
                          index={index}
                          topic={topic}
                          onClick={() => {
                            history.push(`/study/${topic.id}`);
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                </>
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
    // return topics.map((topic, index) => (
    //   <TopicListItem
    //     selectedTopics={selectedTopics}
    //     setSelectedTopics={onCheckTopic}
    //     imAdmin={isAdminFlag}
    //     imOwner={subject.imOwner}
    //     key={index}
    //     index={index}
    //     topic={topic}
    //     onClick={() => {
    //       history.push(`/study/${topic.id}`);
    //     }}
    //   />
    // ));
  };
  return (
    <div className="w-100">
      <div
        style={{
          scrollBehavior: "smooth",
          height: "40vh",
          overflow: "scroll",
          overflowX: "hidden",
        }}
      >
        {renderItems()}
      </div>
    </div>
  );
};
export default SubjectTopicList;
