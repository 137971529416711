import React, { Component, useEffect, useState } from "react";
import AuthenticateLayout from "../../layouts/AuthenticateLayout";
import SubjectsList from "./components/SubjectsList";
import reducer from "./reducer";
import { cmsAPI } from "../../utils/http-client";
import { SubjectSkeleton } from "../../components/SubjectSkeleton";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as SubjectsAction from "./actions";
import {
  useSubjects,
  useGettingSubjects,
  useCategoryActive,
  useParentSubject,
} from "./selectors";
import AppFooter from "../../components/Footer";
import CategoriesList from "./components/CategoriesList";
import { Link, useHistory } from "react-router-dom";
import CategoriesSideBar from "./components/CategoriesSideBar";

const SubjectsView = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subjects = useSubjects();
  const loading = useGettingSubjects();
  const categoryActive = useCategoryActive();
  const parentSubjectActive = useParentSubject();
  const [requestError, setRequestError] = useState(false);
  const [categoriesTab, setCategoriesTab] = useState();
  const [seeAllTab, setseeAllTab] = useState();

  useEffect(() => {
    dispatch(SubjectsAction.getSubjectsAction());
   //console.log("Path:", history.pathname);
  }, []);

  const changeTab = (tabName) => {
    if (tabName === "categoriesTab") {
      setCategoriesTab(true);
      history.push("/subjects/categories");
      setseeAllTab(false);
    }
    if (tabName === "seeAllTab") {
      history.push("/subjects/");
      setCategoriesTab(false);
      setseeAllTab(true);
    }
  };
  const saveInMySubjects = (id) => {
   //console.log("Saving!");
    cmsAPI
      .post("/subjects/save", { subjectId: id })
      .then((res) => {
       //console.log("Saved Subject", res);
      })
      .catch((err) => {
       //console.log("Error saving subject", err);
      });
    return;
  };
  return (
    <AuthenticateLayout customLayout>
      <>
        <div className="main-panel">
          <div className="container container-full">
            <div className="page-inner page-inner-fill">
              <div className="page-with-aside mail-wrapper">
                <CategoriesSideBar />
                <div className="page-content mail-content bg-light">
                  <div className="inbox-body">
                    <div className="row row-projects">
                      <div className="col-md-12">
                        {categoriesTab ? (
                          <>
                            <div className="card">
                              <div className="card-header">
                                <h2>
                                  Materias
                                  {categoryActive && (
                                    <>
                                      {" "}
                                      Filtradas Por{" "}
                                      <strong>
                                        {categoryActive && categoryActive.name}
                                      </strong>
                                    </>
                                  )}
                                </h2>
                              </div>
                              <div className="card-body">
                                {(subjects < [0] || !subjects) && (
                                  <div className="vertical-center">
                                    <div className="container">
                                      <div className="text-center">
                                        <div className="mt-6 text-center">
                                          <h1>
                                            {requestError
                                              ? "Ha ocurrido un error al consultar los datos"
                                              : "No hay materias por mostrar"}
                                          </h1>
                                          <Link
                                            to="/subjects"
                                            className="btn btn-outline-primary"
                                          >
                                            Buscar
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {loading ? (
                                  <SubjectSkeleton />
                                ) : (
                                  subjects >= [0] && (
                                    <SubjectsList subjects={subjects} />
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="card">
                            <div className="card-header">
                              <h2>
                                Materias{" "}
                                {categoryActive ? (
                                  <>
                                    Filtradas Por{" "}
                                    <strong>
                                      {categoryActive && categoryActive.name}
                                    </strong>
                                  </>
                                ) : parentSubjectActive ? (
                                  <strong>
                                    {parentSubjectActive &&
                                      parentSubjectActive.name}
                                  </strong>
                                ) : (
                                  ""
                                )}
                              </h2>
                            </div>

                            <div className="card-body">
                              {(subjects < [0] || (!subjects && !loading)) && (
                                <div className="vertical-center">
                                  <div className="container">
                                    <div className="text-center">
                                      <div className="mt-6 text-center">
                                        <h1>
                                          {requestError
                                            ? "Ha ocurrido un error al consultar los datos"
                                            : "No hay materias por mostrar"}
                                        </h1>
                                        <button
                                          onClick={() => {
                                            dispatch(
                                              SubjectsAction.getSubjectsAction()
                                            );
                                          }}
                                          className="btn btn-outline-primary"
                                        >
                                          Buscar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {loading ? (
                                <SubjectSkeleton />
                              ) : (
                                subjects >= [0] && (
                                  <SubjectsList subjects={subjects} />
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AppFooter />
        </div>
      </>
    </AuthenticateLayout>
  );
};
export default SubjectsView;
export { reducer };
