import React, { Component } from "react";
import reducer from "./reducer";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import UserProfileView from "./UserProfileView";
class MyProfileView extends Component {
  state = {};
  render() {
    return (
      <AuthenticateLayout {...this.props}>
        <UserProfileView {...this.props} />
      </AuthenticateLayout>
    );
  }
}

export default MyProfileView;

export { reducer };
