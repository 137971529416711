import React, { Component } from "react";
import SubjectCard from "../../../components/SubjectCard";
import { Link } from "react-router-dom";
import SubjectBrowserItem from "../containers/SubjectBrowserItem";
import { bindActionCreators } from "redux";
import * as SubjectsAction from "../actions";
import { connect } from "react-redux";

class SubjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { subjects, requestError } = this.props;
    return (
      <>
        {subjects.map(
          (subject, index) =>
            (subject.isParent || this.props.parentSubjectActive) && (
              <>
                {index > 0 && <div className="separator-dashed" />}
                <SubjectBrowserItem
                  {...this.props}
                  saveAction={() => this.saveInMySubjects(subject.id)}
                  key={subject.id}
                  subject={subject}
                />
              </>
            )
        )}
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    parentSubjectActive: state.subject.parentSubjectActive,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubjects: bindActionCreators(
      SubjectsAction.getSubjectsAction,
      dispatch
    ),
    getChildSubjects: bindActionCreators(
      SubjectsAction.getChildSubjects,
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubjectsList);