import React, { Component, useState, useEffect, createRef } from "react";
import { cmsAPI } from "../../../utils/http-client";
import Moment from "react-moment";
import { useHistory, Link } from "react-router-dom";
import ModalAlert from "../../../components/ModalAlert";
import NotificationAlert from "react-notification-alert";
import { notify } from "../../../components/AlertNotify";
import { useDispatch } from "react-redux";
import { getChildSubjects, getSubjectsAction } from "../actions";
const SubjectBrowserItem = (props) => {
  const dispatch = useDispatch();
  const notificationAlert = createRef();
  const [ModalAlertOpen, setModalAlertOpen] = useState(false);
  const history = useHistory();
  const [pathname, setPathname] = useState("");
  const [justsaved, setSaved] = useState("");
  useEffect(() => {
    //console.log("History:", history);
    if (history.location && history.location) {
      let value = history.location.pathname;
      setPathname(value);
    }
  }, [history]);
  const saveInMySubjects = (id) => {
   //console.log("Saving!");
    notify("info", "Guardando...", notificationAlert);
    cmsAPI
      .post("/subjects/save", { subjectId: id })
      .then((res) => {
        notify("success", "Se ha guardado con éxito", notificationAlert);
       //console.log("Saved Subject", res);
        setSaved(true);
      })
      .catch((err) => {
        notify("danger", "Ha ocurrido un error", notificationAlert);

       //console.log("Error saving subject", err);
      });
    return;
  };
  const {
    subject,
    hideActionButton,
    inMySubjects,
    onClickDelete,
    onClickEdit,
  } = props;
  return (
    <div className="card col-md-12 p-3">
      <NotificationAlert ref={notificationAlert} />
      <div className="row">
        <div className="col-md-1 text-center">
          <div className="avatar avatar-lg">
            <img
              src={
                subject.image
                  ? cmsAPI.resolveAssetAPI(subject.image.url)
                  : "/images/image420.png"
              }
              alt="..."
              className="avatar-img rounded-circle"
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="flex-1 ml-3 pt-1">
            <h6 className="text-uppercase fw-bold mb-1">
              {subject.name}
              {/* <span className="text-warning pl-3">pending</span> */}
            </h6>
            <span className="text-muted">{subject.description}</span>
            <p className="card-category text-muted">{subject.category}</p>
          </div>
        </div>
        <div className="col-md-4 float-right text-center">
          <div className="pt-1">
            {subject.hasChild ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  history.push("/subjects/"+subject.id)
                  dispatch(getChildSubjects(subject));
                }}
              >
                Ver más
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-success"
                  onClick={() => {
                    if (subject.imOwner) {
                      history.push(`subjects/${subject.id}`);
                      return;
                    }
                    saveInMySubjects(subject.id);
                  }}
                >
                  {justsaved || subject.imOwner ? (
                    <>
                      Guardado
                      <i className={"fa fa-check"} />
                    </>
                  ) : (
                    <>
                      Guardar <i className={"fa fa-plus"} />
                    </>
                  )}
                </button>
                <Link to={`subjects/${subject.id}`} className="btn btn-primary">
                  Detalles
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubjectBrowserItem;
