import { cmsAPI } from "../../../utils/http-client";

export const ADMIN_CATEGORY_ACTION_TYPE = {
  GET_CATEGORY_START: "GET_CATEGORY_START",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_ERROR: "GET_CATEGORY_ERROR",

  CREATE_CATEGORY_START: "CREATE_CATEGORY_START",
  CREATE_CATEGORY_SUCCESS: "CREATE_CATEGORY_SUCCESS",
  CREATE_CATEGORY_ERROR: "CREATE_CATEGORY_ERROR",

  EDIT_CATEGORY_START: "EDIT_CATEGORY_START",
  EDIT_CATEGORY_SUCCESS: "EDIT_CATEGORY_SUCCESS",
  EDIT_CATEGORY_ERROR: "EDIT_CATEGORY_ERROR",
  EDIT_CATEGORY_CLEAR: "EDIT_CATEGORY_CLEAR",

  DELETE_CATEGORY_START: "DELETE_CATEGORY_START",
  DELETE_CATEGORY_SUCCESS: "DELETE_CATEGORY_SUCCESS",
  DELETE_CATEGORY_ERROR: "DELETE_CATEGORY_ERROR",
  DELETE_CATEGORY_CLEAR: "DELETE_CATEGORY_CLEAR",
};
export const createScaleAction =
  ({ form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_START });
    cmsAPI
      .post("categories", form)
      .then((response) => {
        console.log("createScaleAction-success");
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_SUCCESS,
          newestCreated: response.data,
        });
      })
      .catch((error) => {
        console.error("createScaleAction", error);
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_ERROR,
        });
      });
  };

export const editScaleAction =
  ({ userscaleId, form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_START });
    if (!userscaleId || !form) {
      dispatch({
        type: ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_ERROR,
      });
    }
    cmsAPI
      .put(`categories/${userscaleId}`, form)
      .then((response) => {
        console.log("editScaleAction-success");
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_SUCCESS,
          editedScale: response.data,
        });
      })
      .catch((error) => {
        console.error("editScaleAction", error);
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_ERROR,
        });
      });
  };

export const deleteSubcategoriesAction =
  ({ userscaleId }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_START });
    cmsAPI
      .delete(`categories/${userscaleId}`)
      .then(({ data }) => {
        console.log("deleteSubcategoriesAction-success", data);
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_SUCCESS,
        });
      })
      .catch((error) => {
        console.error("deleteSubcategoriesAction", error);
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_ERROR,
        });
      });
  };
export const getSubcategories =
  (params = "") =>
  (dispatch) => {
    dispatch({ type: ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_START });
    cmsAPI
      .get("categories?_sort=created_at:asc" + params)
      .then((response) => {
        console.log("getSubcategories-success");
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_SUCCESS,
          categories: response.data,
        });
      })
      .catch((error) => {
        console.error("getScalesAction-error", error);
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_ERROR,
        });
      });
  };
