import { cmsAPI } from "../../utils/http-client";

export const STUDY_ACTIONS = {
  FETCH_TOPIC_START: "FETCH_TOPIC_START",
  FETCH_TOPIC_SUCCESS: "FETCH_TOPIC_SUCCESS",
  FETCH_TOPIC_ERROR: "FETCH_TOPIC_ERROR",
};
export const fetchTopic = (topicId) => (dispatch) => {
  dispatch({ type: STUDY_ACTIONS.FETCH_TOPIC_START, fetchingTopic: true });
  cmsAPI
    .get(`topics/${topicId}`)
    .then((res) => {
      dispatch({
        type: STUDY_ACTIONS.FETCH_TOPIC_SUCCESS,
        fetchingTopic: false,
        topic: res.data,
      });
    })
    .catch((err) => {
      dispatch({ type: STUDY_ACTIONS.FETCH_TOPIC_ERROR, fetchingTopic: false });
    });
};
