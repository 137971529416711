import thunk from "redux-thunk";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";

import { reducer as user } from "../views/login";
import { reducer as signup } from "../views/signup";
import { reducer as subject } from "../views/subjects";
import { reducer as profile } from "../views/myprofile/UserProfileView";
import { reducer as forgotPassword } from "../views/forgot-password";
import { reducer as resetPassword } from "../views/reset-password";
import { reducer as subjectProfile } from "../views/subjectProfile";
import { reducer as topicDetails } from "../views/topicDetails";
import createSubject from "../components/CreateSubjectModal/reducer";
import createTopic from "../components/CreateTopicModal/reducer";
import mySubjects from "../views/mysubjects/reducer";
import categoriesDD from "../components/CategoriesDD/reducer";
import categoryView from "../views/category/reducer";
import editFlashcardsView from "../views/editflashcards/reducer";
import userProfile from "../views/profile/reducer";
import study from "../views/study/reducer";
import adminUserScales from "../views/AdminViews/admin-userscales/reducer";
import adminSubcategoryScales from "../views/AdminViews/admin-subcategoryscales/reducer";
import adminSubcategories from "../views/AdminViews/admin-subcategories/reducer";
import adminCategories from "../views/AdminViews/admin-categories/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  const rootReducer = combineReducers({
    subject,
    user,
    signup,
    profile,
    forgotPassword,
    resetPassword,
    subjectProfile,
    topicDetails,
    createSubject,
    categoriesDD,
    createTopic,
    mySubjects,
    categoryView,
    editFlashcardsView,
    userProfile,
    study,
    adminUserScales,
    adminSubcategoryScales,
    adminSubcategories,
    adminCategories
  });
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
}
