import React from "react";

const SubcategoriesTabActions = ({ firstTab, secondTab, thirdTab }) => {
  return (
    <>
      <ul
        className="nav nav-pills nav-secondary  nav-pills-no-bd nav-pills-icons justify-content-center mb-3"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item">
          <a
            className="nav-link active"
            id="pills-scales-tab-icon"
            data-toggle="pill"
            href="#pills-scales-icon"
            role="tab"
            aria-controls="pills-scales-icon"
            aria-selected="true"
          >
            <i className="flaticon-list" />
            Subcategorias
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="pills-profile-tab-icon"
            data-toggle="pill"
            href="#pills-profile-icon"
            role="tab"
            aria-controls="pills-profile-icon"
            aria-selected="false"
          >
            <i className="fa fa-plus" />
            Crear
          </a>
        </li>
        {thirdTab && (
          <li className="nav-item">
            <a
              className="nav-link"
              id="pills-contact-tab-icon"
              data-toggle="pill"
              href="#pills-contact-icon"
              role="tab"
              aria-controls="pills-contact-icon"
              aria-selected="false"
            >
              <i className="flaticon-mailbox" />
              Contact
            </a>
          </li>
        )}
      </ul>
      <div className="tab-content mb-3" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-scales-icon"
          role="tabpanel"
          aria-labelledby="pills-scales-tab-icon"
        >
          <div className="card">
            <div className="card-body">{firstTab}</div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-profile-icon"
          role="tabpanel"
          aria-labelledby="pills-profile-tab-icon"
        >
          <div className="card">
            <div className="card-body">{secondTab}</div>
          </div>
        </div>
        {thirdTab && (
          <div
            className="tab-pane fade"
            id="pills-contact-icon"
            role="tabpanel"
            aria-labelledby="pills-contact-tab-icon"
          >
            {thirdTab}
          </div>
        )}
      </div>
    </>
  );
};

export default SubcategoriesTabActions;
