import React, { useEffect, useState } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import EditFlashcardsBreadCrumbs from "./components/EditFBreadCrumb";
import HeaderActions from "./components/HeaderActions";
import FlashcardForm from "./components/FlashcardForm";
import FlashcardListSideBar from "./components/FlashcardListSideBar";
import {
  fetchFlashcards,
  fetchSubject,
  fetchTopic,
  setFlashcardIndexActive,
} from "./actions";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import {
  useCreateFlashcardSuccess,
  useDelteSingleFCSuccess,
} from "./selectors";
const EditFlashcardsView = (props) => {
  const route = useRouteMatch();
  const dispatch = useDispatch();
  const successCreatedFC = useCreateFlashcardSuccess();
  const [flashcardIndexLocal, setFlashcardIndex] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const successDeleted = useDelteSingleFCSuccess();
  useEffect(() => {
    // console.log("route,", route);
    let { subjectId, topicId, flashcardIndex } = props.match.params;
    dispatch(fetchSubject(subjectId));
    dispatch(fetchTopic(topicId));
    dispatch(fetchFlashcards(topicId));
  }, [successDeleted]);
  return (
    <AuthenticateLayout hideSidebar={true}>
      <FlashcardListSideBar
        loadingFlag={loadingData}
        setLoadingData={(loading) => setLoadingData(loading)}
      />
      <EditFlashcardsBreadCrumbs
        loadingFlag={loadingData}
        setLoadingData={(loading) => setLoadingData(loading)}
      />
      <FlashcardForm
        loadingFlag={loadingData}
        setLoadingData={(loading) => setLoadingData(loading)}
      />
    </AuthenticateLayout>
  );
};
export default EditFlashcardsView;
