import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE } from "../actions";

const DeleteScaleAlert = ({ ...props }) => {
  const dispatch = useDispatch();
  const successDeletingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.deleteUserScale.success
  );
  const errorDeletingScale = useSelector(
    ({ adminSubcategoryScales }) => adminSubcategoryScales.deleteUserScale.error
  );
  let alertClassName = "alert alert-secondary alert-dismissible fade show";
  let alertMessage = "";
  if (successDeletingScale) {
    alertClassName = "alert alert-success alert-dismissible fade show";
    alertMessage = "Se ha eliminado correctamente";
  }
  if (errorDeletingScale) {
    alertClassName = "alert alert-danger alert-dismissible fade show";
    alertMessage = "Ha ocurrido un error";
  }
  if (!successDeletingScale && !errorDeletingScale) return <></>;
  else
    return (
      <div className={alertClassName} role="alert">
        {alertMessage}
        <button
          onClick={() => {
            dispatch({
              type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.DELETE_USERSCALES_CLEAR,
            });
          }}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
};
export default DeleteScaleAlert;
