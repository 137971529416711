import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SideBarLink } from "./components/SideBarLink";
import SideBarDropdown from "./components/SideBarDropdown";
import UserSideBar from "./components/UserSideBar";
import MySubjectsList from "./components/MySubjectsList";
import CreateSubjectModal from "../CreateSubjectModal";
import { toggleModal } from "../CreateSubjectModal/actions";
import { useCreatedSubjectModal } from "../CreateSubjectModal/selector";
import { isAdmin as isAdminFunction } from "../../utils/isAdmin";
import { useUserIsAdmin, useUserRoleType } from "../../redux/selectors";
import CreateTopicModal from "../CreateTopicModal";
import { toggleTopicModal } from "../CreateTopicModal/actions";
import { useLocation } from "react-router-dom";
const SideBar = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modalActive = useCreatedSubjectModal();
  const userRole = useUserRoleType();
  const [isAdmin, setIsAdmin] = useState();
  const isUserAdmin = useUserIsAdmin();
  useEffect(() => {
    setIsAdmin(isUserAdmin);
  }, [userRole, isUserAdmin]);
  return (
    <div className="sidebar sidebar-style-2" data-background-color="dark2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <UserSideBar />
          <ul className="nav nav-primary">
            <SideBarLink
              path={"/dashboard"}
              iconClassName="fas fa-home"
              name="Dashboard"
            />
            {isAdmin && (
              <SideBarLink
                path={"/admin/dashboard"}
                iconClassName="fas fa-user-shield"
                name="Administrar"
              />
            )}
            {/* {isAdmin && (
              <li
                className={
                  "nav-item " +
                  (location.pathname.includes("admin") ? "active" : "")
                }
              >
                <a
                  path={"/admin/dashboard"}
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                >
                  <i className="fas fa-user-shield" />
                  <p>Administrar</p>
                  <span className="caret" />
                </a>
                <div className="collapse" id="dashboard" style={{}}>
                  <ul className="nav nav-collapse">
                    <SideBarLink
                      path={"/admin/dashboard"}
                      iconClassName="fas fa-user-shield"
                      name="Panel"
                    />
                    <SideBarLink
                      path={"/admin/content"}
                      iconClassName="fas fa-book"
                      name="Contenido"
                    />
                  </ul>
                </div>
              </li>
            )} */}
            {/* <SideBarLink
              path={"/subjects"}
              iconClassName="fas fa-book"
              name="Materias"
            /> */}
            <li className={"nav-item"}>
              <CreateSubjectModal
                toggleComponent={
                  <a onClick={() => dispatch(toggleModal())}>
                    <i className={"fa fa-plus"} />
                    <p
                      style={{
                        width: "50%",
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Crear materia
                    </p>
                  </a>
                }
                isOpen={modalActive}
              />
            </li>
            <div style={{ display: "none" }}>
              <CreateTopicModal
                toggleComponent={
                  <a onClick={() => dispatch(toggleTopicModal())}>
                    <i className={"fa fa-plus-square"} />
                    <p
                      style={{
                        width: "50%",
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      Crear pila
                    </p>
                  </a>
                }
              />
            </div>
            <li className="nav-section">
              <span className="sidebar-mini-icon"></span>
              <h4 className="text-section">Mis Materias</h4>
            </li>
            <MySubjectsList />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
