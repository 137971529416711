import { cmsAPI } from "../../utils/http-client";

export const ACTIONS_MYSUBJECTS = {
  GET_MYSUBJECTS_START: "GET_MYSUBJECTS_START",
  GET_MYSUBJECTS_SUCCESS: "GET_MYSUBJECTS_SUCCESS",
  GET_MYSUBJECTS_ERROR: "GET_MYSUBJECTS_ERROR",

  PUSH_MYSUBJECTS: "PUSH_MYSUBJECTS",
};

export const fetchMySubjects = () => (dispatch) => {
  dispatch({ type: ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_START });
  cmsAPI
    .get("subjects/mine")
    .then(({ data }) => {
      dispatch({
        type: ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_SUCCESS,
        subjects: data,
      });
     //console.log("MySubjects", data && data.length);
    })
    .catch((err) => {
      dispatch({ type: ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_ERROR });
     //console.log("Error fetching my subjects", err);
    });
};

export const pushNewSubject = (subject) => (dispatch) => {
  dispatch({ type: ACTIONS_MYSUBJECTS.PUSH_MYSUBJECTS, newSubject: subject });
};
