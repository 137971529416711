import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
} from "reactstrap";
import { ThemeColors } from "../../../lib/Colors";
import { useCategoryActive } from "../selectors";
const CategorySubNav = ({ navPosition, subNavBar, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const categoryActive = useCategoryActive();
  const [color, setColor] = useState("#177dff");

  useEffect(() => {
    if (categoryActive?.hexColor) setColor(categoryActive.hexColor);
    else return;
  }, [categoryActive]);
  return (
    <div style={{ zIndex: -999 }} className={navPosition || "sticky-top"}>
      <Navbar
        style={{
          background: `linear-gradient(90deg, ${color} 0%, ${ThemeColors.primaryBg} 90%)`,
        }}
        color="primary"
        dark
        expand="md"
      >
        <NavbarBrand href="/">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              top: 0,
              position: "absolute",
              width: "160px",
              height: "65px",
              backgroundColor: "rgba(0,51,102,1)",
              borderRadius: "0px 0px 10px 10px",
              boxShadow: " 0px -4px 5px 0px rgba(0,0,0,0.75)",
            }}
          >
            <h2>{categoryActive?.name || "..."}</h2>
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="">Por qué Superbmind</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="">Temas</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="">Precios</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="">FAQ</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default CategorySubNav;
