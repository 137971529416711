import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap/lib";
import { ThemeColors } from "../../lib/Colors";
import { useUser } from "../../redux/selectors";
import { cmsAPI } from "../../utils/http-client";
import { removeSubject } from "../../views/subjectProfile/actions";
import ReactLoading from "react-loading";
import {
  useRemoveSubjectSuccess,
  useRemoveSubjectLoading,
  useRemoveSubjectError,
} from "../../views/subjectProfile/selectors";
import OptionDropdown from "../OptionDropdown";
import { useDispatch } from "react-redux";
import ModalView from "../ModalView";
const renderImage = (item) => {
  return (
    <img
      src={
        item?.image
          ? cmsAPI.resolveAssetAPI(item.image.url)
          : "/images/image420.png"
      }
      className="rounded mx-auto d-block"
      alt="subject logo"
      style={{
        height: "60px",
        width: "60px",
        objectFit: "cover",
      }}
    />
  );
};
const renderName = (item) => {
  return item?.name || "";
};
const SubjectListItem = ({ subject, onClick }) => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const user = useUser();
  const history = useHistory();
  const removeSuccess = useRemoveSubjectSuccess();
  const removeLoading = useRemoveSubjectLoading();
  useEffect(() => {
    if (removeSuccess) setDeleteModal(false);
  }, [removeSuccess]);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (onClick) return onClick();
        history.push(`/subjects/${subject.id}`);
      }}
      onMouseOver={(e) => {
        if (onClick) e.currentTarget.style.opacity = "60%";
      }}
      onMouseLeave={(e) => {
        if (onClick) e.currentTarget.style.opacity = "100%";
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.border = `2px solid ${ThemeColors.primary}`;
        e.currentTarget.style.borderRadius = `5px`;
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.border = ``;
        e.currentTarget.style.borderRadius = ``;
      }}
      style={{
        cursor: "pointer",
      }}
      className="h-100 align-items-center d-flex flex-row list-group-item"
    >
      <div className="">
        <div className="h-100 justify-content-center align-items-center">
          {renderImage(subject)}
        </div>
      </div>
      <div>
        <div className="d-flex flex-column ml-3 w-100">
          <h3>{renderName(subject)}</h3>
        </div>
      </div>
      <div className="ml-auto">
        <OptionDropdown
          options={[
            {
              label: "Editar",
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/subjects/${subject.id}`);
              },
            },
            {
              label: "Eliminar",
              onClick: (e) => {
                e.stopPropagation();
                setDeleteModal(true);
              },
            },
          ]}
        />
        <ModalView
          confirmComponent={
            <Button
              disabled={removeLoading}
              color="danger"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(removeSubject(subject.id));
              }}
            >
              Eliminar
            </Button>
          }
          toggleFlag={deleteModal}
          toggleAction={() => setDeleteModal((prevState) => !prevState)}
          title={"¿Seguro que quieres eliminar esta materia?"}
          hideComponent
        >
          <h1>{renderName(subject)}</h1>
          {removeLoading && (
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={20}
              width={20}
            />
          )}
        </ModalView>
      </div>
    </div>
  );
};

const styles = {
  actionBtn: {
    marginLeft: 10,
  },
};
export default SubjectListItem;
