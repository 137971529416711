// @flow
import React from "react";
import Skeleton from "react-loading-skeleton";
export const LoadingFlashcard = (props) => {
  return (
    <div className="vertical-center">
      <div className="container text-center">
        <Skeleton width={"100%"} height={20} />
        <Skeleton width={"100%"} height={220} />
        <Skeleton width={"100%"} height={20} />
      </div>
    </div>
  );
};
