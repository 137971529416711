import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_USERSCALES_ACTION_TYPE,
  createScaleAction,
  editScaleAction,
} from "../actions";
import { getSubcategories } from "../../admin-categories/actions";
import { ThemeColors } from "../../../../lib/Colors";

const CreateScaleForm = ({ editMode, editData }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [order, setOrder] = useState(null);
  const [slug, setSlug] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const categories = useSelector(
    ({ adminCategories }) => adminCategories.categories
  );
  const successCreatingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.createUserScale.success
  );
  const errorCreatingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.createUserScale.error
  );
  const loadingCreatingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.createUserScale.loading
  );
  const successEditingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.editUserScale.success
  );
  const errorEditingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.editUserScale.error
  );
  const loadingEditingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.editUserScale.loading
  );
  useEffect(() => {
    // getCategories
    dispatch(getSubcategories());
  }, []);
  useEffect(() => {
    console.log("editMode", editMode);
    if (editMode) {
      if (editData != null) {
        setName(editData.name);
        setSlug(editData.slug);
        setOrder(editData.order);
        if (editData.category != null)
          setSelectedCategory({
            ...editData.category,
            value: editData.id,
            label: editData.category?.name,
          });
        else setSelectedCategory(null);
      }
    }
  }, [editMode]);
  const submitForm = (e) => {
    e.preventDefault();
    const form = { order, name, slug, category: selectedCategory };
    if (!name || !slug) {
      alert("Llena los campos requeridos");
      return;
    }
    if (editMode) {
      dispatch(editScaleAction({ userscaleId: editData.id, form }));
      return;
    } else {
      dispatch(createScaleAction({ form }));
    }
  };
  const renderAlert = () => {
    let alertClassName = "alert alert-secondary alert-dismissible fade show";
    let alertMessage = "";
    if (successCreatingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha creado correctamente";
    }
    if (successEditingScale) {
      alertClassName = "alert alert-success alert-dismissible fade show";
      alertMessage = "Se ha editado correctamente";
    }
    if (errorCreatingScale || errorEditingScale) {
      alertClassName = "alert alert-danger alert-dismissible fade show";
      alertMessage = "Ha ocurrido un error";
    }
    if (
      !successCreatingScale &&
      !errorCreatingScale &&
      !successEditingScale &&
      !errorEditingScale
    )
      return;
    else
      return (
        <div className={alertClassName} role="alert">
          {alertMessage}
          <button
            onClick={() => {
              dispatch({
                type: ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_CLEAR,
              });
            }}
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );
  };
  return (
    <div>
      {renderAlert()}
      <form onSubmit={submitForm}>
        <div className="form-group">
          <div className="row">
            <div className="col-4">
              <label htmlFor="scalename">Nombre del grado</label>
              <input
                onChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                required
                type="name"
                className="form-control"
                id="scalename"
                placeholder="e.g Subteniente (promoción especial), Sargento Primero, Cap. 1/o"
              />
            </div>
            <div className="col-4">
              <label htmlFor="order">Orden del grado</label>
              <input
                onChange={(e) => {
                  setOrder(e.target.value);
                }}
                value={order}
                required
                type="name"
                className="form-control"
                id="order"
                placeholder="Escribe un numero en el orden en el que quieres que aparezca este grado"
              />
            </div>
            <div className="col-4">
              <label htmlFor="scaleslug">Slug</label>
              <input
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                value={slug}
                required
                type="name"
                className="form-control"
                id="scaleslug"
                placeholder="e.g subteniente_special_promo, sargento_primero, cap_1ro"
              />
              <small id="emailHelp2" className="form-text text-muted">
                Este es un nombre para usarlo a como referencia a nivel codigo,
                por favor, evite usar caracteres especiales y espacios
              </small>
            </div>
            <div className="col-12">
              <label htmlFor="scalename">Nombre de la categoría</label>
              <Select
                hideSelectedOptions
                onChange={(data) => {
                  setSelectedCategory(data);
                }}
                value={selectedCategory}
                closeMenuOnSelect={false}
                defaultValue={selectedCategory}
                options={categories.map((type) => {
                  type.value = type.id;
                  type.label = type.name;
                  return type;
                })}
              />
              {selectedCategory != null && (
                <button
                  className={"btn btn-primary"}
                  onClick={() => {
                    setSelectedCategory(null);
                  }}
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    backgroundColor: ThemeColors.primary,
                  }}
                >
                  Deseleccionar categoria
                </button>
              )}
            </div>
          </div>
          <div className="col-12 text-center mt-5">
            <button
              disabled={loadingCreatingScale || loadingEditingScale}
              type="submit"
              className="btn btn-primary"
            >
              {editMode ? "Editar" : "Crear"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateScaleForm;
