import React from "react";
import { cmsAPI } from "../../utils/http-client";

export const ACTIONS_PROFILE = {
  EDIT_USER_PROFILE_START: "EDIT_USER_PROFILE_START",
  EDIT_USER_PROFILE_SUCCESS: "EDIT_USER_PROFILE_SUCCESS",
  EDIT_USER_PROFILE_ERROR: "EDIT_USER_PROFILE_ERROR",
};

export const UpdateMe = (form) => (dispatch) => {
  dispatch({ type: ACTIONS_PROFILE.EDIT_USER_PROFILE_START, editUser: {} });
  let { name, email } = form;
  if (!name || !email) {
    dispatch({
      type: ACTIONS_PROFILE.EDIT_USER_PROFILE_SUCCESS,
      editUser: {
        loading: false,
        error: true,
        success: false,
        errorMessage: "Llena los campos requeridos",
      },
    });
  }
  console.log("Form", form);
  cmsAPI
    .put("users/me", form)
    .then(({ data }) => {
      console.log("Update me success", data);
      dispatch({
        type: ACTIONS_PROFILE.EDIT_USER_PROFILE_SUCCESS,
        editUser: { loading: false, error: false, success: true, user: data },
      });
    })
    .catch((err) => {
      console.log("Update me error", err);
      dispatch({
        type: ACTIONS_PROFILE.EDIT_USER_PROFILE_SUCCESS,
        editUser: {
          loading: false,
          error: true,
          success: false,
          errorResponse: err,
        },
      });
    });
};
