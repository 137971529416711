import React, { useEffect, useState } from "react";
import { cmsAPI } from "../../utils/http-client";
import PreviewFlashcardsList from "../PreviewFlashcardsList";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../lib/Colors";
const FlashcardPreviewModal = ({
  flashcards = [],
  topic,
  fetchFlashcards = false,
}) => {
  const [fetching, setFetching] = useState(false);
  const [localFlashcards, setLocalFlashcards] = useState([]);
  useEffect(() => {
    if (flashcards.length >= 1) setLocalFlashcards(flashcards);
    if (flashcards.length <= 0 && fetchFlashcards) {
      setFetching(true);
      cmsAPI
        .get(`flashcards?topic=${topic.id}`)
        .then(({ data }) => {
          flashcards = data;
          setLocalFlashcards(data);
          setFetching(false);
        })
        .catch((err) => {
          console.log("Error getting Flashcards preview");
          setFetching(false);
        });
    }
  }, [fetchFlashcards]);
  return (
    <div>
      <div>
        {fetching ? (
          <ReactLoading
            type={"spin"}
            color={ThemeColors.primary}
            height={20}
            width={20}
          ></ReactLoading>
        ) : (
          <PreviewFlashcardsList
            review={false}
            alignContentFlag={true}
            flashcards={localFlashcards}
          ></PreviewFlashcardsList>
        )}
      </div>
    </div>
  );
};

export default FlashcardPreviewModal;
