export const Routes = [
    { 
        name:"Inicio",
        path: "/",
        pageName:"index",
        disable:false,
        layout: "public",
    },
    { 
        name:"App",
        path: "/download-app",
        pageName:"download-app",
        disable:true,
        layout: "public"
    },
    { 
        name:"Materias",
        path: "/subjects",
        pageName:"subjects",
        disable:true,
        layout: "auth",
        showOnAuth:true,
        noShowOnAuth:false
    },
    { 
        name:"Mis Flashcards",
        path: "/myflashcards",
        pageName:"flashcards",
        disable:true,
        layout: "auth",
        showOnAuth:true,
        noShowOnAuth:false
    },
    { 
        name:"Dashboard",
        path: "/dashboard",
        pageName:"dashboard",
        disable:true,
        layout: "auth",
        showOnAuth:true,
        noShowOnAuth:false
    },
    { 
        name:"Login",
        path: "/login",
        pageName:"login",
        disable:false,
        layout: "auth",
        noShowOnAuth:true
    },
    { 
        name:"Registrar",
        path: "/signup",
        pageName:"signup",
        disable:false,
        layout: "public",
        noShowOnAuth:true
    },
    { 
        name:"Suscribete",
        path: "/suscription",
        pageName:"suscription",
        disable:true,
        layout: "auth",
        noShowOnAuth:true
    },
]