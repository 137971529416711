import React, { Component } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import { MyFlashCards } from "./component/MyFlashCards";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
class FlashcardsView extends Component {
  render() {
    return (
      //https://localhost:3000/myflashcards
      <AuthenticateLayout>
        <MyFlashCards {...this.props} />
      </AuthenticateLayout>
    );
  }
}

export default FlashcardsView;
