import React, { useEffect, useState } from "react";
import StartForm from "./containers/StartForm";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import { cmsAPI } from "../../utils/http-client";
import { getUser } from "../../lib/localstorage";

const StartFlashCards = (props) => {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState(false);
   //console.log("Params",props.match);
    const user = getUser();
    const {match:{params}} = props;
    const countFlashcards = (userID,userTopicId) => {
        cmsAPI.get(`FLASHCARDS/count?topic=${userTopicId}`)
        .then(res => {
            //console.log(res);
            setCount(res.data)
            setLoading(false)
        })
        .catch(err => {
            setRequestError(true)
            setLoading(false)
        })
    }
    useEffect(()=>{
        //console.log("CountFlashcards")
        countFlashcards(user.id,params.userTopicId)
    },[])
    return (
        <AuthenticateLayout>
          <StartForm {...props} count={count} />
        </AuthenticateLayout>
      );
}
export default StartFlashCards;
