import React, { Component } from "react";
import PropTypes from "prop-types";
const DropdownItem = (props) => {
  return (
    <li key={props.key}>
      <a href={props.path}>
        {props.imageURI ? (
          <div className="avatar-sm float-left mr-2">
            <img
              src={props.imageURI}
              alt="º  "
              className="avatar-img rounded-circle"
            />
          </div>
        ) : (
          props.iconClassName && <i className={props.iconClassName} />
        )}
        <span className="sub-item">{props.name || "###"}</span>
      </a>
    </li>
  );
};
DropdownItem.propTypes = {
  path: PropTypes.string,
  iconClassName: PropTypes.string,
  imageURI: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
};
const SideBarDropdown = (props) => {
  return (
    <li className={"nav-item " + (props.active && "active")}>
      <a
        data-toggle="collapse"
        href={`#${props.id}`}
        className="collapsed"
        aria-expanded="false"
      >
        <i className={`${props.iconClassName}` || "fas fa-circle"} />
        <p>{props.name}</p>
        <span className="caret" />
      </a>
      <div className="collapse" id={props.id}>
        <ul className="nav nav-collapse">
          {props.children}
        </ul>
      </div>
    </li>
  );
};
DropdownItem.propTypes = {
  path: PropTypes.string,
  iconClassName: PropTypes.string,
  name: PropTypes.string,
  active: PropTypes.bool,
};
export default SideBarDropdown;
