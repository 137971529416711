import React from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
const CategoryLink = ({ className, link = "#", name = "...", ...props }) => {
  return (
    <DropdownItem>
      <Link className="dropdown-item" to={link}>
        {name}
      </Link>
    </DropdownItem>
  );
};
export default CategoryLink;
