import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../../lib/Colors";
import AdminLayout from "../../../layouts/AdminLayout";
import { cmsAPI } from "../../../utils/http-client";
import ScalesTabActions from "./components/ScalesTabActions";
import CreateScaleForm from "./components/CreateScaleForm";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_USERSCALES_ACTION_TYPE,
  deleteUserScaleAction,
  getUserScales,
} from "./actions";
import EditScaleModal from "./components/EditScaleModal";
import { Button } from "reactstrap";
import DeleteScaleAlert from "./components/DeleteScaleAlert";
const AdminUserScales = () => {
  const dispatch = useDispatch();
  const [editScaleModalisOpen, setEditScaleModalisOpen] = useState(false);
  const [deleteScaleModalisOpen, setDeleteScaleModalisOpen] = useState(false);
  const [scaleToEditData, setScaleToEditData] = useState(null);
  const [scaleToDeleteData, setScaleToDeleteData] = useState(null);
  const fetchingScales = useSelector(
    ({ adminUserScales }) => adminUserScales.loading
  );
  const scales = useSelector(
    ({ adminUserScales }) => adminUserScales.userscales
  );
  const [loading, setLoading] = useState(fetchingScales);
  const successCreatingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.createUserScale.success
  );
  const successEditingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.editUserScale.success
  );
  const successDeletingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.deleteUserScale.success
  );
  const loadingDeletingScale = useSelector(
    ({ adminUserScales }) => adminUserScales.deleteUserScale.error
  );
  useEffect(() => {
    if (successDeletingScale) {
      setTimeout(() => {
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_CLEAR,
        });
        setDeleteScaleModalisOpen(false);
        setScaleToDeleteData(null);
      }, 1000);
    }
    getScales();
  }, [successCreatingScale, successEditingScale, successDeletingScale]);
  useEffect(() => {
    setLoading(fetchingScales);
  }, [fetchingScales]);
  const getScales = () => {
    dispatch(getUserScales());
  };
  const toggleEditScaleModal = ({ scale = null }) => {
    setScaleToEditData(scale);
    setEditScaleModalisOpen(!editScaleModalisOpen);
  };
  const toggleDeleteScaleModal = ({ scale = null }) => {
    setScaleToDeleteData(scale);
    setDeleteScaleModalisOpen(!deleteScaleModalisOpen);
  };
  const deleteScale = () => {
    if (scaleToDeleteData != null && scaleToDeleteData?.id != null)
      dispatch(deleteUserScaleAction({ userscaleId: scaleToDeleteData.id }));
  };
  const ScaleItem = ({ scale, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{scale.id}</td>
        <td>{scale.order}</td>
        <td>{scale.name}</td>
        <td>{scale.slug}</td>
        <td>{scale.category?.name}</td>
        <td>
          <div className="row">
            <button
              onClick={() => toggleEditScaleModal({ scale })}
              type="button"
              className="btn btn-icon btn-round ml-2 btn-info"
            >
              <i className="fa fa-edit" />
            </button>
            <button
              onClick={() => toggleDeleteScaleModal({ scale })}
              type="button"
              className="btn btn-icon btn-round ml-2 btn-danger"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </td>
      </tr>
    );
  };
  const ScalesTable = () => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col">Order</th>
            <th scope="col">Grado</th>
            <th scope="col">Slug</th>
            <th scope="col">Categoria</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {scales != null &&
            scales.length > 0 &&
            scales.map((scale, index) => {
              return <ScaleItem scale={scale} index={index} key={index} />;
            })}
        </tbody>
      </table>
    );
  };
  return (
    <AdminLayout>
      <div>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={40}
              width={40}
            />
          </div>
        )}
        <div>
          <ScalesTabActions
            firstTab={<ScalesTable />}
            secondTab={<CreateScaleForm />}
          />
        </div>
        <EditScaleModal
          modalTitle={"Editando: " + scaleToEditData?.name || ""}
          isOpen={editScaleModalisOpen}
          toggle={toggleEditScaleModal}
        >
          <div className="row justify-center">
            <CreateScaleForm editMode={true} editData={scaleToEditData} />
            <div className="col text-center">
              <Button
                color="secondary"
                style={{ alignSelf: "center" }}
                onClick={toggleEditScaleModal}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </EditScaleModal>
        <EditScaleModal
          modalTitle={"Eliminar grado"}
          isOpen={deleteScaleModalisOpen}
          toggle={toggleDeleteScaleModal}
          footer={
            <>
              <Button
                color="primary"
                style={{ alignSelf: "center" }}
                onClick={toggleDeleteScaleModal}
              >
                Cancelar
              </Button>
              <Button
                disabled={loadingDeletingScale}
                color="danger"
                style={{ alignSelf: "center" }}
                onClick={() => {
                  deleteScale();
                }}
              >
                Eliminar
              </Button>
            </>
          }
        >
          <DeleteScaleAlert></DeleteScaleAlert>
          <div className="row justify-center p-3">
            <h3 className="text-center">
              {"¿Seguro que quieres eliminar el grado de "}
              <strong>{scaleToDeleteData?.name || ""}</strong>
              {"?"}
            </h3>
          </div>
        </EditScaleModal>
      </div>
    </AdminLayout>
  );
};

export default AdminUserScales;
