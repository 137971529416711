import React, { useState } from "react";
import { Children } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { ThemeColors } from "../../lib/Colors";

const DropDownDots = ({
  color,
  style,
  className,
  childComponent,
  dropdownOptions,
  ...props
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      style={style}
      className={className}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <DropdownToggle color={color || "link"}>
        <i
          style={{ fontSize: "1.5em", color: ThemeColors.primary }}
          className="fas fa-ellipsis-v"
        />
      </DropdownToggle>
      <DropdownMenu>
        {dropdownOptions ? (
          dropdownOptions.map((option, index) => {
            if (option.show == false) return;
            return (
              <DropdownItem
                className={option.className}
                divider={option.divider}
                onClick={option.action}
              >
                {option.label || "option" + index}
              </DropdownItem>
            );
          })
        ) : (
          <>
            (<DropdownItem>Editar</DropdownItem>
            <DropdownItem>Ver lista de flashcards</DropdownItem>
            <DropdownItem>Explorar</DropdownItem>
            <DropdownItem divider />
            <DropdownItem>Eliminar</DropdownItem>)
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropDownDots;
