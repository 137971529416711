import React, { Component, createRef, useEffect, useState } from "react";
import { cmsAPI } from "../utils/http-client";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import ModalAlert from "./ModalAlert";
import { notify } from "./AlertNotify";
const SubjectCard = (props) => {
  const notificationAlert = createRef();
  const [ModalAlertOpen, setModalAlertOpen] = useState(false);
  const history = useHistory();
  const [pathname, setPathname] = useState("");
  const [justsaved, setSaved] = useState("");
  useEffect(() => {
   //console.log("History:", history);
    if (history.location && history.location) {
      let value = history.location.pathname;
      setPathname(value);
    }
  }, [history]);
  const saveInMySubjects = (id) => {
   //console.log("Saving!");
    notify("info", "Guardando...", notificationAlert);
    cmsAPI
      .post("/subjects/save", { subjectId: id })
      .then((res) => {
        notify("success", "Se ha guardado con éxito", notificationAlert);
       //console.log("Saved Subject", res);
        setSaved(true);
      })
      .catch((err) => {
        notify("danger", "Ha ocurrido un error", notificationAlert);

       //console.log("Error saving subject", err);
      });
    return;
  };
  const {
    id,
    imOwner,
    name,
    description,
    image,
    createdDate,
    hideActionButton,
    inMySubjects,
    saved,
    onClickEdit,
    onClickDelete,
  } = props;
  //console.log("SubjectCard", name);
  return (
    <>
      <notificationAlert ref={notificationAlert} />

      {props.inBrowser ? (
        <div className="card">
          <div className="item-list">
            <div className="avatar">
              <img
                src={
                  image
                    ? cmsAPI.resolveAssetAPI(image.url)
                    : "/images/image420.png"
                }
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="info-user ml-3">
              <div className="username">{props.name}</div>
              <div className="status">{props.description}</div>
            </div>
            {!props.hideActionButton && (
              <div className="float-right pt-1">
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      saveInMySubjects(id);
                    }}
                  >
                    {(justsaved || imOwner) ? (
                      <>
                        Guardado
                        <i className={"fa fa-check"} />
                      </>
                    ) : (
                      <>
                        Guardar <i className={"fa fa-plus"} />
                      </>
                    )}
                  </button>
                  <Link to={`subjects/${id}`} className="btn btn-primary">
                    Detalles
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="col-md-12">
          <div className="d-flex">
            <div className="avatar avatar-lg">
              <img
                src={
                  image
                    ? cmsAPI.resolveAssetAPI(image.url)
                    : "/images/image420.png"
                }
                alt="..."
                className="avatar-img rounded-circle"
              />
            </div>
            <div className="flex-1 ml-3 pt-1">
              <h6 className="text-uppercase fw-bold mb-1">
                {props.name}
                {/* <span className="text-warning pl-3">pending</span> */}
              </h6>
              <span className="text-muted">{props.description}</span>
            </div>
            {!props.hideActionButton && (
              <div className="float-right pt-1">
                <div>
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      saveInMySubjects(id);
                    }}
                  >
                    {justsaved ? "Guardado" : "Guardar"}{" "}
                    <i className={justsaved ? "fa fa-check" : "fa fa-plus"} />
                  </button>
                  <Link to={`subjects/${id}`} className="btn btn-primary">
                    Detalles
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
SubjectCard.propTypes = {
  inBrowser: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  createdDate: PropTypes.string,
  btnTextMyFlashcard: PropTypes.bool,
  btnText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  inMyFlashcards: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  hideActionButton: PropTypes.bool,
};
export default SubjectCard;
