import React, { useEffect, useState } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import StudySidebar from "./components/StudySidebar";
import { Button } from "reactstrap";
import ReactCardFlip from "react-card-flip";
import { ThemeColors } from "../../lib/Colors";
import { useRouteMatch } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchTopic } from "./actions";
import FlashcardPreviewModal from "../../components/FlashcardPreviewModal/FlashcardPreviewModal";
import ModalView from "../../components/ModalView";

const StudyView = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [PreviewModal, setPreviewModal] = useState(false);
  const [studyTopicId, setStudyTopicId] = useState(null);
  const topic = useSelector(({ study }) => study.topic);
  const route = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    let params = route.params;
    let topicId = params.topicId;
    setStudyTopicId(topicId);
    dispatch(fetchTopic(topicId));
    return () => {};
  }, []);
  const handleSpace = (e) => {
    if (e.keyCode === 32) {
      setIsFlipped(!isFlipped);
    }
  };
  return (
    <AuthenticateLayout hideFooter hideSidebar={true}>
      <StudySidebar />
      <div>
        <div class="container"></div>
        <div className="page-category">
          <div className="row">
            <p>
              Deck:<strong>id</strong>
            </p>
            <p className="ml-2">
              Card:<strong>id</strong>
            </p>
            <p className="ml-auto">
              <button
                onClick={() => setPreviewModal((prevState) => !prevState)}
                type="button"
                class="btn btn-link"
              >
                <i style={{ fontSize: "1.5em" }} className="fas fa-glasses" />
                Ver todas las cartas
              </button>
            </p>
            <ModalView
              hideFooter
              modalBodyStyle={{
                maxHeight: "80vh",
                overflowY: "scroll",
              }}
              modalStyle={{ minWidth: "80vw" }}
              hideComponent
              title={`${topic?.name}`}
              titleStyles={{
                textAlign: "center",
                fontSize: 24,
                fontWeight: "bold",
              }}
              toggleFlag={PreviewModal}
              toggleAction={() => setPreviewModal((prevState) => !prevState)}
              centered={true}
            >
              <div>
                <FlashcardPreviewModal
                  key={12345}
                  topic={{ id: studyTopicId }}
                  fetchFlashcards={true}
                />
              </div>
            </ModalView>
          </div>
        </div>
        <div
          style={{ justifyContent: "center", alignItems: "center" }}
          className="row"
        >
          <div className="d-flex justify-content-center align-items-center">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="col-md-12"
            >
              <div>
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
                  <div
                    onClick={(e) => {
                      setIsFlipped(!isFlipped);
                      e.stopPropagation();
                    }}
                    className="card"
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "60vh",
                        width: "60vw",
                      }}
                      className="card-body"
                    >
                      <p style={{ position: "absolute", right: 0 }}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          type="button"
                          class="btn btn-link"
                        >
                          <i
                            style={{ fontSize: "1.4em", color: "gray" }}
                            className="fas fa-edit"
                          />
                        </button>
                      </p>
                      <p className="demo p-4">{"Pregunta"}</p>
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      setIsFlipped(!isFlipped);
                      e.stopPropagation();
                    }}
                    className="card"
                  >
                    <div
                      style={{ height: "60vh", width: "60vw" }}
                      className="card-body"
                    >
                      <p style={{ position: "absolute", right: 0 }}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          type="button"
                          class="btn btn-link"
                        >
                          <i
                            style={{ fontSize: "1.4em", color: "gray" }}
                            className="fas fa-edit"
                          />
                        </button>
                      </p>
                      <p className="demo p-4">
                        {
                          "Respuesta"
                        }
                      </p>
                    </div>
                  </div>
                </ReactCardFlip>
                <div className="card">
                    {isFlipped && (
                      <div
                        class="btn-group"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        role="group"
                        aria-label="First group"
                      >
                        <button
                          type="button"
                          class="btn w-100"
                          style={{
                            backgroundColor: ThemeColors.confidentColors[1],
                            color: "white",
                          }}
                        >
                          1
                        </button>
                        <button
                          type="button"
                          class="btn w-100"
                          style={{
                            backgroundColor: ThemeColors.confidentColors[2],
                            color: "white",
                          }}
                        >
                          2
                        </button>
                        <button
                          type="button"
                          class="btn w-100"
                          style={{
                            backgroundColor: ThemeColors.confidentColors[3],
                            color: "white",
                          }}
                        >
                          3
                        </button>
                        <button
                          type="button"
                          class="btn w-100"
                          style={{
                            backgroundColor: ThemeColors.confidentColors[4],
                            color: "white",
                          }}
                        >
                          4
                        </button>
                        <button
                          type="button"
                          class="btn w-100"
                          style={{
                            backgroundColor: ThemeColors.confidentColors[5],
                            color: "white",
                          }}
                        >
                          5
                        </button>
                      </div>
                    )}
                    {!isFlipped && (
                      <div>
                        {
                          <button
                            autoFocus
                            onClick={() => setIsFlipped(!isFlipped)}
                            className="btn btn-secondary btn-lg btn-block"
                          >
                            {isFlipped ? "Ver pregunta" : "Ver Respuesta"}
                          </button>
                        }
                      </div>
                    )}
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </AuthenticateLayout>
  );
};

export default StudyView;
