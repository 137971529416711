import React, { Component, createRef } from "react";
import { cmsAPI } from "../utils/http-client";
import { withCookies } from "react-cookie";
import cx from "classnames";
import ModalAlert from "../components/ModalAlert";
import PropTypes from "prop-types";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import NotificationAlert from "react-notification-alert";
import { notify } from "../components/AlertNotify";
import { Link } from "react-router-dom";
import ImageUploader from "react-images-upload";
import Skeleton from "react-loading-skeleton";
import { SubjectSkeleton } from "./SubjectSkeleton";
import Moment from "react-moment";
import TopicCard from "./TopicCard";
import SubjectCard from "../components/SubjectCard";
import { configFactory } from "./CustomUploadAdapter";
import { Spinner, CustomInput, Label, FormGroup } from "reactstrap";

class FlashcardsForm extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {
      privacyRadio: "private",
      //EDIT STATE START
      fetchingUserTopic: true,
      fetchingUserFlashcards: true,
      usertopicId: "",
      userTopic: {},
      userFlashcardsError: true,
      userTopicError: false,
      //EDIT STATE END
      //CARDS
      fetchingTopic: false,
      fetchingTopicError: false,
      fetchingSubject: false,
      fetchingSubjectError: false,
      subject: {},
      topic: {},
      //REQUEST FLAGS
      fetchingSubjects: true,
      fetchingSubjectsError: false,
      //DROPDOWN
      subjects: [],
      subjectTopics: [],
      subjectDD: "",
      topicDD: "",
      //ALERT
      ModalAlertOpen: false,
      //FLASHCARDS ARRAY INDEX ACTIVE
      flashcardIndexActive: 0,
      //BOOL
      showSubjectForm: false,
      showTopicForm: false,
      //FORMS
      subjectForm: {
        image: [],
        name: "",
        description: "",
      },
      topicForm: {
        image: [],
        name: "",
        description: "",
        premium: false,
        public: false,
        owner: "",
        childOf: "",
      },
      flashcards: [{ id: 0, question: "", answer: "" }],
      adapterInitialized: false,
    };
  }
  componentWillUnmount() {
    clearTimeout(this.onTimeOutToSave);
  }
  handlePrivacyChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let { topicForm } = this.state;
   //console.log("Privacy", name, "Value:", value);
    if (value === "public") topicForm.public = true;
    if (value === "private") topicForm.public = false;
   //console.log("Form", topicForm);
    this.setState({
      topicForm,
      privacyRadio: value,
    });
  };
  componentDidMount() {
   //console.log("This.state", this.state.flashcards);
   //console.log("Props FlashcardsForm", this.props);
    if (this.props.onEdit) {
      let {
        match: { params },
      } = this.props;
     //console.log("Match params", this.props);
     //console.log("Params", params.userTopicId);
      let utId = params.userTopicId;
      this.setState({
        usertopicId: utId,
      });
      this.getEditFlashcardsData(utId);
     //console.log("this.state", this.state.usertopicId || "No hay topic");
    } else {
      let {
        match: { params },
      } = this.props;
      //this.getEditFlashcardsData(utId);
      this.getTopicPicked({ id: params.topicId, URI: "/topics" });
    }

    this.intializeUploadAdatper();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.flashcardIndexActive != this.state.flashcardIndexActive ||
      (prevState.fetchingUserFlashcards && !this.state.fetchingUserFlashcards)
    ) {
      this.setState(
        {
          adapterInitialized: false,
        },
        this.intializeUploadAdatper
      );
    }
  }

  intializeUploadAdatper = () => {
    this.editorConfiguration = configFactory({
      endPoint:
        cmsAPI.defaults.baseURL +
        `flashcards/${
          this.state.flashcards[this.state.flashcardIndexActive].id
        }`,
      field: "files.questionmedia",
    });

    this.editorConfigurationAnswer = configFactory({
      endPoint:
        cmsAPI.defaults.baseURL +
        `flashcards/${
          this.state.flashcards[this.state.flashcardIndexActive].id
        }`,
      field: "files.answermedia",
    });
    this.setState({
      adapterInitialized: true,
    });
  };

  componentWillUnmount() {
    this.setState({
      adapterInitialized: false,
    });
  }

  getSubjectPicked = (subjectId) => {
    this.setState({ fetchingSubject: true });
    cmsAPI
      .get(`/subjects/${subjectId}`)
      .then((res) => {
       //console.log("Subjects Response>", res);
        this.setState({ fetchingSubject: false, subject: res.data });
      })
      .catch((err) => {
       //console.log("Subjects Error>", err);
        this.setState({
          fetchingSubject: false,
          fetchingSubjectError: true,
        });
      });
  };
  getTopicPicked = (data) => {
    this.setState({ fetchingTopic: true });
   //console.log("Data GetTopic", data);
    cmsAPI
      .get(`${data.URI}/${data.id}`)
      .then((res) => {
       //console.log("Topic Data", res);
        cmsAPI
          .get(`/flashcards?topic=${res.data.id}`)
          .then((uFlashcards) => {
           //console.log("Success getting flashcards", uFlashcards.data);
            let flashcards = [];
            uFlashcards.data.forEach((item) => {
              flashcards.push(item);
            });
            this.setState(
              {
                flashcards,
                fetchingUserFlashcards: false,
              },
              this.intializeUploadAdatper()
            );
          })
          .catch((err) => {
            this.setState({
              userFlashcardsError: true,
              fetchingUserFlashcards: false,
            });
           //console.log("Error getting u-flashcards", err);
          });
       //console.log("Topic Response>", res);
        this.setState({
          fetchingTopic: false,
          topic: res.data,
          userTopic: res.data,
        });
        this.getSubjectPicked(res.data.subject.id);
      })
      .catch((err) => {
       //console.log("Subjects Error>", err);
        this.setState({
          fetchingTopic: false,
          fetchingTopicError: true,
        });
      });
  };
  getEditFlashcardsData = (userTopicId) => {
    cmsAPI
      .get(`/usertopics/${userTopicId}`)
      .then((res) => {
       //console.log("Success Getting edit data", res);
        this.setState({
          userTopic: res.data,
          fetchingUserTopic: false,
        });
        cmsAPI
          .get(`/userflashcards?usertopic=${userTopicId}`)
          .then((res) => {
           //console.log("Success getting u-flashcards", res.data);
            let flashcards = [];
            res.data.forEach((item) => {
              flashcards.push(item.flashcard);
            });
            this.setState({
              flashcards,
              fetchingUserFlashcards: false,
            });
          })
          .catch((err) => {
            this.setState({
              userFlashcardsError: true,
              fetchingUserFlashcards: false,
            });
           //console.log("Error getting u-flashcards", err);
          });
      })
      .catch((err) => {
       //console.log("Error Getting edit data", err);
        this.setState({
          userTopicError: true,
          fetchingUserTopic: false,
        });
      });
  };
  getSubjectTopics(subjectID) {
    cmsAPI.get(`/topics?in_subjects=${subjectID}&_limit=9999`).then((res) => {
      this.setState({ subjectTopics: res.data, fetchingSubjectTopics: false });
    });
  }
  onDropSubjectImage = (picture) => {
    let { subjectForm } = this.state;
    subjectForm.image.concat(picture);
   //console.log("PictureSubject", picture, "STATE:", subjectForm);
    this.setState({
      subjectForm,
    });
  };
  onDropTopicImage = (picture) => {
   //console.log("PictureTopic", picture);
    let { topicForm } = this.state;
    topicForm.image.concat(picture);
    this.setState({
      topicForm,
    });
  };
  handleSubjectChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    if (name === "subjectDD" && value == "customSubject") {
      this.setState({
        showSubjectForm: true,
        [name]: value,
      });
      return;
    }
    if (name === "subjectDD") {
      this.setState({
        showSubjectForm: false,
        [name]: value,
      });
      return;
    }
    let { subjectForm } = this.state;
    if (name === "name") {
      subjectForm.name = value;
      this.setState({
        subjectForm,
      });
      return;
    }
    if (name === "description") {
      subjectForm.description = value;
      this.setState({
        subjectForm,
      });
      return;
    }
  };
  handleTopicChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    if (name === "topicDD" && value == "customTopic") {
      this.setState({
        showTopicForm: true,
        [name]: value,
      });
      return;
    }
    if (name === "topicDD") {
      this.setState({
        showTopicForm: false,
        [name]: value,
      });
      return;
    }
    let { topicForm } = this.state;
    if (name === "name") {
      topicForm.name = value;
      this.setState({
        topicForm,
      });
      return;
    }
    if (name === "description") {
      topicForm.description = value;
      this.setState({
        topicForm,
      });
      return;
    }
  };

  handleFlashcardChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "subjectDD" && value != 0) {
     //console.log("SubjectDD VALUE", value);
      this.getSubjectTopics(value);
    }
    if (name === "subjectDD" && value === 0) {
     //console.log("SubjectDD NULL VALUE", value);
      this.setState({
        subjectTopics: [],
      });
    }
   //console.log("Valor:", value, " Of:", name);
    this.setState({
      [name]: value,
    });
  };

  handleInput = (e) => {
    e.preventDefault();
    if (this.props.onEdit) {
      this.editFlashcardsAPI();
      return;
    }
    this.createFlashcardsAPI();
  };

  handleFlashcardFormChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    //console.log("Valor:", value, " Of:", name);
    let { flashcards, flashcardIndexActive } = this.state;
    if (name === "question") {
      flashcards[flashcardIndexActive].question = value;
      this.setState({
        flashcards,
      });
      return;
    }
    this.setState({
      [name]: value,
    });
    return;
  };

  paginationActive = (indexActive) => {
    const { flashcards } = this.state;
    const paginationFlashcards = cx({
      "page-item": true,
      active: this.state.flashcardIndexActive == indexActive,
    });
    return paginationFlashcards;
  };

  pushNewFlashcard = () => {
   //console.log("pushNewFlashcard CLICKED!");
    let data = {
      question: "",
      answer: "",
      daft: true,
      topic: this.state.topic.id,
    };
    notify("primary", "Creando...", this.notificationAlert);
    cmsAPI
      .post(`/flashcards`, data)
      .then((res) => {
        notify("success", "Creada", this.notificationAlert);
       //console.log("New Flashcard RES", res);
        const { flashcards } = this.state;
        flashcards.push(res.data);
       //console.log("flashcards", flashcards);
        let flashcardsLength = flashcards.length - 1;
       //console.log("Lenght", flashcardsLength);
        return this.setState({
          flashcards,
          flashcardIndexActive: flashcardsLength,
        });
      })
      .catch((err) => {
       //console.log("New Flashcard ERR", err);
        notify("danger", "No se ha podido crear", this.notificationAlert);
      });
  };
  autoSave = () => {
   //console.log("AutoSave:");
    if (this.onTimeOutToSave) clearTimeout(this.onTimeOutToSave);
    this.onTimeOutToSave = setTimeout(() => {
      this.saveFlashcard();
    }, 5000);
  };
  saveFlashcard = () => {
    let id = this.state.flashcards[this.state.flashcardIndexActive].id;
   //console.log("Save This Flashcard CLICK!!", id);
    let flashcardData = {
      question: this.state.flashcards[this.state.flashcardIndexActive].question,
      answer: this.state.flashcards[this.state.flashcardIndexActive].answer,
      daft: false,
    };
    notify("primary", "Guardando...", this.notificationAlert);
    cmsAPI
      .put(`/flashcards/${id}`, flashcardData)
      .then((res) => {
       //console.log("Saved Flashcard::", res);
        notify("success", "Guardado", this.notificationAlert);
      })
      .catch((err) => {
       //console.log("Saved Flashcard ERROR::", err);
        notify("danger", "Error al guardar flashcard", this.notificationAlert);
      });
  };
  removeFlashcard = () => {
    const flashcardIndex = this.state.flashcardIndexActive;
    const { flashcards } = this.state;
    const flashcardSelected = flashcards[flashcardIndex];
   //console.log("Item Will be Removed:>", flashcardSelected);
    if (flashcards.length == 1) {
      flashcards.splice(flashcardIndex, 1, { question: "", answer: "" });
     //console.log("FlashcardsLESS1:", flashcards);
      this.saveFlashcard();
      return this.setState({
        flashcards,
        flashcardIndexActive: 0,
        ModalAlertOpen: false,
      });
    }
   //console.log("Flashcards:", flashcards);
    flashcards.splice(flashcardIndex, 1);
    return this.setState({
      flashcards,
      flashcardIndexActive: 0,
      ModalAlertOpen: false,
    });
  };

  editFlashcardsAPI = () => {
    notify("primary", "Guardando flashcards...", this.notificationAlert);
    let { flashcards, usertopicId } = this.state;
    if (flashcards.length < 1) {
      notify("danger", "Crea al menos una flashcard.", this.notificationAlert);
      return;
    }
    let form = { flashcards };
   //console.log("DataEdit>", form);
    cmsAPI
      .put(`/userflashcards?usertopic=${usertopicId}`, form)
      .then((res) => {
        notify(
          "success",
          "Flashcards editadas con exito",
          this.notificationAlert
        );
       //console.log("response data edited flashcards", res);
      })
      .catch((err) => {
        notify("danger", "Ha ocurrido un error.", this.notificationAlert);
       //console.log("Error edited flashcards", err);
      });
  };
  createFlashcardsAPI = () => {
    notify("primary", "Creando flashcards...", this.notificationAlert);
    let { flashcards, subject, topic, subjectForm, topicForm } = this.state;
    if (flashcards.length < 1) {
      notify("danger", "Crea al menos una flashcard.", this.notificationAlert);
      return;
    }
    if (!topic) {
      notify(
        "danger",
        "Parece que no tienes los datos necesarios para crear las flashcards.",
        this.notificationAlert
      );
      return;
    }
    let subjectData = subject.id || subjectForm;
   //console.log("Subject will send", subject);
    //let form = { flashcards, subject:subjectData, topic:topic.id };
    let form = { flashcards, topicId: topic.id };
   //console.log("FormWILLSEND>", form);
    cmsAPI
      .post(`/userflashcards/createMultiple`, form)
      .then((res) => {
        notify(
          "success",
          "Flashcards creadas con exito",
          this.notificationAlert
        );
       //console.log("response data creating flashcards", res);
      })
      .catch((err) => {
        notify("danger", "Ha ocurrido un error.", this.notificationAlert);
       //console.log("Error creating flashcards", err);
      });
  };
  render() {
    const { onEdit } = this.props;
    if (onEdit && this.state.fetchingUserTopic) {
      return <SubjectSkeleton />;
    }
    if (onEdit && !this.state.fetchingUserTopic && !this.state.userTopic) {
      return (
        <div className="vertical-center">
          <div className="container">
            <div className="text-center">
              <h2>
                Parece que{" "}
                {this.state.userTopicError
                  ? "ha ocurrido un error"
                  : "no encontramos lo que buscabas"}
              </h2>
              <Link to="/subjects" className="btn btn-primary">
                Vuelve atrás!
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="container">
          <NotificationAlert ref={this.notificationAlert} />
          {/* TITLE */}
          <div className="py-5">
            <h1 className="mb-5 text-center">
              {onEdit ? `Editar mis flashcards` : "Crear mis flashcards"}{" "}
            </h1>
            {onEdit && (
              <div className="container">
                <div className="row featurette">
                  <div className="col-md-7 order-md-2">
                    <h3 className="featurette-heading">
                      {this.state.userTopic.topic &&
                        this.state.userTopic.topic.name}
                    </h3>
                    <p className="lead">
                      {this.state.userTopic.topic &&
                        this.state.userTopic.topic.description}
                    </p>
                  </div>
                  <div className="col-md-5 order-md-1">
                    <img
                      className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                      width={500}
                      height={500}
                      preserveAspectRatio="xMidYMid slice"
                      src={
                        this.state.userTopic.topic.image
                          ? cmsAPI.resolveAssetAPI(
                              this.state.userTopic.topic.image.url
                            )
                          : "/images/image420.png"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="text-center">
              {onEdit ? (
                <div className="lead d-flex justify-content-end">
                  <span className="text-muted">
                    En mis flashcards desde:{" "}
                    <Moment format={"DD/MM/YYYY"}>
                      {this.state.userTopic.created_at}
                    </Moment>
                  </span>
                </div>
              ) : (
                "Ingresa la informacion necesaria para crear las flashcards"
              )}
            </div>
          </div>
          {/* TITLE */}
          <div className="row">
            <div className="col-md order-md-1">
              <form onSubmit={this.handleInput} className="needs-validation">
                {!this.props.onEdit && (
                  <>
                    <div className="row text-center">
                      <div className="col-md mb-3">
                        {this.state.showSubjectForm && (
                          <>
                            <div className="card mb-3">
                              <div className="row no-gutters">
                                <div className="col-md-4 align-self-center">
                                  <ImageUploader
                                    withPreview
                                    singleImage={true}
                                    withIcon={true}
                                    buttonText="Elegir imagen"
                                    onChange={this.onDropSubjectImage}
                                    imgExtension={[".jpg", ".png"]}
                                    maxFileSize={5242880}
                                  />
                                </div>
                                <div className="col-md-8">
                                  <div className="card-body">
                                    <label htmlFor="name">Nombre</label>
                                    <input
                                      onChange={this.handleSubjectChange}
                                      value={this.state.subjectForm.name}
                                      type="text"
                                      className="form-control"
                                      id="subject"
                                      name="name"
                                      placeholder="Nombre de la materia a la que pertenece tus flashcards"
                                    />
                                    <p className="card-text">
                                      <label htmlFor="subjectDescription">
                                        Descripción
                                      </label>
                                      <input
                                        onChange={this.handleSubjectChange}
                                        value={
                                          this.state.subjectForm.description
                                        }
                                        type="text"
                                        className="form-control"
                                        id="subjectDescription"
                                        name="description"
                                        placeholder="Nombre de la materia a la que pertenece tus flashcards"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header text-center">
                          <h4>Materia</h4>
                        </div>
                        <div className="card-body">
                          <SubjectCard
                            {...this.state.subject}
                            hideActionButton
                            {...this.props}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="card-header text-center">
                          <h4>Tema</h4>
                        </div>
                        <div className="card-body">
                          <SubjectCard
                            {...this.state.topic}
                            hideActionButton
                            {...this.props}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                {/* START CARD FLASHCARD FORM */}
                <div className="card">
                  <div className="text-center card-header">
                    <div>
                      <nav aria-label="Page navigation example">
                        <ul
                          className="pagination"
                          style={{
                            justifyContent: "center",
                          }}
                        >
                          <li key="1" className="page-item">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const {
                                  flashcardIndexActive,
                                  flashcards,
                                } = this.state;
                               //console.log("PREV!");
                                if (flashcardIndexActive > 0) {
                                  this.setState({
                                    flashcardIndexActive:
                                      this.state.flashcardIndexActive - 1,
                                  });
                                  return;
                                }
                               //console.log("LIMIT");
                                return;
                              }}
                              className="page-link"
                            >
                              <span aria-hidden="true">&laquo;</span>
                            </div>
                          </li>
                          {this.state.flashcards.map((flashcard, index) => {
                            return (
                              <li
                                key={index}
                                className={this.paginationActive(index)}
                              >
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="page-link"
                                  onClick={() => {
                                    this.setState({
                                      flashcardIndexActive: index,
                                    });
                                  }}
                                >
                                  {index + 1}
                                </div>
                              </li>
                            );
                          })}
                          <li className="page-item">
                            <div
                              className="page-link"
                              onClick={this.pushNewFlashcard}
                              aria-label="Next"
                            >
                              <span aria-hidden="true">+</span>
                            </div>
                          </li>
                          <li className="page-item">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                const {
                                  flashcardIndexActive,
                                  flashcards,
                                } = this.state;
                               //console.log("NEXT!");
                                if (
                                  flashcardIndexActive + 1 <
                                  flashcards.length
                                ) {
                                  this.setState({
                                    flashcardIndexActive:
                                      this.state.flashcardIndexActive + 1,
                                  });
                                  return;
                                }
                               //console.log("LIMIT");
                                return;
                              }}
                              className="page-link"
                            >
                              <span aria-hidden="true">&raquo;</span>
                            </div>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="text-center">
                      <div className="mb-3">
                        <span className="text-muted">
                          Id:
                          {
                            this.state.flashcards[
                              this.state.flashcardIndexActive || 0
                            ].id
                          }
                        </span>
                        <h4 className="mb-3">
                          Flashcard #{this.state.flashcardIndexActive + 1}
                        </h4>
                        <label htmlFor="question">Pregunta</label>
                        {/* <div className="App" style={{ marginBottom: 10 }}>
                          {this.state.adapterInitialized ? (
                            <CKEditor
                              editor={ClassicEditor}
                              config={this.editorConfiguration}
                              data={
                                this.state.flashcards[
                                  this.state.flashcardIndexActive || 0
                                ].question || ""
                              }
                              onInit={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                //console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                this.autoSave();
                                let {
                                  flashcards,
                                  flashcardIndexActive,
                                } = this.state;
                                const data = editor.getData();
                                // console.log("ChangeEditor", {
                                //   event,
                                //   editor,
                                //   data,
                                // });
                                flashcards[
                                  flashcardIndexActive
                                ].question = data;
                                this.setState({
                                  flashcards,
                                });
                              }}
                              onBlur={(event, editor) => {
                               //console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                               //console.log("Focus.", editor);
                              }}
                            />
                          ) : (
                            <Spinner />
                          )}
                        </div> */}
                        <div className="invalid-feedback">
                          Ingresa una pregunta/titulo valido.
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="mb-3">
                        <label htmlFor="answer">Respuesta</label>
                        {/* <div className="App" style={{ marginBottom: 10 }}>
                          {this.state.adapterInitialized && (
                            <CKEditor
                              editor={ClassicEditor}
                              config={this.editorConfigurationAnswer}
                              data={
                                this.state.flashcards[
                                  this.state.flashcardIndexActive || 0
                                ].answer || ""
                              }
                              onInit={(editor) => {
                                // You can store the "editor" and use when it is needed.
                                //console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                this.autoSave();
                                let {
                                  flashcards,
                                  flashcardIndexActive,
                                } = this.state;
                                const data = editor.getData();
                               //console.log("ChangeEditor", {
                                //   event,
                                //   editor,
                                //   data,
                                // });
                                flashcards[flashcardIndexActive].answer = data;
                                this.setState({
                                  flashcards,
                                });
                              }}
                              onBlur={(event, editor) => {
                               //console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                               //console.log("Focus.", editor);
                              }}
                            />
                          )}
                        </div> */}
                        {this.state.flashcards[
                          this.state.flashcardIndexActive || 0
                        ].daft && (
                          <span className="text-muted">
                            <p>Borrador</p>
                          </span>
                        )}
                        <div className="invalid-feedback">
                          Ingresa la respuesta de tu flashcard.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-center text-muted">
                    <div>Acciones a este flashcard</div>
                    <div className="row justify-content-center">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => this.saveFlashcard()}
                        className="btn btn-success"
                      >
                        Guardar
                      </div>
                      <ModalAlert
                        className=""
                        isOpen={this.state.ModalAlertOpen}
                        toggle={() =>
                          this.setState({
                            ModalAlertOpen: !this.state.ModalAlertOpen,
                          })
                        }
                        buttonLabel="Eliminar"
                        bodyText="¿Seguro que quieres eliminar el flashcard?"
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={this.removeFlashcard}
                          className="btn btn-danger"
                        >
                          Eliminar
                        </div>
                      </ModalAlert>
                    </div>
                  </div>
                </div>
                {/* END CARD FLASHCARD */}
                {/* PRIVACY BTNS */}
                {!onEdit && (
                  <div>
                    <hr className="mb-4" />
                    <FormGroup>
                      <Label for="privacyRadios">Acceso a los usuarios</Label>
                      <div>
                        <CustomInput
                          checked={this.state.privacyRadio === "public"}
                          type="radio"
                          id="public"
                          name="privacy"
                          label="Publico"
                          value={"public"}
                          onChange={this.handlePrivacyChange}
                        />
                        <CustomInput
                          checked={this.state.privacyRadio === "private"}
                          type="radio"
                          id="private"
                          name="privacy"
                          label="Privado"
                          value={"private"}
                          onChange={this.handlePrivacyChange}
                        />
                      </div>
                    </FormGroup>
                  </div>
                )}
                <hr className="mb-4" />
                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                >
                  {onEdit ? "Guardar cambios" : "Crear flashcards"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
FlashcardsForm.propTypes = {
  onEdit: PropTypes.bool,
};
export default withCookies(FlashcardsForm);
