import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import propTypes from 'prop-types';

const ModalAlert = (props) => {
  const { buttonLabel, className, children, bodyText, modalTitle, toggle, isOpen } = props;

  return (
    <div>
      <Button color="danger" onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
        <ModalBody>
          {bodyText}
        </ModalBody>
        <ModalFooter>
          {children}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
ModalAlert.propTypes = {
  buttonLabel: propTypes.string,
  className: propTypes.string,
  bodyText: propTypes.string,
  modalTitle: propTypes.string,
  isOpen: propTypes.bool,
  toggle: propTypes.func,
};

export default ModalAlert;
