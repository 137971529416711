import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Input, Spinner, Label } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { useSuperbmindUser } from "../../redux/selectors";
import { useSubject } from "../../views/subjectProfile/selectors";
import { clearState, createTopic, toggleTopicModal } from "./actions";
import {
  useCreating,
  useCreatingError,
  useCreatingSuccess,
  useCreatedTopicModal,
} from "./selector";
import Select from "react-select";
import Loading from "react-loading";
import { useMySubjects } from "../../views/mysubjects/selector";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../lib/Colors";
import { cmsAPI } from "../../utils/http-client";
import {
  useCategoriesDD,
  useFetchingCategories,
} from "../CategoriesDD/selectors";
import { fetchCategoriesDD } from "../CategoriesDD/actions";
import { getSuperbmindUser } from "../../views/login/actions";

const CreateTopicModal = ({
  buttonLabel,
  modalTitle,
  toggleComponent,
  toggle,
  isOpen = false,
  className,
  hideToggleComponent,
  showParentAssign = false,
}) => {
  const fetchingCategories = useFetchingCategories();
  const categoriesArr = useCategoriesDD();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fetchingSubCategory, setFetchingSubCategory] = useState(true);
  const [SubjectSubCategory, setSubjectSubCategory] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const dispatch = useDispatch();
  const creatingLoading = useCreating();
  const creatingSuccess = useCreatingSuccess();
  const creatingError = useCreatingError();
  const isOpenTopicModal = useCreatedTopicModal();
  const subjectProfile = useSubject();
  const [name, setName] = useState("");
  const [invalidname, setInvalidName] = useState(false);
  const [xlsxFile, setXLSXFile] = useState([]);
  const superbmindUser = useSuperbmindUser();
  const mysubjects = useMySubjects();
  const [fetchingSubjects, setFetchingSubjects] = useState(false);
  const [requiredFile, setRequiredFile] = useState(false);
  const [parentSubjects, setParentSubjects] = useState(null);
  const [superbmindSubjects, setSuperbmindSubjects] = useState([]);
  const showImport = useSelector(({ createTopic }) => createTopic.showImport);
  const [localSubjects, setLocalsubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [UserScalesSelected, setUserScalesSelected] = useState([]);
  const [UserScales, setUserScales] = useState([]);
  const [FetchingUserScales, setFetchingUserScales] = useState(false);
  const getSuperbmindSubjects = () => {
    if (superbmindUser?.id && fetchingSubjects == false) {
      console.log("getting superbmind");
      setFetchingSubjects(true);
      cmsAPI
        .get(`subjects?owner=${superbmindUser?.id}`)
        .then(({ data }) => {
          setFetchingSubjects(false);
          setSuperbmindSubjects(data);
        })
        .catch((error) => {
          setFetchingSubjects(false);
        });
    }
  };
  const getSubcategories = () => {
    setFetchingSubCategory(true);
    cmsAPI
      .get("sub-categories?_limit=9999")
      .then((res) => {
        setFetchingSubCategory(false);
        setSubCategoryData(res.data);
      })
      .catch((err) => {
        setFetchingSubCategory(false);
        setSubCategoryData([]);
      });
  };
  const getUserScales = () => {
    setFetchingUserScales(true);
    cmsAPI
      .get("userscales?_limit=9999")
      .then((res) => {
        setFetchingUserScales(false);
        setUserScales(res.data);
      })
      .catch((err) => {
        setFetchingUserScales(false);
        setUserScales([]);
      });
  };
  useEffect(() => {
    getSubcategories();
    dispatch(fetchCategoriesDD());
    dispatch(getSuperbmindUser());
    getUserScales();
  }, []);
  useEffect(() => {
    getSuperbmindSubjects();
  }, [superbmindUser]);
  useEffect(() => {
    if (creatingSuccess) setName("");
    toggleAction();
    dispatch(clearState());
  }, [creatingSuccess]);
  const handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    setInvalidName(false);
    setName(value);
  };
  const toggleAction = () => {
    if (toggle) {
      toggle();
    } else {
      dispatch(toggleTopicModal());
    }
  };
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!name) {
      setInvalidName(true);
      return;
    }
    console.log("xlsxToSend", xlsxFile);
    if (showImport == true && (xlsxFile == null || xlsxFile?.length <= 0)) {
      setRequiredFile(true);
      return;
    }
    let inSubjects = [];
    let mainParentSubjects = {};
    if (subjectProfile != null) {
      mainParentSubjects = subjectProfile;
    } else {
      mainParentSubjects = parentSubjects[0];
    }
    if (!parentSubjects) inSubjects.push(mainParentSubjects.id);
    if (parentSubjects != null && subjectProfile != null) {
      inSubjects = [...parentSubjects, mainParentSubjects.id];
    }
    let topicToSend = {
      name,
      subject: mainParentSubjects.id,
      in_subjects: inSubjects,
      public: true,
      deleted: false,
      userscales: UserScalesSelected,
      categories: selectedCategories,
      sub_categories: SubjectSubCategory,
    };
    if (subjectProfile?.owner?.id === superbmindUser?.id) {
      console.log("SuperbMindADMIN TRUE", subjectProfile?.owner?.id);
      topicToSend.owner = superbmindUser.id;
    }
    dispatch(createTopic(topicToSend, xlsxFile));
  };
  useEffect(() => {
    console.log("File", xlsxFile);
  }, [xlsxFile]);

  const renderCreateButtonContent = () => {
    if (creatingSuccess) {
      return <i className="fas fa-check" />;
    }
    if (creatingLoading) {
      return "Cargando...";
    }
    return "Crear";
  };
  return (
    <div>
      {toggleComponent ? (
        toggleComponent
      ) : hideToggleComponent ? (
        ""
      ) : (
        <Button color="danger" onClick={toggle}>
          {buttonLabel}
        </Button>
      )}
      <Modal
        centered={true}
        isOpen={isOpenTopicModal}
        toggle={toggleAction}
        className={className}
      >
        <ModalHeader toggle={toggleAction}>Nueva pila</ModalHeader>
        <ModalBody className={"text-center"}>
          <h1>Crea una nueva pila</h1>
          <p>
            una Pila es un subconjunto de Flashcards en una clase, similar a los
            capítulos de un libro.
          </p>
          <form>
            <Label className="mt-4" for="name">
              Nombre
            </Label>
            <Input
              required
              name="name"
              value={name}
              onChange={handleInput}
              placeholder="e.g. Armas, Manual de Op."
              invalid={creatingError || invalidname}
              valid={creatingSuccess}
            />

            <Label className="mt-4" for="inSubjects">
              Categoria
            </Label>
            {fetchingCategories || !categoriesArr?.length ? (
              <div className="d-flex justify-content-center">
                <ReactLoading
                  type={"spin"}
                  color={ThemeColors.primary}
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <Select
                onChange={(data) => {
                  console.log("Data", data);
                  setSelectedCategories(data);
                }}
                closeMenuOnSelect={false}
                isMulti
                value={selectedCategories}
                hideSelectedOptions
                options={categoriesArr.map((category) => {
                  // if (selectedCategories.includes(category) === true) return;
                  category.value = category.id;
                  category.label = category.name;
                  return category;
                })}
              />
            )}
            <Label className="mt-4" for="inSubjects">
              Sub categoria
            </Label>
            {fetchingSubCategory ? (
              <div className="d-flex justify-content-center">
                <ReactLoading
                  type={"spin"}
                  color={ThemeColors.primary}
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <Select
                hideSelectedOptions
                isMulti
                onChange={(data) => {
                  setSubjectSubCategory(data);
                }}
                value={SubjectSubCategory}
                closeMenuOnSelect={false}
                defaultValue={SubjectSubCategory}
                options={subCategoryData.map((type) => {
                  type.value = type.id;
                  type.label = type.name;
                  return type;
                })}
              />
            )}

            <Label className="mt-4" for="inSubjects">
              Grado
            </Label>
            {FetchingUserScales ? (
              <div className="d-flex justify-content-center">
                <ReactLoading
                  type={"spin"}
                  color={ThemeColors.primary}
                  height={20}
                  width={20}
                />
              </div>
            ) : (
              <Select
                hideSelectedOptions
                isMulti
                onChange={(data) => {
                  setUserScalesSelected(data);
                }}
                value={UserScalesSelected}
                closeMenuOnSelect={false}
                options={UserScales.map((type) => {
                  type.value = type.id;
                  type.label = type.name;
                  return type;
                })}
              />
            )}

            {showParentAssign && (
              <>
                <Label className="mt-4" for="inSubjects">
                  Pertenece a: {subjectProfile?.name}
                </Label>
                {fetchingSubjects ? (
                  <div className="d-flex justify-content-center">
                    <ReactLoading
                      type={"spin"}
                      color={ThemeColors.primary}
                      height={20}
                      width={20}
                    />
                  </div>
                ) : (
                  <Select
                    isMulti
                    hideSelectedOptions
                    onChange={(data) => {
                      setParentSubjects(data);
                    }}
                    value={parentSubjects}
                    closeMenuOnSelect={false}
                    options={superbmindSubjects.map((subject) => {
                      subject.value = subject.id;
                      subject.label = subject.name;
                      return subject;
                    })}
                  />
                )}
              </>
            )}

            {showImport && (
              <>
                <Label className="mt-4 mb-2" for="inSubjects">
                  Importar flashcards desde archivo (csv)
                </Label>
                <div className="custom-file">
                  <Input
                    invalid={requiredFile}
                    required={showImport ? true : false}
                    type="file"
                    accept=".csv"
                    className="custom-file-input"
                    id="validatedCustomFile"
                    name="xlsx"
                    onChange={(e) => {
                      setRequiredFile(false);
                      let value = e.target.files[0];
                      console.log("logFile", value);
                      setXLSXFile(value);
                    }}
                    placeholder="XLSX"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="validatedCustomFile"
                  >
                    {xlsxFile?.name ? xlsxFile.name : "Elige archivo..."}
                  </label>
                  {/* <div className="invalid-feedback">Formato invalido</div> */}
                </div>
              </>
            )}
            <input
              type="submit"
              style={{ display: "none" }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              toggleAction();
              dispatch(clearState());
            }}
            color="danger"
          >
            Cerrar
          </Button>
          <Button
            type={"submit"}
            color={creatingSuccess ? "success" : "primary"}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            disabled={creatingLoading || creatingSuccess}
          >
            {renderCreateButtonContent()}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CreateTopicModal;
