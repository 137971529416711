import React from 'react';

const RequestDataDeletion = () => {

  return (
    <div className='container'>
      <div className='page-inner'>
        <h1>Quieres borrar tus datos</h1>
        <p>
          Para borrar tus datos porfavor manda un email a <a href='mailto:'>contactopicketchy@superbmind.com.mx</a>
        </p>
      </div>

    </div>
  );
};

export default RequestDataDeletion;