import React, { useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Input,
  Label,
  Tooltip,
} from "reactstrap";
import Skeleton from "react-loading-skeleton";
import { useFetchingSubject, useEditViewTopic } from "../selectors";
import { Link } from "react-router-dom";
import ModalView from "../../../components/ModalView";
import EditDeckInfo from "./EditDeckInfo";
const EditFlashcardsBreadCrumbs = ({loadingFlag, ...props }) => {
  const activeTopic = useEditViewTopic();
  const activeSubject = useFetchingSubject();
  const [toggleModal, setToggleModal] = useState(false);
  const toggleEditModal = () => {
    setToggleModal(!toggleModal);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <Breadcrumb>
      <BreadcrumbItem active={false}>
        <Link to={activeSubject ? `/subjects/${activeSubject?.id}` : "#"}>
          Inicio
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem active={false}>
        <Link to={activeSubject ? `/subjects/${activeSubject?.id}` : "#"}>
          {activeSubject?.name || "..."}
        </Link>
      </BreadcrumbItem>
      <BreadcrumbItem active={true}>
        {activeTopic?.name || "..."}
      </BreadcrumbItem>
      <div className="ml-auto">
        <EditDeckInfo />
      </div>
    </Breadcrumb>
  );
};
export default EditFlashcardsBreadCrumbs;
