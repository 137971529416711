import { useSelector } from "react-redux";
export const useProfileUpdateLoading = () => {
  return useSelector(
    ({ userProfile }) => userProfile && userProfile.editUser && userProfile.editUser.loading
  );
};
export const useProfileUpdateSuccess = () => {
  return useSelector(
    ({ userProfile }) => userProfile && userProfile.editUser?.success
  );
};
export const useProfileUpdateError = () => {
  return useSelector(
    ({ userProfile }) => userProfile && userProfile.editUser?.error
  );
};
export const useProfileUpdatedUser = () => {
  return useSelector(
    ({ userProfile }) => userProfile && userProfile.editUser?.user
  );
};
