import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SignUpActions from "../actions";
import AlertCard from "../../../components/AlertCard";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacy: false,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      repeatPassword: "",
      alertOpen: false,
      alertMessage: "",
      alertType: "danger",
    };
  }
  componentDidUpdate = (prevProps) => {
    if (!prevProps.signUpSuccess && this.props.signUpSuccess) {
      this.setState({ alertOpen: true, alertType: "success" });
      setTimeout(() => this.props.history.push("/login"), 1000);
    }
    if (!prevProps.signUpError && this.props.signUpError == true) {
      this.setState({ alertOpen: true, alertType: "danger" });
    }
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    //console.log("Name:", name, "Value:", value);
    this.setState({ [name]: value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let {
      name,
      firstName,
      lastName,
      username,
      email,
      password,
      repeatPassword,
    } = this.state;
    username = email;
    if (!name || !email || !password || !repeatPassword) {
      //console.log("Llena campos");
      this.setState({
        alertOpen: true,
        alertMessage: "Llena todos los campos",
      });
      return;
    }
    if (password == repeatPassword) {
      let form = { name, username, email, password };
      //console.log("Form", form);
      this.props.signUp(form);
    }
  };
  setAlertMessage = () => {
    if (this.props.signUpStatus == 200 || this.props.signUpSuccess) {
      return (
        <div>
          Registrado con exito, <Link to={"/login"}>inicia sesion</Link>
        </div>
      );
    }
    switch (this.props.errorID) {
      case "Auth.form.error.email.taken":
        return "La cuenta de email ya está en uso";
      case "Auth.form.error.username.taken":
        return "Nombre de usuario ya está en uso";
      case "Auth.form.error.ratelimit":
        return "Has hecho muchas consultas, espere...";
      case "success":
      default:
        return "Error";
    }
  };
  toggleAlert = () => {
    this.setState({ alertOpen: false });
    this.props.clearError();
  };
  render() {
    const { alertOpen, alertType } = this.state;
    if (this.state.legacy)
      return (
        <div className="row">
          <div className="col-md-9 col-lg-8 mx-auto">
            {
              <AlertCard
                isOpen={alertOpen}
                toggle={this.toggleAlert}
                color={alertType}
                message={this.setAlertMessage()}
              />
            }
            <h3 className="login-heading mb-4">Bienvenido a Superbmind</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="form-label-group">
                <input
                  value={this.state.name}
                  name="name"
                  type="text"
                  onChange={this.handleChange}
                  id="name"
                  className="form-control"
                  placeholder="Nombre(s)"
                  required
                  autoFocus
                />
                <label htmlFor="inputFirstName">Nombre(s)</label>
              </div>
              <div className="form-label-group">
                <input
                  value={this.state.email}
                  name="email"
                  type="email"
                  onChange={this.handleChange}
                  id="inputEmail"
                  className="form-control"
                  placeholder="Email"
                  required
                />
                <label htmlFor="inputEmail">Email</label>
              </div>
              <div className="form-label-group">
                <input
                  value={this.state.password}
                  name="password"
                  type="password"
                  onChange={this.handleChange}
                  id="inputPassword"
                  className="form-control"
                  placeholder="Contraseña"
                  required
                />
                <label htmlFor="inputPassword">Contraseña</label>
              </div>
              <div className="form-label-group">
                <input
                  value={this.state.repeatPassword}
                  name="repeatPassword"
                  type="password"
                  onChange={this.handleChange}
                  id="inputRPassword"
                  className="form-control"
                  placeholder="Repite la Contraseña"
                  required
                />
                <label htmlFor="inputRPassword">Repite la Contraseña</label>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  disabled={this.props.loading ? true : false}
                  className="btn btn-primary"
                  type="submit"
                >
                  Crear cuenta{" "}
                </button>
              </div>
              <div className="text-center">
                <Link className="small" to="/login">
                  ¿Ya tienes cuenta? Entra aquí
                </Link>
              </div>
            </form>
          </div>
        </div>
      );
    return (
      <>
        <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center bg-primary-gradient">
          <h1 className="title fw-bold text-white mb-3">Superbmind</h1>
          <p className="subtitle text-white op-7">
            Bienvenido a Superbmind, aprende de los temas de tu interes con el
            mejor contenido
          </p>
        </div>
        <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
          <div className="container container-login container-transparent animated fadeIn">
            <h3 className="text-center">Crea tu cuenta</h3>
            <form onSubmit={this.handleSubmit}>
              <div className="login-form">
                {
                  <AlertCard
                    isOpen={alertOpen}
                    toggle={this.toggleAlert}
                    color={alertType}
                    message={this.setAlertMessage}
                  />
                }
                <div className="form-group">
                  <label htmlFor="fullname" className="placeholder">
                    <b>Nombre</b>
                  </label>
                  <input
                    value={this.state.name}
                    onChange={this.handleChange}
                    autoFocus
                    id="name"
                    name="name"
                    type="text"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="placeholder">
                    <b>Email</b>
                  </label>
                  <input
                    value={this.state.email}
                    onChange={this.handleChange}
                    id="email"
                    name="email"
                    type="email"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="passwordsignin" className="placeholder">
                    <b>Password</b>
                  </label>
                  <div className="position-relative">
                    <input
                      value={this.state.password}
                      onChange={this.handleChange}
                      id="password"
                      name="password"
                      type="password"
                      className="form-control"
                      required
                    />
                    <div className="show-password">
                      <i className="icon-eye" />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="confirmpassword" className="placeholder">
                    <b>Confirm Password</b>
                  </label>
                  <div className="position-relative">
                    <input
                      value={this.state.repeatPassword}
                      onChange={this.handleChange}
                      id="repeatPassword"
                      name="repeatPassword"
                      type="password"
                      className="form-control"
                      required
                    />
                    <div className="show-password">
                      <i className="icon-eye" />
                    </div>
                  </div>
                </div>
                <div className="row form-sub m-0">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name="agree"
                      id="agree"
                    />
                    <label className="custom-control-label" htmlFor="agree">
                      Acepto los terminos y condiciones.
                    </label>
                  </div>
                </div>
                <div className="row form-action">
                  <div className="col-md-6">
                    <a
                      href="/login"
                      id="show-signin"
                      className="btn btn-danger btn-link w-100 fw-bold"
                    >
                      Cancel
                    </a>
                  </div>
                  <div className="col-md-6">
                    <button
                      disabled={this.props.loading ? true : false}
                      type="submit"
                      className="btn btn-primary w-100 fw-bold"
                    >
                      Registrar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    errorID: state.signup.errorID,
    signUpError: state.signup.error,
    signUpStatus: state.signup.status,
    signUpSuccess: state.signup.signUpSuccess,
    loading: state.signup.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: bindActionCreators(SignUpActions.signUp, dispatch),
    clearError: bindActionCreators(SignUpActions.clearError, dispatch),
  };
};
export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
);
