import React, { Component } from "react";
import PublicLayout from "../../layouts/PublicLayout";
import ChangePasswordForm from "./components/ChangePasswordForm";
import reducer from "./reducer";
class ResetPasswordView extends Component {
  state = {};
  render() {
    return (
      <PublicLayout>
        <div className="vertical-center">
          <div className="container shadow p-5">
            <ChangePasswordForm /> 
          </div>
        </div>
      </PublicLayout>
    );
  }
}

export default ResetPasswordView;
export { reducer };
