import { cmsAPI } from "../../utils/http-client";

export const CATEGORY_VIEW_ACTIONS = {
  FETCH_CATEGORY_START: "FETCH_CATEGORY_START",
  FETCH_CATEGORY_SUCCESS: "FETCH_CATEGORY_SUCCESS",
  FETCH_CATEGORY_ERROR: "FETCH_CATEGORY_ERROR",
  CLEAR_STATE: "CLEAR_STATE",
};
export const getCategoryData = (slug) => (dispatch) => {
  cmsAPI
    .get(`/categories?slug=${slug}`)
    .then(({ data }) => {
      //console.log("Category data", data);
      dispatch({
        type: CATEGORY_VIEW_ACTIONS.FETCH_CATEGORY_SUCCESS,
        category: data[0],
      });
    })
    .catch((err) => {
      //console.log("Error getting Category", err);
      dispatch({
        type: CATEGORY_VIEW_ACTIONS.FETCH_CATEGORY_ERROR,
        errorPayload: err,
      });
    });
};
