import { STUDY_ACTIONS } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case STUDY_ACTIONS.FETCH_TOPIC_START:
      return {
        ...state,
        ...payload,
      };
    case STUDY_ACTIONS.FETCH_TOPIC_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case STUDY_ACTIONS.FETCH_TOPIC_ERROR:
      return {
        ...state,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
