import { CATEGORY_VIEW_ACTIONS } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case CATEGORY_VIEW_ACTIONS.FETCH_CATEGORY_START:
      return {
        loading: true,
        ...payload,
      };
    case CATEGORY_VIEW_ACTIONS.FETCH_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
        category: payload.category,
        ...payload,
      };
    case CATEGORY_VIEW_ACTIONS.FETCH_CATEGORY_ERROR:
      return {
        loading: false,
        error: true,
        ...payload,
      };
    case CATEGORY_VIEW_ACTIONS.CLEAR_STATE:
      return {};
    default:
      return { ...state };
  }
};

export default reducer;
