import { SUBJECTS_ACTIONTYPE } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case SUBJECTS_ACTIONTYPE.GET_SUBJECTS_START:
      return {
        loading: true,
        ...payload
      };
    case SUBJECTS_ACTIONTYPE.GET_SUBJECTS_SUCCESS:
      return {
        loading: false,
        fetchingSuccess: true,
        ...payload,
      };
    case SUBJECTS_ACTIONTYPE.GET_SUBJECTS_ERROR:
      return {
        loading: false,
        error: "",
        ...payload
      };
    case SUBJECTS_ACTIONTYPE.GET_SUBJECTS_END:
      return {
        loading: false,
      };
    case SUBJECTS_ACTIONTYPE.GET_SUBJECT_CHILD_SUCCESS:
      return {
        loading: false,
        fetchingSuccess: true,
        ...payload,
      };
    case SUBJECTS_ACTIONTYPE.GET_SUBJECT_CHILD_START:
      return {
        loading: false,
      };
    default:
      return {...state};
  }
};

export default reducer;
