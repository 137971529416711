import { ACTIONS_CREATE_TOPIC } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ACTIONS_CREATE_TOPIC.CREATE_TOPIC_START:
      return {
        ...payload,
        loading: true,
        modalActive: state.modalActive,
        ...state,
      };
    case ACTIONS_CREATE_TOPIC.CREATE_TOPIC_SUCCESS:
      return {
        ...payload,
        loading: false,
        success: true,
        modalActive: false,
        ...state,
      };
    case ACTIONS_CREATE_TOPIC.CREATE_TOPIC_ERROR:
      return {
        ...payload,
        loading: false,
        error: true,
        modalActive: state.modalActive,
        ...state,
      };
    case ACTIONS_CREATE_TOPIC.TOGGLE_TOPIC_MODAL:
      return {
        ...payload,
        modalActive: !state.modalActive || false,
        showImport: payload.showImport || false,
        ...state,
      };
    case ACTIONS_CREATE_TOPIC.CLEAR_STATE:
      return {};
    default:
      return { ...state };
  }
};

export default reducer;
