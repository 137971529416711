import React, { Component } from "react";
import { Link } from "react-router-dom";
const EmailConfirmedView = (props) => {
  return (
    <div className="page-inner">
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center text-center">
          <div className="col-12" style={{ marginTop: "25%" }}>
            <i className="fas fa-check-circle fa-10x"></i>
            <h1 style={{ fontWeight: "bold" }}>Email Confirmado con exito!</h1>
            <h3>
              Vuelve a la App y presiona Continuar o bien, puedes iniciar sesion{" "}
              <Link to="login" style={{ textDecorationLine: "underline" }}>
                Aquí
              </Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmailConfirmedView;
