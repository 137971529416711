import React, { useEffect, useState } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import { cmsAPI } from "../../utils/http-client";
import MatchFlashCardsList from "./components/MatchFlashCardsList";
import { getUser } from "../../lib/localstorage";
import Skeleton from "react-loading-skeleton";
import { LoadingFlashcard } from "./LoadingFlashcard";

const FlashCardMatchView = (props) => {
    const user = getUser()
    const [flashcards, setFlashcards] = useState([]);
    const [loading, setLoading] = useState(true);
    const [requestError, setRequestError] = useState(false);
   //console.log("ParamsFlashcardsList",props.match);
    const {match:{params}} = props;
    const countFlashcards = (userID,userTopicId) => {
        cmsAPI.get(`userflashcards?user=${userID}&usertopic=${userTopicId}`)
        .then(res => {
           //console.log("UserFLASHCARDS:",res);
            setFlashcards(res.data)
            setLoading(false)
        })
        .catch(err => {
            setRequestError(true)
            setLoading(false)
        })
    }
    useEffect(()=>{
       //console.log("CountFlashcards PARAMS", params)
        countFlashcards(user.id,params.userTopicId)
    },[])
    return (
        <AuthenticateLayout>
            {
                loading ? <LoadingFlashcard/> : <MatchFlashCardsList userId={user.id} userTopicId={params.userTopicId}/>
            }
        </AuthenticateLayout>
      );
}
export default FlashCardMatchView;
