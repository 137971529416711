import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_SUBCATEGORY_ACTION_TYPE } from "../actions";

const DeleteSubcategoryAlert = ({ ...props }) => {
  const dispatch = useDispatch();
  const successDeletingSubcategory = useSelector(
    ({ adminSubcategories }) => adminSubcategories.deleteSubcategory.success
  );
  const errorDeletingSubcategory = useSelector(
    ({ adminSubcategories }) => adminSubcategories.deleteSubcategory.error
  );
  let alertClassName = "alert alert-secondary alert-dismissible fade show";
  let alertMessage = "";
  if (successDeletingSubcategory) {
    alertClassName = "alert alert-success alert-dismissible fade show";
    alertMessage = "Se ha eliminado correctamente";
  }
  if (errorDeletingSubcategory) {
    alertClassName = "alert alert-danger alert-dismissible fade show";
    alertMessage = "Ha ocurrido un error";
  }
  if (!successDeletingSubcategory && !errorDeletingSubcategory) return <></>;
  else
    return (
      <div className={alertClassName} role="alert">
        {alertMessage}
        <button
          onClick={() => {
            dispatch({
              type: ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_USERSCALES_CLEAR,
            });
          }}
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    );
};
export default DeleteSubcategoryAlert;
