import React, { Component } from "react";
import NavBar from "../components/NavBar";
import AppFooter from "../components/Footer";
import CarouselLandingPage from "../views/index/components/Carousel";
export const PublicLayout = ({ navPosition, ...props }) => {
  const { children } = props;
  return (
    <div className="wrapper horizontal-layout-3">
      <NavBar navPosition={navPosition} {...props} />
      <div className="">
        {children}
        <AppFooter />
      </div>
    </div>
  );
};

export default PublicLayout;
