"use strict";

import axios from "axios";

let httpApiURL = "https://api.superbmind.com.mx/"
let baseURL = "https://api.superbmind.com.mx/"
// let baseURL = "http://localhost:1337/"

export const cmsAPI = axios.create({
  baseURL: baseURL,
  timeout: 120000,
  headers: {
    common: {},
  },
});

cmsAPI.addJwt = (token) => {
  //console.log("addJwt", token);
  if (token)
    cmsAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
cmsAPI.clearJwt = () => {
  //console.log("clearJwt");
  cmsAPI.defaults.headers.common["Authorization"] = "";
};

cmsAPI.getToken = () => {
  return cmsAPI.defaults.headers.common["Authorization"];
};

cmsAPI.resolveAsset = (url) => {
  return cmsAPI.defaults.baseURL + url;
};
cmsAPI.resolveAssetAPI = (url) => {
  return httpApiURL + url;
};
export const api = axios.create({
  baseURL: baseURL,
  timeout: 30000,
  headers: {},
});
