const localStorage = window.localStorage;

function getItem(itemName) {
  let item = localStorage.getItem(itemName);
  if (!item) return {};
  item = JSON.parse(item);
  return item;
}

function setItem(name, item) {
  localStorage.setItem(name, JSON.stringify(item));
}

function clearItem(name) {
  localStorage.removeItem(name);
}

export function getUser() {
  const user = getItem("user");
  //console.log("GetUser",user)
  return user;
}

export function setUser(user) {
  setItem("user", user);
}

export function clearUser() {
  clearItem("user");
}
export function getRememberLogin() {
  return getItem("remember");
}
export function setFlashcardMatchPending(pendingRequest) {
  setItem("pendingRequests", pendingRequest);
}
export function getsetFlashcardMatchPending() {
  return getItem("pendingRequests");
}
export function setRememberLogin(form) {
  setItem("remember", form);
}

export function clearRememberLogin() {
  clearItem("remember");
}
