import React, { Component } from "react";
import FlashcardContent from "../../../components/FlashcardContent";
const QACards = ({ question, answer, className }) => {
  return (
    <>
      <div className={className ? className : "col-md-3"}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Pregunta</h4>
          </div>
          <div className="card-body">
            <FlashcardContent content={question} />
          </div>
        </div>
      </div>
      <div className={className ? className : "col-md-3"}>
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Respuesta</h4>
          </div>
          <div className="card-body">
            <FlashcardContent content={answer} />
          </div>
        </div>
      </div>
    </>
  );
};
export default QACards;
