import React, { useEffect, useState } from "react";
import { useUser } from "../../../redux/selectors";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert,
} from "reactstrap";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../../lib/Colors";

const ProfilePasswordsForm = (props) => {
  const userState = useUser();
  const [userForm, setUserForm] = useState({});
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  useEffect(() => {
    console.log("User..", userState);
    setUserForm(userState);
  }, [userState]);
  const handleInput = () => {
    console.log("Change password");
  };
  const showAlert = () => {
    if (false)
      return (
        <Alert color={"success"} className="m-3">
          Editado con exito
        </Alert>
      );
    if (false)
      return (
        <Alert color={"danger"} className="m-3">
          Ha ocurrido un error, intenta más tarde
        </Alert>
      );
    else return;
  };
  return (
    <Form>
      {showAlert()}
      <FormGroup>
        <Label for="email">Contraseña actual</Label>
        <Input
          value={currentPassword || ""}
          onChange={(e) => {
            let value = e.target.value;
            setCurrentPassword(value);
          }}
          type="password"
          name="currentPassword"
          id="currentPassword"
          placeholder="**********"
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Contraseña nueva</Label>
        <Input
          value={newPassword || ""}
          onChange={(e) => {
            let value = e.target.value;
            setNewPassword(value);
          }}
          type="password"
          name="newPassword"
          id="newPassword"
          placeholder="**********"
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Repetir contraseña</Label>
        <Input
          value={repeatPassword || ""}
          onChange={(e) => {
            let value = e.target.value;
            setRepeatPassword(value);
          }}
          type="password"
          name="repeatPassword"
          id="repeatPassword"
          placeholder="**********"
        />
      </FormGroup>
      <FormGroup className="d-flex justify-content-between">
        <Button disabled={false} onClick={handleInput}>
          {"Guardar"}
        </Button>
        {false && (
          <ReactLoading
            width={"3vw"}
            color={ThemeColors.primary}
            type={"spin"}
          />
        )}
      </FormGroup>
    </Form>
  );
};
export default ProfilePasswordsForm;
