import { ACTIONS_PROFILE } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ACTIONS_PROFILE.EDIT_USER_PROFILE_START:
      return {
        editUser: {
          loading: true,
        },
      };
    case ACTIONS_PROFILE.EDIT_USER_PROFILE_SUCCESS:
      return {
        editUser: {
          ...payload.editUser,
          loading: false,
          success: true,
        },
      };
    case ACTIONS_PROFILE.EDIT_USER_PROFILE_ERROR:
      return {
        ...state,
        editUser: {
          ...payload.editUser,
          loading: false,
          success: false,
          error: true,
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
