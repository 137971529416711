import React, { Component } from "react";
import LoginLayout from "../../layouts/LoginLayout";
import PublicLayout from "../../layouts/PublicLayout";
import LoginForm from "./components/LoginForm";
import reducer from "./reducer";
class LoginView extends Component {
  state = {};
  render() {
    return (
      <LoginLayout {...this.props}>
        <LoginForm {...this.props} />
      </LoginLayout>
    );
  }
}

export default LoginView;

export { reducer };
