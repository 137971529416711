import React, { useState } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import { ThemeColors } from "../../lib/Colors";
import { cmsAPI } from "../../utils/http-client";
import FlashCard from "../FlashCard";
import { SRLWrapper } from "simple-react-lightbox";
import { useHistory, useRouteMatch } from "react-router-dom";
import DeleteFlashcardModal from "../../views/editflashcards/components/ConfirmDeleteFlashcard";
import { Button } from "reactstrap";
const PreviewFlashcardsList = ({
  alignContentFlag = false,
  flashcards = [],
  review = false,
  deleteAction,
  deleting,
  deletingSuccess,
  deletingError,
}) => {
  const confidentColors = [
    "#13c1d4",
    "#a712c0",
    "#06d6a0",
    "#ffd166",
    "#ef476f",
    "#CCCCCC",
  ];
  const options = {
    buttons: {
      backgroundColor: ThemeColors.secondaryBg,
      iconColor: "#C9C5BA",
      iconPadding: "10px",
    },
    caption: {
      captionColor: "#e8eddf",
      captionFontSize: "18px",
    },
    settings: {
      overlayColor: "rgba(0, 0, 0, 0.9)",
    },
    thumbnails: {
      thumbnailsAlignment: "center",
    },
  };
  const [value, setValue] = useState(3);
  const [showButton, setShowButton] = useState(false);
  const history = useHistory();
  const path = useRouteMatch();
  const callbacks = {
    onLightboxOpened: () => setShowButton(true),
    onLightboxClosed: () => setShowButton(false),
  };

  function handleInput(e) {
    setValue(e.target.value);
  }
  if (flashcards == null || flashcards.length <= 0) return <div></div>;
  return (
    <div className="">
      {flashcards.map((flashcard, index) => {
        let flashcardConfidentColor =
          confidentColors[Math.floor(Math.random() * (6 - 0)) + 0];
        return (
          <div
            key={index}
            style={{
              backgroundColor: "#FAFAFA",
              // backgroundColor: ThemeColors.themePrimary,
              // boxShadow: "0px 0px 6px 2px rgba(0,0,0,0.75)",
              boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
              color: "black",
              borderRadius: 10,
              marginBottom: 15,
              paddingTop: 10,
              paddingBottom: alignContentFlag ? 60 : 20,
              borderTop: `8px solid`,
              borderTopColor: `${flashcardConfidentColor}`,
              // borderBottom: ".5px solid gray",
              // borderRight: ".5px solid gray",
              // borderLeft: ".5px solid gray",
            }}
          >
            <SRLWrapper callbacks={callbacks} options={options}>
              <div className="d-flex flex-row">
                <div className="">
                  <p
                    style={{ fontWeight: "bold", fontSize: 18 }}
                    className="text-dark ml-3"
                  >
                    {index + 1} {flashcard?.name}
                  </p>
                </div>
                <div className="d-flex ml-auto">
                  <div>
                    <Button
                      onClick={() => {
                        history.push(
                          flashcard?.topic
                            ? `/subjects/${flashcard?.topic?.subject}/edit/${flashcard?.topic.id}?flashcardIndex=${index}`
                            : "#"
                        );
                      }}
                      color="link"
                      className={""}
                    >
                      <i className="fas fa-edit"></i>
                    </Button>
                  </div>
                  {review && (
                    <DeleteFlashcardModal
                      toggleColor={"primary"}
                      deletingF={deleting}
                      deletingSuccessF={deletingSuccess}
                      deletingErrorF={deletingError}
                      deleteAction={
                        deleteAction
                          ? (flashcardId) => {
                              deleteAction(flashcardId);
                            }
                          : () => {
                              console.log("No action");
                            }
                      }
                      inReview={review}
                      flashcardId={flashcard?.id}
                      toggleClassName="btn-link"
                    ></DeleteFlashcardModal>
                  )}
                  {
                    <div className={""}>
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-link dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            // background: `${flashcardConfidentColor}`,
                            // backgroundColor: `${flashcardConfidentColor}`,
                            color: `${flashcardConfidentColor}`,
                          }}
                        >
                          <i className="fas fa-graduation-cap"></i>
                        </button>
                        <div class="dropdown-menu">
                          <a
                            class="dropdown-item"
                            style={{ backgroundColor: `${confidentColors[0]}` }}
                            href="#"
                          >
                            1
                          </a>
                          <a
                            class="dropdown-item"
                            style={{ backgroundColor: `${confidentColors[1]}` }}
                            href="#"
                          >
                            2
                          </a>
                          <a
                            class="dropdown-item"
                            style={{ backgroundColor: `${confidentColors[2]}` }}
                            href="#"
                          >
                            3
                          </a>
                          <a
                            class="dropdown-item"
                            style={{ backgroundColor: `${confidentColors[3]}` }}
                            href="#"
                          >
                            4
                          </a>
                          <a
                            class="dropdown-item"
                            style={{ backgroundColor: `${confidentColors[4]}` }}
                            href="#"
                          >
                            5
                          </a>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="d-flex flex-row justify-around">
                <div className="col-6">
                  <p className="text-dark text-center">Pregunta</p>
                  <div
                    style={{
                      height: alignContentFlag ? "100%" : "",
                      ...styles.flashcardContent,
                      display: alignContentFlag ? "flex" : "",
                      flexDirection: alignContentFlag ? "column" : "",
                      justifyContent: alignContentFlag ? "center" : "",
                      alignItems: alignContentFlag ? "center" : "",
                    }}
                  >
                    {flashcard?.subtitle && (
                      <div
                        style={{
                          position: review ? "relative" : "absolute",
                          top: review ? 0 : 55,
                          left: review ? 0 : 40,
                          fontSize: 12,
                          marginBottom: 10,
                          fontWeight: "bold",
                          color: ThemeColors.secondaryBg,
                        }}
                      >
                        {flashcard?.subtitle}
                      </div>
                    )}

                    <div
                      style={{ padding: 6 }}
                      dangerouslySetInnerHTML={{ __html: flashcard?.question }}
                    ></div>
                    {flashcard?.questionmedia?.length > 0 && (
                      <img
                        style={{
                          maxWidth: "10vw",
                        }}
                        id="imageResult"
                        src={cmsAPI.resolveAssetAPI(
                          flashcard?.questionmedia[0].url
                        )}
                        alt=""
                        className="img-fluid rounded shadow-sm mx-auto d-block"
                      />
                    )}
                  </div>
                </div>
                <div className="col-6">
                  <p className="text-dark text-center">Respuesta</p>
                  <div
                    style={{
                      height: alignContentFlag ? "100%" : "",
                      ...styles.flashcardContent,
                      display: alignContentFlag ? "flex" : "",
                      flexDirection: alignContentFlag ? "column" : "",
                      justifyContent: alignContentFlag ? "center" : "",
                      alignItems: alignContentFlag ? "center" : "",
                    }}
                  >
                    <div
                      style={{ padding: 6 }}
                      dangerouslySetInnerHTML={{ __html: flashcard?.answer }}
                    ></div>
                    {flashcard?.answermedia?.length > 0 && (
                      <img
                        style={{
                          maxWidth: "10vw",
                        }}
                        id="imageResult"
                        src={cmsAPI.resolveAssetAPI(
                          flashcard?.answermedia[0].url
                        )}
                        alt=""
                        className="img-fluid rounded shadow-sm mx-auto d-block"
                      />
                    )}
                  </div>
                </div>
              </div>
            </SRLWrapper>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  flashcardContent: {
    padding: 10,
    backgroundColor: "white",
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.75)",
    minHeight: 230,
    borderRadius: 10,
    // height: "100%",
  },
};
export default PreviewFlashcardsList;
