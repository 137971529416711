import { cmsAPI } from "../../../utils/http-client";

export const ADMIN_SUBCATEGORY_ACTION_TYPE = {
  GET_SUBCATEGORY_START: "GET_SUBCATEGORY_START",
  GET_SUBCATEGORY_SUCCESS: "GET_SUBCATEGORY_SUCCESS",
  GET_SUBCATEGORY_ERROR: "GET_SUBCATEGORY_ERROR",

  CREATE_SUBCATEGORY_START: "CREATE_SUBCATEGORY_START",
  CREATE_SUBCATEGORY_SUCCESS: "CREATE_SUBCATEGORY_SUCCESS",
  CREATE_SUBCATEGORY_ERROR: "CREATE_SUBCATEGORY_ERROR",

  EDIT_SUBCATEGORY_START: "EDIT_SUBCATEGORY_START",
  EDIT_SUBCATEGORY_SUCCESS: "EDIT_SUBCATEGORY_SUCCESS",
  EDIT_SUBCATEGORY_ERROR: "EDIT_SUBCATEGORY_ERROR",
  EDIT_SUBCATEGORY_CLEAR: "EDIT_SUBCATEGORY_CLEAR",

  DELETE_SUBCATEGORY_START: "DELETE_SUBCATEGORY_START",
  DELETE_SUBCATEGORY_SUCCESS: "DELETE_SUBCATEGORY_SUCCESS",
  DELETE_SUBCATEGORY_ERROR: "DELETE_SUBCATEGORY_ERROR",
  DELETE_SUBCATEGORY_CLEAR: "DELETE_SUBCATEGORY_CLEAR",
};
export const createScaleAction =
  ({ form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_START });
    cmsAPI
      .post("sub-categories", form)
      .then((response) => {
        console.log("createScaleAction-success");
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_SUCCESS,
          newestCreated: response.data,
        });
      })
      .catch((error) => {
        console.error("createScaleAction", error);
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_ERROR,
        });
      });
  };

export const editScaleAction =
  ({ userscaleId, form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_START });
    if (!userscaleId || !form) {
      dispatch({
        type: ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_ERROR,
      });
    }
    cmsAPI
      .put(`sub-categories/${userscaleId}`, form)
      .then((response) => {
        console.log("editScaleAction-success");
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_SUCCESS,
          editedScale: response.data,
        });
      })
      .catch((error) => {
        console.error("editScaleAction", error);
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_ERROR,
        });
      });
  };

export const deleteSubcategoriesAction =
  ({ userscaleId }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_START });
    cmsAPI
      .delete(`sub-categories/${userscaleId}`)
      .then(({ data }) => {
        console.log("deleteSubcategoriesAction-success", data);
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_SUCCESS,
        });
      })
      .catch((error) => {
        console.error("deleteSubcategoriesAction", error);
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_ERROR,
        });
      });
  };
export const getSubcategories =
  (params = "") =>
  (dispatch) => {
    dispatch({ type: ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_START });
    cmsAPI
      .get("sub-categories?_limit=9999&_sort=created_at:asc" + params)
      .then((response) => {
        console.log("getSubcategories-success");
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_SUCCESS,
          subCategories: response.data,
        });
      })
      .catch((error) => {
        console.error("getScalesAction-error", error);
        dispatch({
          type: ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_ERROR,
        });
      });
  };
