import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { cmsAPI } from "../../../utils/http-client";
import { SideBarLink } from "./SideBarLink";
import { useDispatch } from "react-redux";
import { fetchMySubjects } from "../../../views/mysubjects/actions";
import {
  useLoadingMySubjects,
  useMySubjects,
  useSuccessMySubjects,
  useErrorMySubjects,
} from "../../../views/mysubjects/selector";
import { Spinner } from "reactstrap";

const MySubjectsList = (props) => {
  const dispatch = useDispatch();
  const mySubjects = useMySubjects();
  const loading = useLoadingMySubjects();

  const [UserSubjects, setUserSubjects] = useState([]);
  const [requestError, setRequestError] = useState(false);
  useEffect(() => {
    dispatch(fetchMySubjects());
    console.log("mySubjects",mySubjects);
  }, []);
  if (loading) return <p className="text-center text-muted">cargando...</p>;
  return (
    <>
      {mySubjects &&
        mySubjects.map((uSubject, index) => {
          // console.log("uSubject",uSubject);
          return (
            <SideBarLink
              key={index}
              withImage
              imageStyle={{backgroundColor:'#fff'}}
              path={"/subjects/" + uSubject.id}
              imageURI={uSubject.image != null && uSubject.image != null && uSubject.image.length > 0 && uSubject.image[0]?.formats?.thumbnail?.url}
              name={uSubject.name}
            />
          );
        })}
    </>
  );
};
export default MySubjectsList;
