import { useSelector } from "react-redux";
import { cmsAPI } from "../../utils/http-client";

export const SUBJECTS_ACTIONTYPE = {
  GET_CATEGORIES_START: "GET_CATEGORIES_START",
  GET_CATEGORIES_END: "GET_CATEGORIES_END",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",

  GET_SUBJECTS_START: "GET_SUBJECTS_START",
  GET_SUBJECTS_END: "GET_SUBJECTS_END",
  GET_SUBJECTS_SUCCESS: "GET_SUBJECTS_SUCCESS",
  GET_SUBJECTS_ERROR: "GET_SUBJECTS_ERROR",

  GET_SUBJECT_CHILD_START: "GET_SUBJECT_CHILD_START",
  GET_SUBJECT_CHILD_END: "GET_SUBJECT_CHILD_END",
  GET_SUBJECT_CHILD_SUCCESS: "GET_SUBJECT_CHILD_SUCCESS",
  GET_SUBJECT_CHILD_ERROR: "GET_SUBJECT_CHILD_ERROR",
};

function getChildSubjectSuccess(data, parent) {
  return {
    type: SUBJECTS_ACTIONTYPE.GET_SUBJECT_CHILD_SUCCESS,
    subjects: data,
    parentSubjectActive: parent,
  };
}
function getSubjectSuccess(data, category) {
  return {
    type: SUBJECTS_ACTIONTYPE.GET_SUBJECTS_SUCCESS,
    subjects: data,
    categoryActive: category,
  };
}
function getSubjectError(data, category) {
  return {
    type: SUBJECTS_ACTIONTYPE.GET_SUBJECTS_ERROR,
    error: data,
    categoryActive: category,
  };
}
export const getSubjectsAction = (query, category) => (dispatch) => {
  dispatch({
    type: SUBJECTS_ACTIONTYPE.GET_SUBJECTS_START,
    categoryActive: category,
  });
  let URI = "/subjects?public=true";
  if (query) {
    URI = `/subjects?public=true${query && `&${query}`}`;
  }
  cmsAPI
    .get(URI)
    .then((res) => {
     //console.log("Service.js | getSubjects() RESPONSE:", res);
      dispatch(getSubjectSuccess(res.data, category));
      return res.data;
    })
    .catch((err) => {
     //console.log("Service.js | getSubjects() ERROR:", err);
      dispatch(getSubjectError(err.response, category));
      return [];
    });
};
export const getChildSubjects = (parent) => (dispatch) => {
  dispatch({ type: SUBJECTS_ACTIONTYPE.GET_SUBJECT_CHILD_START });
  let URI = `/subjects?public=true&subjectgroup=${parent.id}`;
  cmsAPI
    .get(URI)
    .then((res) => {
     //console.log("Service.js | getSubjects() RESPONSE:", res);
      dispatch(getChildSubjectSuccess(res.data, parent));
      return res.data;
    })
    .catch((err) => {
     //console.log("Service.js | getSubjects() ERROR:", err);
      dispatch(getSubjectError(err.response));
      return [];
    });
};
