import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../../lib/Colors";
import AdminLayout from "../../../layouts/AdminLayout";
import { useSuperbmindUser } from "../../../redux/selectors";
import { cmsAPI } from "../../../utils/http-client";
import SubjectListItem from "../../../components/SubjectListItem";
import { useRemoveSubjectSuccess } from "../../subjectProfile/selectors";
const SubjectAdminListView = () => {
  const superbmindUser = useSuperbmindUser();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const removeSubjectSuccess = useRemoveSubjectSuccess();
  useEffect(() => {
    if (superbmindUser?.id)
      cmsAPI
        .get(`subjects?owner=${superbmindUser.id}`)
        .then(({ data }) => {
          // console.log("Superbmind Data", data);
          setSubjects(data);
          setLoading(false);
        })
        .catch((err) => {
          // console.log("/ERROR/Superbmind Data", err);
          setLoading(false);
        });
    else return;
  }, [superbmindUser, removeSubjectSuccess]);
  return (
    <AdminLayout>
      <div>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
          <ReactLoading
            type={"spin"}
            color={ThemeColors.primary}
            height={40}
            width={40}
          />
          </div>
        )}
        {subjects != null &&
          subjects.length > 0 &&
          subjects.map((subject) => <SubjectListItem subject={subject} />)}
      </div>
    </AdminLayout>
  );
};

export default SubjectAdminListView;
