import { cmsAPI } from "../../utils/http-client";
import { notify } from "../../components/AlertNotify";
import QueryString from "qs";
export const SUBJECT_PROFILE_ACTION_TYPE = {
  GET_SUBJECT_START: "GET_SUBJECT_START",
  GET_SUBJECT_END: "GET_SUBJECT_END",
  GET_SUBJECT_SUCCESS: "GET_SUBJECT_SUCCESS",
  GET_SUBJECT_ERROR: "GET_SUBJECT_ERROR",

  REMOVE_SUBJECT_START: "REMOVE_SUBJECT_START",
  REMOVE_SUBJECT_END: "REMOVE_SUBJECT_END",
  REMOVE_SUBJECT_SUCCESS: "REMOVE_SUBJECT_SUCCESS",
  REMOVE_SUBJECT_ERROR: "REMOVE_SUBJECT_ERROR",

  GET_SUBJECT_TOPICS_START: "GET_SUBJECT_TOPICS_START",
  GET_SUBJECT_TOPICS_END: "GET_SUBJECT_TOPICS_END",
  GET_SUBJECT_TOPICS_SUCCESS: "GET_SUBJECT_TOPICS_SUCCESS",
  GET_SUBJECT_TOPICS_ERROR: "GET_SUBJECT_TOPICS_ERROR",

  SAVE_IN_MYFLASHCARDS_START: "SAVE_IN_MYFLASHCARDS_START",
  SAVE_IN_MYFLASHCARDS_SUCCESS: "SAVE_IN_MYFLASHCARDS_SUCCESS",
  SAVE_IN_MYFLASHCARDS_ERROR: "SAVE_IN_MYFLASHCARDS_ERROR",
  SAVE_IN_MYFLASHCARDS_END: "SAVE_IN_MYFLASHCARDS_END",
  PUSH_SP_TOPIC: "PUSH_SP_TOPIC",

  EDIT_SUBJECT_NAME_START: "EDIT_SUBJECT_NAME_START",
  EDIT_SUBJECT_NAME_SUCCESS: "EDIT_SUBJECT_NAME_SUCCESS",
  EDIT_SUBJECT_NAME_ERROR: "EDIT_SUBJECT_NAME_ERROR",
  EDIT_SUBJECT_NAME_CLEAR: "EDIT_SUBJECT_NAME_CLEAR",

  DELETE_SP_TOPIC_START: "DELETE_SP_TOPIC_START",
  DELETE_SP_TOPIC_SUCCESS: "DELETE_SP_TOPIC_SUCCESS",
  DELETE_SP_TOPIC_ERROR: "DELETE_SP_TOPIC_ERROR",
  DELETE_SP_TOPIC_CLEAR: "DELETE_SP_TOPIC_CLEAR",

  SORTING_START: "SORTING_START",
  SORTING_SUCCESS: "SORTING_SUCCESS",
  SORTING_ERROR: "SORTING_ERROR",
  SORTING_CLEAR: "SORTING_CLEAR",
};
function saveInMyFlashcardsSuccess() {
  return {
    type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_SUCCESS,
  };
}
export const saveInMyFlashcards = (topicId) => (dispatch) => {
  //console.log("Topic", topicId);
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.SAVE_IN_MYFLASHCARDS_START });
  setTimeout(dispatch(saveInMyFlashcardsSuccess()), 3000);
  return;
};
function getSubjectSuccess(subject) {
  getSubjectProfileTopics(subject.id);
  return {
    type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_SUCCESS,
    subject: subject,
  };
}
function getSubjectError(error) {
  return {
    type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_ERROR,
    error: error,
  };
}
function getSubjectTopicsSuccess(topics) {
  return {
    type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_SUCCESS,
    subjectTopics: topics,
  };
}
function getSubjectTopicsError(error) {
  return {
    type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_ERROR,
    error: error,
    subjectTopics: [],
  };
}

export const getSubjectProfileDATA = (subjectId) => (dispatch) => {
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_START });
  cmsAPI
    .get(`/subjects/${subjectId}`)
    .then((res) => {
      //console.log("Subject Response::", res.data);
      dispatch(getSubjectProfileTopics(res.data.id));
      dispatch(getSubjectSuccess(res.data));
      return;
    })
    .catch((err) => {
      //console.log("Subject ERROR::", err);
      dispatch(getSubjectError(err));
    });
};
export const removeSubject = (subjectId) => (dispatch) => {
  dispatch({
    type: SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_START,
    removeLoading: true,
    removeSuccess: null,
    removeError: null,
  });
  cmsAPI
    .delete(`/subjects/${subjectId}`)
    .then((res) => {
      console.log("Subject Removed");
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_SUCCESS,
        removeLoading: false,
        removeSuccess: true,
      });
    })
    .catch((err) => {
      console.log("Error While tried to remove");
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_ERROR,
        removeLoading: false,
        removeSuccess: false,
        removeError: true,
      });
    });
};
export const getSubjectProfileTopics = (subjectId) => (dispatch) => {
  //console.log("GetTopics");
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_START });
  const query = QueryString.stringify({
    _where: [{ in_subjects: [subjectId] }],
  });
  cmsAPI
    .get(`/topics/fcc?in_subjects.id=${subjectId}`)
    .then((response) => {
      console.log("RESPONSE GET SUBJECT PROFILE TOPICS");
      dispatch(getSubjectTopicsSuccess(response.data));
    })
    .catch((err) => {
      console.log("Service.js | getSubjectProfileTopics() ERROR:", err);
      dispatch(getSubjectTopicsError(err.response));
    });
};
export const pushNewTopic = (topic) => (dispatch) => {
  dispatch({
    type: SUBJECT_PROFILE_ACTION_TYPE.PUSH_SP_TOPIC,
    newTopic: topic,
  });
};
//EDIT SUBJECT
export const editSubjectName = (subjectId, subjectData) => (dispatch) => {
  let subject = subjectData;
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_START });
  if (subject?.image) {
    let formData = new FormData();
    let image = subject.image;
    delete subject.image;
    formData.append("data", JSON.stringify(subject));
    formData.append("files.image", image);
    cmsAPI
      .put(`/subjects/${subjectId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        console.log("Subject edited", data);
        dispatch({
          type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_SUCCESS,
          subject: data,
        });
      })
      .catch((err) => {
        console.log("Error While tried to edit", err);
        dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_ERROR });
      });
  } else {
    cmsAPI
      .put(`/subjects/${subjectId}`, subject)
      .then(({ data }) => {
        //console.log("Subject Name edited");
        dispatch({
          type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_SUCCESS,
          subject: data,
        });
      })
      .catch((err) => {
        //console.log("Error While tried to edit", err);
        dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_ERROR });
      });
  }
};
export const editSubjectClear = (subjectId, subjectName) => (dispatch) => {
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_CLEAR });
};
export const deleteTopic = (topicId) => (dispatch) => {
  dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_START });
  cmsAPI
    .delete(`topics/${topicId}`)
    .then(({ data }) => {
      console.log("DELETED TOPIC", data);
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_SUCCESS,
        deletedTopic: data,
      });
      setTimeout(() => {
        dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_CLEAR });
      }, 1000);
    })
    .catch((err) => {
      console.log("DELETED TOPIC ERR", err);
      dispatch({ type: SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_ERROR });
    });
};
export const saveTopicSorting = (topics) => (dispatch) => {
  dispatch({
    type: SUBJECT_PROFILE_ACTION_TYPE.SORTING_START,
    sortingTopics: { loading: true, success: false, error: false },
  });
  cmsAPI
    .post("topics/sorting", { topics })
    .then((res) => {
      console.log("Response Sorting topics:", res);
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.SORTING_SUCCESS,
        sortingTopics: { loading: false, success: true, error: false },
      });
    })
    .catch((err) => {
      console.log("Error Sorting topics:", err);
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.SORTING_SUCCESS,
        sortingTopics: { loading: false, success: false, error: true },
      });
    });
};
