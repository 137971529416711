import React, { Component, useRef, createRef } from "react";
export const notify = (type, message, reference, icon) => {
  const aType = type || "primary";
  var options = {};
  options = {
    place: "tc",
    message: (
      <div className="text-center">
        <div style={{ minHeight: 25 }}>
          {message || "Heeey, esto es una alerta"}<strong></strong>
        </div>
      </div>
    ),
    type: aType,
    icon: icon,
    autoDismiss: 7,
  };
  reference.current.notificationAlert(options);
  return;
};
