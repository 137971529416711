import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalView = ({
  confirmComponent,
  cancelComponent,
  toggleAction,
  toggleFlag,
  toggleComponent,
  title,
  centered,
  hideComponent,
  confirmText,
  modalBodyStyle,
  modalStyle,
  hideFooter,
  ...props
}) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(toggleFlag);
  const toggle = () => {
    if (toggleAction) return toggleAction();
    setModal(!modal);
  };
  return (
    <div>
      {hideComponent ? (
        <></>
      ) : toggleComponent ? (
        toggleComponent
      ) : (
        <Button color="primary" onClick={toggleAction}>
          {buttonLabel || "toggleModal"}
        </Button>
      )}
      <Modal
        centered={centered}
        isOpen={toggleFlag}
        toggle={toggle}
        className={className}
        style={{ ...modalStyle }}
      >
        {title && (
          <ModalHeader toggle={toggle}>
            <p style={{ ...props.titleStyles }}>{title}</p>
          </ModalHeader>
        )}
        <ModalBody style={{ ...modalBodyStyle }}>{props.children}</ModalBody>
        {!hideFooter && (
          <ModalFooter>
            {confirmComponent ? (
              confirmComponent
            ) : (
              <Button
                disabled={props.loading}
                color={props.confirmColor || "primary"}
                onClick={props.confirmAction || toggle}
              >
                {confirmText || "Guardar"}
              </Button>
            )}
            {cancelComponent ? (
              cancelComponent
            ) : (
              <Button color="secondary" onClick={toggle}>
                Cancelar
              </Button>
            )}
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default ModalView;
