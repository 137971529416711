import React, { createRef, useEffect, useRef, useState } from "react";
import {
  useCreateFlashcardLoading,
  useCreateFlashcardSuccess,
  useEditViewFlashcards,
  useEditViewTopic,
  useFetchingFlashcardsError,
  useFetchingFlashcardsLoading,
  useFlashcardCloneLoading,
  useFlashcardcloneSingleSuccess,
  useFlashcardIndexActive,
  useUpdateMultiSubtitleSuccess,
} from "../selectors";

import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import {
  createBrandNewFlashcard,
  setFlashcardIndexActive,
  reoderFlashcards,
  fetchFlashcards,
} from "../actions";
import {
  useFlashcardsClipboardCut,
  useFlashcardsClipboardCopied,
  useCloneMultiFcLoading,
} from "../selectors";
import FlashcardsAction from "./FlashcardsAction";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ThemeColors } from "../../../lib/Colors";
import { Toast, ToastBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const grid = 8;
const notify = (message) =>
  toast.info(message, {
    position: "bottom-left",
    autoClose: 1750,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
const sideBarContainerRef = createRef();
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,
  // change background colour if dragging
  background: isDragging ? "white" : ThemeColors.themePrimary,
  color: isDragging ? "black" : "white",
  // styles we need to apply on draggables
  cursor: "pointer",
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, draggableListStyle) => ({
  background: ThemeColors.themePrimary,
  // padding: grid,
  // width: 250,
  // height:20,
  ...draggableListStyle,
});
let saveSortTimer = null;
const FlashcardListSideBar = ({loadingFlag, setLoadingData, ...props }) => {
  const handleScroll = (id,behavior) => {
    // console.log("REFFSS HANDLE", refs, id);
    if (refs && refs[id] && refs[id].current) {
      refs[id].current.scrollIntoView({
        behavior: behavior || "smooth",
        block: "center",
      });
    } else {
      console.log("cannot scroll");
    }
    return;
  };
  const loadingMultiple = useCloneMultiFcLoading();
  const cutFlashcardIDs = useFlashcardsClipboardCut();
  const copyFlashcardIDs = useFlashcardsClipboardCopied();
  const flashcards = useEditViewFlashcards();
  const error = useFetchingFlashcardsError();
  const loading = useFetchingFlashcardsLoading();
  const indexActive = useFlashcardIndexActive();
  const topicActive = useEditViewTopic();
  const dispatch = useDispatch();
  const creatingNewFC = useCreateFlashcardLoading();
  const creatingNewFCSuccess = useCreateFlashcardSuccess();
  const [localFlashcards, setLocalFlashcards] = useState([]);
  const [selectedFlashcards, setselectedFlashcards] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [refs, setRefs] = useState(null);
  const successSubtitles = useUpdateMultiSubtitleSuccess();
  const loadingSingleClone = useFlashcardCloneLoading();
  const SingleSuccessClone = useFlashcardcloneSingleSuccess();
  const [SingleSuccessCloneState, setSingleSuccessCloneState] = useState(false);

  useEffect(() => {
    if (!loading && !loadingSingleClone) {
      if (localFlashcards != null && localFlashcards.length && indexActive>=0) {
        if (localFlashcards[indexActive] && localFlashcards[indexActive].id) {
          console.log(
            "localFlashcards[indexActive].id",
            localFlashcards[indexActive].id
          );
          handleScroll(localFlashcards[indexActive].id);
        }
      }
    }
  }, [
    SingleSuccessClone,
    localFlashcards,
    indexActive,
    refs,
    loadingSingleClone,
    loading,
  ]);
  useEffect(() => {
    setLoadingData(
      flashcards != localFlashcards ||
        loading ||
        loadingSingleClone ||
        creatingNewFC ||
        !refs ||
        loadingMultiple
    );
  }, [
    flashcards,
    localFlashcards,
    loading,
    loadingSingleClone,
    creatingNewFC,
    refs,
    loadingMultiple,
  ]);
  useEffect(() => {
    setLoadingScreen(loadingSingleClone);
  }, [loadingSingleClone]);
  useEffect(() => {
    setselectedFlashcards([]);
    setLoadingScreen(true);
    // console.log("Local", localFlashcards);
    // console.log("flashcards", flashcards);
    if (flashcards != null && flashcards.length > 0) {
      setLocalFlashcards(flashcards);
      // console.log("REFS", refs);
      setLoadingScreen(false);
    }
    // console.log("Local", localFlashcards);
    
  }, [flashcards, successSubtitles]);
  // useEffect(() => {
  //   if(topicActive)
  //     dispatch(fetchFlashcards(topicActive.id));
  // },[topicActive, successSubtitles, creatingNewFCSuccess]);
  // useEffect(() => {
  //   if (localFlashcards) dispatch(reoderFlashcards(localFlashcards));
  // }, [localFlashcards]);
  useEffect(() => {
    setselectedFlashcards([]);
    if (localFlashcards != null && localFlashcards.length > 0) {
      let currentFlashcardId = localFlashcards[indexActive]?.id;
      if (indexActive + 1 == localFlashcards.length) {
        currentFlashcardId = localFlashcards[indexActive]?.id;
      } else currentFlashcardId = localFlashcards[indexActive]?.id;
      // console.log("currentFlashcardId", currentFlashcardId);
      handleScroll(currentFlashcardId);
    }
  }, [indexActive]);
  useEffect(() => {
    if (flashcards != null && flashcards.length > 0) {
      setLocalFlashcards(flashcards);
      // console.log("REFS", refs);
    }
    setselectedFlashcards([]);
    if (localFlashcards != null && localFlashcards.length > 0) {
      let lrefs = localFlashcards.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
      }, {});
      // console.log("REFS", refs);
      setRefs(lrefs);
      if (creatingNewFCSuccess || SingleSuccessClone) {
        sideBarContainerRef.current.scrollIntoView();
      }
    }
  }, [creatingNewFCSuccess, localFlashcards, flashcards]);
  const createFlashcardAction = () => {
    //console.log("create flashcard");
    let newFlashcardBody = {
      topic: topicActive.id,
      order: localFlashcards.length + 1,
    };
    if (topicActive) {
      dispatch(createBrandNewFlashcard(newFlashcardBody));
    }
  };
  const setIndexIn = (indexN) => {
    dispatch(setFlashcardIndexActive(indexN));
  };
  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      localFlashcards,
      result.source.index,
      result.destination.index
    );
    // console.log("saveSortTimer", saveSortTimer);
    if (saveSortTimer != null) {
      clearTimeout(saveSortTimer);
      saveSortTimer = null;
    }
    saveSortTimer = setTimeout(() => {
      dispatch(reoderFlashcards(items));
      console.log("Save sort!!!", saveSortTimer);
      clearTimeout(saveSortTimer);
      saveSortTimer = null;
    }, 1300);
    setLocalFlashcards(items);
    dispatch(reoderFlashcards(items));
    setIndexIn(result.destination.index);
  }
  const renderItems = () => {
    if (
      loadingScreen ||
      loading ||
      creatingNewFC ||
      loadingSingleClone ||
      loadingMultiple
    )
      return (
        <div className="d-flex justify-content-center">
          <ReactLoading
            type={"spin"}
            color={"white"}
            height={"20%"}
            width={"20%"}
          />
        </div>
      );
    if (flashcards)
      return localFlashcards.map((fc, index) => {
        return (
          <li
            style={{ cursor: "pointer" }}
            onClick={() => setIndexIn(index)}
            key={index}
            className={"nav-item " + (indexActive == index && "active")}
          >
            <a>
              <i
                style={{ zIndex: 999 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onCheckFlashcard(fc.id);
                }}
                className={
                  selectedFlashcards.includes(fc.id)
                    ? "fa fa-check-square"
                    : "fa fa-square"
                }
              />
              <p>{index + 1}.- </p>
              <p
                style={{
                  whiteSpace: "nowrap",
                  margin: 0,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: 120,
                }}
              >
                {fc.name || `Flashcard #${index + 1}` || "..."}
              </p>
            </a>
          </li>
        );
      });
  };
  const onCheckFlashcard = (selected) => {
    const index = selectedFlashcards.indexOf(selected);
    if (index < 0) {
      selectedFlashcards.push(selected);
    } else {
      selectedFlashcards.splice(index, 1);
    }
    setselectedFlashcards([...selectedFlashcards]);
    // console.log("ckeck:", selected, selectedFlashcards);
  };
  const onCheckAllFlashcard = () => {
    let allIds = [];
    if (selectedFlashcards.length == localFlashcards.length) {
      setselectedFlashcards([]);
      return;
    }
    allIds = localFlashcards.map((fc) => fc.id);
    setselectedFlashcards(allIds);
  };
  return (
    <DragDropContext
      onDragUpdate={(e) => {
        // console.log("update", e);
      }}
      onDragEnd={onDragEnd}
    >
      <ToastContainer />

      <div className="sidebar sidebar-style-2" data-background-color="dark2">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="nav nav-primary"
                >
                  <li
                    className="nav-item submenu sticky-top"
                    style={{
                      background: ThemeColors.themePrimary,
                      paddingBottom: 0,
                      borderBottom: "1px solid #727275",
                    }}
                  >
                    <a
                      data-toggle="collapse"
                      href="#forms"
                      className=""
                      aria-expanded="true"
                    >
                      <p>Acciones</p>
                      <span className="caret" />
                    </a>
                    <div
                      className="collapse show "
                      id="forms"
                      style={{ paddingBottom: 0 }}
                    >
                      <ul className="nav nav-collapse">
                        <li
                          style={{ cursor: "pointer" }}
                          onClick={createFlashcardAction}
                          className={"sub-item"}
                        >
                          <a>
                            <i className="fa fa-plus" />
                            <p>Crear flashcard</p>
                          </a>
                        </li>
                        <li
                          style={{
                            marginBottom: 15,
                          }}
                          className={"sub-item"}
                        >
                          <a>
                            <i
                              style={{ zIndex: 999, cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                onCheckAllFlashcard();
                              }}
                              className={
                                selectedFlashcards.length ==
                                localFlashcards.length
                                  ? "fa fa-check-square"
                                  : "fa fa-square"
                              }
                            ></i>
                            <p className={"text-center"}>
                              {selectedFlashcards.length || ""}
                            </p>
                            <div style={{ justifyContent: "end" }}>
                              {
                                <FlashcardsAction
                                  notifyAction={(notifyMessage) => {
                                    notify(notifyMessage);
                                  }}
                                  enable={
                                    selectedFlashcards?.length ||
                                    copyFlashcardIDs?.length ||
                                    cutFlashcardIDs?.length
                                  }
                                  selectedFlashcardsIds={selectedFlashcards}
                                  setSelectedFlashcards={(
                                    flashcardsIDs = []
                                  ) => {
                                    setselectedFlashcards(flashcardsIDs);
                                  }}
                                />
                              }
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-section">
                    <span className="sidebar-mini-icon">
                      <i className="fa fa-ellipsis-h" />
                    </span>
                    <h4 className="text-section">Flashcards</h4>
                  </li>

                  {flashcards != localFlashcards ||
                  loading ||
                  loadingSingleClone ||
                  creatingNewFC ||
                  !refs ||
                  loadingMultiple ? (
                    <ReactLoading
                      type={"spin"}
                      color={"white"}
                      height={"20%"}
                      width={"20%"}
                    />
                  ) : (
                    localFlashcards.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item?.id?.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className={
                              "nav-item " + (indexActive == index && "active")
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setIndexIn(index);
                            }}
                          >
                            <a ref={refs[item.id]}>
                              <i
                                style={{ zIndex: 999 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onCheckFlashcard(item.id);
                                }}
                                className={
                                  selectedFlashcards.includes(item.id)
                                    ? "fa fa-check-square"
                                    : "fa fa-square"
                                }
                              />
                              <p>{index + 1}.- </p>
                              <p
                                style={{
                                  whiteSpace: "nowrap",
                                  margin: 0,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: 120,
                                }}
                              >
                                {item.name ||
                                  `Flashcard #${index + 1}` ||
                                  "..."}
                              </p>
                            </a>
                          </li>
                        )}
                      </Draggable>
                    ))
                  )}
                  <li ref={sideBarContainerRef} className="nav-section">
                    <span className="sidebar-mini-icon"></span>
                  </li>
                </ul>
              )}
            </Droppable>
          </div>
          <ToastContainer
            position="bottom-left"
            autoClose={1750}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
        </div>
      </div>
    </DragDropContext>
  );
};
// Hook
function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
export default FlashcardListSideBar;
