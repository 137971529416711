import React from "react";
import Skeleton from "react-loading-skeleton";

export const SubjectSkeleton = () => {
  return (
    <div className="">
      <Skeleton height={80} count={5} style={{ marginTop: 50 }} />
    </div>
  );
};
