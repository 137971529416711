import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { ThemeColors } from "../../../lib/Colors";
import AdminLayout from "../../../layouts/AdminLayout";
import { cmsAPI } from "../../../utils/http-client";
import ScalesTabActions from "./components/CategoriesTabActions";
import CreateCategoryForm from "./components/CreateCategoryForm";
import { useDispatch, useSelector } from "react-redux";
import {
  ADMIN_CATEGORY_ACTION_TYPE,
  deleteSubcategoriesAction,
  getSubcategories,
} from "./actions";
import EditScaleModal from "./components/EditCategoryModal";
import { Button } from "reactstrap";
import DeleteScaleAlert from "./components/DeleteCategoryAlert";
const AdminCategories = () => {
  const dispatch = useDispatch();
  const [editCategoryModalisOpen, setEditCategoryModalisOpen] = useState(false);
  const [deleteScaleModalisOpen, setDeleteScaleModalisOpen] = useState(false);
  const [scaleToEditData, setScaleToEditData] = useState(null);
  const [scaleToDeleteData, setScaleToDeleteData] = useState(null);
  const fetchingScales = useSelector(
    ({ adminCategories }) => adminCategories.loading
  );
  const categories = useSelector(
    ({ adminCategories }) => adminCategories.categories
  );
  const [loading, setLoading] = useState(fetchingScales);
  const successCreatingScale = useSelector(
    ({ adminCategories }) => adminCategories.createCategory.success
  );
  const successEditingScale = useSelector(
    ({ adminCategories }) => adminCategories.editCategory.success
  );
  const successDeletingScale = useSelector(
    ({ adminCategories }) => adminCategories.deleteCategory.success
  );
  const loadingDeletingScale = useSelector(
    ({ adminCategories }) => adminCategories.deleteCategory.error
  );
  useEffect(() => {
    if (successDeletingScale) {
      setTimeout(() => {
        dispatch({
          type: ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_CLEAR,
        });
        setDeleteScaleModalisOpen(false);
        setScaleToDeleteData(null);
      }, 1000);
    }
    fetchSubcategories();
  }, [successCreatingScale, successEditingScale, successDeletingScale]);
  useEffect(() => {
    setLoading(fetchingScales);
  }, [fetchingScales]);
  const fetchSubcategories = () => {
    dispatch(getSubcategories());
  };
  const toggleEditScaleModal = ({ scale = null }) => {
    setScaleToEditData(scale);
    setEditCategoryModalisOpen(!editCategoryModalisOpen);
  };
  const toggleDeleteScaleModal = ({ scale = null }) => {
    setScaleToDeleteData(scale);
    setDeleteScaleModalisOpen(!deleteScaleModalisOpen);
  };
  const deleteScale = () => {
    if (scaleToDeleteData != null && scaleToDeleteData?.id != null)
      dispatch(deleteSubcategoriesAction({ userscaleId: scaleToDeleteData.id }));
  };
  const ScaleItem = ({ scale, index }) => {
    return (
      <tr>
        <td>{index + 1}</td>
        <td>{scale.id}</td>
        <td>{scale.name}</td>
        <td>{scale.slug}</td>
        <td>{scale.disabled}</td>
        <td>
          <div className="row">
            <button
              onClick={() => toggleEditScaleModal({ scale })}
              type="button"
              className="btn btn-icon btn-round ml-2 btn-info"
            >
              <i className="fa fa-edit" />
            </button>
            <button
              onClick={() => toggleDeleteScaleModal({ scale })}
              type="button"
              className="btn btn-icon btn-round ml-2 btn-danger"
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </td>
      </tr>
    );
  };
  const ScalesTable = () => {
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col">Categoria</th>
            <th scope="col">Slug</th>
            <th scope="col">Activo</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {categories != null &&
            categories.length > 0 &&
            categories.map((scale, index) => {
              return <ScaleItem scale={scale} index={index} key={index} />;
            })}
        </tbody>
      </table>
    );
  };
  return (
    <AdminLayout>
      <div>
        {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={40}
              width={40}
            />
          </div>
        )}
        <div>
          <ScalesTabActions
            firstTab={<ScalesTable />}
            secondTab={<CreateCategoryForm />}
          />
        </div>
        <EditScaleModal
          modalTitle={"Editando: " + scaleToEditData?.name || ""}
          isOpen={editCategoryModalisOpen}
          toggle={toggleEditScaleModal}
        >
          <div className="row justify-center">
            <CreateCategoryForm editMode={true} editData={scaleToEditData} />
            <div className="col text-center">
              <Button
                color="secondary"
                style={{ alignSelf: "center" }}
                onClick={toggleEditScaleModal}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </EditScaleModal>
        <EditScaleModal
          modalTitle={"Eliminar grado"}
          isOpen={deleteScaleModalisOpen}
          toggle={toggleDeleteScaleModal}
          footer={
            <>
              <Button
                color="primary"
                style={{ alignSelf: "center" }}
                onClick={toggleDeleteScaleModal}
              >
                Cancelar
              </Button>
              <Button
                disabled={loadingDeletingScale}
                color="danger"
                style={{ alignSelf: "center" }}
                onClick={() => {
                  deleteScale();
                }}
              >
                Eliminar
              </Button>
            </>
          }
        >
          <DeleteScaleAlert></DeleteScaleAlert>
          <div className="row justify-center p-3">
            <h3 className="text-center">
              {"¿Seguro que quieres eliminar el grado de "}
              <strong>{scaleToDeleteData?.name || ""}</strong>
              {"?"}
            </h3>
          </div>
        </EditScaleModal>
      </div>
    </AdminLayout>
  );
};

export default AdminCategories;
