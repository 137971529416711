import { useSelector } from 'react-redux'

export function useGettingSubjects () {
  return useSelector(({ subject }) => subject && subject.loading)
}
export function useSubjects () {
  return useSelector(({ subject }) => subject && subject.subjects)
}
export function useParentSubject () {
  return useSelector(({ subject }) => subject && subject.parentSubjectActive)
}
export function useCategoryActive () {
  return useSelector(({ subject }) => subject && subject.categoryActive)
}