import { cmsAPI } from "../../utils/http-client";

export const FLASHCARD_ACTIONTYPE = {
  GET_FLASHCARDS_START: "GET_FLASHCARDS_START",
  GET_FLASHCARDS_SUCCESS: "GET_FLASHCARDS_SUCCESS",
  GET_FLASHCARDS_ERROR: "GET_FLASHCARDS_ERROR",

  SET_ACTIVE_FLASHCARD: "SET_ACTIVE_FLASHCARD",
};

export const setActiveFlashcard = (flashcard) => (dispatch) => {
 //console.log("Dispatch");
  dispatch({
    type: FLASHCARD_ACTIONTYPE.SET_ACTIVE_FLASHCARD,
    activeFlashcard: flashcard,
  });
};
