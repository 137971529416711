import { useSelector } from "react-redux";

export function useFetchingProfile() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.loading
  );
}
export function useFetchingPTopicsSuccess() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.requestSuccess
  );
}
export function useFetchingSubjectError() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.requestError
  );
}
export function useSubject() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.subject
  );
}
export function useSPTopics() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.subjectTopics
  );
}
export function useSPTopicsCount() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.subjectTopics != null && subjectProfile.subjectTopics?.length
  );
}
export function useFetchingPTopics() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.loadingST
  );
}
export function useFetchingPTopicsError() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.requestErrorST
  );
}
export function useSubjectTopics() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.subjectTopics
  );
}
//EDIT SUBJECT
export function useSubjectEditLoading() {
  return useSelector(
    ({ subjectProfile }) =>
      subjectProfile && subjectProfile.editSubject?.loading
  );
}
export function useSubjectEditSuccess() {
  return useSelector(
    ({ subjectProfile }) =>
      subjectProfile && subjectProfile.editSubject?.success
  );
}
export function useSubjectEditError() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.editSubject?.error
  );
}

export function useDeleteSTLoading() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.deleteTopicLoading
  );
}
export function useDeleteSTSuccess() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.deleteTopicSuccess
  );
}
export function useDeleteSTError() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.deleteTopicError
  );
}
export function useRemoveSubjectLoading() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.removeLoading
  );
}
export function useRemoveSubjectSuccess() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.removeSuccess
  );
}
export function useRemoveSubjectError() {
  return useSelector(
    ({ subjectProfile }) => subjectProfile && subjectProfile.removeError
  );
}
