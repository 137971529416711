import React, { Component } from "react";
const FlashcardContent = (props) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.content || "",
      }}
    ></div>
  );
};
export default FlashcardContent;