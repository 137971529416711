import React from "react";
const AppFooter = (props) => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <nav className="pull-left">
          <ul className="nav">
            <li className="nav-item">
              <a className="nav-link" href="#">
                Sporte
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Terminos y Condiciones
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Contacto
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright ml-auto">
          Superbmind ® All Rights Reserved ® 2020
        </div>
      </div>
    </footer>
  );
};
export default AppFooter;
