import React, { Component } from "react";
import cx from "classnames";
import * as ForgotPasswordActions from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AlertCard from "../../../components/AlertCard";
import { withCookies } from "react-cookie";
import { Link, withRouter } from "react-router-dom";

class SendEmailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidEmail: false,
      email: "",
      alertOpen: true,
      alertType: "danger",
    };
  }
  componentDidUpdate = (prevProps) => {
    if (!prevProps.sendEmailSuccess && this.props.sendEmailSuccess) {
      this.setState({ alertOpen: true, alertType: "success" });
    }
    if (!prevProps.sendEmailError && this.props.sendEmailError == true) {
      this.setState({ alertOpen: true, alertType: "danger" });
    }
  };
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email == "") {
      this.setState({
        invalidEmail: true,
      });
      return;
    }
    let form = { email };
   //console.log("Form", form);
    //Send Email to reset password
    this.props.sendResetEmail(form);
  };
  toggleAlert = () => {
    this.setState({ alertOpen: !this.state.alertOpen });
  };
  render() {
    let emailStyle = cx({
      "form-control": true,
      "is-invalid": this.state.invalidEmail,
    });
    let { sendEmailSuccess, sendEmailError, loading } = this.props;
    return (
      <div className="row">
        <div className="col-md-9 col-lg-8 mx-auto">
          {sendEmailError || sendEmailSuccess ? (
            <AlertCard
              isOpen={this.state.alertOpen}
              toggle={() => this.toggleAlert}
              color={sendEmailSuccess ? "success" : "danger"}
              message={sendEmailError ? sendEmailError : "Se ha enviado el email"}
            />
          ) : (
            ""
          )}
          <div className="text-center mb-4">
            <h3 className="login-heading">Recuperar contraseña</h3>
            <p>Ingresa el email de tu cuenta, te enviaremos información para restablecer tu contraseña</p>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="form-label-group">
              <input
                value={this.state.email}
                name="email"
                type="email"
                onChange={this.handleChange}
                id="inputEmail"
                className={emailStyle}
                placeholder="Email"
                required
                autoFocus
              />
              <label htmlFor="inputEmail">Email</label>
            </div>
            <div className="d-flex justify-content-center">
              <button
                disabled={loading ? true : false}
                className="btn btn-primary"
                type="submit"
              >
                Enviar Email
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    sendEmailSuccess: state.forgotPassword.success,
    sendEmailErrorID: state.forgotPassword.errorID,
    sendEmailError: state.forgotPassword.error,
    clearError: state.forgotPassword.clearError,
    loading: state.forgotPassword.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendResetEmail: bindActionCreators(ForgotPasswordActions.forgot, dispatch),
    clear: bindActionCreators(ForgotPasswordActions.clearError, dispatch),
  };
};
export default withCookies(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SendEmailForm))
);
