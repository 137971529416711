import React, { Component } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import { cmsAPI } from "../../utils/http-client";
import TopicDetailsHeader from "./containers/TopicDetailsHeader";
import FlashcardsList from "./containers/FlashcardsList";
import TopicDetails from "./components/TopicDetails";
import reducer from "./reducer";
import { setActiveFlashcard } from "./actions";
class TopicDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectId: "",
      topicId: "",
      subject: {},
      loadingSubject: true,
      topic: {},
      loadingTopic: true,
      loadingFlashcards: true,
      flashcards: [],
    };
  }
  componentDidMount() {
   //console.log("Props", this.props);
    if (this.props.match.params && this.props.match.params.subjectId) {
      cmsAPI
        .get(`/subjects/${this.props.match.params.subjectId}`)
        .then((res) => {
         //console.log("Subject:", res);
          this.setState(
            {
              subject: res.data,
            },
            () => {}
          );
        })
        .catch((err) => {
         //console.log("Subject Err:", err);
          return;
        });
    }
    if (this.props.match.params && this.props.match.params.topicId) {
      cmsAPI
        .get(`/topics/${this.props.match.params.topicId}`)
        .then((res) => {
         //console.log("Topic:", res);
          this.setState(
            {
              topic: res.data,
            },
            () => {}
          );
          cmsAPI
            .get(`/flashcards?topic=${res.data.id}`)
            .then((res) => {
             //console.log("Flashcards:", res);
              this.setState(
                {
                  loadingFlashcards: false,
                  flashcards: res.data,
                },
                () => {
                 //console.log("Flashcards State", this.state.flashcards);
                }
              );
            })
            .catch((err) => {
              this.setState({
                loadingFlashcards: false,
              });
            });
        })
        .catch((err) => {
         //console.log("Topic Err:", err);
          return;
        });
    }
  }
  render() {
    return (
      <AuthenticateLayout>
        <div>
          <TopicDetailsHeader
            subject={this.state.subject}
            topic={this.state.topic}
          />
          <TopicDetails {...this.props} />
        </div>
      </AuthenticateLayout>
    );
  }
}

export default TopicDetailsView;
export { reducer };
