import { cmsAPI } from "../../utils/http-client";
import { setUser, clearUser } from "../../lib/localstorage";

export const RESET_PASSWORD_ACTION_TYPE = {
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_END: "RESET_PASSWORD_END",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
};
function resetPasswordSuccess(data) {
  return {
    type: RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_SUCCESS
  };
}
function resetPasswordError() {
  return {
    type: RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_ERROR,
    error: "Error al enviar email",
  };
}

export const resetPassword = (form) => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_ACTION_TYPE.RESET_PASSWORD_START });
  cmsAPI
    .post("/auth/reset-password", form)
    .then((res) => {
     //console.log("forgot RESPONSE:", res);
      dispatch(resetPasswordSuccess(res.data));
    })
    .catch((err) => {
     //console.log("forgot ERROR:", err);
      dispatch(resetPasswordError(err));
    });
};

export const clearError = () => (dispatch) => {
  dispatch({ type: RESET_PASSWORD_ACTION_TYPE.CLEAR_ERROR });
};
