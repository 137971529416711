import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toggleModal } from "../../../components/CreateSubjectModal/actions";
import { toggleTopicModal } from "../../../components/CreateTopicModal/actions";
import AdminLayout from "../../../layouts/AdminLayout";
const AdminDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <AdminLayout>
      <div>
        <h1>Admin Dashboard</h1>
        <div className="page-category">
          Encuentra variedad de clases en Superbmind®.
        </div>

        <div className="row">
          <div className="col-md-4">
            <div
              onClick={() => {
                dispatch(toggleModal());
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-primary-gradient"
            >
              <div>
                <h2>Crear materia</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                dispatch(toggleTopicModal(true));
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-secondary-gradient"
            >
              <div>
                <h2>Crear pila</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                history.push("/admin/content");
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-info-gradient"
            >
              <div>
                <h2>Ver Contenido</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                history.push("/admin/userscales");
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-info-gradient"
            >
              <div>
                <h2>Escalafon</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                history.push("/admin/subcategory-scales");
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-info-gradient"
            >
              <div>
                <h2>Grados de Subcategorias</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                history.push("/admin/subcategories");
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-info-gradient"
            >
              <div>
                <h2>Subcategorías</h2>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div
              onClick={() => {
                history.push("/admin/categories");
              }}
              style={{
                cursor: "pointer",
                height: 100,
                justifyContent: "center",
                alignItems: "center",
              }}
              className="card card-dark bg-info-gradient"
            >
              <div>
                <h2>Categorías</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminDashboard;
