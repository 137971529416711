import { ADMIN_SUBCATEGORY_ACTION_TYPE } from "./actions";

export const adminSubcategoryInitialState = {
  createSubcategory: {
    success: false,
    error: false,
    loading: false,
    newestCreated: null,
  },
  deleteSubcategory: {
    success: false,
    error: false,
    loading: false,
  },
  editSubcategory: {
    success: false,
    error: false,
    loading: false,
    editedScale: null,
  },
  userscales: [],
  success: false,
  error: false,
  loading: true,
};
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_START:
      return {
        ...state,
        createSubcategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        createSubcategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.CREATE_SUBCATEGORY_ERROR:
      return {
        ...state,
        createSubcategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_START:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        ...payload,
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        ...payload,
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.GET_SUBCATEGORY_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        ...payload,
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_START:
      return {
        ...state,
        editSubcategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        editSubcategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_ERROR:
      return {
        ...state,
        editSubcategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.EDIT_SUBCATEGORY_CLEAR:
      return {
        ...state,
        editSubcategory: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_START:
      return {
        ...state,
        deleteSubcategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        deleteSubcategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_ERROR:
      return {
        ...state,
        deleteSubcategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_SUBCATEGORY_ACTION_TYPE.DELETE_SUBCATEGORY_CLEAR:
      return {
        ...state,
        deleteSubcategory: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
