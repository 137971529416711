import React, { Component } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { cmsAPI } from "../../../utils/http-client";
export const SideBarLink = ({imageStyle,...props}) => {
  const location = useLocation();
  return (
    <li
      className={
        "nav-item " + (props.path === location.pathname ? "active" : "")
      }
    >
      <a href={props.path}>
        {props.withImage ? (
          <div className="avatar-sm float-left mr-2">
            <img
              src={
                props.imageURI
                  ? cmsAPI.resolveAssetAPI(props.imageURI)
                  : "/images/image420.png"
              }
              alt="..."
              className="avatar-img rounded-circle"
              style={{...imageStyle}}
            />
          </div>
        ) : (
          <i className={props.iconClassName + " " + props.className} />
        )}
        <p
          style={{
            width: "50%",
            fontSize: 12,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {props.name || props.path}
        </p>
      </a>
    </li>
  );
};
SideBarLink.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  iconClassName: PropTypes.string,
  className: PropTypes.string,
  withImage: PropTypes.bool,
};
