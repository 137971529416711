import { ACTIONS_CREATE_SUBJECT } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_START:
      return {
        loading: true,
        modalActive: state.modalActive,
        ...payload,
      };
    case ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_SUCCESS:
      return {
        loading: false,
        success: true,
        modalActive: state.modalActive,
        ...payload,
      };
    case ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_ERROR:
      return {
        loading: false,
        error: true,
        modalActive: state.modalActive,
        ...payload,
      };
    case ACTIONS_CREATE_SUBJECT.CLEAR_STATE:
      return {};
    case ACTIONS_CREATE_SUBJECT.TOGGLE_MODAL:
      return {
        modalActive: !state.modalActive || false,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
