import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as UpdateProfileActions from "../actions";
import { connect } from "react-redux";
import { cmsAPI } from "../../../utils/http-client";
class ChangeProfileImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedFile:null
    };
  }
  onChangeHandler = (event) => {
   //console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });
  };
  onClickHandler = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("files", this.state.selectedFile);
   //console.log("Data",data)
    this.props.changeProfilePic(data)
  };
  render() {
    return (
      <div>
      <form className="md-form">
        <div className="file-field">
          <div className="btn btn-primary btn-sm">
            <span>Choose files</span>
            <input onChange={this.onChangeHandler} name="file" type="file"/>
          </div>
        </div>
        <button type="submit" className="btn btn-primary " onClick={this.onClickHandler}>Subir</button>
      </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showAlert: state.profile.showAlert,
    errorID: state.profile.errorID,
    editProfileError: state.profile.error,
    editProfileStatus: state.profile.status,
    editProfileSuccess: state.profile.success,
    loading: state.profile.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeProfilePic: bindActionCreators(
      UpdateProfileActions.changeProfilePic,
      dispatch
    ),
    clearError: bindActionCreators(UpdateProfileActions.clearError, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangeProfileImage);