import React, { useState } from "react";
import { Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router";
import { ThemeColors } from "../../../lib/Colors";

const StudySidebar = () => {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const studyFlashcards = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
  ];
  const renderTab = () => {
    switch (selectedTab) {
      case 0:
        return (
          <div>
            <h4 className="text-section text-center mb-4">
              <ReactTooltip />
              Confianza obtenida
              <div className="progress progress-sm">
                <div
                  className="progress-bar bg-info w-75"
                  role="progressbar"
                  aria-valuenow={75}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
            </h4>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                marginTop: 15,
              }}
            >
              {studyFlashcards.map((fc) => {
                let color =
                  ThemeColors.confidentColors[
                    Math.floor(Math.random() * (6 - 0)) + 0
                  ];
                return (
                  <div
                    style={{
                      alignSelf: "center",
                      borderRadius: 10,
                      backgroundColor: color,
                      height: 40,
                      marginTop: 10,
                      width: "90%",
                    }}
                  ></div>
                );
              })}
            </div>
            <h4 className="text-section text-center">
              <ReactTooltip />
              Tiempo: 00:00
            </h4>
          </div>
        );
      case 1:
        return (
          <div>
            <h1>Resumen</h1>
          </div>
        );
    }
  };
  return (
    <div className="sidebar sidebar-style-2" data-background-color="dark2">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <ul className="nav nav-primary">
            <li className="nav-section">
              <span className="sidebar-mini-icon"></span>
              <h4 className="text-section">
                <ReactTooltip />
                <i
                  onClick={() => {
                    history.goBack();
                  }}
                  data-tip="Ir atrás"
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                  }}
                  className="la flaticon-left-arrow-1"
                />
                Estudiando
              </h4>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  onClick={() => setSelectedTab(0)}
                  color={selectedTab == 0 ? "primary" : "secondary"}
                  size="lg"
                >
                  Esta ronda
                </Button>
                <Button
                  onClick={() => setSelectedTab(1)}
                  color={selectedTab == 1 ? "primary" : "secondary"}
                  size="lg"
                >
                  Resumen
                </Button>
              </div>
              <div>{renderTab()}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StudySidebar;
