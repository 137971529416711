import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UpdateProfileActions from "../actions";
import { Badge } from "reactstrap";
import AlertCard from "../../../components/AlertCard";
import { setUser } from "../../../lib/localstorage";
//import ImageUploader from "react-images-upload";
class ProfileDataForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
      },
      alertOpen: false,
      alertMessage: "Ha ocurrido un error",
      alertType: "danger",
    };
    this.onDrop = this.onDrop.bind(this);
  }
  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles),
    });
  }
  componentDidUpdate = (prevProps) => {
   //console.log("Prev", prevProps);
    if (!prevProps.showAlert && this.props.showAlert) {
      this.setState({ alertOpen: true });
    }
    if (prevProps.showAlert && !this.props.showAlert) {
      this.setState({ alertOpen: false });
    }
    if (!prevProps.editProfileSuccess && this.props.editProfileSuccess) {
      // setUser(
      //   { ...this.props.user, jwt: this.props.jwt, logged: true },
      //   this.props.cookies
      // );
      this.setState({ alertType: "success" });
    }
    if (!prevProps.editProfileError && this.props.editProfileError) {
      this.setState({ alertType: "danger" });
    }
  };
  setAlertMessage = () => {
    if (this.props.errorID) {
      switch (this.props.errorID) {
        case "Auth.form.error.email.taken":
          return "La cuenta de email ya está en uso";
        case "Auth.form.error.username.taken":
          return "Nombre de usuario ya está en uso";
        case "Auth.form.error.ratelimit":
          return "Has hecho muchas consultas, espere...";
        default:
          return;
      }
    }
    if (this.props.editProfileStatus == 200 || this.props.editProfileSuccess) {
      return "Editado con exito";
    } else if (this.state.alertMessage) {
      return this.state.alertMessage;
    }
    this.setState({ alertMessage: "" });
  };
  componentDidMount() {
   //console.log("DidMount", this.props);
    this.setState({ form: { ...this.props.user } });
  }
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
   //console.log(target.file);
   //console.log("Name:", name, "Value:", value);
    this.setState({ form: { [name]: value } });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.state;
   //console.log("Form", form);
    this.props.updateProfile(form);
  };
  toggleAlert = () => {
    this.setState({ alertOpen: false });
    this.props.clearError();
  };
  render() {
    const { form, alertOpen, alertType } = this.state;
    return (
      <div>
        <form className="" onSubmit={this.handleSubmit}>
          <div className="card-body">
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group form-group-default">
                  <label>Nombre(s)</label>
                  <input
                    onChange={this.handleChange}
                    value={form.firstName}
                    type="text"
                    className="form-control"
                    name="firstName"
                    placeholder="Nombre(s)"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group form-group-default">
                  <label>Apellido(s)</label>
                  <input
                    onChange={this.handleChange}
                    value={form.lastName}
                    type="text"
                    className="form-control"
                    name="lastName"
                    placeholder="Apellido(s)"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group form-group-default">
                  <label>Nombre de usuario</label>
                  <input
                    onChange={this.handleChange}
                    value={form.username}
                    type="text"
                    className="form-control"
                    name="username"
                    placeholder="Nombre de usuario"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group form-group-default">
                  <label>Email</label>
                  <input
                    onChange={this.handleChange}
                    value={form.email}
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Name"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
                <div className="form-group form-group-default">
                  <label>Birth Date</label>
                  <input
                    type="text"
                    className="form-control"
                    id="datepicker"
                    name="datepicker"
                    defaultValue="03/21/1998"
                    placeholder="Birth Date"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group form-group-default">
                  <label>Gender</label>
                  <select className="form-control" id="gender">
                    <option>Male</option>
                    <option>Female</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group form-group-default">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={+62008765678}
                    name="phone"
                    placeholder="Phone"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-1">
              <div className="col-md-12">
                <div className="form-group form-group-default">
                  <label>Biografía</label>
                  <textarea
                    className="form-control"
                    name="about"
                    placeholder="About Me"
                    rows={3}
                    defaultValue={"A man who hates loneliness"}
                  />
                </div>
              </div>
            </div>
            <div className="text-right mt-3 mb-3">
              <button className="btn btn-success">Guardar</button>
              <button className="btn btn-danger">Reset</button>
            </div>
          </div>
          {/* <div className="form-group">
            <div className="col-xs-6">
              <AlertCard
                isOpen={alertOpen}
                toggle={this.toggleAlert}
                color={alertType}
                message={this.setAlertMessage()}
              />
              <label htmlFor="username">Nombre de usuario</label>
              <input
                onChange={this.handleChange}
                value={form.username}
                type="text"
                className="form-control"
                name="username"
                id="username"
                placeholder="Nombre de usuario"
                title="Ingresa nombre de usuario."
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-xs-6">
              <label htmlFor="firstName">Nombre(s)</label>
              <input
                onChange={this.handleChange}
                value={form.firstName}
                type="text"
                className="form-control"
                name="firstName"
                id="firstName"
                placeholder="Nombre(s)"
                title="Ingresa nombre(s)."
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-xs-6">
              <label htmlFor="lastName">Apellido(s)</label>
              <input
                onChange={this.handleChange}
                value={form.lastName}
                type="text"
                className="form-control"
                name="lastName"
                id="lastName"
                placeholder="Apellido(s)"
                title="Ingresa apellido(s)."
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-xs-6">
              <label htmlFor="email">Email</label>
              <input
                onChange={this.handleChange}
                value={form.email}
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="tu@email.com"
                title="Ingresa email."
              />
            </div>
          </div>
          {this.props.user.confirmed ? "Email Confirmado" : "Confirma tu email"}
          <div className="form-group">
            <div className="col-xs-12">
              <br />
              <button className="shadow btn btn-lg btn-primary" type="submit">
                Editar
              </button>
            </div>
          </div> */}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showAlert: state.profile.showAlert,
    errorID: state.profile.errorID,
    editProfileError: state.profile.error,
    editProfileStatus: state.profile.status,
    editProfileSuccess: state.profile.success,
    loading: state.profile.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProfile: bindActionCreators(
      UpdateProfileActions.updateProfile,
      dispatch
    ),
    clearError: bindActionCreators(UpdateProfileActions.clearError, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileDataForm);
