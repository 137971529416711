import React, { Component } from 'react';
import PublicLayout from '../../layouts/PublicLayout';
class AppPromoView extends Component {
    state = {  }
    render() { 
        return ( 
            <PublicLayout>
                <h1>App Movil</h1>
            </PublicLayout>
        );
    }
}
 
export default AppPromoView;