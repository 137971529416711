import React from "react";
// import { useUserIsAdmin } from "../redux/selectors";
// const isAdminUser = useUserIsAdmin();
export const isAdmin = (userRole) => {
  // console.log("UserRole", userRole, isAdminUser);
  if (!userRole) return false;
  if (userRole === "admin" || userRole === "occ" || userRole === "superbmind")
    return true;
  else return false;
};
