import { cmsAPI } from "../../utils/http-client";
import { pushNewTopic } from "../../views/subjectProfile/actions";

export const ACTIONS_CREATE_TOPIC = {
  CREATE_TOPIC_START: "CREATE_TOPIC_START",
  CREATE_TOPIC_SUCCESS: "CREATE_TOPIC_SUCCESS",
  CREATE_TOPIC_ERROR: "CREATE_TOPIC_ERROR",
  CREATE_TOPIC_END: "CREATE_TOPIC_END",

  TOGGLE_TOPIC_MODAL: "TOGGLE_TOPIC_MODAL",
  CLEAR_STATE: "CLEAR_STATE",
};

export const createTopic = (topic, files) => (dispatch) => {
  console.log("TOPIC", topic, "FILES", files);
  dispatch({ type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_START });
  if (!topic || !topic.name) {
    dispatch({
      type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_ERROR,
      errorMessage: "Llena los campos requeridos",
    });
    return;
  }
  if (files) {
    var formData = new FormData();
    formData.append("data", JSON.stringify(topic));
    formData.append("files.flashcardsFile", files);
    console.log("Create topic:", topic);
    cmsAPI
      .post("topics/file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        dispatch(pushNewTopic(data));
        dispatch({
          type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_SUCCESS,
          topic: data,
        });
        console.log("Success Creating Topic");
      })
      .catch((err) => {
        dispatch({ type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_ERROR });
        console.log("Error Creating Topic", err);
      });
  } else {
    cmsAPI
      .post("topics", topic)
      .then(({ data }) => {
        dispatch(pushNewTopic(data));
        dispatch({
          type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_SUCCESS,
          topic: data,
        });
        console.log("Success NPM Creating Topic");
      })
      .catch((err) => {
        dispatch({ type: ACTIONS_CREATE_TOPIC.CREATE_TOPIC_ERROR });
        console.log("Error NPM Creating Topic", err);
      });
  }
};
export const clearState = () => (dispatch) => {
  dispatch({ type: ACTIONS_CREATE_TOPIC.CLEAR_STATE });
};
export const toggleTopicModal = (showImport) => (dispatch) => {
  dispatch({ type: ACTIONS_CREATE_TOPIC.TOGGLE_TOPIC_MODAL, showImport });
};
