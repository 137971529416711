import { cmsAPI } from "../../utils/http-client";

export const ACTIONS_CATEGORIES_DD = {
  FETCH_CATEGORIES_START: "FETCH_CATEGORIES_START",
  FETCH_CATEGORIES_SUCCESS: "FETCH_CATEGORIES_SUCCESS",
  FETCH_CATEGORIES_ERROR: "FETCH_CATEGORIES_ERROR",
};

export const fetchCategoriesDD = () => (dispatch) => {
 //console.log("Fetch categories");
  dispatch({ type: ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_START });
  cmsAPI
    .get("categories")
    .then(({ data }) => {
      dispatch({
        type: ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_SUCCESS,
        categories: data,
      });
    })
    .catch((err) => {
      dispatch({ type: ACTIONS_CATEGORIES_DD.FETCH_CATEGORIES_SUCCESS });
    });
};
