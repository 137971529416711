import React, { useEffect, useState } from "react";
import { FaWindowClose } from "react-icons/fa";
import { SRLWrapper } from "simple-react-lightbox";
import { ThemeColors } from "../../lib/Colors";
import { cmsAPI } from "../../utils/http-client";

const ImagePicker = ({
  id,
  name,
  setData,
  data,
  inputProps,
  onKeyDown = () => {},
  ...props
}) => {
  const [imageURI, setImageURI] = useState("");
  const [blur, setBlur] = useState(false);
  useEffect(() => {
    if (data && data.url) {
      console.log("url", data.url);
      setImageURI(cmsAPI.resolveAssetAPI(data.url));
    } else if (data && data.imgURL) {
      console.log("imgURL", data.imgURL);
      setImageURI(data.imgURL);
    } else setImageURI("");
  }, [data]);
  return (
    <>
      <div className="input-group mb-3 px-2 py-2 rounded-pill bg-white shadow-sm">
        <input
          tabIndex={props.tabIndex}
          // enterKeyHint={()=>{
          //   console.log("KEYHINT");
          // }}
          onKeyDown={onKeyDown}
          autoFocus={false}
          {...inputProps}
          id={id}
          name={name}
          type="file"
          onChange={(e) => {
            let targetValue = e.target.files[0];
            if (targetValue) {
              targetValue.imgURL = URL.createObjectURL(e.target.files[0]);
              setImageURI(URL.createObjectURL(e.target.files[0]));
            }
            setData(targetValue,e.target.files[0]);
          }}
          className="form-control border-0 upload"
        />
        <label
          id="upload-label"
          htmlFor={id}
          className="font-weight-light text-muted upload-label"
        >
          Selecciona una imagen
        </label>
        <div className="input-group-append">
          <label htmlFor={id} className="btn btn-light m-0 rounded-pill px-4">
            <i className="fas fa-cloud-upload-alt mr-2 text-muted" />
            <small className="text-uppercase font-weight-bold text-muted">
              Subir imagen
            </small>
          </label>
        </div>
      </div>
      {/* Uploaded image area*/}
      {imageURI && (
        <div className="image-area mt-4">
          <FaWindowClose
            className="close-icon"
            onClick={() => {
              setData([]);
              setImageURI("");
            }}
            color={ThemeColors.danger}
          />
          <SRLWrapper>
            <img
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
              }}
              id="imageResult"
              src={imageURI}
              alt=""
              className="img-fluid rounded shadow-sm mx-auto d-block"
            />
          </SRLWrapper>
        </div>
      )}
    </>
  );
};

export default ImagePicker;
