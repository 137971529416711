import { cmsAPI } from "../../utils/http-client";
import { pushNewSubject } from "../../views/mysubjects/actions";

export const ACTIONS_CREATE_SUBJECT = {
  CREATE_SUBJECT_START: "CREATE_SUBJECT_START",
  CREATE_SUBJECT_SUCCESS: "CREATE_SUBJECT_SUCCESS",
  CREATE_SUBJECT_ERROR: "CREATE_SUBJECT_ERROR",
  CREATE_SUBJECT_END: "CREATE_SUBJECT_END",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  CLEAR_STATE: "CLEAR_STATE",
};

export const createSubject = (subject) => (dispatch) => {
  dispatch({ type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_START });
  if (!subject || !subject.name) {
    dispatch({
      type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_ERROR,
      errorMessage: "Llena los campos requeridos",
    });
    return;
  }
  if (subject.image != null) {
    let formData = new FormData();
    let image = subject.image;
    delete subject.image;
    formData.append("data", JSON.stringify(subject));
    formData.append("files.image", image);
    cmsAPI
      .post("subjects", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        dispatch({
          type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_SUCCESS,
          subject: data,
        });
        dispatch(pushNewSubject(data));
      })
      .catch((err) => {
        dispatch({ type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_ERROR });
      });
  } else {
    cmsAPI
      .post("subjects", subject)
      .then(({ data }) => {
        dispatch({
          type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_SUCCESS,
          subject: data,
        });
        dispatch(pushNewSubject(data));
      })
      .catch((err) => {
        dispatch({ type: ACTIONS_CREATE_SUBJECT.CREATE_SUBJECT_ERROR });
      });
  }
};
export const toggleModal = () => (dispatch) => {
  dispatch({ type: ACTIONS_CREATE_SUBJECT.TOGGLE_MODAL });
};
export const clearState = () => (dispatch) => {
  dispatch({ type: ACTIONS_CREATE_SUBJECT.CLEAR_STATE });
};
