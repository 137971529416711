import { useSelector } from "react-redux";

export const useCategoryActive = () => {
  return useSelector(
    ({ categoryView }) => categoryView && categoryView.category
  );
};
export const useFetchingCategory = () => {
  return useSelector(
    ({ categoryView }) => categoryView && categoryView.loading
  );
};
export const useFetchingCategorySuccess = () => {
  return useSelector(
    ({ categoryView }) => categoryView && categoryView.success
  );
};
export const useFetchingCategoryError = () => {
  return useSelector(({ categoryView }) => categoryView && categoryView.error);
};
