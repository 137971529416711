import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AppFooter from "../../components/Footer";
import NavBar from "../../components/NavBar";
import { getCategoryData } from "./actions";
import CategoryHeaderInfo from "./components/CategoryHeader";
import CategorySubNav from "./components/CategorySubNav";
import InfoCards from "./containers/infoCards";
const CategoryView = (props) => {
  const dispatch = useDispatch();
  const [activeSlug, setActiveSlug] = useState(null);
  useEffect(() => {
    let { categorySlug } = props.match.params;
    setActiveSlug(categorySlug);
    dispatch(getCategoryData(categorySlug));
  }, [props.match.params.categorySlug]);
  return (
    <div className="">
      <NavBar subNavBar={<CategorySubNav />} />
      <CategoryHeaderInfo />
      <InfoCards />
      <AppFooter />
    </div>
  );
};

export default CategoryView;
