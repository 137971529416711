import React from "react";
import { Button } from "reactstrap";

export const ShareSubjectButton = ({ onClick, ...props }) => {
  return (
    <Button onClick={onClick} color="link">
      <i style={{ fontSize: "1.5em" }} className="fas fa-share-alt" />
    </Button>
  );
};
