import React from "react";
const TopicDetailsHeader = (props) => {
  const { topic, subject } = props;
  return (
    <div className="page-header">
      <h4 className="page-title">Detalles de {topic && topic.name}</h4>
      <ul className="breadcrumbs">
        <li className="nav-home">
          <a href="/dashboard">
            <i className="fa fa-home" />
          </a>
        </li>
        <li className="separator">
          <i className="fa fa-chevron-right" />
        </li>
        {subject && (
          <li className="nav-item">
            <a href={`/subjects/${subject && subject.id}`}>
              {subject.name}
            </a>
          </li>
        )}
        <li className="separator">
          <i className="fa fa-chevron-right" />
        </li>
        <li className="nav-item">
          <a href="">{topic && topic.name}</a>
        </li>
      </ul>
    </div>
  );
};
export default TopicDetailsHeader;
