import { ACTIONS_MYSUBJECTS } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_START:
      return {
        subjects: state.subjects || payload.subjects || [],
        loading: true,
        success: null,
        error: null,
        errorMessage: "",
        ...payload,
      };
    case ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_SUCCESS:
      return {
        subjects: state.subjects || payload.subjects || [],
        loading: false,
        success: true,
        ...payload,
      };
    case ACTIONS_MYSUBJECTS.GET_MYSUBJECTS_ERROR:
      return {
        subjects: state.subjects || payload.subjects || [],
        loading: false,
        error: true,
        errorMessage: "",
        ...payload,
      };
    case ACTIONS_MYSUBJECTS.PUSH_MYSUBJECTS:
      return {
        ...state,
        subjects: [...state.subjects, payload.newSubject],
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
