import * as React from "react";
import { useDispatch } from "react-redux";
import { getSubjectsAction } from "../actions";
import CategoriesList from "./CategoriesList";
const CategoriesSideBar = (props) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        className="page-aside bg-light"
        style={{ overflowY: "scroll", height: "100" }}
      >
        <div className="aside-header">
          <div className="title">Categorias</div>
          <div className="description">Encuentra el material que buscas</div>
          <a
            className="btn btn-primary toggle-email-nav"
            data-toggle="collapse"
            href="#email-app-nav"
            role="button"
            aria-expanded="false"
            aria-controls="email-nav"
          >
            <span className="btn-label">
              <i className="icon-menu" />
            </span>
          </a>
        </div>
        <div className="aside-nav collapse" id="email-app-nav">
          <div className="aside-compose">
            <a
              href="#"
              className="btn btn-primary btn-block fw-mediumbold"
              onClick={() => dispatch(getSubjectsAction())}
            >
              Ver Todo
            </a>
          </div>
          <ul className="nav">
            <CategoriesList />
          </ul>
        </div>
      </div>
    </>
  );
};
export default CategoriesSideBar;
