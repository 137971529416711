import { cmsAPI } from "../../utils/http-client";
import { setUser, clearUser } from "../../lib/localstorage";

export const PROFILE_ACTION_TYPE = {
  EDIT_PROFILE_START: "EDIT_PROFILE_START",
  EDIT_PROFILE_END: "EDIT_PROFILE_END",
  EDIT_PROFILE_SUCCESS: "EDIT_PROFILE_SUCCESS",
  EDIT_PROFILE_ERROR: "EDIT_PROFILE_ERROR",

  PROFILE_PICTURE_START: "PROFILE_PICTURE_START",
  PROFILE_PICTURE_END: "PROFILE_PICTURE_END",
  PROFILE_PICTURE_SUCCESS: "PROFILE_PICTURE_SUCCESS",
  PROFILE_PICTURE_ERROR: "PROFILE_PICTURE_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
};
function editProfileSuccess(data) {
 //console.log("DATA editSuccess");
  return {
    type: PROFILE_ACTION_TYPE.EDIT_PROFILE_SUCCESS,
    status: data.status,
    user: data.data,
  };
}
function editError(res) {
  return {
    type: PROFILE_ACTION_TYPE.EDIT_PROFILE_ERROR,
    status: res.status,
    errorID: res.data.message[0].messages[0].id,
    error: "Error al editar",
  };
}
export const clearError = () => (dispatch) => {
  dispatch({ type: PROFILE_ACTION_TYPE.CLEAR_ERROR });
};

export const updateProfile = (form) => async (dispatch) => {
  dispatch({ type: PROFILE_ACTION_TYPE.EDIT_PROFILE_START });
  dispatch(clearError());
  await cmsAPI
    .put(`/users/me`, form)
    .then((res) => {
     //console.log("EDIT RESPONSE:", res);
      dispatch(editProfileSuccess(res));
    })
    .catch((err) => {
     //console.log("EDIT ERROR:", err.response);
      dispatch(editError(err.response));
    });
};

//PROFILE IMAGE
function UploadPPSuccess(data) {
 //console.log("DATA editSuccess");
  return {
    type: PROFILE_ACTION_TYPE.PROFILE_PICTURE_SUCCESS,
    status: data.status
  };
}
function uploadError(res) {
  return {
    type: PROFILE_ACTION_TYPE.PROFILE_PICTURE_ERROR,
    status: res.status,
    error: "Error al subir imagen",
  };
}
export const changeProfilePic = (data) => async (dispatch) => {
  dispatch({type:PROFILE_ACTION_TYPE.PROFILE_PICTURE_START});
  dispatch(clearError())
  await cmsAPI
  .post(`/upload`, data)
  .then((res) => {
   //console.log("Upload RESPONSE:", res);
    dispatch(UploadPPSuccess(res));
  })
  .catch((err) => {
   //console.log("Upload ERROR:", err.response);
    dispatch(uploadError(err.response));
  });
};
