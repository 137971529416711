import React, { Component } from "react";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
import CreateFlashcards from "../../components/FlashcardsForm";
class CreateFlashcardsView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <AuthenticateLayout>
        <div>
          <CreateFlashcards {...this.props} />
        </div>
      </AuthenticateLayout>
    );
  }
}

export default CreateFlashcardsView;
