import { useSelector } from "react-redux";
export const useCreating = () => {
  return useSelector(
    ({ createTopic }) => createTopic && createTopic.loading
  );
};
export const useCreatingSuccess = () => {
  return useSelector(
    ({ createTopic }) => createTopic && createTopic.success
  );
};
export const useCreatingError = () => {
  return useSelector(
    ({ createTopic }) => createTopic && createTopic.error
  );
};
export const useCreatedTopic = () => {
  return useSelector(
    ({ createTopic }) => createTopic && createTopic.Topic
  );
};

export const useCreatedTopicModal = () => {
  return useSelector(
    ({ createTopic }) => createTopic && createTopic.modalActive
  );
};
