import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import { useDispatch } from "react-redux";
import { Button } from "react-scroll";
import ModalView from "../../../components/ModalView";
import { ThemeColors } from "../../../lib/Colors";
import {
  clearUpdateSubtitleByIDs,
  cloneFlashcards,
  cloneFlashcardsClear,
  copyFlashcardsToClipboard,
  cutFlashcardsToClipboard,
  deleteFlashcards,
  pasteFlashcards,
  updateSubtitleByIDs,
} from "../actions";
import {
  Form,
  Input,
  FormGroup,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  useEditViewTopic,
  useDelteMultipleFCError,
  useDelteMultipleFCSuccess,
  useDelteMultipleFCLoading,
  useUpdateMultiSubtitleLoading,
  useUpdateMultiSubtitleSuccess,
  useUpdateMultiSubtitleError,
  useCloneMultiFcSuccess,
  useCloneMultiFcError,
  useCloneMultiFcLoading,
  useFlashcardsClipboardCut,
  useFlashcardsClipboardCopied,
  getFlashcardsCopiedFlashcardsIDs,
  getFlashcardsClipboardStorage,
} from "../selectors";
import ReactTooltip from "react-tooltip";
const FlashcardsAction = ({
  notifyAction,
  enable,
  selectedFlashcardsIds = [],
  setSelectedFlashcards = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const topic = useEditViewTopic();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [subtitle, setSubtitle] = useState("");
  const [deleteMultiple, setDelteMultiple] = useState(false);
  const [subtitleModal, setSubtitleModal] = useState(false);
  const deleteSuccess = useDelteMultipleFCSuccess();
  const deleteLoading = useDelteMultipleFCLoading();
  const deleteError = useDelteMultipleFCError();
  const savingSubtitles = useUpdateMultiSubtitleLoading();
  const successSubtitles = useUpdateMultiSubtitleSuccess();
  const errorSubtitles = useUpdateMultiSubtitleError();
  const successClone = useCloneMultiFcSuccess();
  const errorClone = useCloneMultiFcError();
  const loadingClone = useCloneMultiFcLoading();
  const cutFlashcardIDs = useFlashcardsClipboardCut();
  const copyFlashcardIDs = useFlashcardsClipboardCopied();
  const copyFlashcardIDsStorage = getFlashcardsCopiedFlashcardsIDs();
  const clipboardData = getFlashcardsClipboardStorage();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    if (deleteSuccess == true) setSelectedFlashcards([]);
    setTimeout(() => {
      setDelteMultiple(false);
    }, 1000);
  }, [deleteSuccess]);
  useEffect(() => {
    if (successSubtitles == true) {
      setSelectedFlashcards([]);
    }
    setTimeout(() => {
      setSubtitleModal(false);
      setSubtitle("");
      dispatch(clearUpdateSubtitleByIDs());
    }, 1100);
  }, [successSubtitles]);
  useEffect(() => {
    if (successClone) {
      dispatch(cloneFlashcardsClear());
    }
  }, [successClone]);

  useEffect(() => {
    // console.log("copyFlashcardIDsStorage", clipboardData);
  }, [clipboardData]);

  const isLoading = () => {
    return loadingClone || savingSubtitles || deleteLoading;
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <ReactTooltip />
      <DropdownToggle
        data-tip={"Acciones de multiple flashcards"}
        caret
        // disabled={
        //   selectedFlashcardsIds?.length <= 0 ||
        //   copyFlashcardIDs?.length <= 0 ||
        //   isLoading()
        // }
        style={{
          color: isLoading() ? ThemeColors.primaryBg : "white",
        }}
      >
        Acciones
        {isLoading() && (
          <div style={{ position: "absolute", top: "25%", right: "40%" }}>
            <Loading type="spin" color={ThemeColors.primary} width={25} />
          </div>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <ModalView
          loading={savingSubtitles}
          error={errorSubtitles}
          success={successSubtitles}
          hideComponent
          confirmColor={"success"}
          confirmText={"Guardar"}
          confirmAction={() => {
            dispatch(updateSubtitleByIDs(selectedFlashcardsIds, subtitle));
          }}
          toggleAction={() => {
            setSubtitleModal(!subtitleModal);
          }}
          toggleFlag={subtitleModal}
          centered
          title="Agregar subtitulo"
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              // console.log("Submit Subtitulo");
              dispatch(updateSubtitleByIDs(selectedFlashcardsIds, subtitle));
            }}
          >
            <FormGroup>
              <Input
                value={subtitle}
                onChange={(e) => {
                  let value = e.target.value;
                  setSubtitle(value);
                }}
                name="subtitle"
                id="subtitle"
                placeholder="Subtitulo"
              />
              <Input
                style={{
                  display: "none",
                }}
                type="submit"
                name="submit-subtitle"
                id="submit-subtitle"
                value="Guardar"
              />
            </FormGroup>
          </Form>
          {savingSubtitles && (
            <div className="d-flex justify-content-center align-items-center">
              <Loading
                width={25}
                type="spin"
                color={ThemeColors.primary}
              ></Loading>
            </div>
          )}
          {successSubtitles && <p className="text-success">Guardado</p>}
          {errorSubtitles && <p className="text-danger">Error al guardar</p>}
        </ModalView>
        <DropdownItem
          disabled={isLoading()}
          style={{
            display: "flex",
          }}
          onClick={() => {
            // dispatch(deleteFlashcards(selectedFlashcardsIds, topic.id));
            if (selectedFlashcardsIds?.length <= 0)
              return alert("No has seleccionado flashcards");
            setSubtitleModal(!subtitleModal);
          }}
        >
          <i
            className={"fa fa-list"}
            style={{ color: ThemeColors.primary }}
            onMouseEnter={() => {
              return;
            }}
          />
          Agregar Subtitulo{" "}
          {selectedFlashcardsIds?.length >= 1 &&
            "- " + selectedFlashcardsIds?.length}
        </DropdownItem>
        {/* <DropdownItem
          style={{
            display: "flex",
          }}
          disabled={isLoading()}
          onClick={() => {
            if (selectedFlashcardsIds?.length <= 0)
              return alert("No has seleccionado flashcards");
            dispatch(cloneFlashcards(selectedFlashcardsIds));
          }}
        >
          <i
            className={"fa fa-clone"}
            style={{ color: ThemeColors.primary }}
            onMouseEnter={() => {
              return;
            }}
          />
          Clonar
        </DropdownItem> */}
        <DropdownItem
          disabled={isLoading()}
          style={{
            display: "flex",
          }}
          onClick={() => {
            if (selectedFlashcardsIds?.length <= 0)
              return alert("No has seleccionado flashcards");
            notifyAction(
              "Copiadas " + selectedFlashcardsIds?.length + " flashcards"
            );
            dispatch(
              copyFlashcardsToClipboard(selectedFlashcardsIds, topic.id)
            );
          }}
        >
          <i
            className={"fa fa-copy"}
            style={{ color: ThemeColors.primary }}
            onMouseEnter={() => {
              return;
            }}
          />
          Copiar
        </DropdownItem>
        {((copyFlashcardIDs != null && copyFlashcardIDs?.length) ||
          (cutFlashcardIDs != null && cutFlashcardIDs?.length)) && (
          <DropdownItem
            style={{
              display: "flex",
            }}
            onClick={() => {
              let pasteMethod;
              let pasteIDsArray = [];
              console.log("PEGAR");
              if (copyFlashcardIDs != null && copyFlashcardIDs?.length) {
                pasteMethod = "copy";
                pasteIDsArray = copyFlashcardIDs;
              } else if (cutFlashcardIDs != null && cutFlashcardIDs?.length) {
                pasteMethod = "cut";
                pasteIDsArray = cutFlashcardIDs;
              }
              notifyAction(
                "Pegadas " +
                  pasteIDsArray?.length +
                  " flashcards al final de la lista"
              );
              console.log("PEGAR", pasteMethod);
              dispatch(pasteFlashcards(topic.id));
            }}
          >
            <i
              className={"fa fa-paste"}
              style={{ color: ThemeColors.primary }}
              onMouseEnter={() => {
                return;
              }}
            />
            Pegar
          </DropdownItem>
        )}
        <DropdownItem
          style={{
            display: "flex",
          }}
          disabled={isLoading()}
          onClick={() => {
            if (selectedFlashcardsIds?.length <= 0)
              return alert("No has seleccionado flashcards");
            notifyAction(
              "Cortadas " + selectedFlashcardsIds?.length + " flashcards"
            );
            dispatch(cutFlashcardsToClipboard(selectedFlashcardsIds, topic.id));
          }}
        >
          <i
            className={"fa fa-cut"}
            style={{ color: ThemeColors.primary }}
            onMouseEnter={() => {
              return;
            }}
          />
          Cortar
        </DropdownItem>
        <DropdownItem
          disabled={isLoading()}
          style={{
            display: "flex",
          }}
          onClick={() => {
            if (selectedFlashcardsIds?.length <= 0)
              return alert("No has seleccionado flashcards");
            setDelteMultiple(!deleteMultiple);
          }}
        >
          <i
            className={"fa fa-trash"}
            style={{ color: ThemeColors.danger }}
            onMouseEnter={() => {
              return;
            }}
          />
          <ModalView
            hideComponent
            confirmColor={"danger"}
            confirmText={"Eliminar"}
            confirmAction={() => {
              dispatch(deleteFlashcards(selectedFlashcardsIds, topic.id));
            }}
            toggleAction={() => {
              setDelteMultiple(!deleteMultiple);
            }}
            toggleFlag={deleteMultiple}
            centered
            title="Eliminar flashcards"
          >
            <h2>
              {selectedFlashcardsIds?.length > 1
                ? `Seguro que quieres eliminar ${selectedFlashcardsIds?.length} flashcards?`
                : `Seguro que quieres eliminar ${selectedFlashcardsIds?.length} flashcard?`}
            </h2>
            {deleteLoading && (
              <Loading type="spin" color={ThemeColors.primary}></Loading>
            )}
            {deleteSuccess && <p className="text-success">Eliminado</p>}
            {deleteError && <p className="text-danger">Error al eliminar</p>}
          </ModalView>
          Eliminar{" "}
          {selectedFlashcardsIds?.length >= 1 &&
            "- " + selectedFlashcardsIds?.length}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FlashcardsAction;
