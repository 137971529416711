import React, { useEffect, useState } from "react";
import Loading from "react-loading";
import { useDispatch } from "react-redux";
import { Badge, Button } from "reactstrap";
import ModalView from "../../../components/ModalView";
import { ThemeColors } from "../../../lib/Colors";
import {
  createBrandNewFlashcard,
  deleteSingleFlashcard,
  deleteSingleFlashcardClear,
  fetchFlashcards,
  updateActiveFlashcard,
} from "../actions";
import {
  useDelteSingleFCError,
  useDelteSingleFCLoading,
  useDelteSingleFCSuccess,
  useEditViewFlashcards,
  useEditViewTopic,
  useFlashcardIndexActive,
} from "../selectors";
const DeleteFlashcardModal = ({
  hideToggleComponent,
  toggleComponent,
  flashcardId,
  toggleSize,
  toggleClassName,
  toggleColor,
  inPreview,
  inReview,
  deletingF,
  deletingSuccessF,
  deletingErrorF,
  deleteAction = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const [openDelete, setOpenDelete] = useState(false);
  const activeTopic = useEditViewTopic();
  const indexActive = useFlashcardIndexActive();
  const flashcards = useEditViewFlashcards();
  const [currentFlashcard, setCurrentFlashcard] = useState();
  const deleting = useDelteSingleFCLoading();
  const deletingSuccess = useDelteSingleFCSuccess();
  const deletingError = useDelteSingleFCError();
  useEffect(() => {
    if (inPreview) return;
    if (flashcards <= [] && activeTopic) {
      console.log("NO HAY FC");
      dispatch(fetchFlashcards(activeTopic.id));
    }
    if (indexActive >= 0 && flashcards)
      setCurrentFlashcard(flashcards[indexActive]);
  }, [flashcards, indexActive]);
  useEffect(() => {
    if (inReview) {
      if (inReview && deletingSuccessF) {
        setOpenDelete(false);
        return;
      }
      return;
    }
    if (deletingSuccess) {
      toggle();
      dispatch(deleteSingleFlashcardClear());
    }
  }, [deletingSuccess, deletingSuccessF]);
  const toggle = () => {
    setOpenDelete(!openDelete);
  };
  const deleteLocalAction = () => {
    if (flashcardId) {
      if (deleteAction) {
        deleteAction(flashcardId);
        return;
      }
      console.log("Delte", flashcardId);
      dispatch(deleteSingleFlashcard(flashcardId));
      return;
    }
    if (!currentFlashcard) return;
    if (flashcards != null && flashcards.length) {
      let currentFcId = currentFlashcard.id;
      if (flashcards.length == 1) {
        let flashcardToSend = {
          id: flashcards[indexActive].id,
          question: "",
          answer: "",
        };
        dispatch(
          updateActiveFlashcard(flashcardToSend, {
            questionMedia: [],
            answerMedia: [],
          })
        );
      }
      console.log("Delte", currentFcId);
      dispatch(deleteSingleFlashcard(currentFcId));
    }
  };
  return (
    <ModalView
      toggleComponent={
        toggleComponent ||
        <Button
          size={toggleSize}
          onClick={toggle}
          color={toggleColor || "danger"}
          className={" " + toggleClassName}
        >
          <i className="fas fa-trash"></i>
        </Button>
      }
      confirmComponent={
        <Button
          onClick={deleteLocalAction}
          color="danger"
        >
          Eliminar
        </Button>
      }
      toggleAction={toggle}
      toggleFlag={openDelete}
      centered
      title="Eliminar flashcard"
    >
      <h2>¿estás seguro que quieres eliminar esta flashcard?</h2>
      {(deleting || deletingF) && (
        <Loading type="spin" color={ThemeColors.primary}></Loading>
      )}
      {(deletingSuccess || deletingSuccessF) && (
        <p className="text-success">Eliminado</p>
      )}
      {(deletingError || deletingErrorF) && (
        <p className="text-danger">Error al eliminar</p>
      )}
    </ModalView>
  );
};
export default DeleteFlashcardModal;
