import React, { Component, createRef } from "react";
import ImageUploader from "react-images-upload";
import Select from "react-select";
import { cmsAPI } from "../../../utils/http-client";
import NotificationAlert from "react-notification-alert";
import { notify } from "../../../components/AlertNotify";
import { CustomInput, Label, FormGroup, Spinner } from "reactstrap";

class PickSubjectDD extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {
      privacyRadio: "private",
      //fetchingSubjects
      subjectsLoading: true,
      subjectsError: false,
      subjectsSuccess: false,
      subjects: [],

      //Request Create subject
      loading: false,
      requestError: false,
      requestSuccess: false,
      createdNew: false,

      subjectId: "",
      selectedOption: null,
      showSubjectForm: false,
      options: [{ value: "createSubject", label: "Crear nueva materia" }],

      subjectForm: {
        image: [],
        name: "",
        description: "",
        public: false,
      },
    };
  }
  componentDidMount() {
    //console.log("Props SubjectDD", this.props);
    this.getSubjects();
  }
  getSubjects = () => {
    cmsAPI
      .get(`/subjects?public=true`)
      .then((res) => {
        let { options } = this.state;
        res.data.map((subject) => {
          options.push({ value: subject.id, label: subject.name });
        });
        //console.log("Subjects", res.data, "ºº Options", options);
        this.setState({
          options,
          subjects: res.data,
          subjectsLoading: false,
          subjectsSuccess: true,
        });
      })
      .catch((err) => {
        //console.log("Error al crear subjects");
        this.setState({
          subjectsError: true,
          subjectsLoading: false,
        });
      });
  };
  handleChangeOption = (selectedOption) => {
    if (selectedOption.value === "createSubject") {
      this.setState(
        { selectedOption, showSubjectForm: true, subjectId: "" },
        () => {
          //console.log(
          //   `Option selected:`,
          //   this.state.selectedOption,
          //   this.state.showSubjectForm
          // )
        }
      );
      return;
    }
    this.setState(
      {
        selectedOption,
        showSubjectForm: false,
        subjectId: selectedOption.value,
      },
      () => {
        //console.log(`Option selected:`, this.state.selectedOption)
      }
    );
  };
  handlePrivacyChange = (e) => {
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let { subjectForm } = this.state;
    //console.log("Privacy", name, "Value:", value);
    if (value === "public") subjectForm.public = true;
    if (value === "private") subjectForm.public = false;
    //console.log("Form", subjectForm);
    this.setState({
      subjectForm,
      privacyRadio: value,
    });
  };
  handleSubjectChange = (e) => {
    //console.log("Value@", e);
    if (!e.target) return;
    let target = e.target;
    let value = target.value;
    let name = target.name;
    let { subjectForm } = this.state;
    //console.log("Changinging", name, "Value:", value);
    if (name === "name") {
      subjectForm.name = value;
      this.setState({
        subjectForm,
      });
      return;
    }
    if (name === "description") {
      subjectForm.description = value;
      this.setState({
        subjectForm,
      });
      return;
    }
  };
  onDropSubjectImage = (picture) => {
    let { subjectForm } = this.state;
    subjectForm.image = picture;
    //console.log("PictureSubject", picture, "STATE:", subjectForm);
    this.setState({
      subjectForm,
    });
  };
  handleInput = (e) => {
    e.preventDefault();
    let { subjectForm } = this.state;
    if (!subjectForm.name) {
      return notify(
        "warning",
        "Proporciona un nombre a la materia",
        this.notificationAlert
      );
    }
    if (!subjectForm.description) {
      return notify(
        "warning",
        "Proporciona una descripcion a la materia",
        this.notificationAlert
      );
    }
    this.setState({
      loading: true,
    });
    let form = new FormData();
    form.append("files.image", subjectForm.image[0]);
    form.append(
      "data",
      JSON.stringify({
        name: subjectForm.name,
        description: subjectForm.description,
        public: subjectForm.public,
      })
    );
    //console.log("From will send", form);
    cmsAPI
      .post("/subjects", form, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        //console.log("Sent", res);
        this.setState({
          loading: false,
          createdNew: true,
          subjectId: res.data.id,
        });
        notify("success", "Creado con exito", this.notificationAlert);
      })
      .catch((err) => {
        //console.log("Error send", err);
        this.setState({
          loading: false,
        });
        notify("danger", "Error al crear subject", this.notificationAlert);
      });
  };
  render() {
    return (
      <div className="">
        <NotificationAlert ref={this.notificationAlert} />
        {/* New container */}
        <>
          {/* New Form START*/}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Crear Materia</div>
                </div>
                <div className="card-body">
                  <div className="col-md mb-3">
                    {/* TITLE */}
                    <div className="py-5 text-center">
                      <h2>Crear mis flashcards - Paso 1 / 3</h2>
                      <p className="lead">
                        Selecciona la matería a la que crees que pertenece el
                        tema de tus flashcards
                      </p>
                    </div>
                    {/* TITLE */}
                    <h2 className="text-center">Materias</h2>
                    <Select
                      autoFocus
                      required
                      noOptionsMessage={"No se ha encontrado."}
                      placeholder={"Selecciona una opción"}
                      noOptionsMessage={"Selecciona una opcion"}
                      options={this.state.options}
                      value={this.state.selectedOption}
                      onChange={this.handleChangeOption}
                    />
                    <div className="mt-4">
                      {this.state.showSubjectForm && (
                        <>
                          <form onSubmit={this.handleInput}>
                            {/* new form container */}
                            <>
                              <div className="card-body">
                                <h2 className="text-center">Crear Materia</h2>
                                <div className="row">
                                  <div className="col-md-4">
                                    <ImageUploader
                                      style={{}}
                                      withPreview
                                      singleImage={true}
                                      withIcon={true}
                                      buttonText="Subir imagen"
                                      onChange={this.onDropSubjectImage}
                                      imgExtension={[".jpg", ".jpeg", ".png"]}
                                      maxFileSize={5242880}
                                    />
                                  </div>
                                  <div className="col-md-8">
                                    <div className="d-flex">
                                      <div className="flex-1 ml-3 pt-1">
                                        <label htmlFor="name">Nombre</label>
                                        <input
                                          onChange={this.handleSubjectChange}
                                          value={this.state.subjectForm.name}
                                          type="text"
                                          className="form-control"
                                          id="subject"
                                          name="name"
                                          placeholder="Nombre de la materia a la que pertenece tus flashcards"
                                        />
                                        <p className="card-text">
                                          <label htmlFor="subjectDescription">
                                            Descripción
                                          </label>
                                          <input
                                            style={{ height: 200 }}
                                            onChange={this.handleSubjectChange}
                                            value={
                                              this.state.subjectForm.description
                                            }
                                            type="text"
                                            className="form-control"
                                            id="subjectDescription"
                                            name="description"
                                            placeholder="Descripción de la materia"
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      <FormGroup>
                                        <Label for="privacyRadios">
                                          Acceso a los usuarios
                                        </Label>
                                        <div>
                                          <CustomInput
                                            checked={
                                              this.state.privacyRadio ===
                                              "public"
                                            }
                                            type="radio"
                                            id="public"
                                            name="privacy"
                                            label="Publico"
                                            value={"public"}
                                            onChange={this.handlePrivacyChange}
                                          />
                                          <CustomInput
                                            checked={
                                              this.state.privacyRadio ===
                                              "private"
                                            }
                                            type="radio"
                                            id="private"
                                            name="privacy"
                                            label="Privado"
                                            value={"private"}
                                            onChange={this.handlePrivacyChange}
                                          />
                                        </div>
                                      </FormGroup>
                                    </div>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    disabled={
                                      this.state.createdNew ||
                                      this.state.loading
                                    }
                                    onClick={() => {
                                      //console.log("Clickedd ");
                                    }}
                                    className="btn btn-primary button-small"
                                  >
                                    {this.state.loading ? (
                                      <Spinner color={"white"} />
                                    ) : (
                                      "Crear"
                                    )}
                                  </button>
                                </div>
                              </div>
                            </>
                            {/* new form container */}
                          </form>
                        </>
                      )}
                    </div>
                    <div className="text-center">
                      {this.state.subjectId && (
                        <button
                          onClick={() => {
                            this.props.history.push(
                              `/myflashcards/create/t/${this.state.subjectId}`
                            );
                          }}
                          className="btn btn-primary"
                        >
                          Siguiente paso
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* New Form END */}
        </>
        {/* New container */}
      </div>
    );
  }
}

export default PickSubjectDD;
