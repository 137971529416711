import React, { Component } from "react";
import LoginLayout from "../../layouts/LoginLayout";
import PublicLayout from "../../layouts/PublicLayout";
import SignUpForm from "./components/SignUpForm";
import reducer from "./reducer";

class SignUpView extends Component {
  state = {};
  render() {
    return (
      <LoginLayout>
        <SignUpForm {...this.props} />
      </LoginLayout>
    );
  }
}

export default SignUpView;

export { reducer };
