// @flow
import * as React from "react";
import { useDispatch } from "react-redux";
import { getSubjectsAction } from "../actions";
export const CategorieSideItem = ({ id, name, slug, count }) => {
  const dispatch = useDispatch();
  return (
    <li>
      <a
        href={"#" + name}
        onClick={() => {
          let category = { id, name, slug };
          dispatch(getSubjectsAction(`categories=${id}`, category));
        }}
      >
        <i className="fa fa-circle" /> {name}
        {count && (
          <span className="badge badge-primary float-right">{count}</span>
        )}
      </a>
    </li>
  );
};
