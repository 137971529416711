import React from "react";
import { useCategoryActive } from "../selectors";
const CategoryHeaderInfo = (props) => {
  const categoryActive = useCategoryActive();
  return (
    <div
      style={{ ...styles.bgCover }}
      className="jumbotron bg-cover text-white"
    >
      <div className="container py-5 text-center">
        <h1 className="display-4 font-weight-bold text-primary">
          {categoryActive?.name || "..."}
        </h1>
        <p className="font-italic mb-0 text-dark">
          Using simple jumbotron-style component, create a nice Bootstrap 4
          header with a background image.
        </p>
        <p className="font-italic text-dark">
          Snippe by{" "}
          <a href="#" className="text-dark">
            <u>Superbmind</u>
          </a>
        </p>
        <a href="#" role="button" className="text-white btn btn-primary px-5">
          Empezar ahora
        </a>
      </div>
    </div>
  );
};
const styles = {
  bgCover: {
    background:
      "url(https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1267&q=80)",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    color: "black",
  },
  headerInfo: {
    color: "black",
    minHeight: "20vh",
    backgroundImage: `url("https://images.unsplash.com/photo-1508556497405-ed7dcd94acfc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80")`,
    backgroundSize: "cover",

    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    padding: "20px",
  },
};
export default CategoryHeaderInfo;
