import React, { useState, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Routes } from "../Routes";
import NavLinks from "./NavBarV2/NavLinks";
import { getUser } from "../lib/localstorage";
import { Link, useHistory } from "react-router-dom";
import CategoriesDD from "./CategoriesDD";
import { useDispatch } from "react-redux";
import { logout } from "../views/login/actions";
const NavBar = ({ navPosition, subNavBar, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
  useEffect(() => {
    const userItem = getUser();
    if (userItem) setUser(userItem);
    return () => {};
  }, []);
  const logoutAction = () => {
   //console.log("LOGOUT ACTION");
    dispatch(logout());
    history.push("/login");
  };
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={navPosition || "sticky-top"}>
      <div style={{ zIndex: 999 }}>
        <Navbar color="secondary" dark expand="md">
          <NavbarBrand href="/">Superbmind</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {Routes.map((route) => {
                if (route.noShowOnAuth && user.jwt) return;
                if (route.disable) return;
                if (route.showOnAuth && !user.jwt) return;
                return <NavLinks key={route.name} {...route} />;
              })}
              <CategoriesDD />
              {user.jwt && (
                <>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      {user.username}
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>
                        <Link className="dropdown-item" to={`/profile`}>
                          Mi Perfil
                        </Link>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={logoutAction}>
                        <span className="dropdown-item">Cerrar sesión</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <li
                    style={{ backgroundColor: "gold" }}
                    className={"nav-item"}
                  >
                    <Link
                      style={{
                        fontWeight: "bolder",
                        textShadow: "0px 0px 5px black",
                      }}
                      className="nav-link"
                      to={"/dashboard"}
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className={"nav-item"}>
                    <Link
                      style={{
                        fontWeight: "bolder",
                        textShadow: "0px 0px 5px black",
                      }}
                      className="nav-link"
                      to={"/admin/dashboard"}
                    >
                      Administrar
                    </Link>
                  </li>
                </>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
      {subNavBar}
    </div>
  );
};

export default NavBar;
