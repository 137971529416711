import { cmsAPI } from "../../../utils/http-client";
import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import ProfileDataForm from "../containers/ProfileDataForm";
import ChangeProfileImage from "../containers/ChangeProfileImage";
import SecurityProfileDetails from "../containers/SecurityProfileDetails";
import ImageUploader from "react-images-upload";
import defaultImage from "../../../assets/img/avatar-default.png";
import SuscriptionSelect from "../../../components/SuscriptionSelect";
const ProfileData = (props) => {
 //console.log("UserDATA", props);
  const {
    id,
    name,
    username,
    email,
    firstName,
    lastName,
    biography,
    profile_picture,
  } = props.user;
 //console.log("USER_PD", props.user);
  const [activeTab, setActiveTab] = useState("3");
  const [userForm, setUserForm] = useState({ ...props.user });
  const [validImage, setValidImage] = useState(false);
  const [imagePP, setImagePP] = useState([]);
  const [legacy, setLegacy] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const onDropPPImage = (picture) => {
    let myArray = [1, 2, 3];
    setImagePP(myArray);
   //console.log("PictureSubject", picture[0], "image><", imagePP);
  };
  const uploadProfilePic = () => {
   //console.log("Upload Clicked");
    let formData = FormData();
    formData.append("files", imagePP);
    formData.append("ref", "user");
   //console.log("ProfilePicture", profile_picture);
    cmsAPI
      .put(`/users/me`, formData)
      .then((res) => {
       //console.log("Response", res);
      })
      .catch((err) => {
       //console.log("Response", err);
      });
  };
  if (legacy)
    return (
      <div>
        <div className="container shadow p-3 mb-5 bg-white rounded">
          <div>
            <div className="row">
              <div className="col-sm-10">
                {/* <div className="">
                <h1>{firstName + " " + lastName}</h1>
              </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3">
                <div className="text-center mt-4">
                  <div className="">
                    <h1>{firstName + " " + lastName}</h1>
                    {username} <span className="muted"> ID:{id}</span>
                  </div>
                  <img
                    src={
                      profile_picture
                        ? cmsAPI.resolveAssetAPI(profile_picture.url)
                        : defaultImage
                    }
                    className="avatar img-circle img-thumbnail"
                    alt="avatar"
                  />

                  <div>
                    <fom
                      encType="multipart/form-data"
                      onSubmit={uploadProfilePic}
                    >
                      <ImageUploader
                        withPreview
                        singleImage={true}
                        withIcon={true}
                        buttonText="Cambiar imagen"
                        onChange={onDropPPImage}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                      />
                      <button
                        disabled={imagePP.length > 0 ? false : true}
                        type="submit"
                        className="btn btn-primary"
                      >
                        Guardar Nueva Foto
                      </button>
                    </fom>
                  </div>
                </div>
                <br />
              </div>
              <div className="col-sm-9">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Editar
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      Seguridad
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Suscripcion
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <div className="tab-content">
                          <div className="tab-pane active" id="home">
                            <hr />
                            <div className="text-center">
                              <h5 className="mb-2">EDITAR PERFIL</h5>
                            </div>
                            <ProfileDataForm {...props} />
                            <hr />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <div className="tab-content">
                          <div className="tab-pane active" id="home">
                            <hr />
                            <SecurityProfileDetails />
                            <hr />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <div className="tab-content">
                          <div className="tab-pane active" id="suscription">
                            <hr />
                            Suscripcion
                            <hr />
                            <SuscriptionSelect user={props.user} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else {
    return (
      <div className="page-inner">
        <h4 className="page-title">User Profile</h4>
        <div className="row">
        <div className="col-md-4">
            <div className="card card-profile">
              <div
                className="card-header"
                style={{ backgroundImage: 'url("/assets/img/blogpost.jpg")' }}
              >
                <div className="profile-picture">
                  <div className="avatar avatar-xl">
                    <img
                      src={
                        profile_picture
                          ? cmsAPI.resolveAssetAPI(profile_picture.url)
                          : defaultImage
                      }
                      alt="..."
                      className="avatar-img rounded-circle"
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="user-profile text-center">
                  <div className="name">{firstName + " " + lastName}</div>
                  <div className="job">{username}</div>
                  <div className="desc">{biography && biography}</div>
                </div>
              </div>
              <div className="card-footer">
                <div className="row user-stats text-center">
                  <div className="col">
                    <div className="number">125</div>
                    <div className="title">Post</div>
                  </div>
                  <div className="col">
                    <div className="number">25K</div>
                    <div className="title">Followers</div>
                  </div>
                  <div className="col">
                    <div className="number">134</div>
                    <div className="title">Following</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card card-with-nav">
              <div className="card-header">
                <div className="row row-nav-line">
                  <ul
                    className="nav nav-tabs nav-line nav-color-secondary w-100 pl-3"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active show"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-selected="true"
                      >
                        Timeline
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-selected="false"
                      >
                        Profile
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#settings"
                        role="tab"
                        aria-selected="false"
                      >
                        Settings
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <ProfileDataForm {...props} />
              {/* <div className="card-body">
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Name"
                        defaultValue="John Smith"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-group-default">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="Name"
                        defaultValue="info@gmail.com"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Birth Date</label>
                      <input
                        type="text"
                        className="form-control"
                        id="datepicker"
                        name="datepicker"
                        defaultValue="03/21/1998"
                        placeholder="Birth Date"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Gender</label>
                      <select className="form-control" id="gender">
                        <option>Male</option>
                        <option>Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group form-group-default">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={+62008765678}
                        name="phone"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="st Merdeka Putih, Jakarta India"
                        name="address"
                        placeholder="Address"
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3 mb-1">
                  <div className="col-md-12">
                    <div className="form-group form-group-default">
                      <label>About Me</label>
                      <textarea
                        className="form-control"
                        name="about"
                        placeholder="About Me"
                        rows={3}
                        defaultValue={"A man who hates loneliness"}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-right mt-3 mb-3">
                  <button className="btn btn-success">Save</button>
                  <button className="btn btn-danger">Reset</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileData;
