import { cmsAPI } from "../../../utils/http-client";

export const ADMIN_USERSCALES_ACTION_TYPE = {
  GET_USERSCALES_START: "GET_USERSCALES_START",
  GET_USERSCALES_SUCCESS: "GET_USERSCALES_SUCCESS",
  GET_USERSCALES_ERROR: "GET_USERSCALES_ERROR",

  CREATE_USERSCALES_START: "CREATE_USERSCALES_START",
  CREATE_USERSCALES_SUCCESS: "CREATE_USERSCALES_SUCCESS",
  CREATE_USERSCALES_ERROR: "CREATE_USERSCALES_ERROR",

  EDIT_USERSCALES_START: "EDIT_USERSCALES_START",
  EDIT_USERSCALES_SUCCESS: "EDIT_USERSCALES_SUCCESS",
  EDIT_USERSCALES_ERROR: "EDIT_USERSCALES_ERROR",
  EDIT_USERSCALES_CLEAR: "EDIT_USERSCALES_CLEAR",

  DELETE_USERSCALES_START: "DELETE_USERSCALES_START",
  DELETE_USERSCALES_SUCCESS: "DELETE_USERSCALES_SUCCESS",
  DELETE_USERSCALES_ERROR: "DELETE_USERSCALES_ERROR",
  DELETE_USERSCALES_CLEAR: "DELETE_USERSCALES_CLEAR",
};
export const createScaleAction =
  ({ form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_START });
    cmsAPI
      .post("userscales", form)
      .then((response) => {
        console.log("createScaleAction-success");
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_SUCCESS,
          newestCreated: response.data,
        });
      })
      .catch((error) => {
        console.error("createScaleAction", error);
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_ERROR,
        });
      });
  };

export const editScaleAction =
  ({ userscaleId, form }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_START });
    if (!userscaleId || !form) {
      dispatch({
        type: ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_ERROR,
      });
    }
    cmsAPI
      .put(`userscales/${userscaleId}`, form)
      .then((response) => {
        console.log("editScaleAction-success");
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_SUCCESS,
          editedScale: response.data,
        });
      })
      .catch((error) => {
        console.error("editScaleAction", error);
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_ERROR,
        });
      });
  };

export const deleteUserScaleAction =
  ({ userscaleId }) =>
  (dispatch) => {
    dispatch({ type: ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_START });
    cmsAPI
      .delete(`userscales/${userscaleId}`)
      .then(({ data }) => {
        console.log("deleteUserScaleAction-success", data);
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_SUCCESS,
        });
      })
      .catch((error) => {
        console.error("deleteUserScaleAction", error);
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_ERROR,
        });
      });
  };
export const getUserScales =
  (params = "") =>
  (dispatch) => {
    dispatch({ type: ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_START });
    cmsAPI
      .get("userscales?_sort=created_at:asc&_limit=9999" + params)
      .then((response) => {
        console.log("getUserScales-success");
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_SUCCESS,
          userscales: response.data,
        });
      })
      .catch((error) => {
        console.error("getScalesAction-error", error);
        dispatch({
          type: ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_ERROR,
        });
      });
  };
