import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUserJWT } from "../redux/selectors";
export const LoginLayout = (props) => {
  const { children } = props;
  const user = useUserJWT();
  const history = useHistory();
  useEffect(() => {
    if (user) {
      history.push("subjects");
    } else return;
  }, [user, history]);
  return (
    <div className="login">
      <div className="wrapper wrapper-login wrapper-login-full p-0">
        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
