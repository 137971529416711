import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { Button, FormGroup, Input, Label, Tooltip } from "reactstrap";
import ModalView from "../../../components/ModalView";
import { useMySubjects, useUserIsAdmin } from "../../../redux/selectors";
import {
  useErrorMySubjects,
  useLoadingMySubjects,
  useSuccessMySubjects,
} from "../../mysubjects/selector";
import { fetchMySubjects } from "../../mysubjects/actions";
import { clearUpdateTopic, editTopic } from "../actions";
import {
  useEditViewTopic,
  useFetchingSubject,
  useUpdateTopicError,
  useUpdateTopicLoading,
  useUpdateTopicSuccess,
} from "../selectors";
import Select from "react-select";
import Loading from "react-loading";
import { useSuperbmindUser } from "../../../redux/selectors";
import { cmsAPI } from "../../../utils/http-client";
import { getSuperbmindUser } from "../../login/actions";
import { ThemeColors } from "../../../lib/Colors";
import { fetchCategoriesDD } from "../../../components/CategoriesDD/actions";
import {
  useCategoriesDD,
  useFetchingCategories,
} from "../../../components/CategoriesDD/selectors";

const EditDeckInfo = (props) => {
  const fetchingCategories = useFetchingCategories();
  const categoriesArr = useCategoriesDD();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [fetchingSubCategory, setFetchingSubCategory] = useState(true);
  const [TopicSubCategory, setTopicSubCategory] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const dispatch = useDispatch();
  const topicActive = useEditViewTopic();
  const subjectActive = useFetchingSubject();

  const mysubjects = useMySubjects();

  const [localSubjects, setLocalsubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const [UserScalesSelected, setUserScalesSelected] = useState([]);
  const [UserScales, setUserScales] = useState([]);
  const [FetchingUserScales, setFetchingUserScales] = useState(false);

  const isAdmin = useUserIsAdmin();
  const superbmindUser = useSuperbmindUser();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const getSubcategories = () => {
    setFetchingSubCategory(true);
    cmsAPI
      .get("sub-categories?_limit=9999")
      .then((res) => {
        setFetchingSubCategory(false);
        setSubCategoryData(res.data);
      })
      .catch((err) => {
        setFetchingSubCategory(false);
        setSubCategoryData([]);
      });
  };
  useEffect(() => {
    getSubcategories();
    dispatch(fetchCategoriesDD());
    dispatch(getSuperbmindUser());
  }, []);

  useEffect(() => {
    // console.log("Is Admin", isAdmin, isAdmin);
    if (!superbmindUser) {
      dispatch(getSuperbmindUser());
    }
    if (isAdmin) {
      if (superbmindUser?.id) {
        // console.log("FETCH SUPERBMIND SUBJECTS", isAdmin);
        cmsAPI
          .get(`subjects?owner=${superbmindUser.id}`)
          .then(({ data }) => {
            // console.log("Superbmind Data", data);
            setLocalsubjects(data);
            setLoading(false);
          })
          .catch((err) => {
            // console.log("/ERROR/Superbmind Data", err);
            setLoading(false);
          });
      } else return;
    } else {
      if (!mysubjects) {
        dispatch(fetchMySubjects());
        setLocalsubjects(mysubjects);
      }
    }
  }, [mysubjects, superbmindUser]);

  const updateSuccess = useUpdateTopicSuccess();
  const updateError = useUpdateTopicError();
  const updateLoading = useUpdateTopicLoading();

  const [toggleModal, setToggleModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [invalidName, setInvalidName] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("nombre no válido");
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleEditModal = () => {
    setToggleModal(!toggleModal);
  };
  const [topicName, setTopicName] = useState(topicActive?.name || "");
  const [topicOriginalName, setOriginalTopicName] = useState(
    topicActive?.name || ""
  );
  useEffect(() => {
    if (updateSuccess == true) setToggleModal(false);
  }, [updateSuccess]);
  useEffect(() => {
    if (!toggleModal) dispatch(clearUpdateTopic());
  }, [toggleModal]);
  useEffect(() => {
    if (
      topicActive != null &&
      topicActive?.userscales != null &&
      topicActive?.userscales.length > 0
    ) {
      let defaultActive = topicActive?.userscales.map((s) => {
        s.value = s.id;
        s.label = s.name;
        return s;
      });
      setUserScalesSelected(defaultActive || []);
    } else {
      setUserScalesSelected([]);
    }
    if (
      topicActive != null &&
      topicActive?.categories != null &&
      topicActive?.categories.length > 0
    ) {
      let defaultActive = topicActive?.categories.map((s) => {
        s.value = s.id;
        s.label = s.name;
        return s;
      });
      setSelectedCategories(defaultActive || []);
    } else {
      setSelectedCategories([]);
    }
    if (
      topicActive != null &&
      topicActive?.sub_categories != null &&
      topicActive?.sub_categories.length > 0
    ) {
      let defaultActive = topicActive?.sub_categories.map((s) => {
        s.value = s.id;
        s.label = s.name;
        return s;
      });
      setTopicSubCategory(defaultActive || []);
    } else {
      setTopicSubCategory([]);
    }
    setOriginalTopicName(topicActive?.name || "");
    setTopicName(topicActive?.name || "");
  }, [topicActive]);

  const getUserScales = () => {
    setFetchingUserScales(true);
    cmsAPI
      .get("userscales?_limit=9999")
      .then((res) => {
        setFetchingUserScales(false);
        setUserScales(res.data);
      })
      .catch((err) => {
        setFetchingUserScales(false);
        setUserScales([]);
      });
  };
  useEffect(() => {
    getUserScales();
  }, []);

  const handleSave = (e) => {
    //console.log("Save Click");
    e.preventDefault();
    if (!topicName) {
      setInvalidName(true);
      setInvalidMessage("campo requerido");
    } else {
      let topicToSend = {
        name: topicName,
        userscales: UserScalesSelected,
        categories: selectedCategories,
        sub_categories: TopicSubCategory,
      };
      if (selectedSubjects != null && selectedSubjects.length > 0) {
        topicToSend.in_subjects = selectedSubjects;
      }
      dispatch(editTopic({ topicId: topicActive?.id, data: topicToSend }));
    }
  };
  if (!subjectActive) return <Loading type="spin" width={20}></Loading>;
  return (
    <ModalView
      confirmComponent={
        <Button type="submit" className="btn btn-success" onClick={handleSave}>
          Guardar
        </Button>
      }
      title="Editar pila"
      centered
      toggleAction={() => toggleEditModal()}
      toggleFlag={toggleModal}
      toggleComponent={
        <Button size="sm" onClick={toggleEditModal} id="TooltipExample">
          Editar
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target="TooltipExample"
            toggle={toggle}
          >
            Edita la información de la pila
          </Tooltip>
        </Button>
      }
    >
      <FormGroup>
        <Label for="topicName" sm={2}>
          Nombre
        </Label>
        <Input
          invalid={invalidName}
          required
          onChange={(e) => {
            let value = e.target.value;
            setTopicName(value);
          }}
          value={topicName}
          name="topicName"
          placeholder="nombre de la pila"
        />
        <Label className="mt-4" for="inSubjects">
          Categoria
        </Label>
        {fetchingCategories || !categoriesArr?.length ? (
          <div className="d-flex justify-content-center">
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={20}
              width={20}
            />
          </div>
        ) : (
          <Select
            onChange={(data) => {
              console.log("Data", data);
              setSelectedCategories(data);
            }}
            closeMenuOnSelect={false}
            isMulti
            value={selectedCategories}
            hideSelectedOptions
            options={categoriesArr.map((category) => {
              // if (selectedCategories.includes(category) === true) return;
              category.value = category.id;
              category.label = category.name;
              return category;
            })}
          />
        )}
        <Label className="mt-4" for="inSubjects">
          Sub categoria
        </Label>
        {fetchingSubCategory ? (
          <div className="d-flex justify-content-center">
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={20}
              width={20}
            />
          </div>
        ) : (
          <Select
            hideSelectedOptions
            isMulti
            onChange={(data) => {
              setTopicSubCategory(data);
            }}
            value={TopicSubCategory}
            closeMenuOnSelect={false}
            defaultValue={TopicSubCategory}
            options={subCategoryData.map((type) => {
              type.value = type.id;
              type.label = type.name;
              return type;
            })}
          />
        )}

        <Label className="mt-4" sm={2} for="inSubjects">
          Grado
        </Label>
        {FetchingUserScales ? (
          <div className="d-flex justify-content-center">
            <ReactLoading
              type={"spin"}
              color={ThemeColors.primary}
              height={20}
              width={20}
            />
          </div>
        ) : (
          <Select
            hideSelectedOptions
            isMulti
            onChange={(data) => {
              setUserScalesSelected(data);
            }}
            value={UserScalesSelected}
            closeMenuOnSelect={false}
            defaultValue={UserScalesSelected}
            options={UserScales.map((type) => {
              type.value = type.id;
              type.label = type.name;
              return type;
            })}
          />
        )}

        {localSubjects != null && (
          <>
            <Label for="inSubjects" sm={2}>
              En materias
            </Label>
            <Select
              onChange={(data) => {
                //console.log("Selected Data", data);
                setSelectedSubjects(data);
              }}
              closeMenuOnSelect={false}
              isMulti
              defaultValue={localSubjects.find((s) => s.id == subjectActive.id)}
              options={localSubjects.map((subject) => {
                subject.value = subject.id;
                subject.label = subject.name;
                return subject;
              })}
            />
          </>
        )}
      </FormGroup>
    </ModalView>
  );
};
export default EditDeckInfo;
