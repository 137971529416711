import { useSelector } from "react-redux";
//SUBJECT
export const useFetchingSubject = () => {
  return useSelector(
    ({ editFlashcardsView }) => editFlashcardsView && editFlashcardsView.subject
  );
};
export const useFetchingSubjectLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.loadingSubject
  );
};
export const useFetchingSubjectSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.successSubject
  );
};
export const useFetchingSubjectError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.errorSubject
  );
};
//TOPIC
export const useEditViewTopic = () => {
  return useSelector(
    ({ editFlashcardsView }) => editFlashcardsView && editFlashcardsView.topic
  );
};
export const useEditViewLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.loadingTopic
  );
};
export const useFetchingTopicSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.successTopic
  );
};
export const useFetchingTopicError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.errorTopic
  );
};
//CREATE FLASHCARDS
export const useCreateFlashcardLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.createFlashcard?.loading
  );
};
export const useCreateFlashcardSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.createFlashcard?.success
  );
};
//FLASHCARDS
export const useEditViewFlashcards = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.flashcards
  );
};
export const useFetchingFlashcardsLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.loadingFlashcards
  );
};
export const useFetchingFlashcardsSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.successFlashcards
  );
};
export const useFetchingFlashcardsError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.errorFlashcards
  );
};
export const useFlashcardIndexActive = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.indexActive
  );
};
//EDIT TOPIC
export const useUpdateTopicLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.updateTopic &&
      editFlashcardsView.updateTopic.loading
  );
};
export const useUpdateTopicSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.updateTopic &&
      editFlashcardsView.updateTopic.success
  );
};
export const useUpdateTopicError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.updateTopic &&
      editFlashcardsView.updateTopic.error
  );
};
//Flashcard Form
export const useFlashcardsForm = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.flashcardsFrom
  );
};
export const useFlashcardFormAcive = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.flashcardFormActive
  );
};
//UPDATE FLASHCARD STATE         saveSingleFlashcard
export const useUpdateFlashcardLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.saveSingleFlashcard &&
      editFlashcardsView.saveSingleFlashcard.loading
  );
};
export const useUpdateFlashcardSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.saveSingleFlashcard &&
      editFlashcardsView.saveSingleFlashcard.success
  );
};
export const useUpdateFlashcardError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView &&
      editFlashcardsView.saveSingleFlashcard &&
      editFlashcardsView.saveSingleFlashcard.error
  );
};
//DELETE FLASHCARD
export const useDelteSingleFCLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.delteSingleFlashcard?.loading
  );
};
export const useDelteSingleFCSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.delteSingleFlashcard?.success
  );
};
export const useDelteSingleFCError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.delteSingleFlashcard?.error
  );
};
export const useDelteMultipleFCLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.deleteMultiple?.loading
  );
};
export const useDelteMultipleFCSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.deleteMultiple?.success
  );
};
export const useDelteMultipleFCError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.deleteMultiple?.error
  );
};
// MULTI SUBTITLE
export const useUpdateMultiSubtitleLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.updateMultiSubtitle?.loading
  );
};
export const useUpdateMultiSubtitleSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.updateMultiSubtitle?.success
  );
};
export const useUpdateMultiSubtitleError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.updateMultiSubtitle?.error
  );
};
export const useCloneMultiFcSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.cloneMultiple?.success
  );
};
export const useCloneMultiFcError = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.cloneMultiple?.error
  );
};
export const useCloneMultiFcLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView.cloneMultiple?.loading
  );
};
//
export const useFlashcardsClipboard = () => {
  // return useSelector(
  //   ({ editFlashcardsView }) =>
  //     editFlashcardsView && editFlashcardsView.flashcards_clipboard
  // );
  let data = localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  return data;
};
export const useFlashcardsClipboardCopied = () => {
  // return useSelector(
  //   ({ editFlashcardsView }) =>
  //     editFlashcardsView &&
  //     editFlashcardsView.flashcards_clipboard?.copy_clipboard?.flashcardsIDs
  // );
  let data = localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  // console.log("flashcards_clipboard_COPY", data);
  let flashcardsIDs = data?.copy_clipboard?.flashcardsIDs;
  return flashcardsIDs;
};
export const useFlashcardsClipboardCut = () => {
  // return useSelector(
  //   ({ editFlashcardsView }) =>
  //     editFlashcardsView &&
  //     editFlashcardsView.flashcards_clipboard?.cut_clipboard?.flashcardsIDs
  // );
  let data = localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  // console.log("flashcards_clipboard_CUT", data);
  let flashcardsIDs = data?.cut_clipboard?.flashcardsIDs;
  return flashcardsIDs;
};
export const useFlashcardCloneLoading = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView?.cloneSingle?.loading
  );
};
export const useFlashcardcloneSingleSuccess = () => {
  return useSelector(
    ({ editFlashcardsView }) =>
      editFlashcardsView && editFlashcardsView?.cloneSingle?.success
  );
};

export const getFlashcardsClipboardStorage = () => {
  // return useSelector(
  //   ({ editFlashcardsView }) =>
  //     editFlashcardsView && editFlashcardsView.flashcards_clipboard
  // );
  let data = localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  return data;
};
export const getFlashcardsCopiedFlashcardsIDs = () => {
  // return useSelector(
  //   ({ editFlashcardsView }) =>
  //     editFlashcardsView &&
  //     editFlashcardsView.flashcards_clipboard?.copy_clipboard?.flashcardsIDs
  // );
  let data = localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  // console.log("flashcards_clipboard_COPY", data);
  let flashcardsIDs = data?.copy_clipboard?.flashcardsIDs;
  return flashcardsIDs;
};
