import { cmsAPI } from "../../utils/http-client";
import { setUser, clearUser } from "../../lib/localstorage";

export const USER_ACTION_TYPE = {
  USER_LOGIN_START: "USER_LOGIN_START",
  USER_LOGIN_END: "USER_LOGIN_END",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_ERROR: "USER_LOGIN_ERROR",
  USER_LOGOUT: "USER_LOGOUT",

  FETCH_SUPERBMINDUSER_START: "FETCH_SUPERBMINDUSER_START",
  FETCH_SUPERBMINDUSER_SUCCESS: "FETCH_SUPERBMINDUSER_SUCCESS",
  FETCH_SUPERBMINDUSER_ERROR: "FETCH_SUPERBMINDUSER_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
};
function loginSuccess(data) {
  const { user, jwt } = data;
  cmsAPI.addJwt(jwt);
  return {
    type: USER_ACTION_TYPE.USER_LOGIN_SUCCESS,
    ...user,
    jwt,
  };
}
function loginError() {
  return {
    type: USER_ACTION_TYPE.USER_LOGIN_ERROR,
    error: "Email o contraseña incorrectos",
  };
}
export const setUserData = (user) => (dispatch) => {
  let data;
  dispatch({ type: USER_ACTION_TYPE.USER_LOGIN_SUCCESS, ...user });
};
export const logout = (cookies) => (dispatch) => {
  clearUser(cookies);
  cmsAPI.clearJwt();
  dispatch({ type: USER_ACTION_TYPE.CLEAR_ERROR });
};

export const login = (form) => (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.USER_LOGIN_START });
  cmsAPI
    .post("/auth/local", form)
    .then((res) => {
      //console.log("login RESPONSE:", res);
      dispatch(loginSuccess(res.data));
    })
    .catch((err) => {
      //console.log("login ERROR:", err);
      dispatch(loginError());
    });
};
export const getSuperbmindUser = () => (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_START });
  cmsAPI
    .get("/users?username_eq=Superbmind&verified=true&role=5&isSuperbmind=true")
    .then(({ data }) => {
      // console.log("login RESPONSE:", data[0]);
      dispatch({
        type: USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_SUCCESS,
        ...data[0],
      });
      return data[0];
    })
    .catch((err) => {
      //console.log("login ERROR:", err);
      dispatch({ type: USER_ACTION_TYPE.FETCH_SUPERBMINDUSER_ERROR });
      return {};
    });
};
export const clearError = () => (dispatch) => {
  dispatch({ type: USER_ACTION_TYPE.CLEAR_ERROR });
};
