import React, { Component, createRef } from "react";
import NotificationAlert from "react-notification-alert";
import AlertNotify, { notify } from "../../components/AlertNotify";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";

class DashboardView extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    //notify("primary", "Testing notifications alerts", this.notificationAlert);
  }
  render() {
    return (
      <AuthenticateLayout>
        <NotificationAlert ref={this.notificationAlert} />
        <div>
          <h1>Dashboard</h1>
          <div className="page-category">
            Encuentra variedad de clases en Superbmind®.
          </div>

          <div className="row">
            
          </div>
        </div>
      </AuthenticateLayout>
    );
  }
}

export default DashboardView;
