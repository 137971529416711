import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { cmsAPI } from "../../utils/http-client";
import ActionsDD from "./components/ActionsDD";
import { ThemeColors } from "../../lib/Colors";
import ReactLoading from "react-loading";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveTopicSorting } from "../../views/subjectProfile/actions";
import { useUserIsAdmin } from "../../redux/selectors";
const TopicListItem = ({
  noContentShowActions = false,
  selectedTopics = [],
  index,
  onClick,
  createTopic,
  topic,
  imOwner,
  imAdmin,
  setSelectedTopics = () => { },
  onCreateTopic,
  onCheckAllTopics,
  topics,
  noContent,
  showImport,
  reorderModeToggle = () => { },
  reorderFlag,
  ...props
}) => {
  let importingTimer = null;
  const path = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [importing, setImporting] = useState(false);
  const isAdmin = useUserIsAdmin();
  const [flashcardsCount, setFlashcardsCount] = useState(0);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const checkImporting = () => {
    cmsAPI
      .get(`/topics/check-importing/${topic.id}`)
      .then(({ data }) => {
        console.log("Check importing", data);
        setImporting(data.importing);
        setFlashcardsCount(data?.flashcardsCount);
        if (data.importing == true) {
          setTimeout(() => {
            checkImporting();
          }, 3000);
        }
      })
      .catch((err) => {
        console.log("Error::", err);
      });
  };
  useEffect(() => {
    if (topic?.importing_flashcards) {
      checkImporting();
    }
    setFlashcardsCount(topic?.flashcardsCount);
  }, [topic]);
  const [isHover, setIsHover] = useState(false);
  const renderImage = (item) => {
    if (createTopic)
      if (reorderFlag || noContentShowActions) return;
      else
        return (
          <i
            onClick={(e) => {
              e.stopPropagation();
              if (
                selectedTopics.length < topics.length &&
                selectedTopics.length > 0
              ) {
                onCheckAllTopics(true);
                return;
              }
              if (onCheckAllTopics) onCheckAllTopics();
            }}
            style={{ fontSize: "1.5em", color: ThemeColors.primary }}
            className={
              selectedTopics.length < topics.length && selectedTopics.length > 0
                ? "fas fa-minus-circle"
                : selectedTopics.length >= topics.length
                  ? "fas fa-check-circle"
                  : "far fa-circle"
            }
          />
        );
    if (reorderFlag)
      return (
        <i
          style={{ fontSize: "1.5em", color: ThemeColors.primary }}
          className="fas fa-grip-horizontal"
        ></i>
      );
    return (
      <i
        onClick={(e) => {
          e.stopPropagation();
          if (selectedTopics.includes(topic.id)) {
            setSelectedTopics(topic, 0);
          } else {
            setSelectedTopics(topic, 1);
          }
        }}
        style={{ fontSize: "1.5em", color: ThemeColors.primary }}
        className={
          selectedTopics.includes(topic.id)
            ? "fas fa-check-circle"
            : "far fa-circle"
        }
      />
    );
  };
  const renderName = () => {
    if (noContentShowActions) return "Comienza creando o importando una pila";
    if (createTopic) return "Pilas";
    return topic.name;
  };
  const renderActionBtns = () => {
    if (createTopic)
      return (
        <div className="d-flex flex-row">
          {reorderFlag ? (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                reorderModeToggle();

                const topicsIds = topics.map((topic) => topic.id);
                dispatch(saveTopicSorting(topicsIds));
              }}
              style={styles.actionBtn}
              color="primary"
              size="sm"
            >
              GUARDAR
            </Button>
          ) : (
            <>
              {!noContentShowActions && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    reorderModeToggle();
                  }}
                  style={styles.actionBtn}
                  color="link"
                >
                  <i style={{ fontSize: "1.5em" }} class="fas fa-random"></i>
                </Button>
              )}
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (onCreateTopic) onCreateTopic();
                }}
                style={styles.actionBtn}
                color="link"
              >
                <i style={{ fontSize: "1.5em" }} class="fas fa-plus"></i>
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  if (onCreateTopic) onCreateTopic(true);
                }}
                style={styles.actionBtn}
                color="link"
              >
                <i style={{ fontSize: "1.5em" }} class="fas fa-upload"></i>
              </Button>
            </>
          )}
        </div>
      );
    if (importing)
      return (
        <ReactLoading
          type={"spin"}
          color={ThemeColors.primary}
          height={20}
          width={20}
        ></ReactLoading>
      );
    if (!topic?.importing_flashcards && topic?.flashcardsCount <= 0) {
      return (
        <div className="d-flex flex-row">
          <Button
            style={{ ...styles.actionBtn, fontSize: "1em" }}
            color="warning"
            onClick={(e) => {
              e.stopPropagation();
              history.push(topic ? `${path.url}/edit/${topic.id}` : "#");
            }}
          >
            <i className="fas fa-plus" />
            Agrega flashcards
          </Button>
          <ActionsDD
            showPreviewModal={(show) => {
              setShowPreviewModal(show);
            }}
            showPreviewOutside={showPreviewModal}
            hideDelete={false}
            topic={topic}
            color="link"
            style={styles.actionBtn}
            childComponent={
              <i style={{ fontSize: "1.5em" }} className="fas fa-ellipsis-v" />
            }
          />
        </div>
      );
    }
    return (
      <div className="d-flex flex-row">
        <Button
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={styles.actionBtn}
          color="link"
        >
          <i style={{ fontSize: "1.5em" }} className="fas fa-play" />
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setShowPreviewModal(true);
          }}
          style={styles.actionBtn}
          color="link"
        >
          <i style={{ fontSize: "1.5em" }} className="fas fa-glasses" />
        </Button>
        <ActionsDD
          showPreviewModal={(show) => {
            setShowPreviewModal(show);
          }}
          showPreviewOutside={showPreviewModal}
          hideDelete={false}
          topic={topic}
          color="link"
          style={styles.actionBtn}
          childComponent={
            <i style={{ fontSize: "1.5em" }} className="fas fa-ellipsis-v" />
          }
        />
      </div>
    );
  };
  if (noContent && createTopic) {
    return (
      <div
        style={{
          cursor: "pointer",
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.border = `2px solid ${ThemeColors.primary}`;
          e.currentTarget.style.borderRadius = `5px`;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.border = ``;
          e.currentTarget.style.borderRadius = ``;
        }}
        onClick={(e) => {
          if (onCreateTopic) onCreateTopic();
        }}
        className="align-items-center d-flex flex-row list-group-item"
      >
        <div className="">
          <div className="h-100 justify-content-center align-items-center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                if (onCreateTopic) onCreateTopic(true);
              }}
              style={styles.actionBtn}
              color="link"
            >
              <i style={{ fontSize: "1.5em" }} class="fas fa-plus"></i>
            </Button>
          </div>
        </div>
        <div>
          <div className="d-flex flex-column ml-3">
            <span style={{ fontSize: "1.2vw" }}>{"Crear pil/a"}</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        cursor: createTopic ? "auto" : onClick && "pointer",
      }}
      onMouseOver={(e) => {
        if (createTopic) return;
        e.currentTarget.style.border = `2px solid ${ThemeColors.primary}`;
        e.currentTarget.style.borderRadius = `5px`;
      }}
      onMouseLeave={(e) => {
        if (createTopic) return;
        e.currentTarget.style.border = ``;
        e.currentTarget.style.borderRadius = ``;
      }}
      onClick={onClick}
      className="align-items-center d-flex flex-row list-group-item"
    >
      <div className="">
        <div className="h-100 justify-content-center align-items-center">
          {renderImage()}
        </div>
      </div>
      <div>
        <div className="d-flex flex-column ml-3">
          <span style={{ fontSize: "1.2vw" }}>{renderName()}</span>
          {flashcardsCount > 0 && (
            <span style={{ fontSize: "1vw" }} className="text-muted">
              {flashcardsCount} Flashcards
            </span>
          )}
        </div>
      </div>
      <>
        {(imOwner || isAdmin) && (
          <div className="ml-auto">{renderActionBtns()}</div>
        )}
      </>
    </div>
  );
};

const styles = {
  actionBtn: {
    marginLeft: 2,
  },
};
export default TopicListItem;
