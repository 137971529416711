import { useSelector } from "react-redux";
export const useCategoriesDD = () => {
  return useSelector(
    ({ categoriesDD }) => categoriesDD && categoriesDD.categories
  );
};
export const useFetchingCategories = () => {
  return useSelector(
    ({ categoriesDD }) => categoriesDD && categoriesDD.loading
  );
};
export const useSuccessCategories = () => {
  return useSelector(
    ({ categoriesDD }) => categoriesDD && categoriesDD.success
  );
};
export const useErrorCategories = () => {
  return useSelector(
    ({ categoriesDD }) => categoriesDD && categoriesDD.error
  );
};
