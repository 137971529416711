import { cmsAPI } from "../../../utils/http-client";

export const ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE = {
  GET_SUBCATEGORYSCALES_START: "GET_SUBCATEGORYSCALES_START",
  GET_SUBCATEGORYSCALES_SUCCESS: "GET_SUBCATEGORYSCALES_SUCCESS",
  GET_SUBCATEGORYSCALES_ERROR: "GET_SUBCATEGORYSCALES_ERROR",

  CREATE_SUBCATEGORYSCALES_START: "CREATE_SUBCATEGORYSCALES_START",
  CREATE_SUBCATEGORYSCALES_SUCCESS: "CREATE_SUBCATEGORYSCALES_SUCCESS",
  CREATE_SUBCATEGORYSCALES_ERROR: "CREATE_SUBCATEGORYSCALES_ERROR",

  EDIT_SUBCATEGORYSCALES_START: "EDIT_SUBCATEGORYSCALES_START",
  EDIT_SUBCATEGORYSCALES_SUCCESS: "EDIT_SUBCATEGORYSCALES_SUCCESS",
  EDIT_SUBCATEGORYSCALES_ERROR: "EDIT_SUBCATEGORYSCALES_ERROR",
  EDIT_SUBCATEGORYSCALES_CLEAR: "EDIT_SUBCATEGORYSCALES_CLEAR",

  DELETE_SUBCATEGORYSCALES_START: "DELETE_SUBCATEGORYSCALES_START",
  DELETE_SUBCATEGORYSCALES_SUCCESS: "DELETE_SUBCATEGORYSCALES_SUCCESS",
  DELETE_SUBCATEGORYSCALES_ERROR: "DELETE_SUBCATEGORYSCALES_ERROR",
  DELETE_SUBCATEGORYSCALES_CLEAR: "DELETE_SUBCATEGORYSCALES_CLEAR",
};
export const createScaleAction = (form) => (dispatch) => {
  dispatch({
    type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.CREATE_SUBCATEGORYSCALES_START,
  });
  cmsAPI
    .post("subcategory-scales", form)
    .then((response) => {
      console.log("createScaleAction-success");
      dispatch({
        type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.CREATE_SUBCATEGORYSCALES_SUCCESS,
        newestCreated: response.data,
      });
    })
    .catch((error) => {
      console.error("createScaleAction", error);
      dispatch({
        type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.CREATE_SUBCATEGORYSCALES_ERROR,
      });
    });
};

export const editSubcategoryScalesAction =
  ({ subcategoryScalesId, userScales }) =>
  (dispatch) => {
    dispatch({
      type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.EDIT_SUBCATEGORYSCALES_START,
    });
    if (!subcategoryScalesId || !userScales) {
      dispatch({
        type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.EDIT_SUBCATEGORYSCALES_ERROR,
      });
    }
    cmsAPI
      .put(`subcategory-scales/${subcategoryScalesId}`, {
        userscales: userScales,
      })
      .then((response) => {
        console.log("editSubcategoryScalesAction-success");
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.EDIT_SUBCATEGORYSCALES_SUCCESS,
          SubcategoryScale: response.data,
        });
      })
      .catch((error) => {
        console.error("editSubcategoryScalesAction", error);
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.EDIT_SUBCATEGORYSCALES_ERROR,
        });
      });
  };

export const deleteUserScaleAction =
  ({ userscaleId }) =>
  (dispatch) => {
    dispatch({
      type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.DELETE_SUBCATEGORYSCALES_START,
    });
    cmsAPI
      .delete(`userscales/${userscaleId}`)
      .then(({ data }) => {
        console.log("deleteUserScaleAction-success", data);
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.DELETE_SUBCATEGORYSCALES_SUCCESS,
        });
      })
      .catch((error) => {
        console.error("deleteUserScaleAction", error);
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.DELETE_SUBCATEGORYSCALES_ERROR,
        });
      });
  };
export const getUserScales =
  (params = "") =>
  (dispatch) => {
    dispatch({
      type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.GET_SUBCATEGORYSCALES_START,
    });
    cmsAPI
      .get("subcategory-scales?_limit=9999" + params)
      .then((response) => {
        console.log("getUserScales-success");
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.GET_SUBCATEGORYSCALES_SUCCESS,
          userscales: response.data,
        });
      })
      .catch((error) => {
        console.error("getScalesAction-error", error);
        dispatch({
          type: ADMIN_SUBCATEOGRY_SCALES_ACTION_TYPE.GET_SUBCATEGORYSCALES_ERROR,
        });
      });
  };
