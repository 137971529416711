import QueryString from "qs";
import { cmsAPI } from "../../utils/http-client";
import { SUBJECT_PROFILE_ACTION_TYPE } from "../subjectProfile/actions";
import { useEditViewTopic } from "./selectors";

export const EDITFLASHCARDS_VIEW_ACTIONS = {
  FETCH_SUBJECT_START: "FETCH_SUBJECT_START",
  FETCH_SUBJECT_SUCCESS: "FETCH_SUBJECT_SUCCESS",
  FETCH_SUBJECT_ERROR: "FETCH_SUBJECT_ERROR",

  FETCH_SUBJECTS_FORTOPIC_START: "FETCH_SUBJECT_FORTOPIC_START",
  FETCH_SUBJECT_FORTOPIC_SUCCESS: "FETCH_SUBJECT_FORTOPIC_SUCCESS",
  FETCH_SUBJECT_FORTOPIC_ERROR: "FETCH_SUBJECT_FORTOPIC_ERROR",

  FETCH_TOPIC_START: "FETCH_TOPIC_START",
  FETCH_TOPIC_SUCCESS: "FETCH_TOPIC_SUCCESS",
  FETCH_TOPIC_ERROR: "FETCH_TOPIC_ERROR",

  FETCH_FLASHCARDS_START: "FETCH_FLASHCARDS_START",
  FETCH_FLASHCARDS_SUCCESS: "FETCH_FLASHCARDS_SUCCESS",
  FETCH_FLASHCARDS_ERROR: "FETCH_FLASHCARDS_ERROR",

  DELETE_FLASHCARD_START: "DELETE_FLASHCARD_START",
  DELETE_FLASHCARD_SUCCESS: "DELETE_FLASHCARD_SUCCESS",
  DELETE_FLASHCARD_ERROR: "DELETE_FLASHCARD_ERROR",
  DELETE_FLASHCARD_CLEAR: "DELETE_FLASHCARD_CLEAR",

  SUBTITLE_FLASHCARDS_START: "SUBTITLE_FLASHCARDS_START",
  SUBTITLE_FLASHCARDS_SUCCESS: "SUBTITLE_FLASHCARDS_SUCCESS",
  SUBTITLE_FLASHCARDS_ERROR: "SUBTITLE_FLASHCARDS_ERROR",
  SUBTITLE_FLASHCARDS_CLEAR: "SUBTITLE_FLASHCARDS_CLEAR",

  DELETE_MULTI_FLASHCARDS_START: "DELETE_MULTI_FLASHCARDS_START",
  DELETE_MULTI_FLASHCARDS_SUCCESS: "DELETE_MULTI_FLASHCARDS_SUCCESS",
  DELETE_MULTI_FLASHCARDS_ERROR: "DELETE_MULTI_FLASHCARDS_ERROR",
  DELETE_MULTI_FLASHCARDS_CLEAR: "DELETE_MULTI_FLASHCARDS_CLEAR",

  CREATE_MYNEWFLASHCARD_START: "CREATE_MYNEWFLASHCARD_START",
  CREATE_MYNEWFLASHCARD_SUCCESS: "CREATE_MYNEWFLASHCARD_SUCCESS",
  CREATE_MYNEWFLASHCARD_ERROR: "CREATE_MYNEWFLASHCARD_ERROR",
  CREATE_MYNEWFLASHCARD_CLEAR: "CREATE_MYNEWFLASHCARD_CLEAR",

  UPDATE_TOPIC_START: "UPDATE_TOPIC_START",
  UPDATE_TOPIC_SUCCESS: "UPDATE_TOPIC_SUCCESS",
  UPDATE_TOPIC_ERROR: "UPDATE_TOPIC_ERROR",
  UPDATE_TOPIC_CLEAR: "UPDATE_TOPIC_CLEAR",

  UPDATE_FLASHCARD_START: "UPDATE_FLASHCARD_START",
  UPDATE_FLASHCARD_SUCCESS: "UPDATE_FLASHCARD_SUCCESS",
  UPDATE_FLASHCARD_ERROR: "UPDATE_FLASHCARD_ERROR",
  UPDATE_FLASHCARD_CLEAR: "UPDATE_FLASHCARD_CLEAR",

  UPDATE_FLASHCARDS_START: "UPDATE_FLASHCARD_START",
  UPDATE_FLASHCARDS_SUCCESS: "UPDATE_FLASHCARD_SUCCESS",
  UPDATE_FLASHCARDS_ERROR: "UPDATE_FLASHCARD_ERROR",
  UPDATE_FLASHCARDS_CLEAR: "UPDATE_FLASHCARD_CLEAR",

  SET_FLASHCARD_ACTIVE: "SET_FLASHCARD_ACTIVE",
  SET_FLASHCARD_FORM_VALUE: "SET_FLASHCARD_FORM_VALUE",

  REORDER_FLASHCARDS: "REORDER_FLASHCARDS",

  CLONE_MULTIPLE_FLASHCARDS_START: "CLONE_MULTIPLE_FLASHCARDS_START",
  CLONE_MULTIPLE_FLASHCARDS_SUCCESS: "CLONE_MULTIPLE_FLASHCARDS_SUCCESS",
  CLONE_MULTIPLE_FLASHCARDS_ERROR: "CLONE_MULTIPLE_FLASHCARDS_ERROR",
  CLONE_MULTIPLE_FLASHCARDS_CLEAR: "CLONE_MULTIPLE_FLASHCARDS_CLEAR",

  COPY_MULTIPLE_FLASHCARDS_START: "COPY_MULTIPLE_FLASHCARDS_START",
  COPY_MULTIPLE_FLASHCARDS_SUCCESS: "COPY_MULTIPLE_FLASHCARDS_SUCCESS",
  COPY_MULTIPLE_FLASHCARDS_ERROR: "COPY_MULTIPLE_FLASHCARDS_ERROR",
  COPY_MULTIPLE_FLASHCARDS_CLEAR: "COPY_MULTIPLE_FLASHCARDS_CLEAR",

  CUT_MULTIPLE_FLASHCARDS_START: "CUT_MULTIPLE_FLASHCARDS_START",
  CUT_MULTIPLE_FLASHCARDS_SUCCESS: "CUT_MULTIPLE_FLASHCARDS_SUCCESS",
  CUT_MULTIPLE_FLASHCARDS_ERROR: "CUT_MULTIPLE_FLASHCARDS_ERROR",
  CUT_MULTIPLE_FLASHCARDS_CLEAR: "CUT_MULTIPLE_FLASHCARDS_CLEAR",

  PASTE_MULTIPLE_FLASHCARDS_START: "PASTE_MULTIPLE_FLASHCARDS_START",
  PASTE_MULTIPLE_FLASHCARDS_SUCCESS: "PASTE_MULTIPLE_FLASHCARDS_SUCCESS",
  PASTE_MULTIPLE_FLASHCARDS_ERROR: "PASTE_MULTIPLE_FLASHCARDS_ERROR",
  PASTE_MULTIPLE_FLASHCARDS_CLEAR: "PASTE_MULTIPLE_FLASHCARDS_CLEAR",

  CLONE_SINGLE_FLASHCARDS_START: "CLONE_SINGLE_FLASHCARDS_START",
  CLONE_SINGLE_FLASHCARDS_SUCCESS: "CLONE_SINGLE_FLASHCARDS_SUCCESS",
  CLONE_SINGLE_FLASHCARDS_ERROR: "CLONE_SINGLE_FLASHCARDS_ERROR",
  CLONE_SINGLE_FLASHCARDS_CLEAR: "CLONE_SINGLE_FLASHCARDS_CLEAR",
};

//GET
export const fetchTopic = (topicId) => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_START });
  cmsAPI
    .get(`topics/${topicId}`)
    .then(({ data }) => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_SUCCESS,
        topic: data,
      });
    })
    .catch(() => {
      dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_TOPIC_ERROR });
    });
};

export const fetchSubject = (subjectId) => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_START });
  cmsAPI
    .get(`subjects/${subjectId}`)
    .then(({ data }) => {
      dispatch({
        type: SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_SUCCESS,
        subject: data,
      });
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_SUCCESS,
        subject: data,
      });
    })
    .catch(() => {
      dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_ERROR });
    });
};

export const fetchFlashcards = (topicId) => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_START });
  const query = QueryString.stringify({
    _where: [{ topic: topicId }],
    _sort: "order:ASC",
  });
  cmsAPI
    .get(`flashcards?${query}`)
    .then(({ data }) => {
      let indexActive;
      if (data?.length > 0) {
        indexActive = 0;
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_SUCCESS,
          flashcards: data,
          indexActive: indexActive,
        });
      } else {
        cmsAPI
          .post(`/flashcards`, { topic: topicId, order: 0 })
          .then((createResponse) => {
            dispatch({
              type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_SUCCESS,
              flashcards: [createResponse.data],
              indexActive: 0,
            });
          })
          .catch((err) => {
            //console.log("Error", err);
            dispatch({
              type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_ERROR,
            });
          });
      }
    })
    .catch((err) => {
      //console.log("Error", err);
      dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_FLASHCARDS_ERROR });
    });
};

//CREATE FLASHCARD
export const createBrandNewFlashcard = (newFlashcardBody) => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_START });
  cmsAPI
    .post(`/flashcards`, newFlashcardBody)
    .then((responseNewFc) => {
      cmsAPI
        .get(`flashcards?topic=${responseNewFc.data?.topic?.id}&_sort=order:ASC`)
        .then((repsonseFlashcards) => {
          dispatch({
            type: EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_SUCCESS,
            newFlashcard: responseNewFc.data,
            flashcards: repsonseFlashcards.data,
          });
        });
    })
    .catch((err) => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.CREATE_MYNEWFLASHCARD_ERROR,
      });
    });
};
export const nextIndex =
  ({ maxLength, currentIndex, topicId }) =>
  (dispatch) => {
    //console.log(maxLength, currentIndex, topicId);
    if (currentIndex >= maxLength - 1) {
      //console.log("Create");
      dispatch(
        createBrandNewFlashcard({ topic: topicId, order: maxLength + 1 })
      );
    } else {
      //console.log("Next");
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.SET_FLASHCARD_ACTIVE,
        nextIndex: true,
        prevIndex: false,
      });
    }
  };
export const prevIndex =
  ({ currentIndex, nextIndex }) =>
  (dispatch) => {
    if (currentIndex <= 0) return;
    else
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.SET_FLASHCARD_ACTIVE,
        nextIndex: false,
        prevIndex: true,
      });
  };
export const setFlashcardIndexActive = (indexActive) => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.SET_FLASHCARD_ACTIVE,
    indexActive,
  });
};

//EDIT TOPIC
export const editTopic =
  ({ topicId, data }) =>
  (dispatch) => {
    dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_START });
    cmsAPI
      .put(`topics/${topicId}`, data)
      .then(({ data }) => {
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_SUCCESS,
          topic: data,
        });
        ////console.log("Topic edited success");
      })
      .catch((err) => {
        dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_ERROR });
        ////console.log("Topic edited error");
      });
  };

export const reoderFlashcards = (flashcards) => (dispatch) => {
  // //console.log("reorder", flashcards);
  dispatch(sortFlashcards(flashcards));
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.REORDER_FLASHCARDS,
    flashcards,
  });
};
export const sortFlashcards = (flashcards) => (dispatch) => {
  cmsAPI
    .post("/flashcards/saveSort", { flashcards })
    .then((res) => {
      // //console.log("Res", res.data);
    })
    .catch((err) => {
      //console.log("Err", err);
    });
};
//SAVE FLASHCARD UPDATE FLASHCARD
export const updateActiveFlashcard = (flashcard, media) => (dispatch) => {
  if (!flashcard) return;
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_START,
  });
  let fcToSend = Object.assign({}, flashcard);
  //console.log("FCTOSEND", fcToSend);
  let questionmedia = fcToSend.questionmedia;
  let answermedia = fcToSend.answermedia;

  if (questionmedia != null && questionmedia.length)
    delete flashcard.questionmedia;
  else flashcard.questionmedia = [];
  if (answermedia != null && answermedia.length) delete flashcard.answermedia;
  else flashcard.answermedia = [];

  let formData = new FormData();
  if (questionmedia || answermedia) {
    delete flashcard.order;
    formData.append("data", JSON.stringify(flashcard));
    if (questionmedia) formData.append("files.questionmedia", questionmedia);
    if (answermedia) formData.append("files.answermedia", answermedia);
    cmsAPI
      .put(`flashcards/${flashcard.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_SUCCESS,
          savedFlashcard: data,
        });
      })
      .catch((err) => {
        ////console.log("Error", err);

        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_ERROR,
        });
      });
  } else {
    delete flashcard.order;
    cmsAPI
      .put(`flashcards/${flashcard.id}`, flashcard)
      .then(({ data }) => {
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_SUCCESS,
          savedFlashcard: data,
        });
      })
      .catch((err) => {
        ////console.log("Error", err);

        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_ERROR,
        });
      });
  }
  setTimeout(() => {
    dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_FLASHCARD_CLEAR });
  }, 3000);
};

export const clearUpdateTopic = () => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.UPDATE_TOPIC_CLEAR });
};

//Get oficial content creator subjects that can be assigned to a topic
export const fetchSubjectsToSelect = (topicId) => (dispatch) => {
  dispatch({ type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECTS_FORTOPIC_START });
  cmsAPI
    .get(`subjects?owner.isSuperbmind=true`)
    .then(({ data }) => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_FORTOPIC_SUCCESS,
      });
    })
    .catch(() => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.FETCH_SUBJECT_FORTOPIC_ERROR,
      });
    });
};

//DELETE MULTIPLE FLASHCARDS
export const deleteFlashcards =
  (flashcardsToDelete = [], topicId) =>
  (dispatch) => {
    //console.log("Delete Action", flashcardsToDelete.length);
    dispatch({
      type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_START,
    });
    if (flashcardsToDelete.length <= 0) {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_ERROR,
        error_message: "No has seleccionado flashcards",
      });
      return;
    }
    cmsAPI
      .post("flashcards/delete", {
        flashcardsIDs: flashcardsToDelete,
        topicId: topicId,
      })
      .then((res) => {
        //console.log("Delete Success", res.data?.length || 0);
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_SUCCESS,
          flashcards: res.data,
          indexActive: 0,
        });
        setTimeout(() => {
          dispatch({
            type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_CLEAR,
          });
        }, 1500);
      })
      .catch((err) => {
        //console.log("Delete Error", err);
        dispatch({
          type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_MULTI_FLASHCARDS_ERROR,
        });
      });
  };
//DELETE SINGLE FLASHCARD
export const deleteSingleFlashcard = (flashcardId) => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_START,
    deleteSingleFlashcard: {},
  });
  cmsAPI
    .delete(`flashcards/${flashcardId}`)
    .then(({ data }) => {
      //console.log("DataDeleted", data);
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_SUCCESS,
        flashcardDeletedId: data.id,
      });
    })
    .catch(() => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_ERROR,
      });
    });
};
export const deleteSingleFlashcardClear = () => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.DELETE_FLASHCARD_CLEAR,
  });
};
export const updateSubtitleByIDs = (flashcardIDs, subtitle) => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_START,
  });
  cmsAPI
    .post("flashcards/subtitle", { flashcardsIDs: flashcardIDs, subtitle })
    .then((responseSubtitle) => {
      //console.log("RESPONSE SUBTITLE DATA:", responseSubtitle.data);
      cmsAPI
        .get(`flashcards?topic=${responseSubtitle.data[0]?.topic?.id}`)
        .then((repsonseFlashcards) => {
          dispatch({
            type: EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_SUCCESS,
            editedSubtitleFlashcards: responseSubtitle.data,
            flashcards: repsonseFlashcards.data,
          });
        });
    })
    .catch((error) => {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_ERROR,
      });
    });
};
export const clearUpdateSubtitleByIDs = () => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.SUBTITLE_FLASHCARDS_CLEAR,
  });
};
//clipboard
export const cloneSingleFlashcard = (flashcard) => (dispatch) => {
  let clonedFlashcardId = flashcard.id;
  delete flashcard.id;
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_START,
    cloneSingle: {
      clonedId: null,
      clone: null,
      loading: true,
      error: false,
      success: false,
    },
  });
  cmsAPI
    .post("flashcards/clone-single", { originalFlashcardId: clonedFlashcardId })
    .then(({ data }) => {
      //console.log("Fetched");
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_SUCCESS,
        flashcards: data,
        cloneSingle: {
          clonedId: clonedFlashcardId,
          loading: false,
          error: false,
          success: false,
        },
      });
    })
    .catch((err) => {
      //console.log("Error::", err);
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_SINGLE_FLASHCARDS_ERROR,
        cloneSingle: {
          clonedId: null,
          clone: null,
          loading: false,
          error: false,
          success: false,
        },
      });
    });
};
export const cloneFlashcards =
  (flashcardsIDsToClone, topicId, next = () => {}, action) =>
  (dispatch) => {
    //console.log("ACTIONNNNN", flashcardsIDsToClone, action);
    if (flashcardsIDsToClone != null && flashcardsIDsToClone.length > 0) {
      dispatch({
        type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_START,
      });
      cmsAPI
        .post("flashcards/clonemultiple", {
          flashcardsIDs: flashcardsIDsToClone,
          topicId,
          action,
        })
        .then(({ data }) => {
          //console.log("Response::", data);
          dispatch({
            type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_SUCCESS,
            clonedFlashcards: data,
            flashcards: data,
          });
          next();
        })
        .catch((err) => {
          //console.log("Error::", err);
          dispatch({
            type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_ERROR,
          });
          next();
        });
    } else {
      //console.log("No fc id");
      next();
    }
  };
export const cloneFlashcardsClear = () => (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.CLONE_MULTIPLE_FLASHCARDS_CLEAR,
  });
};
//COPY
export const copyFlashcardsToClipboard =
  (flashcardsIDs, topicId) => (dispatch) => {
    let data = {
      mode: "copy",
      topic_id: topicId,
      copy_clipboard: { flashcardsIDs },
      cut_clipboard: { flashcardsIDs: null },
    };
    dispatch({
      type: EDITFLASHCARDS_VIEW_ACTIONS.COPY_MULTIPLE_FLASHCARDS_START,
      flashcards_clipboard: data,
    });
    localStorage.setItem("flashcards_clipboard", JSON.stringify(data));
  };
export const copyFlashcardsToClipboardClear = () => (dispatch) => {
  let data = {
    mode: "",
    topic_id: null,
    copy_clipboard: { flashcardsIDs: null },
    cut_clipboard: { flashcardsIDs: null },
  };
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.COPY_MULTIPLE_FLASHCARDS_CLEAR,
    flashcards_clipboard: data,
  });
  localStorage.setItem("flashcards_clipboard", JSON.stringify(data));
};
//COPY
//CUT
export const cutFlashcardsToClipboard =
  (flashcardsIDs, topicId) => (dispatch) => {
    let data = {
      mode: "cut",
      topic_id: topicId,
      copy_clipboard: { flashcardsIDs: null },
      cut_clipboard: { flashcardsIDs },
    };
    dispatch({
      type: EDITFLASHCARDS_VIEW_ACTIONS.CUT_MULTIPLE_FLASHCARDS_START,
      flashcards_clipboard: data,
    });
    localStorage.setItem("flashcards_clipboard", JSON.stringify(data));
  };
export const cutFlashcardsToClipboardClear = (flashcardsIDs) => (dispatch) => {
  let data = {
    topic_id: null,
    copy_clipboard: { flashcardsIDs: null },
    cut_clipboard: { flashcardsIDs: null },
  };
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.CUT_MULTIPLE_FLASHCARDS_CLEAR,
    flashcards_clipboard: data,
  });
  localStorage.setItem("flashcards_clipboard", JSON.stringify(data));
};
//CUT

export const pasteFlashcards = (topicId) => async (dispatch) => {
  dispatch({
    type: EDITFLASHCARDS_VIEW_ACTIONS.PASTE_MULTIPLE_FLASHCARDS_START,
  });
  //console.log("Paste actions", flashcardsIDs, mode, topicId);
  let data = await localStorage.getItem("flashcards_clipboard");
  data = JSON.parse(data);
  let { mode } = data;
  if (mode == "copy") {
    let { flashcardsIDs } = data.copy_clipboard;
    dispatch(
      cloneFlashcards(
        flashcardsIDs,
        topicId,
        () => {
          dispatch(copyFlashcardsToClipboardClear());
        },
        mode
      )
    );
  }
  if (mode == "cut") {
    let { flashcardsIDs } = data.cut_clipboard;
    dispatch(
      cloneFlashcards(
        flashcardsIDs,
        topicId,
        () => {
          dispatch(cutFlashcardsToClipboardClear());
        },
        mode
      )
    );
  } else return;
};
//clipboard
