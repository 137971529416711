import React from "react";
import { SubjectTopicsList } from "../../../components/SubjectTopicsList";

//v1 deprecated
const TopicsList = (props) => {
  return (
    <>
      <SubjectTopicsList subjectId={props.subjectId} />
    </>
  );
};
export default TopicsList;
