import React, { Component, useEffect, useState, Style } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cmsAPI } from "../../../utils/http-client";
import { SubjectTopicsList } from "../../../components/SubjectTopicsList";
import { withRouter, Link, useHistory } from "react-router-dom";
import * as SubjectsTopicsActions from "../actions";
import { SubjectListSkeleton } from "../../../components/SubjectListSkeleton";
import PropTypes from "prop-types";
import Moment from "react-moment";
import FlashcardsList from "../components/TopicsList";
import TopicsList from "../components/TopicsList";
import ModalAlert from "../../../components/ModalAlert";
import { ProgressPie } from "../../../components/ProgressPie";
import SubjectProfileHeader from "../components/SubjectProfileHeader";
import SubjectTopicList from "../components/SubjectTopicList";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { useSPTopicsCount } from "../selectors";

const SubjectProfile = (props) => {
  const history = useHistory();
  const [setLegacy, legacyOn] = useState(false);
  const [ModalAlertOpen, setModalAlertOpen] = useState(false);
  const [selectedExternalTopics, setSelectedExternalTopics] = useState([]);
  const { subject, fetchingSubject, subjectError } = props;
  const [activeTab, setActiveTab] = useState("1");
  const [SelectedFlashcardsCount, setSelectedFlashcardsCount] = useState(0);
  const topicCount = useSPTopicsCount();
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  if (props.fetching) {
    return <SubjectListSkeleton />;
  }
  if (!fetchingSubject && subjectError) {
    return (
      <div className="vertical-center">
        <div className="container">
          <div className="text-center">
            <h2>
              Parece que{" "}
              {subjectError
                ? "ha ocurrido un error"
                : "no encontramos lo que buscabas"}
            </h2>
            <Link to="/subjects" className="btn btn-primary">
              Vuelve atrás!
            </Link>
          </div>
        </div>
      </div>
    );
  }
  const { id, name, description, image, createdDate } = subject;
  let getImage = "";
  if (image) {
    getImage = cmsAPI.resolveAssetAPI(image.url);
  }
  return (
    <div style={{ backgroundColor: "white" }}>
      <SubjectProfileHeader
        selectedTopics={selectedExternalTopics}
        selectedFlashcardsCount={SelectedFlashcardsCount}
      />
      <hr style={{ width: "100%" }} />
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              {`Pilas ${topicCount ? "(" + topicCount + ")" : ""}`}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              Discentes
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => {
                toggle("3");
              }}
            >
              Acerca de
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane style={{ width: "100%" }} tabId="1">
            <Row>
              <Col sm="12">
                <SubjectTopicList
                  setSelectedFlashcardsCount={setSelectedFlashcardsCount}
                  SelectedFlashcardsCount={SelectedFlashcardsCount}
                  externalSelected={(selected) => {
                    setSelectedExternalTopics(selected);
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <h1>Discentes</h1>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <h1>Acerca de</h1>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};
SubjectProfile.propTypes = {
  fetchingSubject: PropTypes.bool,
  subjectError: PropTypes.bool,
  subject: PropTypes.object,
};

export default SubjectProfile;
