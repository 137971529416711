export const ThemeColors = {
  primaryBg: "#003366",
  secondaryBg: "#003F7E",
  themePrimary: "#1f283e",
  gold: "#FFDF00",
  primary: "#4791f4",
  secondary: "#74b5ef",
  searchBg: "#969696",
  success: "#05c308",
  danger: "#ff595e",
  confidentColors: {
    1:"#13c1d4",
    2:"#a712c0",
    3:"#06d6a0",
    4:"#ffd166",
    5:"#ef476f",
    0:"#CCCCCC",
  }
};
