import React from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
const NavLinks = (props) =>{
    const {
        name,
        path
    } = props;
    let location = useLocation();
    //console.log("LocationPathName",location.pathname,">Path",path);
    return (
        <li className={"nav-item "+(path === location.pathname ? "active" : "")}>
            <Link className="nav-link" to={path}>{name}{ path === location.pathname ? <span className="sr-only">(current)</span> : ""}</Link>
        </li>
    )
}
export default withRouter(NavLinks);