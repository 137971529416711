import { ADMIN_USERSCALES_ACTION_TYPE } from "./actions";

export const adminUserScalesInitialState = {
  createUserScale: {
    success: false,
    error: false,
    loading: false,
    newestCreated: null,
  },
  deleteUserScale: {
    success: false,
    error: false,
    loading: false,
  },
  editUserScale: {
    success: false,
    error: false,
    loading: false,
    editedScale: null,
  },
  userscales: [],
  success: false,
  error: false,
  loading: true,
};
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_START:
      return {
        ...state,
        createUserScale: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_SUCCESS:
      return {
        ...state,
        createUserScale: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.CREATE_USERSCALES_ERROR:
      return {
        ...state,
        createUserScale: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_START:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        ...payload,
      };
    case ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        ...payload,
      };
    case ADMIN_USERSCALES_ACTION_TYPE.GET_USERSCALES_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        ...payload,
      };
    case ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_START:
      return {
        ...state,
        editUserScale: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_SUCCESS:
      return {
        ...state,
        editUserScale: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_ERROR:
      return {
        ...state,
        editUserScale: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.EDIT_USERSCALES_CLEAR:
      return {
        ...state,
        editUserScale: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_START:
      return {
        ...state,
        deleteUserScale: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_SUCCESS:
      return {
        ...state,
        deleteUserScale: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_ERROR:
      return {
        ...state,
        deleteUserScale: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_USERSCALES_ACTION_TYPE.DELETE_USERSCALES_CLEAR:
      return {
        ...state,
        deleteUserScale: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
