import React, { Component, createRef } from "react";
import NotificationAlert from "react-notification-alert";
import AlertNotify, { notify } from "../../components/AlertNotify";
//import CardSection from "../../components/CardSection/CardSection";
//import CheckoutForm from "../../components/CardSection/Checkout";
import SuscriptionSelect from "../../components/SuscriptionSelect";
import { AuthenticateLayout } from "../../layouts/AuthenticateLayout/AuthenticateLayout";
class SuscriptionView extends Component {
  notificationAlert = createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    //notify("primary", "Testing notifications alerts", this.notificationAlert);
  }
  render() {
    // if (1 > 0) {
    //   return <CheckoutForm />;
    // }
    return (
      <AuthenticateLayout>
        <NotificationAlert ref={this.notificationAlert} />
        <SuscriptionSelect />
      </AuthenticateLayout>
    );
  }
}

export default SuscriptionView;
