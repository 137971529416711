import { SIGNUP_ACTION_TYPE } from "./actions";

export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case SIGNUP_ACTION_TYPE.SIGNUP_START:
      return {
        error: false,
        loading: true,
      };
    case SIGNUP_ACTION_TYPE.SIGNUP_SUCCESS:
      return {
        error: false,
        signUpSuccess: true,
        loading: false,
        errorID: "success",
      };
    case SIGNUP_ACTION_TYPE.SIGNUP_ERROR:
      return {
        loading: false,
        error: true,
        ...payload,
      };
    case SIGNUP_ACTION_TYPE.CLEAR_ERROR:
      return {
        error: false,
        status: null,
        loading: false,
      };
    case SIGNUP_ACTION_TYPE.SIGNUP_END:
      return {
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
