import React from "react";
import Skeleton from "react-loading-skeleton";
const FlashcardFormSkeleton = () => {
  return (
    <div className="row">
      <div className="col-md-12 col-lg-6">
        <Skeleton width={"100%"} height={"40vh"} />
      </div>
      <div className="col-md-12 col-lg-6">
        <Skeleton width={"100%"} height={"40vh"} />
      </div>
    </div>
  );
};

export default FlashcardFormSkeleton;
