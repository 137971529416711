import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { Modal, Button, Input, Spinner, Label } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import {
  useSuperbmindUser,
  useUser,
  useUserIsAdmin,
} from "../../redux/selectors";
import { clearState, createSubject, toggleModal } from "./actions";
import Switch from "react-switch";
import ImagePicker from "../../components/ImagePicker";
import {
  useCreatedSubject,
  useCreating,
  useCreatingError,
  useCreatingSuccess,
} from "./selector";
import ReactLoading from "react-loading";
import Select from "react-select";
import { ThemeColors } from "../../lib/Colors";
import { cmsAPI } from "../../utils/http-client";
import { getSuperbmindUser } from "../../views/login/actions";
import { fetchCategoriesDD } from "../CategoriesDD/actions";
import {
  useCategoriesDD,
  useErrorCategories,
  useFetchingCategories,
  useSuccessCategories,
} from "../CategoriesDD/selectors";
import { editSubjectName } from "../../views/subjectProfile/actions";
import {
  useSubjectEditLoading,
  useSubjectEditSuccess,
  useSubjectEditError,
  useSubject,
} from "../../views/subjectProfile/selectors";
import { isAdmin } from "../../utils/isAdmin";

const CreateSubjectModal = ({
  buttonLabel,
  modalTitle,
  toggleComponent,
  toggle,
  isOpen = false,
  className,
  editMode,
  subjectToEdit,
  hideToggleDefault = true,
}) => {
  const user = useUser();
  const [currentSubject, setCurrentSubject] = useState(subjectToEdit);
  const superbmindUser = useSuperbmindUser();
  const categoriesArr = useCategoriesDD();
  const fetchingCategories = useFetchingCategories();
  const fetchingCatError = useErrorCategories();
  const fetchingCatSuccess = useSuccessCategories();
  const EditLoading = useSubjectEditLoading();
  const EditSuccess = useSubjectEditSuccess();
  const EditError = useSubjectEditError();
  const [name, setName] = useState("");
  const subjectProfile = useSubject();
  const [subjectImage, setSubjectImage] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [officialSubject, setOfficialSubject] = useState(false);
  const [publicSubject, setPublicSubject] = useState(false);
  const [isPrimarySubject, setPrimarySubject] = useState(false);
  const [FetchingTypes, setFetchingTypes] = useState(false);
  const [parentSubjects, setParentSubjects] = useState([]);
  const [childrenSubjects, setchildrenSubjects] = useState([]);
  const [SubjectType, setSubjectType] = useState({});
  const [SubjectSubCategory, setSubjectSubCategory] = useState([]);
  const [AllTypes, setTypesData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [fetchingSubCategory, setFetchingSubCategory] = useState(true);
  const [FetchingUserScales, setFetchingUserScales] = useState(false);
  const [UserScales, setUserScales] = useState([]);
  const [UserScalesSelected, setUserScalesSelected] = useState([]);

  const [officialDisabled, setOfficialDisabled] = useState(false);
  const [superbmindSubjects, setSuperbmindSubjects] = useState([]);
  const [ImageFile, setImageFile] = useState([]);
  const [fetchingSubjects, setFetchingSubjects] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const creatingLoading = useCreating();
  const creatingSuccess = useCreatingSuccess();
  const creatingError = useCreatingError();
  const newSubject = useCreatedSubject();
  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    if (editMode && currentSubject?.id) {
      console.log("Edit");
      let subjectToSave = {
        name,
        image: ImageFile,
        public: publicSubject,
        primary: isPrimarySubject,
        categories: selectedCategories,
        children: childrenSubjects,
        in_subjects: parentSubjects,
        parent: parentSubjects.length >= 1 ? parentSubjects[0] : null,
        officialSubject,
        userscales: UserScalesSelected,
        sub_categories: SubjectSubCategory,
      };
      dispatch(editSubjectName(currentSubject.id, subjectToSave));
      return;
    }
    let owner = user.id;
    if (officialSubject) {
      owner = superbmindUser.id;
    }
    dispatch(
      createSubject({
        name,
        image: ImageFile,
        public: publicSubject,
        primary: isPrimarySubject,
        type: SubjectType,
        categories: selectedCategories,
        children: childrenSubjects,
        in_subjects: parentSubjects,
        officialSubject,
        owner,
        userscales: UserScalesSelected,
        sub_categories: SubjectSubCategory,
      })
    );
    return;
  };
  useEffect(() => {}, []);
  const getTypes = () => {
    setFetchingTypes(true);
    cmsAPI
      .get("types")
      .then((res) => {
        setFetchingTypes(false);
        setTypesData(res.data);
      })
      .catch((err) => {
        setFetchingTypes(false);
        setTypesData([]);
      });
  };
  const getUserScales = () => {
    setFetchingUserScales(true);
    cmsAPI
      .get("userscales?_limit=9999")
      .then((res) => {
        setFetchingUserScales(false);
        setUserScales(res.data);
      })
      .catch((err) => {
        setFetchingUserScales(false);
        setUserScales([]);
      });
  };
  const getSubcategories = () => {
    setFetchingSubCategory(true);
    cmsAPI
      .get("sub-categories?_limit=9999")
      .then((res) => {
        setFetchingSubCategory(false);
        setSubCategoryData(res.data);
      })
      .catch((err) => {
        setFetchingSubCategory(false);
        setSubCategoryData([]);
      });
  };
  const editSubject = () => {
    dispatch(editSubjectName(currentSubject.id, subjectToEdit));
  };
  const getSuperbmindSubjects = () => {
    if (superbmindUser?.id) {
      console.log("getting superbmind");
      setFetchingSubjects(true);
      cmsAPI
        .get(`subjects?owner=${superbmindUser?.id}`)
        .then(({ data }) => {
          setFetchingSubjects(false);
          setSuperbmindSubjects(data);
        })
        .catch((error) => {
          setFetchingSubjects(false);
        });
    }
  };
  useEffect(() => {
    getTypes();
    getUserScales();
    getSubcategories();
    dispatch(fetchCategoriesDD());
    dispatch(getSuperbmindUser());
  }, []);
  useEffect(() => {
    if (!currentSubject && editMode) {
      async function fetchEditData() {
        await cmsAPI
          .get(`subjects/${subjectToEdit.id}`)
          .then(({ data }) => {
            console.log("GET", data);
            setCurrentSubject(data);
            if (
              data != null &&
              data?.userscales != null &&
              data?.userscales.length > 0
            ) {
              let defaultActive = data?.userscales.map((s) => {
                s.value = s.id;
                s.label = s.name;
                return s;
              });
              setUserScalesSelected(defaultActive || []);
            }
            if (
              data != null &&
              data?.sub_categories != null &&
              data?.sub_categories.length > 0
            ) {
              let defaultActive = data?.sub_categories.map((s) => {
                s.value = s.id;
                s.label = s.name;
                return s;
              });
              setSubjectSubCategory(defaultActive || []);
            }
            if (data != null && data?.type != null) {
              let t = data?.type;
              t.value = t.id;
              t.label = t.name;
              let defaultActive = t;
              setSubjectType(defaultActive || null);
            }
          })
          .catch((error) => {
            console.log("error getting subject to edit");
          });
      }
      fetchEditData();
    } else if (currentSubject) {
      setCurrentSubject(subjectToEdit);
      if (
        subjectToEdit != null &&
        subjectToEdit?.userscales != null &&
        subjectToEdit?.userscales.length > 0
      ) {
        let defaultActive = subjectToEdit?.userscales.map((s) => {
          s.value = s.id;
          s.label = s.name;
          return s;
        });
        setUserScalesSelected(defaultActive || []);
      }
      if (
        subjectToEdit != null &&
        subjectToEdit?.sub_categories != null &&
        subjectToEdit?.sub_categories.length > 0
      ) {
        let defaultActive = subjectToEdit?.sub_categories.map((s) => {
          s.value = s.id;
          s.label = s.name;
          return s;
        });
        setSubjectSubCategory(defaultActive || []);
      }
      if (subjectToEdit != null && subjectToEdit?.type != null) {
        let t = subjectToEdit?.type;
        t.value = t.id;
        t.label = t.name;
        let defaultActive = t;
        setSubjectType(defaultActive || null);
      }
    }
    if (editMode && currentSubject) {
      console.log("ASSIGN DATA", currentSubject);
      let {
        name,
        image,
        categories,
        owner,
        primary,
        parent,
        children,
        in_subjects,
      } = currentSubject;
      let isPublic = currentSubject.public;
      let parentOption = {};
      if (parent)
        parentOption = { ...parent, value: parent?.id, label: parent.name };
      setName(name);
      setSubjectImage(image);
      setSelectedCategories(
        categories.map((cat) => {
          cat.value = cat.id;
          cat.label = cat.name;
          return cat;
        })
      );
      if (superbmindUser?.id == owner?.id) {
        setOfficialSubject(true);
        setOfficialDisabled(true);
      }
      setPublicSubject(isPublic);
      setPrimarySubject(primary);

      setParentSubjects(
        in_subjects.map((subject) => {
          subject.value = subject.id;
          subject.label = subject.name;
          return subject;
        })
      );
      setchildrenSubjects(
        children.map((child) => {
          child.value = child.id;
          child.label = child.name;
          return child;
        })
      );
    }
  }, [editMode, currentSubject]);
  useEffect(() => {
    getSuperbmindSubjects();
  }, [superbmindUser]);
  useEffect(() => {
    if (newSubject) {
      //console.log("Push To", newSubject.id);
      history.push(`/subjects/${newSubject.id}`);
    }
    dispatch(clearState());
  }, [newSubject]);
  const handleInput = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    setName(value);
  };
  const toggleAction = () => {
    if (toggle) {
      toggle();
    } else {
      dispatch(toggleModal());
    }
  };
  const renderCreateButtonContent = () => {
    if (creatingSuccess || EditSuccess) return <i className="fas fa-check" />;
    if (creatingLoading || EditLoading) return "Cargando...";
    if (editMode) return "Guardar";
    return "Crear";
  };
  return (
    <div>
      {toggleComponent ||
        (!hideToggleDefault && (
          <Button color="danger" onClick={toggle}>
            {buttonLabel}
          </Button>
        ))}
      <Modal
        centered={true}
        isOpen={isOpen}
        toggle={toggleAction}
        className={className}
      >
        <ModalHeader toggle={toggleAction}>Nueva materia</ModalHeader>
        <ModalBody className={"text-center"}>
          <h1>{editMode ? "Editando materia" : "Crea una materia nueva"}</h1>
          {!editMode && (
            <p>
              una materia es un contenedor de pilas las cuales contienen
              flashcards
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <Label className="mt-4" for="name">
              Nombre
            </Label>
            <Input
              tabIndex={1}
              autoFocus
              required
              name="name"
              value={name}
              onChange={handleInput}
              placeholder="e.g. Biología, Ley militar"
              invalid={creatingError}
              valid={creatingSuccess}
            />
            <Label className="mt-4" for="image">
              Imagen
            </Label>
            <ImagePicker
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              tabIndex={-1}
              id={"subjectImagePicker"}
              name="subjectImage"
              data={subjectImage}
              setData={(data, file) => {
                console.log("IMAGE DATA:::", data, "File:", file);
                if (data) {
                  setImageFile(file);
                  setSubjectImage(data);
                } else setSubjectImage(null);
              }}
            />
            {user.isAdmin && (
              <>
                <Label className="mt-4" for="inSubjects">
                  Categoria
                </Label>
                {fetchingCategories || !categoriesArr?.length ? (
                  <div className="d-flex justify-content-center">
                    <ReactLoading
                      type={"spin"}
                      color={ThemeColors.primary}
                      height={20}
                      width={20}
                    />
                  </div>
                ) : (
                  <Select
                    onChange={(data) => {
                      console.log("Data", data);
                      setSelectedCategories(data);
                    }}
                    closeMenuOnSelect={false}
                    isMulti
                    value={selectedCategories}
                    hideSelectedOptions
                    options={categoriesArr.map((category) => {
                      // if (selectedCategories.includes(category) === true) return;
                      category.value = category.id;
                      category.label = category.name;
                      return category;
                    })}
                  />
                )}
                <>
                  <Label className="mt-4" for="inSubjects">
                    Sub categoria
                  </Label>
                  {fetchingSubCategory ? (
                    <div className="d-flex justify-content-center">
                      <ReactLoading
                        type={"spin"}
                        color={ThemeColors.primary}
                        height={20}
                        width={20}
                      />
                    </div>
                  ) : (
                    <Select
                      hideSelectedOptions
                      isMulti
                      onChange={(data) => {
                        setSubjectSubCategory(data);
                      }}
                      value={SubjectSubCategory}
                      closeMenuOnSelect={false}
                      defaultValue={SubjectSubCategory}
                      options={subCategoryData.map((type) => {
                        type.value = type.id;
                        type.label = type.name;
                        return type;
                      })}
                    />
                  )}
                </>
                <>
                  <Label className="mt-4" for="inSubjects">
                    Grado
                  </Label>
                  {FetchingUserScales ? (
                    <div className="d-flex justify-content-center">
                      <ReactLoading
                        type={"spin"}
                        color={ThemeColors.primary}
                        height={20}
                        width={20}
                      />
                    </div>
                  ) : (
                    <Select
                      hideSelectedOptions
                      isMulti
                      onChange={(data) => {
                        setUserScalesSelected(data);
                      }}
                      value={UserScalesSelected}
                      defaultValue={UserScalesSelected}
                      closeMenuOnSelect={false}
                      options={UserScales.map((type) => {
                        type.value = type.id;
                        type.label = type.name;
                        return type;
                      })}
                    />
                  )}
                </>
                <div
                  className="text-center d-flex flex-column mt-4"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label>Materia oficial de Superbmind</label>
                  <Switch
                    disabled={
                      officialDisabled || user.role.type == "superbmind"
                    }
                    onColor={ThemeColors.primary}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setOfficialSubject(!officialSubject)}
                    checked={officialSubject || user.role.type == "superbmind"}
                  />
                </div>
                <div
                  className="text-center d-flex flex-column mt-4"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <label>Público</label>
                  <Switch
                    onColor={ThemeColors.primary}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onChange={() => setPublicSubject(!publicSubject)}
                    checked={publicSubject}
                  />
                </div>
                {isPrimarySubject && (
                  <>
                    <Label className="mt-4" for="inSubjects">
                      Sección:
                    </Label>
                    {FetchingTypes ? (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          type={"spin"}
                          color={ThemeColors.primary}
                          height={20}
                          width={20}
                        />
                      </div>
                    ) : (
                      <Select
                        hideSelectedOptions
                        onChange={(data) => {
                          setSubjectType(data);
                        }}
                        value={SubjectType}
                        defaultValue={SubjectType}
                        closeMenuOnSelect={false}
                        options={AllTypes.map((type) => {
                          type.value = type.id;
                          type.label = type.name;
                          return type;
                        })}
                      />
                    )}
                  </>
                )}
                {(!parentSubjects || parentSubjects?.length <= 0) && (
                  <div
                    className="text-center d-flex flex-column mt-4"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label>Sección primaria</label>
                    <Switch
                      onColor={ThemeColors.primary}
                      checkedIcon={false}
                      uncheckedIcon={false}
                      onChange={() => setPrimarySubject(!isPrimarySubject)}
                      checked={isPrimarySubject}
                    />
                  </div>
                )}
                {!isPrimarySubject && (
                  <>
                    <Label className="mt-4" for="inSubjects">
                      Se encuentra en:
                    </Label>
                    {fetchingSubjects ? (
                      <div className="d-flex justify-content-center">
                        <ReactLoading
                          type={"spin"}
                          color={ThemeColors.primary}
                          height={20}
                          width={20}
                        />
                      </div>
                    ) : (
                      <Select
                        isMulti
                        hideSelectedOptions
                        onChange={(data) => {
                          setParentSubjects(data);
                        }}
                        value={parentSubjects}
                        closeMenuOnSelect={false}
                        options={superbmindSubjects.map((subject) => {
                          subject.value = subject.id;
                          subject.label = subject.name;
                          return subject;
                        })}
                      />
                    )}
                  </>
                )}
                <Label className="mt-4" for="inSubjects">
                  Contiene a:
                </Label>
                {fetchingSubjects ? (
                  <div className="d-flex justify-content-center">
                    <ReactLoading
                      type={"spin"}
                      color={ThemeColors.primary}
                      height={20}
                      width={20}
                    />
                  </div>
                ) : (
                  <Select
                    hideSelectedOptions
                    isMulti
                    onChange={(data) => {
                      setchildrenSubjects(data);
                    }}
                    closeMenuOnSelect={false}
                    value={childrenSubjects}
                    options={superbmindSubjects.map((subject) => {
                      subject.value = subject.id;
                      subject.label = subject.name;
                      return subject;
                    })}
                  />
                )}
              </>
            )}
            <input
              disable={creatingLoading}
              autoFocus
              type="submit"
              style={{ display: "none" }}
              onClick={(e) => {
                handleSubmit(e);
              }}
            />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              toggleAction();
              dispatch(clearState());
            }}
            color="danger"
          >
            Cerrar
          </Button>
          <Button
            autoFocus
            type="submit"
            color={creatingSuccess ? "success" : "primary"}
            onClick={() => handleSubmit()}
            disable={creatingLoading}
          >
            {renderCreateButtonContent()}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default CreateSubjectModal;
