import React, { Component } from "react";
class SecurityProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        email: "",
        prev_password: "",
        new_password: "",
        repeat_password: "",
      },
      alertOpen: false,
      alertMessage: "Ha ocurrido un error",
      alertType: "danger",
    };
  }
  componentDidUpdate = (prevProps) => {
   //console.log("Prev", prevProps);
    if (!prevProps.showAlert && this.props.showAlert) {
      this.setState({ alertOpen: true });
    }
    if (prevProps.showAlert && !this.props.showAlert) {
      this.setState({ alertOpen: false });
    }
    if (!prevProps.editProfileSuccess && this.props.editProfileSuccess) {
      // setUser(
      //   { ...this.props.user, jwt: this.props.jwt, logged: true },
      //   this.props.cookies
      // );
      this.setState({ alertType: "success" });
    }
    if (!prevProps.editProfileError && this.props.editProfileError) {
      this.setState({ alertType: "danger" });
    }
  };
  setAlertMessage = () => {
    if (this.props.errorID) {
      switch (this.props.errorID) {
        case "Auth.form.error.email.taken":
          return "La cuenta de email ya está en uso";
        case "Auth.form.error.username.taken":
          return "Nombre de usuario ya está en uso";
        case "Auth.form.error.ratelimit":
          return "Has hecho muchas consultas, espere...";
        default:
          return;
      }
    }
    if (this.props.editProfileStatus == 200 || this.props.editProfileSuccess) {
      return "Editado con exito";
    } else if (this.state.alertMessage) {
      return this.state.alertMessage;
    }
    this.setState({ alertMessage: "" });
  };
  componentDidMount() {
   //console.log("DidMount", this.props);
    this.setState({ form: { ...this.props.user } });
  }
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
   //console.log(target.file);
   //console.log("Name:", name, "Value:", value);
    this.setState({ form: { [name]: value } });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
        prev_password,
        new_password,
        repeat_password
      } = this.state;
    if (!prev_password || !new_password || !repeat_password) {
     //console.log("Llena campos");
      this.setState({
        alertOpen: true,
        alertMessage: "Llena todos los campos",
      });
      return;
    }
    if (new_password == repeat_password && prev_password) {
      let form = { new_password };
     //console.log("Form", form);
      this.props.signUp(form);
    }
  };
  toggleAlert = () => {
    this.setState({ alertOpen: false });
    this.props.clearError();
  };
  render() {
    const { form } = this.state;
    return (
      <>
        <form className="form" action="##" method="post" id="updateProfile">
          <div className="text-center">
            <h5 className="mb-2">CAMBIAR CONTRASEÑA</h5>
          </div>
          <div className="form-group">
            <div className="col-xs-6">
              <label>Contraseña Actual</label>
              <div className="form-group pass_show">
                <input
                  type="password"
                  onChange={this.handleChange}
                  value={form.prev_password}
                  className="form-control"
                  placeholder="Contraseña Actual"
                />
              </div>
              <label>Nueva Contraseña</label>
              <div className="form-group pass_show">
                <input
                  type="password"
                  onChange={this.handleChange}
                  value={form.new_password}
                  className="form-control"
                  placeholder="Nueva Contraseña"
                />
              </div>
              <label>Confirmar Contraseña</label>
              <div className="form-group pass_show">
                <input
                  type="password"
                  onChange={this.handleChange}
                  value={form.repeat_password}
                  className="form-control"
                  placeholder="Confirmar Contraseña"
                />
              </div>
              <div className="form-group">
                <div className="col-xs-12">
                  <br />
                  <button
                    className="shadow btn btn-md btn-primary"
                    type="submit"
                  >
                    Cambiar contraseña
                  </button>
                  <button className="shadow ml-2 btn btn-md" type="reset">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}

export default SecurityProfileDetails;
