import React, { Component } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
class StartForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      invalidAmount: false,
      id:""
    };
  }
  componentDidMount() {
   //console.log("PARAMS",this.props)
    const { match:{params} } = this.props;
    this.setState({
      amount: this.props.count,
      id:params.userTopicId
    });
  }
  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    //console.log("name", name, "value", value);
    this.setState({
      invalidAmount: false,
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { amount } = this.state;
    if (amount <= 0 || amount > this.props.count) {
      this.setState({
        invalidAmount: true,
      });
      return;
    }
    this.setState({
      invalidAmount: false,
    });
    return;
  };
  render() {
    let amountStyle = cx({
      "form-control": true,
      "is-invalid": this.state.invalidAmount,
    });
    const { amount,id } = this.state;
    return (
      <div className="vertical-center">
        <div className="shadow container p-3">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">
                Selecciona el numero de flashcards que quieras repasar
              </label>
              <input
                onChange={this.handleChange}
                value={amount ? amount : ""}
                type="text"
                className={amountStyle}
                id="amount"
                name="amount"
                aria-describedby="textHelp"
                placeholder="Cantidad"
              />
              <div className="invalid-feedback">
                Ingresa una cantidad entre el rango.
              </div>
              <small id="amount" className="form-text text-muted">
                Del 1 al {this.props.count}.
              </small>
            </div>
            <Link to={`/myflashcards/match/start/${id}`}>
              <button type="submit" className="btn btn-primary">
                Empezar
              </button>
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

export default StartForm;
