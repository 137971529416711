import { SUBJECT_PROFILE_ACTION_TYPE } from "./actions";
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_START:
      return {
        loading: true,
        requestSuccess: false,
        requestError: false,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_SUCCESS:
      return {
        loading: false,
        requestSuccess: true,
        requestError: false,
        subject: { ...payload.subject },
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_ERROR:
      return {
        loading: false,
        requestSuccess: false,
        requestError: true,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_END:
      return {
        loading: false,
        requestSuccess: false,
        requestError: false,
        ...payload,
      };
    //SUBJECT TOPICS
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_START:
      return {
        ...state,
        loadingST: true,
        requestSuccessST: false,
        requestErrorST: false,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_SUCCESS:
      return {
        ...state,
        loadingST: false,
        requestSuccessST: true,
        requestErrorST: false,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_ERROR:
      return {
        ...state,
        loadingST: false,
        requestSuccessST: false,
        requestErrorST: true,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.GET_SUBJECT_TOPICS_END:
      return {
        ...state,
        loadingST: false,
        requestSuccessST: false,
        requestErrorST: false,
        ...payload,
      };
    //FLASHCARDS
    case SUBJECT_PROFILE_ACTION_TYPE.SAVE_IN_MYFLASHCARDS_START:
      return {
        loadingFC: true,
        alert: true,
        alertMessage: "Guardando en Mis Flashcards",
      };
    case SUBJECT_PROFILE_ACTION_TYPE.SAVE_IN_MYFLASHCARDS_SUCCESS:
      return {
        ...state,
        loadingFC: false,
        uTopic: [...payload.uTopic],
        successFC: true,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.SAVE_IN_MYFLASHCARDS_ERROR:
      return {
        ...state,
        loadingFC: false,
        errorFC: {
          id: payload.errorId,
          message: "Ocurrió un error al guardarlo en Mis Flashcards",
        },
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.PUSH_SP_TOPIC:
      return {
        ...state,
        subjectTopics: state.subjectTopics != null && state.subjectTopics.length > 0 ? [...state.subjectTopics, payload.newTopic] : [payload.newTopic],
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_START:
      return {
        ...state,
        editSubject: {
          loading: true,
        },
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_SUCCESS:
      return {
        ...state,
        editSubject: {
          loading: false,
          success: true,
        },
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_ERROR:
      return {
        ...state,
        editSubject: {
          loading: false,
          success: false,
          error: true,
        },
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.EDIT_SUBJECT_NAME_CLEAR:
      return {
        ...state,
        editSubject: {},
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.SAVE_IN_MYFLASHCARDS_END:
      return {
        loadingFC: false,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_START:
      return {
        ...state,
        deleteTopicLoading: true,
        deleteTopicSuccess: null,
        deleteTopicError: null,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_SUCCESS:
      let StateTopics = [];
      StateTopics = state.subjectTopics;
      let indexToDelete = StateTopics.findIndex(
        (i) => i.id === payload.deletedTopic.id
      );
      StateTopics.splice(indexToDelete, 1);
      console.log("DeleteIndex", indexToDelete, StateTopics);
      return {
        ...state,
        deleteTopicLoading: false,
        deleteTopicSuccess: true,
        deleteTopicError: false,
        subjectTopics: [...StateTopics],
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_ERROR:
      return {
        ...state,
        deleteTopicLoading: false,
        deleteTopicSuccess: false,
        deleteTopicError: true,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.DELETE_SP_TOPIC_CLEAR:
      return {
        ...state,
        deleteTopicLoading: false,
        deleteTopicSuccess: null,
        deleteTopicError: null,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_START:
      return {
        ...state,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case SUBJECT_PROFILE_ACTION_TYPE.REMOVE_SUBJECT_ERROR:
      return {
        ...state,
        ...payload,
      };
    default:
      return { ...state };
  }
};

export default reducer;
