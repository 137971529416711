import { ADMIN_CATEGORY_ACTION_TYPE } from "./actions";

export const adminCategoryInitialState = {
  createCategory: {
    success: false,
    error: false,
    loading: false,
    newestCreated: null,
  },
  deleteCategory: {
    success: false,
    error: false,
    loading: false,
  },
  editCategory: {
    success: false,
    error: false,
    loading: false,
    editedScale: null,
  },
  categories: [],
  success: false,
  error: false,
  loading: true,
};
export const reducer = (state = {}, action) => {
  const { type, ...payload } = action;
  switch (type) {
    case ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_START:
      return {
        ...state,
        createCategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        createCategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.CREATE_CATEGORY_ERROR:
      return {
        ...state,
        createCategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_START:
      return {
        ...state,
        success: false,
        error: false,
        loading: true,
        ...payload,
      };
    case ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        ...payload,
      };
    case ADMIN_CATEGORY_ACTION_TYPE.GET_CATEGORY_ERROR:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        ...payload,
      };
    case ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_START:
      return {
        ...state,
        editCategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        editCategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_ERROR:
      return {
        ...state,
        editCategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.EDIT_CATEGORY_CLEAR:
      return {
        ...state,
        editCategory: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_START:
      return {
        ...state,
        deleteCategory: {
          success: false,
          error: false,
          loading: true,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        deleteCategory: {
          success: true,
          error: false,
          loading: false,
          newestCreated: payload.newestCreated,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        deleteCategory: {
          success: false,
          error: true,
          loading: false,
          ...payload,
        },
      };
    case ADMIN_CATEGORY_ACTION_TYPE.DELETE_CATEGORY_CLEAR:
      return {
        ...state,
        deleteCategory: {
          success: false,
          error: false,
          loading: false,
          editedScale: null,
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
