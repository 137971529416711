import React, { useState, createRef, useEffect } from "react";
import TopicCard from "./TopicCard";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import NotificationAlert from "react-notification-alert";
import { notify } from "./AlertNotify";
import { cmsAPI } from "../utils/http-client";
import Skeleton from "react-loading-skeleton";

export const SubjectTopicsList = (props) => {
  //subjects/id{topiclist
  const notificationAlert = createRef();
  const [usetTopicResponse, setUserTopic] = useState({});

  const [topics, setTopics] = useState([]);
  const [fetchingTopics, setTopicsLoading] = useState();
  const [topicsError, setTopicsError] = useState(false);

  const { subjectId } = props;
  const getSubjectTopics = (subjectId) => {
    setTopicsLoading(true);
    cmsAPI
      .get(`/topics?in_subjects=${subjectId}&_limit=9999`)
      .then((res) => {
        setTopics(res.data);
        setTopicsLoading(false);
        return;
      })
      .catch((err) => {
        //console.log("Topics Error>", err);
        setTopicsError(true);
        setTopicsLoading(false);
        return;
      });
  };
  const saveInMyFlashcards = (topicId) => {
    const data = { topicId: topicId };
    notify("primary", "Guardando en mis flashcards...", notificationAlert);
    cmsAPI
      .post(`/userflashcards/clone`, data)
      .then((res) => {
        //console.log("Saved in MyFlashcards", res);
        notify(
          "success",
          "Se ha guardado en Mis Flashcards",
          notificationAlert
        );
        setUserTopic(res.data);
      })
      .catch((err) => {
        //console.log("Error MyFlashcards", err.response);
        notify(
          "danger",
          "Ha ocurrido un error, intenta más tarde",
          notificationAlert
        );
        setUserTopic({});
      });
  };
  useEffect(() => {
    //console.log("Props.SUBJECTtoPICSLIST", props);
    //1s To prevent error while rendering
    setTimeout(getSubjectTopics(subjectId), 1000);
    //getSubjectTopics(subjectId);
  }, []);
  if (!topics && !fetchingTopics) {
    //console.log("requestError", topicsError);
    return (
      <div className="mt-6 text-center">
        <h3>
          {topicsError
            ? "Ha ocurrido un error al consultar los datos"
            : "No hay temas por mostrar, explora otras materias"}
        </h3>
        {topicsError ? (
          <button
            disabled={fetchingTopics}
            onClick={() => {
              getSubjectTopics(subjectId);
            }}
          >
            Volver a consultar
          </button>
        ) : (
          <Link to="/subjects" className="btn btn-outline-primary">
            Materias
          </Link>
        )}
      </div>
    );
  }
  if (fetchingTopics) {
    return <Skeleton height={150} count={3} />;
  }
  return (
    <>
      <NotificationAlert ref={notificationAlert} />
      <div className="col-md-12">
        {topics.map((topic, index) => (
          <>
            {index > 0 && <div className="separator-dashed" />}
            <TopicCard
              key={index}
              saved={true}
              inMyFlashcards
              key={topic.id}
              {...topic}
            />
          </>
        ))}
      </div>
    </>
  );
};
export default withRouter(SubjectTopicsList);
