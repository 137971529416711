import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { getUser } from "./lib/localstorage";
import { CookiesProvider } from "react-cookie";
import HomeView from "./views/index";
import RequestDataDeletionView from "./views/index/requestDataDeletion";
import LoginView from "./views/login";
import AppPromoView from "./views/download-app";
import FlashCardMatchView from "./views/flashcard-match";
import MyFlashCardsView from "./views/myflashcards";
import StartFlashCardsForm from "./views/myflashcards/StartFlashCardsForm";
import SubjectProfileView from "./views/subjectProfile";
import SignUpView from "./views/signup";
import ResetPasswordView from "./views/reset-password";
import MyProfileView from "./views/myprofile";
import ForgotPasswordView from "./views/forgot-password";
import CheckoutView from "./views/checkout";
import CreateFlashcardsView from "./views/createflashcards";
import EditFlashcardsView from "./views/editflashcards";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";
import CreateSubjectView from "./views/createsubject";
import CreateTopicView from "./views/createtopic";
import SuscriptionView from "./views/suscription";
import DashboardView from "./views/dashboard";
import TopicDetailsView from "./views/topicDetails";
import EmailConfirmedView from "./views/emailconfirmed";
import MySubjectsView from "./views/mysubjects";
import CategoryView from "./views/category";
import ProfileView from "./views/profile";
import AdminDashboard from "./views/AdminViews/admin-dashboard";
import SubjectAdminListView from "./views/AdminViews/subject-list";
import FlashcardsPreviewView from "./views/flashcards-preview";
import SimpleReactLightbox from "simple-react-lightbox";
import StudyView from "./views/study";
import AdminUserScales from "./views/AdminViews/admin-userscales";
import { adminUserScalesInitialState } from "./views/AdminViews/admin-userscales/reducer";
import AdminSubCateogryUserScales from "./views/AdminViews/admin-subcategoryscales";
import { adminSubcategoryScalesInitialState } from "./views/AdminViews/admin-subcategoryscales/reducer";
import AdminSubcategories from "./views/AdminViews/admin-subcategories";
import { adminSubcategoryInitialState } from "./views/AdminViews/admin-subcategories/reducer";
import AdminCategories from "./views/AdminViews/admin-categories";
import { adminCategoryInitialState } from "./views/AdminViews/admin-categories/reducer";
import PrivacyNotice from "./views/index/privacyPolicy";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
//const stripePromise = loadStripe("pk_test_51Gy2rxFmrr9LWiixblgd4Pa0PtVHLvc37daIxZLDXuHHdi3ZOwDzcHVkDaelgU0aMG6pbE00XF7r0AKydrXREiHy00e5Ts4rqA");
function App() {
  Moment.globalMoment = moment;
  Moment.globalLocale = "es";
  const initialState = {
    user: getUser(),
    adminUserScales: adminUserScalesInitialState,
    adminSubcategoryScales: adminSubcategoryScalesInitialState,
    adminSubcategories:adminSubcategoryInitialState,
    adminCategories:adminCategoryInitialState
  };
  const store = configureStore(initialState);
  return (
    <CookiesProvider>
      {/* <Elements stripe={stripePromise}> */}
      <SimpleReactLightbox>
        <Provider store={store}>
          <Router>
            <Switch>
              {/* <PrivateRoute path="/checkout" component={CheckoutView} /> */}
              <PrivateRoute path="/profile/v2" component={ProfileView} />
              <PrivateRoute path="/profile" exact component={MyProfileView} />
              {/* <PrivateRoute path="/suscription" component={SuscriptionView} /> */}
              <PrivateRoute
                path="/myflashcards/create/f/:topicId"
                component={CreateFlashcardsView}
              />
              <PrivateRoute
                path="/myflashcards/create/t/:subjectId"
                component={CreateTopicView}
              />
              <PrivateRoute
                path="/myflashcards/create/"
                exact
                component={CreateSubjectView}
              />
              <PrivateRoute
                path="/myflashcards/edit/:userTopicId"
                component={EditFlashcardsView}
              />
              <PrivateRoute
                path="/myflashcards/match/start/:userTopicId"
                component={FlashCardMatchView}
              />
              <PrivateRoute
                path="/myflashcards/match/:userTopicId"
                component={StartFlashCardsForm}
              />
              <PrivateRoute
                path="/myflashcards"
                exact
                component={MyFlashCardsView}
              />
              {/* STUDY */}
              <PrivateRoute path="/study/:topicId" component={StudyView} />
              {/* FLASHCARDS */}
              <PrivateRoute
                path="/flashcards-preview/:topicId"
                component={FlashcardsPreviewView}
              />
              {/* FLASHCARDS */}
              {/* SUBJECTS */}
              <PrivateRoute
                path="/subjects/:subjectId/edit/:topicId"
                component={EditFlashcardsView}
              />
              <PrivateRoute
                path="/subjects/:subjectId/t/:topicId"
                component={TopicDetailsView}
              />
              <PrivateRoute
                path="/subjects/:subjectId"
                component={SubjectProfileView}
              />
              {/* <PrivateRoute path="/subjects" component={SubjectsView} /> */}
              {/* SUBJECTS */}
              {/* ADMIN */}
              <PrivateRoute path="/dashboard" component={DashboardView} />
              <PrivateRoute
                path="/admin/dashboard"
                component={AdminDashboard}
              />
              <PrivateRoute
                path="/admin/content"
                component={SubjectAdminListView}
              />
              <PrivateRoute
                path="/admin/userscales"
                component={AdminUserScales}
              />
              <PrivateRoute
                path="/admin/subcategories"
                component={AdminSubcategories}
              />
              <PrivateRoute
                path="/admin/categories"
                component={AdminCategories}
              />
              <PrivateRoute
                path="/admin/subcategory-scales"
                component={AdminSubCateogryUserScales}
              />
              <Route path="/c/:categorySlug" component={CategoryView} />
              {/* ADMIN */}
              {/* AUTH */}
              <Route path="/forgot-password" component={ForgotPasswordView} />
              <Route path="/reset-password" component={ResetPasswordView} />
              <Route path="/signup" component={SignUpView} />
              <Route path="/login" component={LoginView} />
              <Route exact path="/" component={HomeView} />
              {/* AUTH */}
              <Route path="/download-app" component={AppPromoView} />
              <Route path="/email-confirmed" component={EmailConfirmedView} />
              <Route exact path="/request-data-deletion" component={RequestDataDeletionView} />
              <Route exact path="/privacy-policy" component={PrivacyNotice} />
              <Route exact path="/" component={HomeView} />
              <PrivateRoute
                path="*"
                component={() => <Redirect to="/dashboard"></Redirect>}
              />
            </Switch>
          </Router>
        </Provider>
      </SimpleReactLightbox>
      {/* </Elements> */}
    </CookiesProvider>
  );
}

export default App;
